/* eslint-disable no-undef */
import React, { useContext, useState, ChangeEvent } from 'react';
import { Col, Row, Divider } from 'antd';
import { ReactComponent as SearchIcon } from '../../../assets/image/svg/search-icon.svg';
import { ReactComponent as BackArrow } from '../../../assets/image/svg/arrow-up-short.svg';

import { Title } from 'components/styles';
import { HeaderStyled } from './Header.styled';

import { ModalCustom } from 'components/modal';
import { ModalTypeEnum } from 'components/modal/ModalCustom';
import { useHistory, useParams } from 'react-router-dom';
import { capitalize } from 'utils/helpers/functions';
import { ArchivesContext } from 'state/archives/archivesContext';
import SiderMobile from 'components/sider/SiderMobile';

type HeaderPropType = {
  title?: string;
  /*eslint-disable */
  type?: any;
  setSearchState: (e: ChangeEvent<HTMLInputElement>) => void;
  searchState: string;
};

type RouteParamsType = {
  type: 'user' | 'archive';
  companySlug?: string;
  websiteSlug?: string;
};

const Header = ({ setSearchState, searchState }: HeaderPropType) => {
  const [modalType, setModalType] = useState(ModalTypeEnum.addCompany);
  const [modalVisible, setModalVisible] = useState(false);
  const history = useHistory();
  const { headerName } = useContext(ArchivesContext);

  const { type, companySlug } = useParams<RouteParamsType>();

  const toggleModal = (type: ModalTypeEnum) => {
    setModalType(type);
    setModalVisible(!modalVisible);
  };

  const renderSearchText = () =>
    type === 'user' ? 'Search by name, email or company' : 'Search by company or website';

  return (
    <HeaderStyled span={24}>
      <Row justify={'space-between'} align="bottom" className={'top__title'}>
        <div className="headerWrapper">
          <Title.H2 fontSize={36} className={'title-text'}>
            {companySlug && capitalize(`Archived ${headerName}`)}
            {!companySlug && capitalize('Archived Companies')}
            {/* {capitalize(title!)} */}
            <div className={companySlug && 'border'}></div>
          </Title.H2>
          {companySlug && (
            <span onClick={() => history.goBack()}>
              <BackArrow />
              <p>Back</p>
            </span>
          )}
        </div>
      </Row>
      <Divider style={{ margin: '24px 0 0' }} />

      <SiderMobile style={{ marginTop: '30px', marginBottom: '15px' }} />

      <Col span={24} className={'top__title-and-search-wrapper'}>
        <Row justify={'space-between'} align="middle" className={'top__title-and-search'}>
          <div className={'search-container flex flex-center'}>
            <div className={'searchInput'}>
              <input
                type="text"
                onChange={(e) => setSearchState(e)}
                value={searchState}
                placeholder={renderSearchText()}
              />
              <SearchIcon />
            </div>
          </div>
        </Row>
      </Col>
      <ModalCustom
        visible={modalVisible}
        onCancel={toggleModal.bind(Header, modalType)}
        width={'80%'}
        centered={true}
        type={modalType}
        data={{
          onClose: () => {
            toggleModal.call(Header, modalType);
          },
        }}
      />
    </HeaderStyled>
  );
};

export default React.memo(Header);
