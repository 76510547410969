import * as Api from 'api';
import React, { useContext, useEffect } from 'react';
import { SiderNavigationContext } from 'state/sider/navigation/siderNavigationContext';
import { SiderNavigationStyled } from './SiderNavigation.styled';
import { useHistory } from 'react-router';
import { ajax } from 'services';
import { Skeleton } from 'antd';
import Companies from './components/Companies';

interface SiderNavigationProps {
  readonly collapsed: boolean;
  readonly filteredCompanies: ICompany[];
  readonly companySearchKey?: string;
}

const SiderNavigation = ({
  collapsed,
  filteredCompanies,
  companySearchKey,
}: SiderNavigationProps) => {
  const history = useHistory();
  const { siderActions, companiesLoading, setCompaniesLoading } =
    useContext(SiderNavigationContext);
  const [currentUrl, setCurrentUrl] = React.useState('');
  const [show, setShow] = React.useState(true);

  useEffect(() => {
    if (!collapsed) {
      setTimeout(() => {
        setShow(true);
      }, 100);
    } else {
      setShow(false);
    }
  }, [collapsed]);

  useEffect(() => {
    history.listen((location, action) => {
      if (action === 'PUSH') {
        setCurrentUrl(location.pathname);
      }
    });

    ajax
      .get(Api.endpoints.siderAllCompanies)
      .then((response) => {
        siderActions.setCompanies(response.data);
      })
      .then(() => {
        setCompaniesLoading(false);
      });
  }, []);

  return (
    <SiderNavigationStyled className="sider-navigation">
      <div className="wrapper" style={show ? { display: 'block' } : { display: 'none' }}>
        {companiesLoading ? (
          <Skeleton active title={false} paragraph={{ rows: 4, width: '100%' }} />
        ) : (
          <Companies
            companySearchKey={companySearchKey}
            filteredCompanies={filteredCompanies}
            currentUrl={currentUrl}
          />
        )}
      </div>
    </SiderNavigationStyled>
  );
};

export default SiderNavigation;
