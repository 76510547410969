import React, { useContext, useEffect, useState } from 'react';
import { StarterHeaderStyled } from './StarterHeader.styled';
import { AppContext } from 'state/appContext';
import * as Api from 'api';
import { Skeleton } from 'antd';
import AddButtonsMobile from 'components/baseComponents/AddButtonsMobile';

export default function StarterHeader() {
  const {
    state: { user },
  } = useContext(AppContext);

  const [isLoading, setIsLoading] = useState(true);
  const [stats, setStats] = useState(null);

  useEffect(() => {
    Api.getStorylineStats().then((res) => {
      setStats(res.data.data);
      setIsLoading(false);
    });
  }, []);

  return (
    <StarterHeaderStyled>
      {user && stats ? (
        <>
          <div>
            <h1>{`Hello ${user.first_name}, welcome back!`}</h1>
            <div className="stats-container">
              <div className="stats-box">
                <p>Companies</p>
                <span>{stats?.['active-companies']}</span>
              </div>
              <div className="stats-box">
                <p>Storyline in progress</p>
                <span>{stats?.['active-slides']}</span>
              </div>
              <div className="stats-box">
                <p>Finished Storyline</p>
                <span>{stats?.['expired-slides']}</span>
              </div>
            </div>
          </div>
        </>
      ) : (
        <Skeleton paragraph={{ rows: 1 }} active />
      )}
      <AddButtonsMobile />
    </StarterHeaderStyled>
  );
}
