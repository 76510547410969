import React from 'react';
import { Switch, Route, BrowserRouter, Redirect } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { AuthContainerRouter } from './authContainer';
import Users from './user/UsersPage';
import UserDetails from './user/UserDetails';
import UserProfile from './user/UserProfile';
import Archive from './archives/archivePage';
import ArchiveProduct from './archives/archiveProductPage';
import ArchiveWebsite from './archives/archiveWebsitePage';
import StarterContainer from './starterContainer';
import { routesPaths } from 'utils/helpers';
import { PrivateRoute } from 'components/common/userMove';
import { SetPasswordPageLazy } from './authContainer/containers';
import { AddCompanyPageLazy } from './authContainer/containers';
import { InviteUsersPageLazy } from './authContainer/containers';
import LandingPage from './landingPage/LandingPage';
import FaqContainer from './faqContainer/FaqContainer';
import { StatisticPageWebsiteProvider } from 'state/productContainer/statisticPage/website/statisticPageWebsiteContext';
import { StatisticPageCompanyProvider } from 'state/productContainer/statisticPage/company/statisticPageCompanyContext';
import PrivacyPolicy from './dashboardLayout/containers/documentPages/PrivacyPolicy';
import TermsAndConditions from './dashboardLayout/containers/documentPages/TermsAndConditions';
import FrequentQuestions from './dashboardLayout/containers/documentPages/FrequentQuestions';
import { SearchContainerProvider } from 'state/searchContainer/SearchContainerContext';
import { BaseRightDrawerProvider } from 'state/baseComponents/BaseRightDrawerContext';
import { ArchivesProvider } from 'state/archives/archivesContext';
import { ParamsContextProvider } from 'state/params/ParamsContext';
import { ListPageProvider } from 'state/productContainer/listPage/listPageContext';
import LoginRegister from './authContainer/containers/loginRegister/LoginRegister';
import statisticPageWrapper from './productContainer/containers/statisticPage/StatisticPageWrapper';
import listPageWrapper from './productContainer/containers/listPage/ListPageWrapper';

function Routes() {
  return (
    <BrowserRouter>
      <ParamsContextProvider>
        <ListPageProvider>
          <BaseRightDrawerProvider>
            <SearchContainerProvider>
              <StatisticPageWebsiteProvider>
                <StatisticPageCompanyProvider>
                  <ArchivesProvider>
                    <QueryParamProvider ReactRouterRoute={Route}>
                      <Switch>
                        <Route exact path={'/'}>
                          <LandingPage />
                        </Route>

                        <Route exact path={'/faq'}>
                          <FaqContainer />
                        </Route>
                        <Route exact path={'/add-company'}>
                          <AuthContainerRouter>
                            <AddCompanyPageLazy />
                          </AuthContainerRouter>
                        </Route>
                        <Route exact path={'/invite-users'}>
                          <AuthContainerRouter>
                            <InviteUsersPageLazy />
                          </AuthContainerRouter>
                        </Route>
                        <Route
                          exact
                          path={'/:authType(login|register)'}
                          component={LoginRegister}
                        />
                        <Route path={'/password'}>
                          <AuthContainerRouter>
                            <SetPasswordPageLazy />
                          </AuthContainerRouter>
                        </Route>

                        <PrivateRoute path={'/frequent-questions'} component={FrequentQuestions} />

                        <PrivateRoute path={'/privacy-policy'} component={PrivacyPolicy} />

                        <PrivateRoute path={'/terms-of-use'} component={TermsAndConditions} />

                        <PrivateRoute
                          exact
                          path={'/company/:companySlug'}
                          component={statisticPageWrapper}
                        />
                        <PrivateRoute
                          exact
                          path={'/company/:companySlug/:websiteSlug/'}
                          component={statisticPageWrapper}
                        />
                        <PrivateRoute
                          exact
                          path={'/company/:companySlug/:websiteSlug/:productSlug/list'}
                          component={listPageWrapper}
                        />

                        <PrivateRoute exact path="/user" component={Users} />
                        <PrivateRoute exact path="/user/profile" component={UserProfile} />
                        <PrivateRoute exact path="/user/:companySlug/:id" component={UserDetails} />

                        <PrivateRoute exact path="/archive" component={Archive} />
                        <PrivateRoute
                          exact
                          path="/archive/:companySlug"
                          component={ArchiveWebsite}
                        />
                        <PrivateRoute
                          exact
                          path="/archive/:companySlug/:websiteSlug"
                          component={ArchiveProduct}
                        />

                        <PrivateRoute path={routesPaths.home} component={StarterContainer} />

                        <Redirect path="*" to="/" />
                      </Switch>
                    </QueryParamProvider>
                  </ArchivesProvider>
                </StatisticPageCompanyProvider>
              </StatisticPageWebsiteProvider>
            </SearchContainerProvider>
          </BaseRightDrawerProvider>
        </ListPageProvider>
      </ParamsContextProvider>
    </BrowserRouter>
  );
}

export default Routes;
