import React from 'react';

const DeleteIcon = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15.556"
      height="20"
      viewBox="0 0 15.556 20"
      {...props}
    >
      <g id="delete_outline-24px" transform="translate(-5 -3)">
        <path
          id="Path_166"
          data-name="Path 166"
          d="M6.111,20.778A2.229,2.229,0,0,0,8.333,23h8.889a2.229,2.229,0,0,0,2.222-2.222V7.444H6.111ZM8.333,9.667h8.889V20.778H8.333Zm8.333-5.556L15.556,3H10L8.889,4.111H5V6.333H20.556V4.111Z"
          transform="translate(0 0)"
        />
      </g>
    </svg>
  );
};

export default DeleteIcon;
