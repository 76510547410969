import { useContext, useEffect, useMemo, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { ajax } from 'services';

import { useHistory } from 'react-router-dom';
import { routesPaths, accordingRoles } from 'utils/helpers';
import endpoints from 'services/endpoints';
import { addUserToCompany, getCompaniesWithWebsites } from 'services';
import { validateAxiosErrorInForms, isOneOf, isEqualArray } from 'utils/helpers/functions';
import { InvitedUserRoles, UserNames } from 'utils/constants';
import { message } from 'antd';
import { mutate } from 'swr';
import { AppContext } from '../../../../state/appContext';

type UseUserAddFormPropsType = {
  setUsersCount: (count: number) => void;
  fromModal?: boolean;
  onClose?: () => void;
  CSVData: any[];
};

export const rowFields = {
  email: '',
  first_name: '',
  last_name: '',
  type: '',
};

const useUserAddForm = ({
  setUsersCount,
  fromModal,
  onClose,
  CSVData,
}: UseUserAddFormPropsType) => {
  const { replace } = useHistory();

  const { data: companiesWithWebsite } = getCompaniesWithWebsites();

  // const { data: websites } = useGetAllWebsites();
  const [loading, setLoading] = useState(false);
  const [isWebsiteAdmin, setIsWebsiteAdmin] = useState(false);
  const [websitesData, setWebsitesData] = useState<null | WebsiteType[]>(null);
  const [companiesData, setCompaniesData] = useState<CompanyWithWebsite[]>([]);
  const [roles, setRoles] = useState<RolesTypes[]>([]);

  const { state } = useContext(AppContext);

  const isCompanyAdmin: boolean | undefined = state?.user?.role?.includes('Company Admin');

  const arrayFieldsSchema = Yup.array<InviteUserFormType>().of(
    Yup.object().shape({
      first_name: Yup.string()
        .required('The field is required (min 3 char.)')
        .min(3, 'Min length should be 3 characters'),
      last_name: Yup.string()
        .required('The field is required (min 3 char.)')
        .min(3, 'Min length should be 3 characters'),
      email: Yup.string()
        .email('Is not a valid email')
        .required('The field is required (min 3 char.)')
        .min(3, 'Min length should be 3 characters'),
      type: Yup.string().required('Role is required'),
    }),
  );

  const websiteSchema = isWebsiteAdmin
    ? Yup.string().required('Please choose a valid websites')
    : Yup.string().notRequired();

  const schema = Yup.object().shape({
    company_slug: Yup.string().required(),
    website_slug: websiteSchema,
    people: arrayFieldsSchema,
  });

  const { handleSubmit, control, errors, watch, setValue, setError } = useForm<InviteUsersFormType>(
    {
      resolver: yupResolver(schema),
      reValidateMode: 'onChange',
      mode: 'onSubmit',
      shouldFocusError: true,
      defaultValues: {
        company_slug: '',
        people: [rowFields],
      },
    },
  );

  const watchPeople = watch(['people']);
  const watchCompany = watch(['company_slug']);
  const watchWebsite = watch(['website_slug']);

  const { setValue: fakeSetValue } = useForm();

  const { fields, append, remove } = useFieldArray<InviteUserFormType>({
    control,
    name: 'people',
  });

  useEffect(() => {
    if (isWebsiteAdmin) {
      ajax.get(endpoints.getWebsitesOfCompany(watchCompany.company_slug)).then((data) => {
        setWebsitesData(data.data.data as WebsiteType[]);
      });
      if (
        watchCompany.company_slug !== companiesWithWebsite?.data[0].slug &&
        watchWebsite.website_slug
      )
        setValue('website_slug', '');
    }
  }, [watchCompany.company_slug, isWebsiteAdmin]);

  useEffect(() => {
    if (companiesData?.length) {
      const chosenCompany = companiesData.find((c) => c.slug == watchCompany.company_slug);
      if (isCompanyAdmin) setRoles(accordingRoles(2));
      else setRoles(accordingRoles(Number(chosenCompany?.hasWebsites)));
    }
  }, [watchCompany, companiesData]);

  useEffect(() => {
    for (let i = 0; i < watchPeople.people.length; i++) {
      if (watchPeople.people[i].type === '4' || watchPeople.people[i].type === 'Website Admin') {
        setIsWebsiteAdmin(true);
        break;
      } else if (isWebsiteAdmin) {
        setIsWebsiteAdmin(false);
      }
    }
  }, [watchPeople.people]);

  useEffect(() => {
    setUsersCount(fields.length - (errors.people?.length ?? 0));

    return () => {
      setUsersCount(-1);
    };
  }, [fields, errors]);

  useEffect(() => {
    if (!CSVData.length) return;
    const users: InviteUserFormType[] = [];
    if (!isEqualArray(CSVData[0], UserNames)) {
      message.error('Please upload correct type CSV');
      return;
    }
    CSVData.forEach((val, index) => {
      if (!index) return;
      if (isCompanyAdmin) {
        if (val[3] === 'Company Admin') return;
      }
      if (val[index]?.length < 1) return;
      if (!isOneOf(val[3], InvitedUserRoles)) return;
      if (val[3] === 'Website Admin') setIsWebsiteAdmin(true);
      users.push({
        first_name: val[0],
        last_name: val[1],
        email: val[2],
        type: val[3],
      });
    });
    remove();
    requestAnimationFrame(() => {
      append(users);
    });
  }, [CSVData, roles]);

  useEffect(() => {
    companiesWithWebsite?.data[0] && setValue('company_slug', companiesWithWebsite?.data[0].slug);
    setCompaniesData(companiesWithWebsite! && companiesWithWebsite.data!);
  }, [companiesWithWebsite, setValue]);

  useEffect(() => {
    if (roles[0]) {
      !CSVData.length &&
        setValue('people', [
          {
            type: roles[0].id.toString(),
          },
        ]);

      fakeSetValue('people[0].type', roles[0].id.toString());
    }
  }, [roles, setValue]);

  const getCompanies: SelectItemType[] = useMemo(
    () =>
      companiesData?.map(({ name, slug }) => ({
        label: name,
        value: slug,
      })) ?? [],
    [companiesData],
  );
  const getWebsites: SelectItemType[] = useMemo(
    () =>
      websitesData?.map(({ name, slug }: NameSlugType) => ({
        label: name,
        value: slug,
      })) ?? [],
    [websitesData],
  );

  const getRoles: SelectItemType[] = useMemo(
    () =>
      roles.map(({ name, id }) => ({
        label: name,
        value: id.toString(),
      })) ?? [],
    [companiesData, watchCompany],
  );

  const onSkip = () => {
    replace(routesPaths.home);
  };

  const onSubmit = async (data: InviteUsersFormType) => {
    try {
      setLoading(true);
      await addUserToCompany(data);
      message.success('Invitations were sent');
      if (fromModal) {
        onClose?.();
      } else {
        onSkip();
      }
      mutate(endpoints.getAllUsers);
    } catch (error) {
      validateAxiosErrorInForms<InviteUsersFormType>(error, setError);
    } finally {
      setLoading(false);
    }
  };

  return {
    handleSubmit,
    control,
    errors,
    onSubmit,
    onSkip,
    getCompanies,
    getWebsites,
    fields,
    getRoles,
    loading,
    isWebsiteAdmin,
  };
};

export default useUserAddForm;
