import React from 'react';
interface MediumArrowIconProps {
  readonly width?: string;
  readonly height?: string;
  readonly fill?: string;
  readonly style?: React.CSSProperties;
}

function MediumArrowIcon({ width, height, fill, style }: MediumArrowIconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 15.752 15.753"
      style={style}
    >
      <g id="arrow-up-short" transform="translate(15.752) rotate(90)">
        <path
          id="Path_278"
          data-name="Path 278"
          d="M1.125,13.5A1.125,1.125,0,0,0,2.25,12.375V1.125a1.125,1.125,0,1,0-2.25,0v11.25A1.125,1.125,0,0,0,1.125,13.5Z"
          transform="translate(6.752 0)"
          fill="#01ae92"
          fillRule="evenodd"
        />
        <path
          id="Path_279"
          data-name="Path 279"
          d="M7.08,8.673a1.125,1.125,0,0,0,1.593,0l6.75-6.75A1.126,1.126,0,1,0,13.83.33L7.877,6.286,1.923.331A1.126,1.126,0,0,0,.33,1.924l6.75,6.75Z"
          transform="translate(0 6.748)"
          fill={fill}
          fillRule="evenodd"
        />
      </g>
    </svg>
  );
}

export default MediumArrowIcon;
