import React, { useEffect, useState } from 'react';
import { BaseFloatingLabelStyled } from './BaseFloatingLabel.styled';

interface Props {
  label: string;
  value: string | number | readonly string[] | undefined;
  children: React.ReactNode;
  textAreaType?: boolean;
  defaultValue?: boolean;
  characterCount?: boolean;
  select?: boolean;
}

const BaseFloatingLabel = ({
  children,
  label,
  value,
  textAreaType,
  defaultValue,
  characterCount,
  select,
}: Props) => {
  const [focus, setFocus] = useState(false);

  let labelClass =
    focus || (value && value != null && (value as string).length !== 0)
      ? 'label label-float'
      : 'label';

  if (defaultValue) labelClass = 'label label-float';

  return (
    <BaseFloatingLabelStyled
      onBlur={() => !defaultValue && setFocus(false)}
      onFocus={() => setFocus(true)}
      $textAreaType={textAreaType}
      $characterCount={characterCount}
      select={select}
    >
      {children}
      <label className={labelClass}>{label}</label>
    </BaseFloatingLabelStyled>
  );
};

export default BaseFloatingLabel;
