import styled from 'styled-components';
import { ReactComponent as ArrowSelectTree } from 'assets/image/svg/arrow-select-tree.svg';

export const SiderNavigationStyled = styled.div`
  padding: 0 20px;
  flex: 1;
  margin: 0;
  .wrapper {
    background-color: transparent;
    .website-skeleton,
    .product-skeleton {
      padding-left: 39px !important;
    }
    .not-found {
      display: flex;
      align-items: center;
      gap: 2px;
      font-size: 0.725rem;
      color: rgba(125, 140, 162, 0.5);
      margin-left: 1.725rem;
    }
    .list {
      position: unset;
      .company {
        margin-bottom: 20px;
        justify-content: space-between;
        color: #808e9b;
        p {
          overflow-wrap: anywhere;
        }
      }
      .website {
        padding-left: 10px !important;
        gap: 10px;
        img {
          margin-top: -5px;
        }
        p {
          font-size: 16px !important;
          color: #181818;
          overflow-wrap: anywhere;
        }
      }
      .product {
        padding-left: 30px !important;
        p {
          gap: 10px;
          font-size: 16px !important;
          img {
            margin-top: 2px;
          }
        }
      }
      .company,
      .website,
      .product {
        background-color: transparent !important;
        display: flex !important;
        align-items: center !important;
        p {
          font-size: 18px;
          font-family: 'Noir Pro Regular';
          position: relative;
          letter-spacing: 0px;
          line-height: 1.8rem;
          border-radius: 5px;
          width: auto;
          align-items: flex-start;
          display: flex;
          padding: 0.3rem 0;
          cursor: pointer;
        }
      }
      .website,
      .product {
        p {
          font-family: 'Noir Pro Regular';
          position: relative;
          letter-spacing: 0px;
          line-height: 1.8rem;
          font-weight: normal;
        }
      }
      .arrow-div {
        cursor: pointer;
        min-width: 30px;
        height: 30px;
        width: 30px;
        border-radius: 4px;
        border: 1px solid #9fa2af4d;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
`;

export const ArrowSelectTreeStyled = styled(ArrowSelectTree)`
  display: inline-block;
  margin: 0 !important;
  margin-top: auto;
  margin-bottom: auto;
`;
