import { useCallback, useMemo, useState } from 'react';
import { listUsers } from 'services';

export enum TableHeadEnum {
  UserName = 'User Name',
  Company = 'Company',
  // Role = 'Role',
  Settings = 'Settings',
}

const useUsersPage = () => {
  const [activeHead, setActiveHead] = useState(TableHeadEnum.UserName);
  const [sortingOrderIsAsc, setSortingOrderIsAsc] = useState<boolean>(false);
  const { data } = listUsers();

  const allUsers: ListUserDataType[] = data ? data.data : [];

  const sortedProductItems = useMemo(() => {
    //let currentSortKey: keyof typeof allUsers[0] = 'id';
    let currentSortKey: keyof ListUserDataType = 'id';
    switch (activeHead) {
      case TableHeadEnum.UserName:
        currentSortKey = 'first_name';
        break;
      case TableHeadEnum.Company:
        currentSortKey = 'company_name';
        break;
      // case TableHeadEnum.Role:
      //   currentSortKey = 'role';
      //   break;
      default:
        currentSortKey = 'first_name'; //on settings click just filter by first_name
        //currentSortKey = 'id';
        break;
    }

    // const sort = (a: typeof allUsers[0], b: typeof allUsers[0])
    const sortFunct = (a: ListUserDataType, b: ListUserDataType) => {
      if (typeof a[currentSortKey]! === 'string') {
        // @ts-ignore: Unreachable code error
        if (a[currentSortKey]!.toLowerCase() > b[currentSortKey]!.toLowerCase())
          return sortingOrderIsAsc ? 1 : -1;
        // @ts-ignore: Unreachable code error
        else if (a[currentSortKey]!.toLowerCase() < b[currentSortKey]!.toLowerCase())
          return sortingOrderIsAsc ? -1 : 1;
        else return 0;
      }
      if (a[currentSortKey]! > b[currentSortKey]!) return sortingOrderIsAsc ? 1 : -1;
      else if (a[currentSortKey]! < b[currentSortKey]!) return sortingOrderIsAsc ? -1 : 1;
      else return 0;
    };

    const sortedItems = allUsers?.sort(sortFunct);

    return sortedItems;
  }, [activeHead, sortingOrderIsAsc, allUsers]);

  const onSelectSort = useCallback(
    (val: TableHeadEnum) => {
      if (activeHead.toLowerCase() === val.toLowerCase()) {
        setSortingOrderIsAsc(!sortingOrderIsAsc);
      } else {
        setActiveHead(val);
        setSortingOrderIsAsc(false);
      }
    },
    [setSortingOrderIsAsc, sortingOrderIsAsc, activeHead, setActiveHead],
  );

  return {
    activeHead,
    onSelectSort,
    sortedProductItems,
    sortingOrderIsAsc,
  };
};

export default useUsersPage;
