import React from 'react';
import { SkippedAddButtonStyled } from './SkippedAddButton.styled';
import { AddIcon } from 'components/svg';

interface IProps {
  readonly text: string;
  readonly span: string;
  readonly onClick?: () => void;
}

const SkippedAddButton = ({ text, span, onClick }: IProps) => {
  return (
    <SkippedAddButtonStyled onClick={onClick}>
      <AddIcon fill={'#808E9B'} stroke={'#808E9B'} />
      <span>{span}</span> <p>{text}</p>
    </SkippedAddButtonStyled>
  );
};

export default SkippedAddButton;
