// Images

import storyHubBgr from './images/storyHubBgr.jpg';
import FRTLogo from './svg/FRT-logo.svg';
import GradientHome from './icons/gradientMask.jpg';
import Story1 from './images/story1.png';
import Story2 from './images/story2.png';
import leftBlock from './images/leftBlock.png';
import rightBlock from './images/rightBlock.png';
import tutorial from './images/tutorial.png';
import laptop from './images/laptop.png';
import cloudDesktop from './images/cloud-desktop.png';
import thumbnail from './images/tutorial-thumbnail.png';
import faqThumbnail from './images/faq-thumbnail.png';
import businessStructure from './images/business-structure-faq.png';
import greenRectangle from './images/green-rectangle.png';
import profileImage from './images/profile-image.png';

import AddCompany from './images/landing-add-company-icon.png';
import AddSite from './images/landing-add-site-icon.png';
import AddStoryline from './images/landing-add-storyline-icon.png';

// SVGs
import Picon1 from './svg/purpose-icon1.svg';
import Picon2 from './svg/purpose-icon2.svg';
import Picon3 from './svg/purpose-icon3.svg';
import Picon4 from './svg/purpose-icon4.svg';
import Picon5 from './svg/purpose-icon5.svg';
import Ficon1 from './svg/feature-icon1.svg';
import Ficon2 from './svg/feature-icon2.svg';
import Ficon3 from './svg/feature-icon3.svg';
import Ficon4 from './svg/feature-icon4.svg';
import Ficon5 from './svg/feature-icon5.svg';
import Ficon6 from './svg/feature-icon6.svg';
import scrollArrow from './svg/scroll-arrow.svg';
import slogan1 from './svg/slogan1.svg';
import slogan2 from './svg/slogan2.svg';
import slogan3 from './svg/slogan3.svg';
import slogan4 from './svg/slogan4.svg';
import greenTick from './svg/green-tick.svg';
import plus from './svg/orange-plus.svg';
import plus2 from './svg/orange-plus2.svg';
import disclaimer from './svg/disclaimer.svg';
import logo from './svg/logo.svg';
import copyright from './svg/copyright.svg';
import faqPlus from './svg/faq-plus.svg';
import faqMinus from './svg/faq-minus.svg';
import chatFill from './svg/chat-fill.svg';
import faqBullet from './svg/faq-bullet.svg';
import addUserIcon from './svg/add-user-icon.svg';
import userManagementIcon from './svg/user-management-icon.svg';
import burgerIcon from './svg/burger-icon.svg';
import searchIcon from './svg/feather-search.svg';
import logoutIcon from './svg/logout-icon.svg';
import arrowRight from './svg/arrow-right.svg';
import arrowLeft from './svg/arrow-left.svg';
import walkThroughArrow from './svg/walkthrough-arrow.svg';
import addStoryIcon from './svg/share-your-story-add-icon.svg';
import emailIcon from './svg/email-icon.svg';

export default {
  storyHubBgr,
  FRTLogo,
  GradientHome,
  Story1,
  Story2,
  AddCompany,
  AddSite,
  AddStoryline,
  leftBlock,
  rightBlock,
  tutorial,
  laptop,
  cloudDesktop,
  thumbnail,
  businessStructure,
  greenRectangle,
  faqThumbnail,
  profileImage,
};

export const svg = {
  Picon1,
  Picon2,
  Picon3,
  Picon4,
  Picon5,
  Ficon1,
  Ficon2,
  Ficon3,
  Ficon4,
  Ficon5,
  Ficon6,
  scrollArrow,
  slogan1,
  slogan2,
  slogan3,
  slogan4,
  greenTick,
  plus,
  plus2,
  disclaimer,
  logo,
  copyright,
  faqMinus,
  faqPlus,
  chatFill,
  faqBullet,
  userManagementIcon,
  addUserIcon,
  burgerIcon,
  searchIcon,
  logoutIcon,
  arrowLeft,
  arrowRight,
  walkThroughArrow,
  addStoryIcon,
  emailIcon,
};
