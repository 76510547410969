import React from 'react';

export const UserManagementIcon = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg
      id="Component_42_10"
      data-name="Component 42 – 10"
      xmlns="http://www.w3.org/2000/svg"
      width="50"
      height="50"
      viewBox="0 0 50 50"
      {...props}
    >
      <g
        id="Ellipse_41"
        data-name="Ellipse 41"
        fill={props.fill || '#fff'}
        stroke={props.stroke || 'rgba(159,162,175,0.3)'}
        strokeWidth="2"
      >
        <circle cx="25" cy="25" r="23" stroke="none" />
        <circle cx="25" cy="25" r="24" fill="none" />
      </g>
      <path
        id="Icon_awesome-users"
        data-name="Icon awesome-users"
        d="M4.286,10.821A2.857,2.857,0,1,0,1.429,7.964,2.86,2.86,0,0,0,4.286,10.821Zm20,0a2.857,2.857,0,1,0-2.857-2.857A2.86,2.86,0,0,0,24.286,10.821Zm1.429,1.429H22.857a2.849,2.849,0,0,0-2.013.83A6.53,6.53,0,0,1,24.2,17.964h2.946a1.427,1.427,0,0,0,1.429-1.429V15.107A2.86,2.86,0,0,0,25.714,12.25Zm-11.429,0a5,5,0,1,0-5-5A5,5,0,0,0,14.286,12.25Zm3.429,1.429h-.371a6.9,6.9,0,0,1-6.116,0h-.371a5.144,5.144,0,0,0-5.143,5.143v1.286A2.143,2.143,0,0,0,7.857,22.25H20.714a2.143,2.143,0,0,0,2.143-2.143V18.821A5.144,5.144,0,0,0,17.714,13.679Zm-9.987-.6a2.849,2.849,0,0,0-2.013-.83H2.857A2.86,2.86,0,0,0,0,15.107v1.429a1.427,1.427,0,0,0,1.429,1.429H4.371A6.546,6.546,0,0,1,7.728,13.08Z"
        transform="translate(11 13.25)"
        fill="#4b4dc8"
      />
    </svg>
  );
};
