import styled from 'styled-components';

export const NavigationMenuStyled = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  .navigation-menu_header {
    background-color: white;
    border-radius: 10px;
    display: flex;
    padding: 30px;

    img {
      border-radius: 10px;
    }

    p {
      font-family: Noir Pro Light;
      font-size: 16px;
    }

    span {
      font-family: Noir Pro Regular;
      font-size: 16px;
    }
  }

  .navigation-menu_body {
    display: flex;
    justify-content: center;
    flex: 1;

    ul {
      padding: 100px 0px;
      text-align: center;

      li > a,
      li > button {
        text-align: center;
        font-family: Noir Pro Bold;
        font-size: 21px;
        text-decoration: none;
        color: black;
        line-height: 50px;
        img {
          margin-left: 5px;
        }

        &:hover {
          color: #4b4dc8;
        }
      }
    }
  }

  .navigation-menu_footer {
    display: flex;
    justify-content: center;
    padding: 30px 0px;
    border-top: 1px solid #eaecf2;
  }

  @media (max-width: 768px) {
    .navigation-menu_body {
      ul {
        padding: 50px;
        li > a,
        li > button {
          font-size: 16px;
          line-height: 40px;
        }
      }
    }
    .navigation-menu_footer {
      img {
        transform: scale(0.8);
      }
    }
  }
`;
