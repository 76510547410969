import React from 'react';

import { PurposeBoxStyled } from './PurposeBox.styled';

type PurposeBoxProps = {
  title: string;
  icon: string;
};

function PurposeBox({ icon, title }: PurposeBoxProps) {
  return (
    <PurposeBoxStyled>
      <img src={icon} alt="" />
      <span>{title}</span>
    </PurposeBoxStyled>
  );
}

export default PurposeBox;
