import styled from 'styled-components';

export const AddCompanyComponentStyled = styled.form`
  background-color: transparent;
  flex: 1;
  width: 100%;
  h2 {
    padding: 0 2.5rem;
    padding-bottom: 1rem;
  }
  .content__form-container {
    padding: 0 2.5rem;

    & > div:last-child {
      border-bottom: 0 !important;
    }
  }
  .content__submit-container {
    padding: 0 2.5rem;
    p {
      text-transform: uppercase;
      opacity: 0.5;
      transition: all 0.2s;
      &:hover {
        opacity: 1;
      }
    }
    .btn {
      height: 4rem;
      border-radius: 2rem;
      width: 20rem;
      margin-left: 20%;
    }
  }
  @media only screen and (max-width: 1370px) {
    h2 {
      padding: 0 2.5rem;
      padding-bottom: 0.5rem;
    }
    .content__submit-container {
      .btn {
        height: 4rem;
      }
    }
  }
  @media only screen and (max-width: 550px) {
    .content__form-container {
      .ant-row {
        flex-direction: column;
        align-items: flex-start;
        .ant-col {
          min-width: 100%;
          margin: 0.25rem 0rem;
          .ant-row {
            width: 100%;
            padding: 0;
          }
        }
      }
    }
    .content__submit-container {
      flex-direction: column-reverse;
      margin-top: 1rem;
      button {
        margin: 0.25rem 0rem !important;
      }
    }
    .line-loader-container {
      display: none;
    }
  }
`;
