import styled, { css } from 'styled-components';

type ButtonStyledProps = {
  $active?: boolean;
  $withArrow?: boolean;
  $backgroundColor?: string;
};

export const BaseSubmitButtonStyled = styled('button')<ButtonStyledProps>`
  position: relative;
  font-size: ${21 / 16}rem;
  background: ${({ $backgroundColor }) => $backgroundColor ?? 'transparent'};
  width: 100%;
  color: white;
  height: 62px;
  border-radius: 10px;
  font-family: 'Noir Pro Bold';
  @media only screen and (min-width: 1440px) {
    height: 70px !important;
  }
  &:focus {
    color: #ffffff;
    border-color: #3b3b7d;
  }

  &:disabled {
    cursor: default;
    color: #808e9b;
    background-color: #dfe2e9;
  }

  ${({ $active }) =>
    $active &&
    css`
      outline: none;
      color: #ffffff;
      span {
        opacity: 1;
      }
      svg {
        opacity: 1;
      }
      &.btn--sorting,
      &.btn--bordered {
        svg {
          opacity: 1;

          path {
            fill: #fff;
          }
        }
      }

      z-index: 1;
      &.btn::after {
        all: unset;
        content: ' ';
        position: absolute;
        background-color: #3b3b7d;
        opacity: 0.6;
        width: 100%;
        height: 100%;
        border-radius: 1.2rem;
        z-index: -1;
      }
    `}
`;
