import React from 'react';

interface InfoIconProps {
  readonly className?: string;
  readonly width?: string;
  readonly height?: string;
  readonly wrapperStyle?: React.CSSProperties;
  readonly svgStyle?: React.CSSProperties;
  readonly title?: string;
}

const InfoIcon = ({
  className,
  width = '24',
  height = '24',
  title,
  svgStyle,
  wrapperStyle,
}: InfoIconProps) => {
  return (
    <span title={title} style={wrapperStyle}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        width={width}
        height={height}
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        style={svgStyle}
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
        />
      </svg>
    </span>
  );
};

export default InfoIcon;
