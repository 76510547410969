import { Col } from 'antd';
import TextSkeleton from '../../../components/skeletons/TextSkeleton';
import React from 'react';
import { TableComponentStyled } from './TableComponentSkeleton.styled';

const TableComponentSkeleton = () => {
  return (
    <>
      <TableComponentStyled align={'middle'}>
        <Col offset={0} span={5}>
          <TextSkeleton width={'100%'} />
        </Col>
        <Col span={4} offset={2}>
          <TextSkeleton width={'100%'} />
        </Col>
        <Col offset={2} span={10}>
          <TextSkeleton width={'100%'} />
        </Col>
      </TableComponentStyled>
      <TableComponentStyled align={'middle'}>
        <Col offset={0} span={5}>
          <TextSkeleton width={'100%'} />
        </Col>
        <Col span={4} offset={2}>
          <TextSkeleton width={'100%'} />
        </Col>
        <Col offset={2} span={10}>
          <TextSkeleton width={'100%'} />
        </Col>
      </TableComponentStyled>
      <TableComponentStyled align={'middle'}>
        <Col offset={0} span={5}>
          <TextSkeleton width={'100%'} />
        </Col>
        <Col span={4} offset={2}>
          <TextSkeleton width={'100%'} />
        </Col>
        <Col offset={2} span={10}>
          <TextSkeleton width={'100%'} />
        </Col>
      </TableComponentStyled>
      <TableComponentStyled align={'middle'}>
        <Col offset={0} span={5}>
          <TextSkeleton width={'100%'} />
        </Col>
        <Col span={4} offset={2}>
          <TextSkeleton width={'100%'} />
        </Col>
        <Col offset={2} span={10}>
          <TextSkeleton width={'100%'} />
        </Col>
      </TableComponentStyled>
      <TableComponentStyled align={'middle'}>
        <Col offset={0} span={5}>
          <TextSkeleton width={'100%'} />
        </Col>
        <Col span={4} offset={2}>
          <TextSkeleton width={'100%'} />
        </Col>
        <Col offset={2} span={10}>
          <TextSkeleton width={'100%'} />
        </Col>
      </TableComponentStyled>
    </>
  );
};

export default TableComponentSkeleton;
