import * as Api from 'api';
import { message, Row } from 'antd';
import { FormFieldsBuilder } from 'components';
import React, { useContext, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { moveWebsiteForms } from './const';
import { FormPageTypeEnum } from 'utils/enums';
import { useForm } from 'react-hook-form';
import { WebsiteMoveStyled } from './WebsiteMove.styled';
import { ajax, endpoints, moveWebsite, useGetCompanies, useGetWebsite } from 'services';
import { validateAxiosErrorInForms } from 'utils/helpers/functions';
import { useHistory } from 'react-router-dom';
import { trigger } from 'swr';
import { routesPaths } from 'utils/helpers';
import { SiderNavigationContext } from 'state/sider/navigation/siderNavigationContext';
import BaseSubmitButton from 'components/baseComponents/BaseSubmitButton';
import { BaseRightDrawerContext } from 'state/baseComponents/BaseRightDrawerContext';

const schema = Yup.object().shape({
  company_slug: Yup.string().required('The field is required (min 3 char.)').min(3),
});

type WebsiteMoveType = {
  companySlug?: string;
  websiteSlug?: string;
};
const WebsiteMove = ({ companySlug, websiteSlug }: WebsiteMoveType) => {
  const { siderActions } = useContext(SiderNavigationContext);
  const { onClose } = useContext(BaseRightDrawerContext);
  const { data } = useGetWebsite(companySlug!, websiteSlug);
  const [loading, setLoading] = useState(false);
  const [fieldData, setFieldData] = useState<FormSectionWrapperType[]>(moveWebsiteForms);

  const { handleSubmit, control, errors, watch, setValue, setError } = useForm<MoveWebsiteFormType>(
    {
      resolver: yupResolver(schema),
      shouldFocusError: true,
    },
  );

  const { push } = useHistory();

  const { data: companies } = useGetCompanies();

  useEffect(() => {
    const items: SelectItemType[] =
      companies?.data?.map(({ name, slug }) => ({
        label: name,
        value: slug,
      })) ?? [];

    const currentField = fieldData[0].fields[0];
    currentField.itemsArray = items;
    currentField.value = companies?.data[0].slug;

    setFieldData([...fieldData]);
    setValue(currentField.name as 'company_slug', currentField.value);
  }, [companies]);

  const onSubmit = async (formData: MoveWebsiteFormType) => {
    try {
      setLoading(true);
      if (data?.data) {
        const { company_slug, slug } = data.data;
        await moveWebsite(data.data.company_slug, data.data.slug, formData);

        await trigger(endpoints.getWebsite(company_slug, slug));
        ajax.get(Api.endpoints.siderAllWebsites(company_slug)).then((response) => {
          siderActions.setWebsites(response.data, company_slug);
        });
        ajax.get(Api.endpoints.siderAllWebsites(formData.company_slug)).then((response) => {
          siderActions.setWebsites(response.data, formData.company_slug);
        });
        requestAnimationFrame(() =>
          push(routesPaths.toCompanyOrWebsiteStatisticBySlug(formData.company_slug)),
        );

        message.success('Website moved successfully');
        onClose();
      }
    } catch (error) {
      validateAxiosErrorInForms(error, setError);
    } finally {
      setLoading(false);
    }
  };

  return (
    <WebsiteMoveStyled onSubmit={handleSubmit(onSubmit)}>
      <FormFieldsBuilder<MoveWebsiteFormType>
        fieldsData={fieldData}
        control={control}
        setValue={setValue}
        watch={watch}
        errors={errors}
        page={FormPageTypeEnum.addCompany}
      />
      <Row align={'middle'} justify={'end'} className={'content__submit-container'}>
        <BaseSubmitButton
          title={'Move Website'}
          className={'btn--fill'}
          backgroundColor={'#4B4DC8'}
          style={{ marginTop: '12px' }}
          withArrow
        />
      </Row>
    </WebsiteMoveStyled>
  );
};

export default WebsiteMove;
