import { ajax } from 'services';
import * as Api from '../../../api';
import { getStatisticPageCompanyParams } from './params';

import { StatisticPageCompany } from './types';

export const getStatisticPageCompany = (params: getStatisticPageCompanyParams) => {
  return ajax.get<StatisticPageCompany>(
    Api.endpoints.statisticPageCompany(
      params.args.companySlug,
      params.args.startDate,
      params.args.endDate,
    ),
  );
};
