import { Checkbox } from 'antd';
import styled from 'styled-components';

export const BaseCheckboxStyled = styled(Checkbox)`
  .ant-checkbox {
    top: 0;
  }
  &.checkbox-pausing {
    position: absolute;
    bottom: -6.5rem;
    left: 0;
    @media only screen and (max-width: 337px) {
      bottom: -8rem !important;
    }
  }
  &.checkbox-sticky {
    position: absolute;
    bottom: -6.5rem;
    left: 0;
    @media only screen and (min-width: 425px) and (max-width: 1440px) {
      bottom: -5.5rem !important;
    }
  }
  .ant-checkbox-inner {
    width: 1.5rem;
    height: 1.5rem;
    border-color: #5c5cc2 !important;
    border-radius: 3px;
    &::after {
      width: 0.5625rem;
      height: 0.75rem;
      border-radius: 3px;
    }
  }
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner,
  .ant-checkbox-checked::after {
    border: 1px solid #5c5cc2;
    border-radius: 3px;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #5c5cc2;
  }
`;
