import styled from 'styled-components';

export const ActivateWebsiteModalStyled = styled.div`
  padding: 1rem 4rem;
  .text {
    margin-top: 1.5rem;
    span {
      font-family: ${({ theme }) => theme.fontFamilies.bold};
    }
  }
  .content__submit-container {
    .btn--fill {
      height: 4rem;
      border-radius: 2rem;
      margin-left: 1rem;
    }
  }
`;
