import React, { createContext, useState, Dispatch } from 'react';

interface TypesArchivesContext {
  readonly companyName?: string;
  readonly setCompanyName: Dispatch<React.SetStateAction<string | undefined>>;
  readonly websiteName?: string;
  readonly setWebsiteName: Dispatch<React.SetStateAction<string | undefined>>;
  readonly websitesCount?: number;
  readonly setWebsitesCount: Dispatch<React.SetStateAction<number | undefined>>;
  readonly isActive?: boolean;
  readonly setIsActive: Dispatch<React.SetStateAction<boolean | undefined>>;
  readonly slug?: string;
  readonly setSlug: Dispatch<React.SetStateAction<string | undefined>>;
  readonly companySlug?: string;
  readonly setCompanySlug: Dispatch<React.SetStateAction<string | undefined>>;
  readonly headerName?: string;
  readonly setHeaderName: Dispatch<React.SetStateAction<string | undefined>>;
}

interface Props {
  readonly children: React.ReactNode;
}

const ArchivesContext = createContext({} as TypesArchivesContext);

const ArchivesProvider = ({ children }: Props) => {
  const [companyName, setCompanyName] = useState<string | undefined>();
  const [websiteName, setWebsiteName] = useState<string | undefined>();
  const [websitesCount, setWebsitesCount] = useState<number | undefined>();
  const [slug, setSlug] = useState<string | undefined>();
  const [companySlug, setCompanySlug] = useState<string | undefined>();
  const [isActive, setIsActive] = useState<boolean>();
  const [headerName, setHeaderName] = useState<string | undefined>();

  return (
    <ArchivesContext.Provider
      value={{
        companyName,
        setCompanyName,
        websiteName,
        setWebsiteName,
        websitesCount,
        setWebsitesCount,
        isActive,
        setIsActive,
        slug,
        setSlug,
        companySlug,
        setCompanySlug,
        headerName,
        setHeaderName,
      }}
    >
      {children}
    </ArchivesContext.Provider>
  );
};

export { ArchivesContext, ArchivesProvider };
