/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Button } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useGetWebsite } from 'services';
import { BaseRightDrawerContext } from 'state/baseComponents/BaseRightDrawerContext';
import WebsiteEditInner from 'views/productContainer/containers/websiteDetail/components/WebsiteEdit';
import WebsiteMove from 'views/productContainer/containers/websiteDetail/components/WebsiteMove';
import { WebsiteSettingsStyled } from './WebsiteSettings.styled';

interface WebsiteSettingsProps {
  readonly companySlug?: string;
  readonly websiteSlug?: string;
}

const WebsiteSettings = ({ companySlug, websiteSlug }: WebsiteSettingsProps) => {
  const { setTitle } = useContext(BaseRightDrawerContext);
  const { data } = useGetWebsite(companySlug!, websiteSlug);
  const [isEdit, setIsEdit] = useState(true);

  useEffect(() => {
    if (isEdit) {
      setTitle('Edit Details');
    } else {
      setTitle('Move Website');
    }
  }, [isEdit]);

  if (data == null) return null;

  return (
    <WebsiteSettingsStyled>
      <div className="switcher-div">
        <Button className={isEdit ? 'active' : ''} onClick={() => setIsEdit(true)}>
          Edit Details
        </Button>
        <Button className={!isEdit ? 'active' : ''} onClick={() => setIsEdit(false)}>
          Move Website
        </Button>
      </div>
      {isEdit ? (
        <WebsiteEditInner companySlug={companySlug} websiteSlug={websiteSlug} />
      ) : (
        <WebsiteMove companySlug={companySlug} websiteSlug={websiteSlug} />
      )}
    </WebsiteSettingsStyled>
  );
};

export default WebsiteSettings;
