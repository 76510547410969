import styled from 'styled-components';
import SearchIcon from 'assets/image/svg/feather-search.svg';

export const SearchContainerStyled = styled.div`
  height: 100%;
  width: 100%;
  position: fixed;
  background-color: #f6f7fc;
  overflow-y: auto;
  ${({ theme }) => theme.scrollable()}
  z-index: 1;
  top: 0;
  left: 0;

  .container {
    width: 65%;
    padding: 30px 0px;
    margin: 0 auto;
  }

  .search-input-container {
    position: relative;

    .search-close-btn {
      position: absolute;
      right: -50px;
      top: 30%;
    }
  }

  .search-results {
    padding: 30px 0px;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #eaecf2;
    p {
      font-family: Noir Pro Regular;
      font-size: 18px;
    }

    span {
      margin-left: 10px;
      font-family: Noir Pro Bold;
      font-size: 18px;
      color: #4b4dc8;
    }
  }

  .search-count {
    p {
      margin: 30px 0px;
      font-family: Noir Pro Bold;
      font-size: 18px;
    }
  }

  .load-more-btn {
    width: 100%;
    text-align: center;
    padding: 15px;
    margin: 30px 0px;
    background-color: #e8edf8;
    color: #9a9de0;
    border-radius: 10px;
    font-family: Noir Pro Bold;
    font-size: 18px;
  }

  .search {
    display: block;
    background-color: white !important;
    border-radius: 10px;
    border: 2px solid transparent;
    line-height: 100%;
    width: 100%;
    height: 60px;
    box-shadow: 0px 10px 30px #1818180d;
    background: #f6f7fc url(${SearchIcon}) no-repeat 20px center;
    padding-left: 65px;
    &::placeholder {
      color: #7d8ca2;
      opacity: 0.3;
    }

    &:focus {
      border: 2px solid #4b4dc8;
    }
  }

  .container__content {
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    background-color: #ffffff;
    margin-top: 30px;
    box-shadow: 0px 10px 30px #1818180d;
    border-radius: 10px;
    -webkit-transition: height 0.5s linear;
    transition: height 0.5s linear;

    .scrollable {
      max-height: calc(100% - 6rem);
      margin: 0 30px;
      overflow: hidden;
    }
  }

  @media only screen and (max-width: 1366px) {
    .search {
      height: 40px;
    }
  }

  @media only screen and (max-width: 992px) {
    .container {
      width: 100%;
      padding: 30px 20px;
    }

    .container__content {
      max-height: 100%;
      min-height: auto !important;
      padding-bottom: 10px;
    }

    .search {
      width: 92%;
    }

    .search-input-container {
      .search-close-btn {
        right: 0px;
      }
    }
  }

  @media only screen and (max-width: 680px) {
    .scrollable {
      margin: 0 5px !important;
    }
  }

  @media only screen and (max-width: 576px) {
    .container {
      padding: 30px 15px;
    }

    .search {
      width: 88%;
    }
  }

  @media only screen and (max-width: 680px) {
    .container {
      padding: 15px;
    }
  }
`;
