import styled, { keyframes } from 'styled-components';
import dotScaled from 'assets/image/svg/loader-scaled-dots.svg';
// import dot from 'assets/image/svg/dot.svg';
import dot from 'assets/image/icons/dot.png';

const SliderLine = keyframes`
  0% {
    left: -4.5rem;
  }
  100% {
    left: 100%;
  }
`;

export const LoaderLineStyled = styled.div`
  overflow-x: hidden;
  .line-loader {
    background-image: url(${dot});
    width: 100%;
    height: 6px;
    margin: 1.4rem 0;
    background-position: center;
    background-size: contain;
    background-repeat: repeat-x;
    position: relative;
    /* opacity: 0.9; */
    &::after {
      content: ' ';
      height: 1.25rem;
      width: 4.5rem;
      background-image: url(${dotScaled});
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      position: absolute;
      top: calc(-50% - 0.078125rem - 3px);
      left: -4.5rem;
      transition: all 1s linear;
      animation-duration: 2s;
      animation-name: none;
      animation-iteration-count: infinite;
      animation-timing-function: ease-out;
    }

    &.active {
      &::after {
        animation-name: ${SliderLine};
      }
    }
  }
`;
