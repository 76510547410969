import { Skeleton, Col } from 'antd';
import TextSkeleton from './TextSkeleton';
import React from 'react';
import { HomePageTableItemStyled } from './HomePageTableItem.styled';

const HomePageTableItem = () => {
  return (
    <HomePageTableItemStyled className={'product-item'} align={'middle'}>
      {/* <Col span={1}>
          <TextSkeleton width={'2rem'} />
        </Col>
        <Col span={2}>
          <Skeleton.Image />
        </Col>
        <Col xxl={{ span: 4, offset: 1 }} lg={{ span: 3, offset: 1 }}>
          <TextSkeleton width={'4rem'} />
        </Col>
        <Col xxl={{ span: 3, offset: 2 }} lg={{ span: 3, offset: 2 }}>
          <TextSkeleton width={'8rem'} />
        </Col>
        <Col offset={1} xxl={{ span: 2 }}>
          <TextSkeleton width={'4rem'} />
        </Col>
        <Col offset={1} span={1}>
          <TextSkeleton width={'2rem'} />
        </Col>
        <Col xxl={{ span: 5, offset: 1 }} lg={{ span: 6, offset: 1 }}>
          <Row
            align={'middle'}
            justify={'space-around'}
            className={'flex flex-row '}
          >
            <Skeleton.Avatar size={'small'} />

            <TextSkeleton width={'4rem'} />

            <TextSkeleton width={'4rem'} />

            <TextSkeleton width={'4rem'} />
          </Row>
        </Col> */}
      <Col span={1}>
        <TextSkeleton width={'100%'} />
      </Col>
      <Col offset={1} span={21}>
        <Skeleton avatar={{ shape: 'square', size: 80 }} paragraph={{ rows: 1 }} />
      </Col>
    </HomePageTableItemStyled>
  );
};

export default HomePageTableItem;
