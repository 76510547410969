import { Col, Divider, Row } from 'antd';
import { ColProps } from 'antd/lib/col';
import { Text } from 'components/styles';
import React from 'react';
import { TableHeadSettingsItemStyled } from './TableHeadSettingsItem.styled';

type TableHeadSettingsItemPropsType = {
  text: string;
  index: number;
  arrayLength: number;
} & ColProps;

const TableHeadSettingsItem = ({
  text,
  onSelect,
  index,
  arrayLength,
  ...props
}: TableHeadSettingsItemPropsType) => {
  return (
    <>
      <Col lg={3} md={3} sm={3} xs={3}>
        <TableHeadSettingsItemStyled {...props}>
          <div
            className={'flex flex-row flex-center justify-start text-wrapper nowrap'}
            onClick={onSelect}
          >
            <Text fontSize={16}>{text}</Text>
          </div>
        </TableHeadSettingsItemStyled>
      </Col>
      {index !== arrayLength - 1 && (
        <Col lg={1} md={1} sm={1} xs={1}>
          <Row align={'middle'} justify={'center'}>
            <Divider type={'vertical'} className={'divider'} />
          </Row>
        </Col>
      )}
    </>
  );
};

export default TableHeadSettingsItem;
