import styled from 'styled-components';

export const EmbedStyled = styled.div`
  padding: 0 2rem;
  .description {
    margin: 0.7rem 0;
  }
  @media only screen and (max-width: 450px) {
    padding: 0 3rem;
    article {
      padding: 0 !important;
      .ant-typography {
        display: flex;
        flex-direction: column;
        padding: 0 !important;
        .ant-typography-copy {
          position: initial;
          display: flex;
          align-self: flex-end;
          margin-top: 2.5rem;
        }
      }
    }
  } ;
`;
