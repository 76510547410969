import React from 'react';

import { BaseBadgeShapeSelectorStyled } from './BaseBadgeShapeSelector.styled';
import { Text } from 'components/styles';
import { Divider, Tooltip } from 'antd';

type BaseBadgeShapeSelectorProps = {
  value?: string;
  // eslint-disable-next-line no-unused-vars
  onChange?: (val: string) => void;
  itemsArray?: {
    label: string;
    value: string;
    tooltipText?: string;
  }[];
};

const BaseBadgeShapeSelector = ({
  value: defaultValue,
  onChange,
  itemsArray,
}: BaseBadgeShapeSelectorProps) => {
  return (
    <>
      <BaseBadgeShapeSelectorStyled>
        <div className="baseBadgeShape">
          <span
            style={{
              fontSize: '1.125rem',
              fontFamily: 'Noir Pro Regular',
              marginRight: '15px',
              marginTop: '-3px',
              whiteSpace: 'nowrap',
            }}
          >
            Badge shape:{' '}
          </span>
          {itemsArray?.map(({ label, value, tooltipText }, index) => (
            <Tooltip title={tooltipText} key={index}>
              <Text
                className={`${value} ${defaultValue === value ? 'active' : ''}`}
                onClick={onChange?.bind(BaseBadgeShapeSelector, value)}
              >
                {label}
              </Text>
            </Tooltip>
          ))}
        </div>
        <Divider />
      </BaseBadgeShapeSelectorStyled>
    </>
  );
};

export default BaseBadgeShapeSelector;
