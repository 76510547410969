import { Text } from 'components/styles';
import React from 'react';

import { FeatureBoxStyled } from './FeatureBox.styled';

type PurposeBoxProps = {
  title: string;
  icon: string;
};

function FeatureBox({ icon, title }: PurposeBoxProps) {
  return (
    <FeatureBoxStyled>
      <img src={icon} alt="" width="36px" height="36" />
      <Text>{title}</Text>
    </FeatureBoxStyled>
  );
}

export default FeatureBox;
