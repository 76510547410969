import React from 'react';
import { BaseTimePickerStyled } from './BaseTimePicker.styled';
import moment from 'moment-timezone';
import { constants } from 'utils';
import { ReactComponent as ClockSlideConfig } from 'assets/image/svg/clock-slide-config.svg';
import { ReactComponent as DropDownArrow } from 'assets/image/svg/Icon ionic-ios-arrow-back.svg';

type BaseTimePickerProps = {
  value?: string;
  onChange?: (val: string) => void;
  disabled?: boolean;
};

const BaseTimePicker = ({ value, onChange, disabled, ...props }: BaseTimePickerProps) => {
  return (
    <BaseTimePickerStyled
      disabled={disabled}
      bordered={false}
      use12Hours
      format={constants.TIME_FORMAT}
      {...props}
      defaultValue={moment(value, constants.TIME_FORMAT)}
      value={moment(value, constants.TIME_FORMAT)}
      onChange={(value: moment.Moment | null) => {
        // eslint-disable-next-line react/prop-types
        onChange?.(value?.format(constants.TIME_FORMAT).toString() ?? '');
      }}
      suffixIcon={<ClockSlideConfig />}
      clearIcon={false}
      inputReadOnly
    />
  );
};

export default BaseTimePicker;
