import React, { createContext, useState, useEffect } from 'react';

interface TypesParamsContext {
  slugs: slugTypes;
  setSlugs: React.Dispatch<React.SetStateAction<slugTypes>>;
}

type slugTypes = {
  companySlug: string;
  websiteSlug: string;
  slideSlug: string;
  productSlug: string;
};

interface Props {
  readonly children: React.ReactNode;
}

const ParamsContext = createContext({} as TypesParamsContext);

const ParamsContextProvider = ({ children }: Props) => {
  const [slugs, setSlugs] = useState<slugTypes>({
    companySlug: '',
    websiteSlug: '',
    slideSlug: '',
    productSlug: '',
  });

  return (
    <ParamsContext.Provider
      value={{
        slugs,
        setSlugs,
      }}
    >
      {children}
    </ParamsContext.Provider>
  );
};

export { ParamsContext, ParamsContextProvider };
