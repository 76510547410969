import React, { useContext } from 'react';
import * as Api from 'api';
import { useHistory } from 'react-router-dom';
import { ajax, endpoints } from 'services';
import { SiderNavigationContext } from 'state/sider/navigation/siderNavigationContext';
import { routesPaths } from 'utils/helpers';
import { Skeleton } from 'antd';
import Websites from './Websites';
import { ArrowIcon } from 'components/svg';
import { trigger } from 'swr';

interface Props {
  readonly currentUrl: string;
  readonly filteredCompanies: ICompany[];
  readonly companySearchKey?: string;
}

const Companies = ({ currentUrl, filteredCompanies, companySearchKey }: Props) => {
  const history = useHistory();
  const {
    siderActions,
    siderState,
    openCompanyDropdownSlugs,
    toggleOpenCompanyDropdownSlugs,
    setOpenCompanyDropdownSlugs,
    setOpenWebsiteDropdownSlugs,
    openWebsiteDropdownSlugs,
    toggleOpenWebsiteDropdownSlugs,
  } = useContext(SiderNavigationContext);

  const companies = companySearchKey ? filteredCompanies : siderState.companies;

  const getWebsites = (companySlug: ICompany['slug']) => {
    if (siderState.websites.find((item) => item.companySlug === companySlug)) return;

    ajax.get(Api.endpoints.siderAllWebsites(companySlug)).then((response) => {
      siderActions.setWebsites(response.data, companySlug);
    });
  };

  const companyOnClick = (company: ICompany) => {
    getWebsites(company.slug);
    if (
      history.location.pathname.includes(company.slug) ||
      !openCompanyDropdownSlugs.includes(company.slug)
    ) {
      toggleOpenCompanyDropdownSlugs(company.slug);
    } else {
      setOpenCompanyDropdownSlugs([company.slug, ...openCompanyDropdownSlugs]);
    }
    history.push(routesPaths.toCompanyOrWebsiteStatisticBySlug(company.slug));
  };

  const getProducts = (companySlug: ICompany['slug'], websiteSlug: IWebsite['slug']) => {
    if (siderState.products.find((item) => item.websiteSlug === websiteSlug)) return;

    ajax.get(Api.endpoints.siderAllProducts(companySlug, websiteSlug)).then((response) => {
      siderActions.setProducts(response.data, websiteSlug);
    });
  };

  const websiteOnClick = (company: ICompany, website: IWebsite) => {
    getProducts(company.slug, website.slug);
    if (
      (history.location.pathname.includes(company.slug) &&
        history.location.pathname.includes(website.slug)) ||
      (!openCompanyDropdownSlugs.includes(company.slug) &&
        !openWebsiteDropdownSlugs.includes(website.slug))
    ) {
      toggleOpenWebsiteDropdownSlugs(website.slug);
    } else {
      setOpenWebsiteDropdownSlugs([website.slug, ...openWebsiteDropdownSlugs]);
    }
    history.push(routesPaths.toCompanyOrWebsiteStatisticBySlug(company.slug, website.slug));
  };

  const productOnClick = (company: ICompany, website: IWebsite, product: IProduct) => {
    history.push(routesPaths.toProductSlidesListById(company.slug, website.slug, product.slug));
    trigger(endpoints.getSlides(website.slug, product.slug));
  };

  return (
    <>
      {companies.map((company) => (
        <div className="list" key={company.slug}>
          <div
            className="company"
            style={
              openCompanyDropdownSlugs.includes(company.slug) ? { marginBottom: '10px' } : undefined
            }
          >
            <p onClick={() => companyOnClick(company)}>{company.name}</p>
            <div
              className="arrow-div"
              style={
                history.location.pathname.includes(company.slug) ||
                currentUrl.includes(company.slug)
                  ? { background: '#4B4DC8' }
                  : undefined
              }
              onClick={() => {
                getWebsites(company.slug);
                toggleOpenCompanyDropdownSlugs(company.slug);
              }}
            >
              <ArrowIcon
                style={
                  openCompanyDropdownSlugs.includes(company.slug)
                    ? { transform: 'rotate(0)' }
                    : { marginBottom: '-3px' }
                }
                fill={
                  history.location.pathname.includes(company.slug) ||
                  currentUrl.includes(company.slug)
                    ? '#fff'
                    : '#181818'
                }
              />
            </div>
          </div>
          {openCompanyDropdownSlugs.includes(company.slug) &&
            (!siderState.websites.find((item) => item.companySlug === company.slug) ? (
              <div className="website-skeleton" style={{ marginBottom: '20px' }}>
                <Skeleton active paragraph={{ rows: 2, width: '100%' }} title={false} />
              </div>
            ) : (
              <Websites
                company={company}
                currentUrl={currentUrl}
                productOnClick={productOnClick}
                websiteOnClick={websiteOnClick}
              />
            ))}
        </div>
      ))}
    </>
  );
};

export default Companies;
