import { Button, Col, Row } from 'antd';
import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { Controller } from 'react-hook-form';

import { UserAddFormStyled } from './UserAddForm.styled';
import { BaseSecondaryButton, BaseSelect } from 'components/baseComponents';
import UserAddFieldsRow from './UserAddFieldsRow';
import { Text } from 'components/styles';
import { ReactComponent as BulkUserAdd } from 'assets/image/svg/bulk-user-add.svg';
import { ReactComponent as BusinessInviteUsers } from 'assets/image/svg/business-invite-users.svg';
import { FormPageTypeEnum } from 'utils/enums';
import useUserAddForm from './useUserAddForm';
import { LoaderLine } from '../../userMove/index';

type UserAddFormPropsType = {
  fromModal?: boolean;
  onUploadMethodSwith: () => void;
  setUsersCount: (count: number) => void;
  onClose?: () => void;
  CSVData: any[];
};

const UserAddForm = ({
  onUploadMethodSwith,
  setUsersCount,
  fromModal,
  onClose,
  CSVData,
}: UserAddFormPropsType): React.ReactElement => {
  const { fontFamilies } = useContext(ThemeContext);
  const {
    handleSubmit,
    control,
    errors,
    onSubmit,
    onSkip,
    getCompanies,
    getWebsites,
    fields,
    getRoles,
    loading,
    isWebsiteAdmin,
  } = useUserAddForm({
    setUsersCount,
    fromModal,
    onClose,
    CSVData,
  });

  return (
    <UserAddFormStyled onSubmit={handleSubmit(onSubmit)}>
      <Col span={24} className={'content__form-container'}>
        <Row align={'middle'} className={'form-container__company-select'}>
          <Col span={1}>
            <BusinessInviteUsers />
          </Col>
          <Col span={9}>
            <Controller
              control={control}
              name={'company_slug'}
              as={BaseSelect}
              itemsArray={getCompanies}
              page={FormPageTypeEnum.addUsers}
            />
          </Col>
          <Col span={5} />
          {isWebsiteAdmin && (
            <Col span={9}>
              <Controller
                disabled={false}
                control={control}
                name="website_slug"
                as={
                  <BaseSelect
                    // onChange={() => {}}
                    placeholder={'Website name'}
                    itemsArray={getWebsites}
                  />
                }
                error={errors?.['website_slug']}
                style={{ width: '100%', margin: '0.5rem 0' }}
              />
            </Col>
          )}
        </Row>
        <div className={'scrollable'}>
          {fields.map((item, index) => (
            <UserAddFieldsRow<InviteUserFormType>
              key={item.id}
              item={item}
              index={index}
              control={control}
              errors={errors}
              itemsArray={getRoles}
            />
          ))}
        </div>
      </Col>
      <div style={{ padding: '0 6% 0 3%' }}>
        <LoaderLine loading={loading} />
      </div>
      <Row align={'middle'} justify={'space-between'} className={'content__submit-container'}>
        <Button type={'text'} onClick={fromModal ? onClose : onSkip} className={'skip-step'}>
          <Text fontSize={18} fontFamily={fontFamilies.regular} color={'#53536D66'}>
            {fromModal ? 'CANCEL' : 'skip & Go to Dashboard'}
          </Text>
        </Button>

        <Button
          type={'text'}
          onClick={onUploadMethodSwith}
          className={'invite-user__bulk-upload'}
          icon={<BulkUserAdd />}
        >
          <Text fontSize={18} fontFamily={fontFamilies.regular} color={'#5C5BC1'}>
            Switch to Bulk Upload
          </Text>
        </Button>
        <BaseSecondaryButton
          title={'Next'}
          className={'btn--fill'}
          backgroundColor={'#5C5BC1'}
          htmlType={'submit'}
          withArrow={true}
        />
      </Row>
    </UserAddFormStyled>
  );
};

export default UserAddForm;
