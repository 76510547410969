import useRequest from './useRequest';
import ajax from './ajax';
import endpoints from './endpoints';

export const useGetProduct = (companySlug: string, websiteSlug: string, productSlug: string) =>
  useRequest<ProductDataType>(endpoints.getProduct(companySlug, websiteSlug, productSlug));

export const addProduct = (companySlug: string, websiteSlug: string, product_id: number) =>
  ajax.post<RegisterResponseType>(
    `api/v1/companies/${companySlug}/websites/${websiteSlug}/products/attach`,
    { product_id },
  );

export const deleteProduct = (companySlug: string, websiteSlug: string, product_slug: string) =>
  ajax.post<DeleteResponseType>(
    `api/v1/companies/${companySlug}/websites/${websiteSlug}/products/detach`,
    { product_slug },
  );

export const createProductSlideSettings = (
  websiteSlug: string,
  productSlug: string,
  requestData: CreateProductSlideSettingsRequestType,
) =>
  ajax.post<MessageResponseType>(
    `api/v1/websites/${websiteSlug}/products/${productSlug}/settings/prdslidesettings`,
    { ...requestData },
  );

export const getProductPageSettings = (websiteSlug: string, productSlug: string) =>
  useRequest<GetProductPageSettingsType>(
    `api/v1/websites/${websiteSlug}/products/${productSlug}/productSlideDefaultSettings`,
  );

export const createAdvertSettings = (
  websiteSlug: string,
  productSlug: string,
  requestData: CreateAdvertSettingsRequestType,
) =>
  ajax.post<MessageResponseType>(
    `api/v1/websites/${websiteSlug}/products/${productSlug}/settings/advertsettings`,
    { ...requestData },
  );

export const getEmbedLink = (websiteSlug: string, productSlug: string) =>
  useRequest<GetPluginLinkType>(endpoints.getEmbedLink(websiteSlug, productSlug));

export const listProductsMenuTree = (companySlug: string, websiteSlug: string) =>
  ajax.get(`api/v1/ux/tree/companies/${companySlug}/websites/${websiteSlug}/products`);
