import { Col } from 'antd';
import TextSkeleton from './TextSkeleton';
import React from 'react';
import { SetupPageStyled } from './SetupPage.styled';

const SetupPage = () => {
  return (
    <>
      <SetupPageStyled align={'middle'}>
        <Col offset={0} span={5}>
          <TextSkeleton width={'100%'} />
        </Col>
        <Col span={4} offset={2}>
          <TextSkeleton width={'100%'} />
        </Col>
        <Col offset={2} span={10}>
          <TextSkeleton width={'100%'} />
        </Col>
      </SetupPageStyled>
      <SetupPageStyled align={'middle'}>
        <Col offset={0} span={5}>
          <TextSkeleton width={'100%'} />
        </Col>
        <Col span={4} offset={2}>
          <TextSkeleton width={'100%'} />
        </Col>
        <Col offset={2} span={10}>
          <TextSkeleton width={'100%'} />
        </Col>
      </SetupPageStyled>
      <SetupPageStyled align={'middle'}>
        <Col offset={0} span={5}>
          <TextSkeleton width={'100%'} />
        </Col>
        <Col span={4} offset={2}>
          <TextSkeleton width={'100%'} />
        </Col>
        <Col offset={2} span={10}>
          <TextSkeleton width={'100%'} />
        </Col>
      </SetupPageStyled>
      <SetupPageStyled align={'middle'}>
        <Col offset={0} span={5}>
          <TextSkeleton width={'100%'} />
        </Col>
        <Col span={4} offset={2}>
          <TextSkeleton width={'100%'} />
        </Col>
        <Col offset={2} span={10}>
          <TextSkeleton width={'100%'} />
        </Col>
      </SetupPageStyled>
      <SetupPageStyled align={'middle'}>
        <Col offset={0} span={5}>
          <TextSkeleton width={'100%'} />
        </Col>
        <Col span={4} offset={2}>
          <TextSkeleton width={'100%'} />
        </Col>
        <Col offset={2} span={10}>
          <TextSkeleton width={'100%'} />
        </Col>
      </SetupPageStyled>
    </>
  );
};

export default SetupPage;
