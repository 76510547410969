import styled from 'styled-components';
import { Row } from 'antd';
import InfoIcon from 'assets/image/svg/drawer-info-icon-big.svg';

export const RestoreUserStyled = styled(Row)`
  background: url(${InfoIcon}) no-repeat center center;
  display: flex;
  flex-direction: column;
  height: 100%;
  .user__activate {
    margin-top: auto;
    margin-bottom: 30px;
  }
`;
