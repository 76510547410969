import { DynamicFieldsEnum } from 'utils/enums';

export const addCompanyForms: FormSectionWrapperType[] = [
  {
    id: 123123123,
    title: 'Logo',
    fields: [
      {
        id: 1111222,
        type: DynamicFieldsEnum.HiddenInput,
        value: '',
        name: 'logo',
      },
    ],
  },
  {
    id: 314,
    title: 'Company',
    fields: [
      {
        id: 2432,
        type: DynamicFieldsEnum.Input,
        value: '',
        name: 'name',
        placeholder: 'Company name',
        label: 'Company name',
      },
    ],
  },
  {
    id: 34,
    title: 'Address',
    fields: [
      {
        id: 2432,
        type: DynamicFieldsEnum.Input,
        value: '',
        name: 'address',
        placeholder: 'Company address',
        label: 'Company address',
      },
    ],
  },
  {
    id: 123,
    title: 'Country',
    fields: [
      {
        id: 2,
        type: DynamicFieldsEnum.Select,
        value: '',
        name: 'country_iso',
        itemsArray: [],
        // customType: 'twoInputInRow',
        label: 'Country',
      },
    ],
  },
  {
    id: 1232,
    title: 'Region',
    fields: [
      {
        id: 243,
        type: DynamicFieldsEnum.Input,
        value: '',
        name: 'region',
        placeholder: 'e.g. Essex',
        // customType: 'twoInputInRow',
        label: 'Region',
      },
    ],
  },
  {
    id: 14,
    title: 'City',
    fields: [
      {
        id: 2432,
        type: DynamicFieldsEnum.Input,
        value: '',
        name: 'city',
        placeholder: 'City',
        // customType: 'twoInputInRow',
        label: 'City',
      },
    ],
  },
  {
    id: 142,
    title: 'Postal code',
    fields: [
      {
        id: 3,
        type: DynamicFieldsEnum.Input,
        value: '',
        name: 'postal_code',
        placeholder: 'WX8 FG6',
        // customType: 'twoInputInRow',
        label: 'Postal code',
      },
    ],
  },
];

export const MoveCompany: FormSectionWrapperType[] = [
  {
    id: 123,
    title: 'receiving organization',
    fields: [
      {
        id: 2,
        type: DynamicFieldsEnum.Select,
        value: '',
        name: 'company_slug',
        itemsArray: [],
        customType: 'twoInputInRow',
      },
    ],
  },
  {
    id: 314,
    title: 'Notes',
    fields: [
      {
        id: 2432,
        type: DynamicFieldsEnum.TextArea,
        value: '',
        name: 'notes',
        placeholder: 'notes',
      },
    ],
  },
];
