import { useCallback, useMemo, useState } from 'react';

export enum TableHeadEnum {
  Company = 'Company',
  Product = 'Product',
  Website = 'Website',
}

type useArchivePagePropsType<TEnum, TItemsType> = {
  setCurrentSortKey: (activeHead: TEnum) => keyof TItemsType;
  items: TItemsType[];
  initialActiveHead: TEnum;
};

const useArchivePage = <TEnum, TItemsType extends Record<string, unknown>>({
  setCurrentSortKey,
  items,
  initialActiveHead,
}: useArchivePagePropsType<TEnum, TItemsType>) => {
  const [activeHead, setActiveHead] = useState<TEnum>(initialActiveHead);
  const [sortingOrderIsAsc, setSortingOrderIsAsc] = useState<boolean>(false);

  // const { data: users } = useGetUsers();

  const sortedProductItems = useMemo(() => {
    const currentSortKey: keyof TItemsType = setCurrentSortKey(activeHead);
    const sort = (a: TItemsType, b: TItemsType) => {
      const aCurrent = a[currentSortKey];
      const bCurrent = b[currentSortKey];
      if (Array.isArray(aCurrent)) {
        aCurrent.sort();
        const aLength = (aCurrent as unknown[]).length;
        const bLength = (bCurrent as unknown[]).length;
        if (aLength > bLength) return sortingOrderIsAsc ? 1 : -1;
        else if (aLength < bLength) return sortingOrderIsAsc ? -1 : 1;
        else return 0;
      } else {
        if (aCurrent > bCurrent) return sortingOrderIsAsc ? 1 : -1;
        else if (aCurrent < bCurrent) return sortingOrderIsAsc ? -1 : 1;
        else return 0;
      }
    };

    const sortedItems = items?.sort(sort);

    return sortedItems;
  }, [activeHead, sortingOrderIsAsc, items]);

  const onSelectSort = useCallback(
    (val: TEnum) => {
      if (activeHead === val) {
        setSortingOrderIsAsc(!sortingOrderIsAsc);
      } else {
        setActiveHead(val);
        setSortingOrderIsAsc(false);
      }
    },
    [setSortingOrderIsAsc, sortingOrderIsAsc, activeHead, setActiveHead],
  );

  return {
    activeHead,
    onSelectSort,
    sortedProductItems,
    sortingOrderIsAsc,
  };
};

export default useArchivePage;
