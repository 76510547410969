import { Row } from 'antd';
import styled from 'styled-components';

export const ConfigHeaderStyled = styled(Row)`
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 1000px) {
    .mobileMenuWrapper {
      margin: 15px 0;
      display: block;
      width: 100%;
    }
  }

  .headerWrapper {
    display: flex;

    .titleWrapper {
      .ant-breadcrumb-separator {
        margin: 0 30px;
      }
      span {
        display: flex;
        align-items: center;
        h1 {
          white-space: nowrap;
        }
      }
    }
    .switcherWrapper {
      display: flex;
      align-items: center;
    }
  }
  .mobileBtnWrapper {
    position: relative;
    &:before {
      content: '';
      height: 100%;
      width: 1px;
      background-color: #808e9b1f;
      position: absolute;
      left: -40px;
      top: 0;
    }
  }

  h1 {
    font-family: 'Noir Pro Bold';
    font-size: 34px;
    color: black;
  }

  .switch-label {
    color: black;
    font-size: 16px;
  }

  .ant-switch-checked {
    background-color: #4b4dc8;
  }

  .add-buttons-container {
    justify-content: flex-end;
  }

  @media (max-width: 1367px) {
    h1 {
      font-size: 21px;
    }

    .switch-label {
      font-size: 14px;
    }
  }

  @media (max-width: 768px) {
    display: block;
    .headerWrapper {
      display: block;
      padding: 20px 0;
      margin: 0 0 20px;
      border-bottom: 1px solid #808e9b1f;
      .titleWrapper {
        margin: 0 0 10px;
      }
      .ant-breadcrumb-separator {
        display: none !important;
      }
    }
    h1 {
      font-size: 18px;
    }

    .add-buttons-container {
      justify-content: flex-start;
    }

    .switch-label {
      font-size: 14px;
    }
  }
`;
