/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react';
import { ProductContainerContextProvider } from 'state/productContainer/state/context';
import StorylineEditInner from 'views/productContainer/containers/settingPage/SettingPage';

const StorylineEdit = () => {
  return (
    <ProductContainerContextProvider>
      <StorylineEditInner />
    </ProductContainerContextProvider>
  );
};

export default StorylineEdit;
