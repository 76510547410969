import React, { createContext, Dispatch, useCallback, useReducer, useState } from 'react';

enum SiderNavigationActionTypes {
  setCompanies = 'setCompanies',
  setWebsites = 'setWebsites',
  setProducts = 'setProducts',
  clear = 'clear',
}

interface Actions {
  readonly type: SiderNavigationActionTypes;
  readonly payload: any;
  readonly companySlug?: ICompany['slug'];
  readonly websiteSlug?: IWebsite['slug'];
  readonly id?: IWebsite['id'];
  readonly index?: number;
}

interface SiderNavigationContextProps {
  readonly openCompanyDropdownSlugs: readonly string[];
  readonly openWebsiteDropdownSlugs: readonly string[];
  readonly companiesLoading: boolean;
  readonly collapsed: boolean;
  readonly filteredCompanies: ICompany[];
  readonly companySearchKey: string | undefined;
  readonly setCompaniesLoading: Dispatch<React.SetStateAction<boolean>>;
  readonly setOpenCompanyDropdownSlugs: Dispatch<React.SetStateAction<string[]>>;
  readonly setOpenWebsiteDropdownSlugs: Dispatch<React.SetStateAction<string[]>>;
  readonly toggleOpenCompanyDropdownSlugs: (arg0: string) => void;
  readonly toggleOpenWebsiteDropdownSlugs: (arg0: string) => void;
  readonly setCollapsed: Dispatch<React.SetStateAction<boolean>>;
  readonly setFilteredCompanies: Dispatch<React.SetStateAction<ICompany[]>>;
  readonly setCompanySearchKey: Dispatch<React.SetStateAction<string | undefined>>;
  readonly siderState: {
    readonly companies: ICompany[];
    readonly websites: ReadonlyArray<{
      readonly companySlug: ICompany['slug'];
      readonly data: readonly IWebsite[];
    }>;
    readonly products: ReadonlyArray<{
      readonly websiteSlug: IWebsite['slug'];
      readonly data: readonly IProduct[];
    }>;
  };
  readonly siderActions: {
    readonly setCompanies: (payload: ICompany[]) => void;
    readonly setWebsites: (payload: IWebsite[], companySlug: ICompany['slug']) => void;
    readonly setProducts: (payload: IProduct[], websiteSlug: IWebsite['slug']) => void;
  };
}

export const siderReducer = (
  siderState: SiderNavigationContextProps['siderState'],
  siderActions: Actions,
): SiderNavigationContextProps['siderState'] => {
  switch (siderActions.type) {
    case SiderNavigationActionTypes.setCompanies:
      return {
        ...siderState,
        companies: siderActions.payload.data,
      };
    case SiderNavigationActionTypes.setWebsites:
      return {
        ...siderState,
        websites: [
          ...siderState.websites.filter((el) => el.companySlug !== siderActions.companySlug),
          {
            companySlug: siderActions.companySlug!,
            data: siderActions.payload.data,
          },
        ],
      };
    case SiderNavigationActionTypes.setProducts:
      return {
        ...siderState,
        products: [
          ...siderState.products.filter((el) => el.websiteSlug !== siderActions.websiteSlug),
          {
            websiteSlug: siderActions.websiteSlug!,
            data: siderActions.payload.data,
          },
        ],
      };
    case SiderNavigationActionTypes.clear:
      return {
        ...siderState,
        companies: [],
        websites: [],
        products: [],
      };
    default:
      return siderState;
  }
};

const SiderNavigationContext = createContext({} as SiderNavigationContextProps);

type SiderNavigationProviderProps = {
  children: React.ReactNode;
};

const SiderNavigationProvider = (props: SiderNavigationProviderProps) => {
  const { children } = props;
  const [openCompanyDropdownSlugs, setOpenCompanyDropdownSlugs] = useState<string[]>([]);
  const [openWebsiteDropdownSlugs, setOpenWebsiteDropdownSlugs] = useState<string[]>([]);
  const [companiesLoading, setCompaniesLoading] = useState(true);
  const [collapsed, setCollapsed] = useState(false);
  const [filteredCompanies, setFilteredCompanies] = useState<ICompany[]>([]);
  const [companySearchKey, setCompanySearchKey] = useState<string | undefined>();

  const toggleOpenCompanyDropdownSlugs = useCallback(
    (slug: string) => {
      if (openCompanyDropdownSlugs.includes(slug)) {
        setOpenCompanyDropdownSlugs(openCompanyDropdownSlugs.filter((el) => el !== slug));
      } else {
        setOpenCompanyDropdownSlugs([slug, ...openCompanyDropdownSlugs]);
      }
    },
    [openCompanyDropdownSlugs],
  );

  const toggleOpenWebsiteDropdownSlugs = useCallback(
    (slug: string) => {
      if (openWebsiteDropdownSlugs.includes(slug)) {
        setOpenWebsiteDropdownSlugs(openWebsiteDropdownSlugs.filter((el) => el !== slug));
      } else {
        setOpenWebsiteDropdownSlugs([slug, ...openWebsiteDropdownSlugs]);
      }
    },
    [openWebsiteDropdownSlugs],
  );

  const [siderState, dispatch] = useReducer(siderReducer, {
    companies: [],
    websites: [],
    products: [],
  });

  const siderActions = {
    setCompanies: (payload: ICompany[]) => {
      dispatch({ type: SiderNavigationActionTypes.setCompanies, payload });
    },
    setWebsites: (payload: IWebsite[], companySlug: ICompany['slug']) => {
      dispatch({ type: SiderNavigationActionTypes.setWebsites, payload, companySlug });
    },
    setProducts: (payload: IProduct[], websiteSlug: IWebsite['slug']) => {
      dispatch({ type: SiderNavigationActionTypes.setProducts, payload, websiteSlug });
    },
  };

  return (
    <SiderNavigationContext.Provider
      value={{
        siderState,
        siderActions,
        toggleOpenCompanyDropdownSlugs,
        openCompanyDropdownSlugs,
        setOpenCompanyDropdownSlugs,
        toggleOpenWebsiteDropdownSlugs,
        openWebsiteDropdownSlugs,
        setOpenWebsiteDropdownSlugs,
        companiesLoading,
        setCompaniesLoading,
        collapsed,
        setCollapsed,
        filteredCompanies,
        setFilteredCompanies,
        companySearchKey,
        setCompanySearchKey,
        dispatch,
      }}
    >
      {children}
    </SiderNavigationContext.Provider>
  );
};

export { SiderNavigationContext, SiderNavigationProvider };
