import { Col } from 'antd';
import React from 'react';
import { WebsiteAddFieldsRowStyled } from './WebsiteAddFieldsRow.styled';
import { Controller, DeepMap, FieldError } from 'react-hook-form';
import BaseInput from 'components/baseComponents/BaseInput';
import { Control } from 'react-hook-form';

type WebsiteAddFieldsRowPropsType<T> = {
  control: Control;
  index: number;
  item: AddWebsiteFormType;
  errors: DeepMap<T, FieldError> | any;
  setValue?: (
    name: string,
    value: unknown,
    config?: Partial<{ shouldValidate: boolean; shouldDirty: boolean }> | undefined,
  ) => void;
};
const WebsiteAddFieldsRow = <
  InviteUsersFormType extends Record<string, string | undefined | null>,
>({
  control,
  index,
  item,
  errors,
  setValue,
}: WebsiteAddFieldsRowPropsType<InviteUsersFormType>): React.ReactElement => {
  return (
    <WebsiteAddFieldsRowStyled align={'middle'}>
      <Col span={24}>
        <Controller
          control={control}
          name={`websites[${index}].url`}
          defaultValue={item.url}
          inputTitle={false}
          label={'URL'}
          as={<BaseInput inputTitle={false} />}
          error={errors.websites?.[index]?.url}
          setFormValue={setValue}
        />
      </Col>
      <Col span={24}>
        <Controller
          control={control}
          name={`websites[${index}].name`}
          as={<BaseInput inputTitle={false} />}
          defaultValue={item.name}
          inputTitle={false}
          label={'Display name'}
          error={errors.websites?.[index]?.name}
          setFormValue={setValue}
        />
      </Col>
    </WebsiteAddFieldsRowStyled>
  );
};

export default WebsiteAddFieldsRow;
