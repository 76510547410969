import React from 'react';
import { lazyLoadable } from 'utils';

export const LoginPageLazy = lazyLoadable(
  () => import('./LoginPage'),
  // TODO: needs proper skeleton
  <>
    {/* <HomePageTableHead />
    <HomePageTableItem /> */}
  </>,
);
