import styled from 'styled-components';
import SearchIcon from 'assets/image/svg/feather-search.svg';

export const DashboardHeaderStyled = styled.header`
  width: 100%;
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;

  .header-logo {
    height: 40px;
    display: none;
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  .header-right-side {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 1;
  }

  .search-mobile-container {
    display: none;
  }

  .search {
    display: block;
    background-color: white !important;
    border-radius: 10px;
    line-height: 100%;
    width: 100%;
    height: 60px;
    box-shadow: 0px 10px 30px #1818180d;
    background: #f6f7fc url(${SearchIcon}) no-repeat 20px center;
    padding-left: 65px;
    &::placeholder {
      color: #7d8ca2;
      opacity: 0.3;
    }
  }

  .search-btn {
    display: none;
    border-radius: 10px;
    background-color: white;
    padding: 15px;
  }

  .burger-btn {
    border-radius: 10px;
    background-color: #4b4dc8;
    padding: 5px;
    margin-left: 30px;
    display: flex;
    align-items: center;

    .burger-icon {
      padding: 0px 20px;
    }

    .profile-image {
      object-fit: cover;
      width: 50px;
      height: 50px;
      border-radius: 10px;
    }
  }

  @media only screen and (max-width: 1366px) {
    .search {
      height: 40px;
    }

    .burger-btn {
      height: 40px;
      padding: 5px;

      .profile-image {
        width: 30px;
        height: 30px;
      }
    }
  }

  @media only screen and (max-width: 1000px) {
    background-color: white;
    .search {
      height: 40px;
      background-color: #e8edf8 !important;
    }

    .header-logo {
      display: block;
      margin-right: 30px;
    }

    .burger-btn {
      padding: 15px;
      margin-left: 15px;
      .burger-icon {
        padding: 0px;
      }
      .profile-image {
        display: none;
      }
    }
  }

  @media only screen and (max-width: 767.9px) {
    justify-content: space-between;
    padding: 30px;

    .header-logo {
      display: block;
    }

    .search {
      display: none;
    }

    .search-mobile-container {
      display: block;
      position: relative;

      flex: 1;
      .search {
        display: block;
        padding-right: 15px;
        background-color: #e8edf8 !important;
      }
      button {
        position: absolute;
        right: 5px;
        top: 35%;
      }
    }

    .search-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 40px;
    }
  }

  @media only screen and (max-width: 680px) {
    padding: 15px;
  }
`;
