import React from 'react';
import { lazyLoadable } from 'utils';

export const ListPageLazy = lazyLoadable(
  () => import('./ListPage'),
  <>
    {/* <HomePageTableHead /> */}
    {/* <HomePageTableItem /> */}
  </>,
);
