export enum AppTypesEnum {
  ApplicationReady = 'ApplicationReady',
  SetUser = 'SetUser',
  ToggleUserLoginStatus = 'ToggleUserLoginStatus',
  SetUserTree = 'SetUserTree',
  ToggleTerms = 'ToggleTerms',
  ToggleSider = 'ToggleSider',
  UpdateMobileStats = 'UpdateMobileStats',
}

type AppPayload = {
  [AppTypesEnum.ApplicationReady]: AppReadyType;
  [AppTypesEnum.SetUser]: UserObjectType;
  [AppTypesEnum.ToggleUserLoginStatus]: boolean;
  [AppTypesEnum.SetUserTree]: SiderTreeBeardTreeType[];
  [AppTypesEnum.ToggleTerms]: boolean;
  [AppTypesEnum.ToggleSider]: boolean;
  [AppTypesEnum.UpdateMobileStats]: {
    statsVisible: boolean;
    screenFeedback?: boolean;
  };
};

export type AppActions = ActionMap<AppPayload>[keyof ActionMap<AppPayload>];

export const appReducer = (state: AppContextType, action: AppActions): AppContextType => {
  switch (action.type) {
    case AppTypesEnum.ApplicationReady:
      return {
        ...state,
        ...action.payload,
      };
    case AppTypesEnum.SetUser:
      return {
        ...state,
        user: action.payload,
      };
    case AppTypesEnum.ToggleUserLoginStatus:
      return {
        ...state,
        hasToken: action.payload,
      };
    case AppTypesEnum.SetUserTree:
      return {
        ...state,
        userTree: action.payload,
      };
    case AppTypesEnum.ToggleTerms: {
      return {
        ...state,
        termsVisible: action.payload,
      };
    }
    case AppTypesEnum.ToggleSider:
      return {
        ...state,
        isSiderOpen: action.payload,
      };
    case AppTypesEnum.UpdateMobileStats: {
      const { screenFeedback, statsVisible } = action.payload;
      if (typeof screenFeedback !== 'undefined')
        return {
          ...state,
          mobileStats: {
            ...state.mobileStats,
            statsVisible,
            screenFeedback,
          },
        };

      return {
        ...state,
        mobileStats: {
          ...state.mobileStats,
          statsVisible,
        },
      };
    }
    default:
      return state;
  }
};

type AppActionsType = {
  setApplicationReady: (data: AppReadyType) => {
    type: AppTypesEnum.ApplicationReady;
    payload: AppReadyType;
  };
  setUser: (data: UserObjectType) => {
    type: AppTypesEnum.SetUser;
    payload: UserObjectType;
  };
  toggleUserLoginStatus: (status: boolean) => {
    type: AppTypesEnum.ToggleUserLoginStatus;
    payload: boolean;
  };
  setUserTree: (data: SiderTreeBeardTreeType[]) => {
    type: AppTypesEnum.SetUserTree;
    payload: SiderTreeBeardTreeType[];
  };
  toggleTerms: (status: boolean) => {
    type: AppTypesEnum.ToggleTerms;
    payload: boolean;
  };
  toggleSider: (status: boolean) => {
    type: AppTypesEnum.ToggleSider;
    payload: boolean;
  };
  updateMobileStats: ({
    statsVisible,
    screenFeedback,
  }: {
    statsVisible: boolean;
    screenFeedback?: boolean;
  }) => {
    type: AppTypesEnum.UpdateMobileStats;
    payload: {
      statsVisible: boolean;
      screenFeedback: boolean;
    };
  };
};

export const appActions: AppActionsType = {
  setApplicationReady: (data) => ({
    type: AppTypesEnum.ApplicationReady,
    payload: data,
  }),
  setUser: (data) => ({
    type: AppTypesEnum.SetUser,
    payload: data,
  }),
  toggleUserLoginStatus: (payload) => ({
    type: AppTypesEnum.ToggleUserLoginStatus,
    payload,
  }),
  setUserTree: (payload) => ({
    type: AppTypesEnum.SetUserTree,
    payload,
  }),
  toggleTerms: (payload) => ({
    type: AppTypesEnum.ToggleTerms,
    payload,
  }),
  toggleSider: (payload) => ({
    type: AppTypesEnum.ToggleSider,
    payload,
  }),
  updateMobileStats: ({ statsVisible, screenFeedback }) => ({
    type: AppTypesEnum.UpdateMobileStats,
    payload: {
      statsVisible,
      screenFeedback: screenFeedback ? screenFeedback : false,
    },
  }),
};
