import { useForm } from 'react-hook-form';
import { updateAuthUser } from 'services';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { message } from 'antd';
import { validateAxiosErrorInForms } from 'utils/helpers/functions';
import { useContext } from 'react';
import { AppContext } from 'state/appContext';
import { emailRegexp } from 'utils/helpers/const';
import { appActions } from 'state/appReducer';

const schema = Yup.object().shape({
  first_name: Yup.string().required('First name is required').max(50, 'Max 50 characters allowed.'),
  last_name: Yup.string().required('Last name is required').max(50, 'Max 50 characters allowed.'),
  email: Yup.string().matches(emailRegexp, 'Invalid email address.'),
});

type PersonalInfo = {
  id: string;
  isDisabled: boolean;
  name: string;
  value: string;
  placeholder: string;
};

const useUserDetailPage = () => {
  const { dispatch } = useContext(AppContext);
  const {
    state: { user },
  } = useContext(AppContext);

  const avatar = user?.avatar as string;
  const authUser = user || {
    id: 0,
    first_name: '',
    last_name: '',
    email: '',
    is_active: false,
    role: [],
    initial_company: '',
  };
  const is_active = true;

  const fields: PersonalInfo[] = authUser.initial_company
    ? [
        {
          id: authUser.initial_company,
          isDisabled: true,
          name: 'initial_company',
          value: authUser.initial_company,
          placeholder: 'Company name',
        },
        {
          id: authUser.first_name,
          isDisabled: !is_active,
          name: 'first_name',
          value: authUser.first_name,
          placeholder: 'First name',
        },
        {
          id: authUser.last_name,
          isDisabled: !is_active,
          name: 'last_name',
          value: authUser.last_name,
          placeholder: 'Last name',
        },
        {
          id: authUser.email,
          isDisabled: !is_active,
          name: 'email',
          value: authUser.email,
          placeholder: 'Email',
        },
      ]
    : [
        {
          id: authUser.first_name,
          isDisabled: !is_active,
          name: 'first_name',
          value: authUser.first_name,
          placeholder: 'Name',
        },
        {
          id: authUser.last_name,
          isDisabled: !is_active,
          name: 'last_name',
          value: authUser.last_name,
          placeholder: 'Surname',
        },
        {
          id: authUser.email,
          isDisabled: !is_active,
          name: 'email',
          value: authUser.email,
          placeholder: 'Email',
        },
      ];

  const { handleSubmit, control, errors, register, setValue, getValues, setError } = useForm({
    reValidateMode: 'onChange',
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  const onSubmit = handleSubmit(async (data) => {
    const params: Omit<UserSubmitInfo, 'companies'> = {
      first_name: data.first_name,
      last_name: data.last_name,
      email: data.email,
    };

    if (emailRegexp.test(params.email) === false) {
      message.error('Invalid email address.');
      return;
    }
    try {
      await updateAuthUser(Number(user?.id), params);
      message.success('User updated!');
      dispatch(appActions.setUser({ ...user, ...params }));
    } catch (error) {
      validateAxiosErrorInForms(error, setError);
    }
  });

  return {
    handleSubmit,
    control,
    errors,
    onSubmit,
    authUser,
    avatar,
    getValues,
    setValue,
    fields,
    register,
    is_active,
  };
};

export default useUserDetailPage;
