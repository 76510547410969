import { Row } from 'antd';
import styled from 'styled-components';

export const FormFieldSectionStyled = styled(Row)`
  width: 100%;
  .setup-item__left-side {
    p {
      text-align: left;
    }
    .left-side__title {
      text-transform: uppercase;
    }
    .left-side__bottom-text {
    }
  }
  /* .setup-item__right-side__text {
    text-align: right;
    opacity: 0.6;
  } */
`;
