import styled from 'styled-components';

export const BulkWebsiteAddStyled = styled.form`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;

  .bulk-website-add__download-sample {
    margin-top: 40px;
    background-color: transparent;
  }

  .file-drop {
    border-radius: 10px;
    padding: 30px;
    border: 1px dashed #808e9b;
    cursor: pointer;
  }

  .switch-to-manual {
    align-self: center;
    background-color: transparent;
    p {
      text-decoration: underline;
    }
  }
  /* .content__form-container {
    padding-left: 2.5rem;
    padding-right: 4rem;
    .form-container__company-select {
      margin-bottom: 2.1rem;
    }
  }

  .file-drop-zone-container {
    padding-left: 2.5rem;
    padding-right: 4rem;
    .file-drop {
      background-color: red;
      background: #f6f9fc 0% 0% no-repeat padding-box;
      border-radius: 12px;
      padding: 1.4rem 2rem;
      cursor: pointer;
      svg {
        margin-right: 5%;
      }
      .drop-zone__title {
        text-transform: uppercase;
        margin-bottom: 0.2rem;
      }
    }
  }

  .content__submit-container {
    padding-top: 1rem;
    flex-wrap: nowrap;
    .switch-to-manual {
      padding-left: 0;
    }
    .wrapper {
      justify-content: flex-end;
      display: flex;
      .invite-user__download-sample {
        flex-wrap: nowrap;
        align-items: center;
        p {
          margin-left: 1rem;
        }
      }
    }

    .btn {
      height: 4rem;
      border-radius: 2rem;
      width: 15rem;
      margin-left: 1rem;
    }
  } */
`;
