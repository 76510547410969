import styled from 'styled-components';

export const UserAddStyled = styled.div`
  width: 100%;
  @media only screen and (max-width: 550px) {
    .form-container__company-select {
      padding: 0rem 1rem;
      justify-content: space-between;
      .ant-col.ant-col-9 {
        min-width: 100%;
      }
      .ant-col.ant-col-5 {
        display: none;
      }
    }
    .content__form-container {
      .scrollable {
        padding: 0rem 1rem;
        .ant-row {
          flex-direction: column;
          .ant-col {
            min-width: 100%;
            padding: 0;
            margin: 0.25rem 0rem;
            .ant-row {
              .input-container {
                min-width: 100%;
              }
            }
          }
        }
      }
    }
    .content__submit-container {
      flex-direction: column;
      button {
        margin: 0.25rem 0rem;
      }
      .skip-step {
        display: none;
      }
    }
  }
`;
