import { Row } from 'antd';
import styled from 'styled-components';

export const HeaderAccountStyled = styled(Row)`
  height: 100%;
  margin-right: 1.4rem;
  .pop-over {
    height: 2rem;
    cursor: pointer;
    .pop-over__text {
      display: block;
      color: #000;
      text-transform: capitalize;
      margin: 0 1rem;
      min-width: max-content;
    }
  }
  span {
    .user_avatar {
      width: 2.5rem;
      height: 2.5rem;
      border-radius: 50%;
      object-fit: cover;
      margin-right: 0.25rem;
    }
  }
`;
export const AccountPopoverStyled = styled.div`
  .pdf-link {
    :hover {
      color: rgba(0, 0, 0, 0.85);
      background: rgba(0, 0, 0, 0.018);
    }
  }
`;
