import { message } from 'antd';
import { useProductContainerContext } from 'state/productContainer/state/context';
import React, { createContext, useState } from 'react';
import { DropResult } from 'react-beautiful-dnd';

interface IListPageContext {
  readonly pinnedSlides: SlideObjectType[];
  readonly notPinnedSlides: SlideObjectType[];
  readonly setPinnedSlides: (slides: SlideObjectType[]) => void;
  readonly setNotPinnedSlides: (slides: SlideObjectType[]) => void;
  readonly handlePinnedSlidesDrag: (
    websiteSlug: string,
    productSlug: string,
  ) => (result: DropResult) => void;
  readonly handleNotPinnedSlidesDrag: (
    websiteSlug: string,
    productSlug: string,
  ) => (result: DropResult) => void;
}

interface Props {
  readonly children: React.ReactNode;
}

const ListPageContext = createContext({} as IListPageContext);

const findSrcAndDestSlides = (slides: SlideObjectType[], result: DropResult) => {
  const sourceSlide = slides.find((_, index) => index === result.source.index) as SlideObjectType;

  const destinationSlide = slides.find(
    (_, index) => index === result.destination?.index,
  ) as SlideObjectType;

  return {
    sourceSlide,
    destinationSlide,
  };
};

const ListPageProvider = ({ children }: Props) => {
  const { updateSlidesOnDrop } = useProductContainerContext();

  // State
  const [pinnedSlides, setPinnedSlides] = useState<SlideObjectType[]>([]);
  const [notPinnedSlides, setNotPinnedSlides] = useState<SlideObjectType[]>([]);

  // FNs
  const handlePinnedSlidesDrag = (
    websiteSlug: string,
    productSlug: string,
  ): ((result: DropResult) => void) => {
    return (result: DropResult) => {
      if (result.destination === null) {
        message.error('Invalid slide destination');
        return;
      }

      const { sourceSlide, destinationSlide } = findSrcAndDestSlides(pinnedSlides, result);

      updateSlidesOnDrop({
        websiteSlug,
        productSlug,
        sourceSlide: {
          order: sourceSlide.order,
          slug: sourceSlide.slug,
          status: sourceSlide.status,
        },
        destionationSlide: {
          order: destinationSlide.order,
          slug: destinationSlide.slug,
          status: destinationSlide.status,
        },
      });
      const items = [...pinnedSlides];
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination!.index, 0, reorderedItem);
      setPinnedSlides(items);
    };
  };

  const handleNotPinnedSlidesDrag = (
    websiteSlug: string,
    productSlug: string,
  ): ((result: DropResult) => void) => {
    return (result: DropResult) => {
      if (result.destination === null) {
        message.error('Invalid slide destination');
        return;
      }

      const { sourceSlide, destinationSlide } = findSrcAndDestSlides(notPinnedSlides, result);

      updateSlidesOnDrop({
        websiteSlug,
        productSlug,
        sourceSlide: {
          order: sourceSlide.order,
          slug: sourceSlide.slug,
          status: sourceSlide.status,
        },
        destionationSlide: {
          order: destinationSlide.order,
          slug: destinationSlide.slug,
          status: destinationSlide.status,
        },
      });

      const items = [...notPinnedSlides];
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination!.index, 0, reorderedItem);
      setNotPinnedSlides(items);
    };
  };

  return (
    <ListPageContext.Provider
      value={{
        pinnedSlides,
        setPinnedSlides,
        notPinnedSlides,
        setNotPinnedSlides,
        handlePinnedSlidesDrag,
        handleNotPinnedSlidesDrag,
      }}
    >
      {children}
    </ListPageContext.Provider>
  );
};

export { ListPageContext, ListPageProvider };
