import styled from 'styled-components';

export const WebsiteSettingsStyled = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  .switcher-div {
    display: flex;
    background: white;
    border-radius: 10px;
    border: 1px solid #8085da33;
    padding: 5px;
    margin-bottom: 1rem;
    button {
      font-family: 'Noir Pro Regular';
      font-size: ${18 / 16}rem;
      color: rgba(128, 142, 155, 0.6);
      height: 60px;
      width: 100%;
      display: flex;
      justify-content: center;
      border-radius: 10px;
      &.active {
        background: #4b4dc8;
        color: white;
      }
    }
  }
`;
