import React from 'react';
import BaseContentHeader from 'components/baseComponents/BaseContentHeader';
import DashboardLayout from 'views/dashboardLayout/DashboardLayout';
import { DocumentPageStyled } from './DocumentPage.styled';

export default function PrivacyPolicy() {
  return (
    <DashboardLayout>
      <DocumentPageStyled>
        <BaseContentHeader>
          <h1>Privacy Policy</h1>
        </BaseContentHeader>
        <p>
          <strong>This privacy notice was last updated in September 2021.&nbsp;</strong>{' '}
        </p>
        <p>
          The terms (&ldquo;us&rdquo;, &ldquo;we&rdquo;, the &ldquo;Company&rdquo; or
          &ldquo;FRT&rdquo;) refer to Flat Rock Technology Limited, a company incorporated in
          England and Wales (registration number 6461660) having its registered office at 5 St
          John&rsquo;s Lane, London EC1M 4BH.&nbsp;
        </p>
        <p>
          We, at Flat Rock Technology treat the privacy of our clients with the highest importance.
          This policy details the measures we take to preserving and safely guarding your privacy
          when using our Service.&nbsp;
        </p>
        <p>
          This policy is committed to complying with the General Data Protection Regulation and the
          Data Protection Act 2018, once enacted. Looking after the personal information you share
          with us is very important, and we want you to be confident that your personal data is kept
          safely and securely and to understand how we use it to offer you a better service.&nbsp;
        </p>
        <p>
          Without prejudice to your rights under applicable law, we reserve the right (as ‘Data
          Controller’) to amend this Privacy Policy without prior notice to reflect technological
          advancements, legal and regulatory changes and good business practices. If We change our
          privacy practices, an updated version of this Privacy Policy will reflect those changes
          and we will notify you of such changes by updating the effective date of this Privacy
          Policy.
        </p>

        <h2>1. Information collection&nbsp;</h2>
        <p>
          Operation of the Service may require collection and processing of the following data:{' '}
          <br />
          1.1 Information given to us when you contact us for any reason. <br />
          1.2 Data offered by filled out forms, like a registration or purchase.&nbsp;
        </p>
        <h2>2. Cookies&nbsp;</h2>
        <p>
          Our advertisers and us may have the occasion to collect information in regards to your
          computer for our services. The information is gained in a statistical manner for our use
          or advertisers. Data gathered will not identify you personally. It is strictly aggregate
          statistical data about our users and how they used our resources. No identifying personal
          information will be shared at any time via cookies. Close to the above, data gathering can
          be about general online use through a cookie file. When used, cookies are automatically
          placed in your hard drive where information transferred to your computer can be found.
          These cookies are designed to help us correct and improve our services or products for
          you. You may elect to decline all cookies via your computer. Every computer has the
          ability to decline file downloads like cookies. Your browser has an option to enable the
          declining of cookies. We are not responsible, nor do we have control of the cookies
          downloaded from advertisements. They are downloaded only if you click on the
          advertisement.&nbsp;
        </p>
        <h2>3. Your information and how it is used&nbsp;</h2>
        <p>
          Primarily, we collect and store data about you to help us provide better service and
          products to you. The following are purposes we may use your information for: <br />
          3.1 At any time you request information from us via a form or other electronic
          transmission we may use your information to fulfil that request relating to our services
          and products. We may also communicate with you on other products or services you may find
          of interest, only when consent has been provided. <br />
          3.2 Contracts we make with you create a commitment, which may require contact or use of
          your information. <br />
          3.3 We have the right to notify you of changes of our products or services that could
          affect you. <br />
          3.4 Information on products or services similar to those of an existing consumer purchase
          may be communicated to you. The information sent to you in a communication will be similar
          to the subject of a recent sale. <br />
          3.5 We may also use your information or allow a third party use of this data, to offer you
          information about unrelated products or services you may be interested in. We or third
          parties can only communicate if you have consented to such communication and data use.{' '}
          <br />
          3.6 New consumers can be contacted by us or third parties only if consent has been
          granted, and only for those communications you have granted. <br />
          3.7 Be aware we do not reveal identifiable information about you to our advertisers,
          though we may at times share statistical visitor information with our advertisers.&nbsp;
        </p>
        <h2>4. Collection of non-personal information&nbsp;</h2>
        <p>
          Non-Personal Information is information that is not personal, such as questions, comments,
          ideas and suggestions that you provide, or information that we may collect that is not
          linked to any Personal Information. Non-Personal Information will be treated as
          non-confidential, and we may disclose and/or use that information without any obligation
          whatsoever to you, for any purpose and through any means.&nbsp;
        </p>
        <h2>5. Storage of personal data&nbsp;</h2>
        <p>
          5.1 The European Economic Area is large, but we may have to transfer data outside of this
          area. If data is transferred outside the European Economic Area it will be for storing and
          processing. A processing staff operating outside this area may belong to our product or
          service or a supplier, in which they can process or store your information. An example: to
          process and complete your sale or offer support services we may have to go outside the
          European Economic Area for the transfer. When you click submit of your payment details,
          personal information or other electronic communication you agree to the transfer for
          storage and processing. We take all necessary steps for security known to be in agreement
          with the Privacy Policy found here. <br />
          5.2 Information submitted by you is stored on secure servers we have. Any payment or
          transaction details will be encrypted for full safety measures to be in use. <br />
          5.3 As you know, transmission of data on the internet is never guaranteed regarding
          safety. It is impossible to guarantee your safety with electronic data and transmission.
          You are therefore at your own risk if you elect to transmit any data. When offered you may
          create a password, but you are responsible for keeping it confidential.&nbsp;
        </p>
        <h2>6. Information sharing&nbsp;</h2>
        <p>
          6.1 If necessary, we may share personal information to our group members including such
          entities as subsidiaries, holding companies and their subsidiaries. Information is shared
          only when applicable. <br />
          6.2 Third party disclosure may be necessary in regards to personal information: <br />
          6.2.1 A sale of our business or its assets, in full or part, to a third party may require
          personal data sharing. <br />
          6.2.2 Legally, we may be asked to share and disclose data details.
          <br />
          6.2.3 To assist in reducing credit risk and fraud protection.&nbsp;
        </p>
        <h2>7. Third party links&nbsp;</h2>
        <p>
          Links on the Service that belong to third parties may be found. These websites have their
          Privacy Policy, which you agree to when you link to the site. You should read this third
          party policy. We do not accept claims of liability or responsibility in any way for these
          policies or links, as we have no way to control the third party sites.
        </p>
        <h2>8. Contacting us&nbsp;</h2>
        <p>
          We welcome any queries, requests you may have regarding this Privacy Policy, or comments.
          Please do not hesitate and feel free to contact us on our support section.
        </p>
      </DocumentPageStyled>
    </DashboardLayout>
  );
}
