import React, { useContext, useEffect, useState, useCallback } from 'react';
import DashboardLayout from 'views/dashboardLayout/DashboardLayout';
import BaseSubmitButton from 'components/baseComponents/BaseSubmitButton';
import { UserDetailsStyled } from './UserDetails.styled';
import { DynamicFormField } from 'components/baseComponents';
import { Col, Divider, message, Skeleton, Radio, Collapse, Row, Switch, Checkbox } from 'antd';
import { ArrowIcon } from 'components/svg';
import { Text } from 'components/styles';
import WebsiteBorder from 'assets/image/svg/sider-website-border.svg';
import useUserDetails from './useUserDetails';
import ConfigHeader from './components/ConfigHeader';
import PermissionToggleItem from './components/PermissionToggleItem';
import { Controller } from 'react-hook-form';
import { cache } from 'swr';
import { updateAuthUserImage } from 'services/userServices';
import { ImageTypes } from 'utils/constants';
import { AppContext } from 'state/appContext';
import { useHistory } from 'react-router-dom';

type FormType = {
  [key: string]: any;
};

type PersonalInfo = {
  id: number;
  isDisabled: boolean;
  name: string;
  value: string;
  placeholder: string;
};

const UserDetails = () => {
  const {
    fields,
    User: { id, role, is_active, first_name, last_name },
    avatar,
    control,
    errors,
    onSubmit,
    onResendInvite,
    companies,
    setCompanies,
    available_companies,
  } = useUserDetails();
  const { state } = useContext(AppContext);
  const [personalInfo, setPersonalInfo] = useState<PersonalInfo[]>(fields);
  const [activeKey] = useState<number>(1);
  const [userImage, setUserImage] = useState({
    type: 'image.png',
    url: '',
  });
  const { goBack } = useHistory();

  useEffect(() => {
    if (avatar) {
      setUserImage({ ...userImage, url: avatar });
    }
  }, [avatar]);

  useEffect(() => {
    setCompanies([...available_companies]);
  }, [available_companies]);

  useEffect(() => {
    setPersonalInfo(fields);
  }, [fields[0].value, fields[1].value, is_active, fields[2].value, fields[3].value]);

  const handleUserRoleChange = useCallback(
    (companyId: number, type: 'company_admin' | 'website_admin') => {
      let tempCompanies = [...companies];
      //TODO: Currently, time complexity of this code is O(n square) which is not good. Think of a way to get O(n) time complexity, that is, do only one mapping instead of nested mapping.
      tempCompanies = tempCompanies.map((company) => {
        if (company.id === companyId) {
          // Check if company admin or website admin checkbox was touched
          if (type === 'company_admin') {
            if (company.user_role === null) {
              company.user_role = 3;
              company.has_access = true;
              company.websites.map((website) => {
                website.has_access = true;
                return website;
              });
            } else {
              company.user_role = null;
              company.has_access = false;
              company.websites.map((website) => {
                website.has_access = false;
                return website;
              });
            }
          }
          // Check if company admin or website admin checkbox was touched
          if (type === 'website_admin') {
            if (company.user_role === null) {
              company.user_role = 4;
              company.has_access = true;
              company.websites.map((website) => {
                website.has_access = true;
                return website;
              });
            } else {
              company.user_role = null;
              company.has_access = false;
              company.websites.map((website) => {
                website.has_access = false;
                return website;
              });
            }
          }
        }
        return company;
      });
      setCompanies(tempCompanies);
    },
    [companies],
  );

  const handleUserPermissionChange = useCallback(
    (companyId: number, websiteId: number) => {
      let tempCompanies = [...companies];
      //TODO: Currently, time complexity of this code is O(n square) which is not good. Think of a way to get O(n) time complexity, that is, do only one mapping instead of nested mapping.
      tempCompanies = tempCompanies.map((company) => {
        if (company.id === companyId) {
          company.websites.map((website) => {
            if (website.id === websiteId) {
              website.has_access = !website.has_access;
            }
            return website;
          });
          // Check if at least one permission is ON and assign user website admin role
          const permissions = company.websites.map((p) => p.has_access);
          if (permissions.indexOf(true) !== -1) {
            company.user_role = 4;
            company.has_access = true;
          }
          // Vice versa
          if (permissions.indexOf(true) === -1) {
            company.user_role = null;
            company.has_access = false;
          }
        }
        return company;
      });
      setCompanies(tempCompanies);
    },
    [companies],
  );

  const renderPermissions = () => (
    <>
      {state.user?.role != null && state.user.role[0] === 'Super Admin' ? (
        <Controller
          disabled={!is_active}
          control={control}
          name="organization_admin"
          as={
            <PermissionToggleItem
              disabled={!is_active}
              text="Organization Admin"
              defaultChecked={role === 'Organization Admin'}
              value={role === 'Organization Admin'}
              onChange={() => {}}
            />
          }
          defaultValue={role === 'Organization Admin'}
          onFocus={() => {}}
        />
      ) : null}
      {companies.map((company, index) => {
        return (
          <div key={index} className="company-box">
            <Collapse
              accordion
              ghost
              expandIconPosition="right"
              expandIcon={({ isActive }) => (
                <div className="arrow-div" style={isActive ? { background: '#4B4DC8' } : undefined}>
                  <ArrowIcon
                    style={isActive ? { transform: 'rotate(0)' } : { marginBottom: '-3px' }}
                    fill={isActive ? '#fff' : '#181818'}
                  />
                </div>
              )}
            >
              <Collapse.Panel
                className="website_header"
                header={company.name}
                key={index}
                extra={
                  <div
                    className="role-checkbox"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <Radio
                      onChange={handleUserRoleChange.bind(this, company.id, 'website_admin')}
                      checked={company.user_role === 4}
                    >
                      Website Admin
                    </Radio>
                    <Radio
                      onChange={handleUserRoleChange.bind(this, company.id, 'company_admin')}
                      checked={company.user_role === 3}
                    >
                      Company Admin
                    </Radio>
                  </div>
                }
              >
                {company.websites.map((website) => {
                  return (
                    <Row key={website.id}>
                      <div className="website-box">
                        <img src={WebsiteBorder} alt="" />
                        <label>{website.name}</label>
                      </div>

                      <Switch
                        className={'switch'}
                        onChange={handleUserPermissionChange.bind(this, company.id, website.id)}
                        checked={website.has_access}
                        disabled={company.user_role === 3 ? true : false}
                      />
                    </Row>
                  );
                })}
              </Collapse.Panel>
            </Collapse>
          </div>
        );
      })}
    </>
  );

  const renderPersonalInfo = () => {
    if (personalInfo[0].value.length > 0) {
      return (
        <>
          {personalInfo.map(({ id, isDisabled, name, value, placeholder }) => (
            <DynamicFormField<FormType>
              key={id}
              name={name}
              label={placeholder}
              value={value}
              disabled={isDisabled}
              type="Input"
              control={control}
              errors={errors}
            />
          ))}
        </>
      );
    }
  };

  const handleUpload = async (e: any) => {
    if (e.target.files.length > 1) return;
    const uploadedFile = e.target.files[0];
    const imageUrl = URL.createObjectURL(uploadedFile);
    if (!ImageTypes.some((t) => t === uploadedFile.type)) {
      message.error('Please upload JPG, PNG or JPEG type files');
      return;
    }
    if (uploadedFile.size > 2097152) {
      message.error('File is larger than 2mb');
      return;
    }

    setUserImage({
      type: uploadedFile.type,
      url: imageUrl,
    });

    const formData = new FormData();
    formData.append('avatar', uploadedFile);
    formData.append('_method', 'PUT');
    try {
      updateAuthUserImage(id, formData);
      message.success('Image was successfully uploaded.');
    } catch (e) {
      console.error('There was an error while uploading image please try again later.');
    }
  };

  return (
    <DashboardLayout>
      <UserDetailsStyled>
        <ConfigHeader name={first_name + ' ' + last_name} isActive={is_active} id={id} />
        <Divider style={{ marginBottom: '7px' }}></Divider>

        <form onSubmit={onSubmit} className={'config-form'}>
          <div className="userContainerWrapper">
            <Row align="middle" justify="start" className={'user-image-container'}>
              <Col md={16}>
                <div className="user-image-box">
                  <div className="avatarWrapper">
                    <label>
                      {avatar ? (
                        <>
                          <img
                            width={'103px'}
                            height={'103px'}
                            src={userImage.url}
                            alt={`${first_name}'s profile image`}
                            className="user_image"
                          />
                          <input
                            type={'file'}
                            id={'imageUpload'}
                            name={'userImage'}
                            accept=".jpeg, .png, .jpg"
                            hidden={true}
                            onChange={handleUpload}
                          />
                        </>
                      ) : (
                        <Skeleton.Button
                          style={{ width: '124px', height: '124px', borderRadius: '10px' }}
                          active
                        />
                      )}
                    </label>
                    <div className="responsive_style UserDetails">
                      <h3>{`${first_name} ${last_name}`}</h3>
                    </div>
                  </div>

                  <div className="user-details">
                    <h3>{`${first_name} ${last_name}`}</h3>
                    <Divider
                      style={{ borderTop: '2px solid #DDE3EF', margin: '15px 0px' }}
                    ></Divider>
                    <p className="user-image-feedback">Minimum portrait dimensions 300 x 300px</p>
                  </div>
                </div>
              </Col>
              <Col className={'btn_wrapper'} md={8}>
                <div className="responsive_content">
                  <Divider
                    style={{ borderTop: '2px solid #DDE3EF', margin: '0 0px 15px' }}
                  ></Divider>
                  <p className="user-image-feedback">Minimum portrait dimensions 300 x 300px</p>
                </div>

                <BaseSubmitButton
                  title={'Resend Invite'}
                  type={'button'}
                  withArrow
                  className={'resend-invite-btn'}
                  backgroundColor={'#01AE92'}
                  onClick={() => onResendInvite()}
                />
                <Text className={'resend-feedback'} fontSize={13}>
                  An email with a link to recover user&apos;s password will be sent to them
                </Text>
              </Col>
            </Row>
          </div>
          <Row style={{ marginTop: '60px' }}>
            <Col md={7} xs={24} className={'config__right-part'}>
              <h3>Change Details</h3>
              <Divider style={{ margin: '60px 0px', marginTop: '30px' }}></Divider>
              {renderPersonalInfo()}
            </Col>
            <Col md={1} xs={0}>
              <Divider style={{ margin: '60px 0px', marginTop: '51px' }}></Divider>
            </Col>

            <Col md={16} xs={24}>
              <h3>Permissions</h3>
              <Divider style={{ margin: '60px 0px', marginTop: '30px' }}></Divider>
              <Collapse
                activeKey={[activeKey]}
                ghost
                className={'middle-part__collapsible'}
                // expandIcon={() => <Arrow />}
              >
                {renderPermissions()}
              </Collapse>
            </Col>
          </Row>
          <Divider style={{ margin: '60px 0px', marginTop: '30px' }}></Divider>
          <Row>
            <Col xs={24} className={'submit-container'}>
              <BaseSubmitButton
                title={'Cancel'}
                className={'submit-btn'}
                backgroundColor={'#808E9B'}
                style={{ width: '284px' }}
                type={'reset'}
                onClick={() => {
                  goBack();
                  return false;
                }}
              />

              {is_active && (
                <BaseSubmitButton
                  title={'Save Changes'}
                  withArrow
                  className={'submit-btn'}
                  backgroundColor={'#4B4DC8'}
                  style={{ width: '284px' }}
                  onClick={() => {
                    cache.clear();
                  }}
                />
              )}
            </Col>
          </Row>
        </form>
      </UserDetailsStyled>
    </DashboardLayout>
  );
};

export default UserDetails;
