import { Row, Select } from 'antd';
import React, { forwardRef, useEffect, useState } from 'react';
import { BaseSelectStyled, ErrorStyled } from './BaseSelect.styled';
import { Text } from 'components/styles';
import { ReactComponent as ArrowFormSelect } from 'assets/image/svg/arrow-form-field-select.svg';
import { RefSelectProps, SelectProps, SelectValue } from 'antd/lib/select';
import { FormPageTypeEnum } from 'utils/enums';
import { ReactComponent as InfoCircle } from '../../assets/image/svg/info-circle.svg';
import { Message } from 'react-hook-form/dist/types/form';
import BaseFloatingLabel from './BaseFloatingLabel';

const { Option } = Select;

type MessageError = {
  message: Message;
};

type SelectError = {
  label: MessageError;
  value: MessageError;
};

type BaseSelectProps = SelectProps<any> & {
  label?: string;
  input?: boolean;
  page?: FormPageTypeEnum;
  name?: string;
  error?: SelectError & MessageError;
  itemsArray?: {
    label: string;
    value: string;
  }[];
};

// eslint-disable-next-line react/display-name
const BaseSelect = forwardRef(
  (
    {
      itemsArray,
      label,
      input,
      onChange,
      page,
      error,
      name,
      placeholder,
      ...props
    }: BaseSelectProps,
    ref: React.ForwardedRef<RefSelectProps>,
  ): React.ReactElement => {
    const [items, setItems] = useState<typeof itemsArray>(
      input ? [{ label: '', value: '' }, ...(itemsArray ?? [])] : itemsArray,
    );
    const [defaultValue, setDefaultValue] = useState<boolean>();

    const onChangeVal = (value: SelectValue, option: any) => {
      onChange?.(value, option);
    };

    useEffect(() => {
      setItems(input ? [{ label: '', value: '' }, ...(itemsArray ?? [])] : itemsArray);
    }, [itemsArray]);

    useEffect(() => {
      if (!props.defaultValue) return;
      const companyOption = itemsArray?.find((el, index) => {
        if (el.value === props.defaultValue)
          return { label: el.label, value: el.value, key: index };
      });
      setDefaultValue(props.defaultValue ? true : false);
      onChangeVal(props.defaultValue, companyOption);
    }, [props.defaultValue]);

    function onSearch(val: string) {
      if (val.length > 2) {
        return;
      }

      const isNum = /^\d+$/.test(val);

      if (isNum) {
        const exists = items?.find(({ value }) => value === val);
        const isLess = val.length < (items?.[0].value.length ?? 0);
        if (isLess && exists) {
          val = '';
        }
        if (items && (!exists || isLess)) {
          items[0] = { label: val, value: val };
          items && setItems([...items]);
        }
      }
    }

    if (name === 'company_id') {
      return (
        <Row
          align={'middle'}
          style={{
            flexWrap: 'nowrap',
            width: '100%',
            position: 'relative',
          }}
          className={'base-selector-row-container'}
        >
          <BaseFloatingLabel
            label={label ?? ''}
            value={props.value || defaultValue}
            defaultValue={defaultValue}
            select={true}
          >
            <BaseSelectStyled
              $error={!!error?.value?.message || !!error?.label?.message || !!error?.message}
              suffixIcon={<ArrowFormSelect />}
              // placeholder={placeholder}
              {...props}
              dropdownMatchSelectWidth={false}
              $label={label}
              bordered={false}
              $fullWidth={
                page === FormPageTypeEnum.registration ||
                page === FormPageTypeEnum.addCompany ||
                page === FormPageTypeEnum.addWebsite ||
                page === FormPageTypeEnum.addUsers
              }
              showSearch={input}
              size={'large'}
              className={'flex flex-center'}
              onChange={onChangeVal}
              onSearch={onSearch}
              ref={ref}
              filterOption={(input, option) => {
                return option?.value.toLowerCase().indexOf(input.toLowerCase()) >= 0;
              }}
            >
              {items
                ?.filter(({ value }) => value !== '')
                .map(({ value, label }, index) => (
                  <Option key={index} value={value}>
                    {label}
                  </Option>
                ))}
            </BaseSelectStyled>
            {error && (
              <ErrorStyled
                $error={
                  !!error?.value?.message || !!error?.label?.message || error?.message
                    ? true
                    : false
                }
              >
                <div className={'input__input-error-container'}>
                  <Text fontSize={12} className={'input-error__text'}>
                    {error?.message}
                  </Text>
                  <InfoCircle />
                </div>
              </ErrorStyled>
            )}
          </BaseFloatingLabel>
          {/* {label && (
            <Text color={'#000000'} style={{ paddingLeft: '1rem' }}>
              {label}
            </Text>
          )} */}
        </Row>
      );
    }

    return (
      <Row
        align={'middle'}
        style={{
          flexWrap: 'nowrap',
          width: '100%',
          position: 'relative',
        }}
        className={`base-selector-row-container`}
      >
        <BaseFloatingLabel
          label={label ?? ''}
          value={props.value}
          defaultValue={defaultValue}
          select={true}
        >
          <BaseSelectStyled
            $label={label}
            $error={!!error?.value?.message || !!error?.label?.message || !!error?.message}
            $CountryInput={input && name === 'country_iso'}
            suffixIcon={<ArrowFormSelect />}
            placeholder={placeholder}
            {...props}
            dropdownMatchSelectWidth={false}
            bordered={false}
            $fullWidth={
              page === FormPageTypeEnum.registration ||
              page === FormPageTypeEnum.addCompany ||
              page === FormPageTypeEnum.addWebsite ||
              page === FormPageTypeEnum.addUsers
            }
            showSearch={input}
            size={props.size ?? 'middle'}
            className={`flex flex-center`}
            onChange={onChangeVal}
            onSearch={onSearch}
            ref={ref}
            filterOption={(input, option) => {
              return option?.value.toLowerCase().indexOf(input.toLowerCase()) >= 0;
            }}
          >
            {/* {<Option key="" value=""></Option>} */}
            {items?.map(({ value, label }, index) => (
              <Option key={index} value={value}>
                {label}
              </Option>
            ))}
          </BaseSelectStyled>
          {error && (
            <ErrorStyled
              $error={
                !!error?.value?.message || !!error?.label?.message || error?.message ? true : false
              }
            >
              <div className={'input__input-error-container'}>
                <Text fontSize={12} className={'input-error__text'}>
                  {error?.message}
                </Text>
                <InfoCircle />
              </div>
            </ErrorStyled>
          )}
        </BaseFloatingLabel>
        {/* {label && (
          <Text color={'#000000'} style={{ paddingLeft: '1rem' }}>
            {label}
          </Text>
        )} */}
      </Row>
    );
  },
);

export default BaseSelect;
