import styled from 'styled-components';

export const StorylinesStyled = styled.div`
  /* the parent */
  .slick-list {
    margin-left: -30px;
  }

  .slick-track {
    display: flex;
    align-items: stretch;
  }

  .slick-slide {
    height: auto;

    > div {
      height: 100%;
    }
  }

  .storylines-controls {
    padding: 20px 0px;
    display: flex;
    justify-content: space-between;

    button {
      border: 1px solid #e2e3e7;
      padding: 8px 12px;
      background-color: white;
      border-radius: 10px;
      margin-left: 15px;
    }

    h2 {
      font-family: 'Noir Pro Bold';
      font-size: 21px;
    }
  }
`;
