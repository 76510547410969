import React, { useContext, useEffect, useState } from 'react';
import { Button, Row } from 'antd';
import { StatisticStyled } from './Statistic.styled';
import Visualization from './Visualization';
import { ThemeContext } from 'styled-components';
import { ReactComponent as DownloadIcon } from 'assets/image/svg/dowload-icon.svg';
import { Text } from 'components/styles';
import SelectTimeRange from './SelectTimeRange';
import { CSVLink } from 'react-csv';
import { StatisticPageWebsite, TimeRangeProps } from 'api/statisticPage/website/types';
import { StatisticPageCompany } from 'api/statisticPage/company/types';
import { SiderNavigationContext } from 'state/sider/navigation/siderNavigationContext';

type CSVHeader = {
  label: string;
  key: 'product' | 'impressions' | 'clicks' | 'website' | 'clickRate' | 'startDate' | 'endDate';
};

type CSVData =
  | {
      website: string;
      clicks: number;
      impressions: number;
    }
  | {
      product: string;
      clicks: number;
      impressions: number;
    };

interface Props {
  range: TimeRangeProps['range'];
  setRange: TimeRangeProps['setRange'];
  currentData: StatisticPageWebsite | StatisticPageCompany;
  dashboard?: boolean;
  dashboardChartOnChange?: (value: string) => void;
  type?: string;
}

const Statistic: React.FC<Props> = ({
  range,
  setRange,
  currentData,
  dashboard,
  dashboardChartOnChange,
  type,
}) => {
  const { fontFamilies } = useContext(ThemeContext);
  const [csvData, setCSVData] = useState<CSVData[]>([]);
  const [csvHeaders, setCSVHeaders] = useState<CSVHeader[]>([]);
  const { siderState } = useContext(SiderNavigationContext);

  useEffect(() => {
    if (type === 'Company') {
      const data = (currentData.data as StatisticPageCompany['data']).websites.map((i) => {
        return {
          website: i.name,
          clicks: i.totalClicks,
          impressions: i.totalImpressions,
          clickRate: `${i.clickRatePercentage}%`,
          startDate: range.start,
          endDate: range.end,
        };
      });
      setCSVData(data as CSVData[]);
      setCSVHeaders([
        {
          label: 'Website',
          key: 'website',
        },
        {
          label: 'Clicks',
          key: 'clicks',
        },
        {
          label: 'Impressions',
          key: 'impressions',
        },
        {
          label: 'Click Rate',
          key: 'clickRate',
        },
        {
          label: 'Start Date',
          key: 'startDate',
        },
        {
          label: 'End Date',
          key: 'endDate',
        },
      ]);
    }

    if (type === 'Website') {
      const data = (currentData.data as StatisticPageWebsite['data']).products.map((i) => {
        return {
          product: i.name,
          clicks: i.totalClicks,
          impressions: i.totalImpressions,
          clickRate: `${i.clickRatePercentage}%`,
          startDate: range.start,
          endDate: range.end,
        };
      });
      setCSVData(data as CSVData[]);
      setCSVHeaders([
        {
          label: 'Product',
          key: 'product',
        },
        {
          label: 'Clicks',
          key: 'clicks',
        },
        {
          label: 'Impressions',
          key: 'impressions',
        },
        {
          label: 'Click Rate',
          key: 'clickRate',
        },
        {
          label: 'Start Date',
          key: 'startDate',
        },
        {
          label: 'End Date',
          key: 'endDate',
        },
      ]);
    }
  }, [currentData]);

  return (
    <StatisticStyled className={'statistic'}>
      <Row align={'middle'} justify={'space-between'} className={'top-part'}>
        <Row align={'middle'} className="title-row">
          <Text color={'#4B4DC8'} fontFamily={fontFamilies.bold} className="title">
            {dashboard ? 'Engagement Data' : 'Conversion Trends'}
          </Text>
          <div className="vertical-divider" />
          <Button type={'text'} className={'grab-all'} icon={<DownloadIcon fill="#808E9B" />}>
            <CSVLink
              data={csvData}
              headers={csvHeaders}
              filename={`${currentData.data.name}_${range.start}_${range.end}.csv`}
              style={{
                fontSize: `16px`,
                color: '#181818',
                fontFamily: `${fontFamilies.regular}`,
              }}
            >
              <span className="hidden-mobile">Grab all </span>data
            </CSVLink>
          </Button>
        </Row>
        <Row align={'middle'} className="select-range-row">
          {dashboard && dashboardChartOnChange && (
            <select
              className="select-company"
              defaultValue={1}
              onChange={(e) => dashboardChartOnChange(e.target.value)}
            >
              {siderState.companies.map((company) => (
                <option value={company.slug} key={company.slug}>
                  {company.name}
                </option>
              ))}
            </select>
          )}
          <SelectTimeRange range={range} setRange={setRange} />
        </Row>
      </Row>
      <div className={'chart-container'}>
        <Visualization data={{ data: currentData.data as StatisticPageWebsite['data'] }} />
      </div>
    </StatisticStyled>
  );
};

export default Statistic;
