import { FC } from 'react';
import { Row } from 'antd';
import { BaseSelect } from 'components/baseComponents';
import BaseRadioGroup from 'components/baseComponents/BaseRadioGroup';
import React, { useContext, useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { trigger } from 'swr';
import { deleteUser, endpoints, getUsersExcept } from 'services';
import { mutate } from 'swr';
import { DisableUserStyled } from './DisableUser.styled';
import { validateAxiosErrorInForms } from '../../../utils/helpers/functions';
import { AppContext } from 'state/appContext';
import BaseSubmitButton from 'components/baseComponents/BaseSubmitButton';
import { BaseRightDrawerContext } from 'state/baseComponents/BaseRightDrawerContext';

type RemoveFormFieldsType = {
  deleteAll: string;
  companySlug: string;
};

const DisableUser: FC<{ id: number; companySlug: string }> = ({ id, companySlug }) => {
  const { onClose: drawerClose } = useContext(BaseRightDrawerContext);
  const {
    state: { userTree },
  } = useContext(AppContext);

  const users = getUsersExcept(id as unknown as string).data;
  const { handleSubmit, control, watch, setValue, setError } = useForm({});

  useEffect(() => {
    setValue('companySlug' as never, users && users.length && users.data[0].id);
  }, [users]);

  const { deleteAll } = watch();

  const getUsers = useMemo(
    () =>
      users?.data.map(({ first_name, last_name, id }: UserPrimitive) => ({
        label: first_name + ' ' + last_name,
        value: id,
      })) ?? [],
    [users],
  );

  const onSubmit = async (data: RemoveFormFieldsType) => {
    const { deleteAll } = data;
    let { companySlug: inheritmantEntity } = data;
    if (deleteAll === 'delete') {
      inheritmantEntity = '';
    }
    try {
      await deleteUser(deleteAll, id as number, Number(inheritmantEntity));
      // replace(routesPaths.user);
      trigger(endpoints.getAllUsers);

      trigger(endpoints.getCompanyUser(companySlug as string, id as number));
      mutate(endpoints.siderTree);

      drawerClose();
    } catch (error) {
      validateAxiosErrorInForms(error, setError);
    }
  };

  return (
    <DisableUserStyled onSubmit={handleSubmit(onSubmit)}>
      <Controller
        as={BaseRadioGroup}
        control={control}
        name={'deleteAll'}
        defaultValue={'delete'}
        itemsArray={[
          { label: 'Do nothing with the entities they manage', value: 'delete' },
          { label: 'Re-assign all entities to', value: 'assign' },
        ]}
      />
      <Controller
        disabled={deleteAll === 'delete'}
        as={<BaseSelect size={'large'} />}
        control={control}
        name={'companySlug'}
        itemsArray={getUsers}
      />
      <Row align={'middle'} className={'content__submit-container'}>
        <BaseSubmitButton
          title={'Disable'}
          className={'btn--fill'}
          backgroundColor={'#4B4DC8'}
          withArrow
        />
      </Row>
    </DisableUserStyled>
  );
};

export default DisableUser;
