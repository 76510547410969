import React, { useState } from 'react';
import { AddWebsiteStyled } from './AddWebsite.styled';
import BulkWebsiteAdd from './components/BulkWebsiteAdd';
import WebsiteAddForm from './components/WebsiteAddForm';

type AddWebsitePropsType = {
  onClose?: () => void;
  parentSlug?: string;
  fromModal?: boolean;
};

const AddWebsite = ({ onClose, parentSlug, fromModal }: AddWebsitePropsType) => {
  const [bulkAction, setBulkAction] = useState(false);
  const [websiteCount, setWebsiteCount] = useState(0);
  const [CSVData, setCSVData] = useState([]);
  const onUploadMethodSwith = () => {
    setBulkAction(!bulkAction);
  };

  return (
    <AddWebsiteStyled>
      {/* <TopTitleAndSelector websiteCount={websiteCount} /> */}
      <div style={{ display: bulkAction ? 'block' : 'none', height: '100%' }}>
        <BulkWebsiteAdd onUploadMethodSwith={onUploadMethodSwith} setCSVData={setCSVData} />
      </div>
      <div style={{ display: bulkAction ? 'none' : 'block', height: '100%' }}>
        <WebsiteAddForm
          onUploadMethodSwith={onUploadMethodSwith}
          setWebsiteCount={setWebsiteCount}
          fromModal={fromModal ? true : false}
          onClose={onClose}
          CSVData={CSVData}
          parentSlug={parentSlug as string}
        />
      </div>
    </AddWebsiteStyled>
  );
};

export default AddWebsite;
