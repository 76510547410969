import { useContext, useEffect, useState } from 'react';
import * as Api from 'api';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ThemeContext } from 'styled-components';
import { useHistory } from 'react-router-dom';

import { AppContext } from 'state/appContext';
import { routesPaths } from 'utils/helpers';
import { validateAxiosErrorInForms } from 'utils/helpers/functions';
import { addCompanyForms } from './const';
import { createCompany, registerCompany, endpoints, useGetCountries, ajax } from 'services';
import { trigger as swrMutate } from 'swr';
import { SiderNavigationContext } from 'state/sider/navigation/siderNavigationContext';

const schema = Yup.object().shape({
  name: Yup.string().required('The field is required (min 3 char.)').min(3),
  address: Yup.string().required('The field is required (min 3 char.)').min(3),
  country_iso: Yup.string().required('The field is required'),
  region: Yup.string().required('The field is required (min 3 char.)').min(3),
  city: Yup.string().required('The field is required (min 3 char.)').min(3),
  postal_code: Yup.string()
    .required('The field is required (min 3 char.)')
    .min(3, 'Min length should be 3 characters'),
});

type seAddCompanyPagePropType = {
  fromModal?: boolean;
  onClose?: () => void;
  firstCompany?: boolean;
};

const useAddCompanyPage = ({ fromModal, onClose, firstCompany }: seAddCompanyPagePropType) => {
  const { siderActions } = useContext(SiderNavigationContext);
  const { state } = useContext(AppContext);
  const { fontFamilies } = useContext(ThemeContext);
  const [loading, setLoading] = useState(false);
  const { handleSubmit, control, errors, watch, setValue, setError } = useForm<AddCompanyFormType>({
    resolver: yupResolver(schema),
    shouldFocusError: true,
  });
  const { replace } = useHistory();
  const { data } = useGetCountries();

  const [fieldData, setFieldData] = useState<FormSectionWrapperType[]>(addCompanyForms);

  useEffect(() => {
    const items: SelectItemType[] =
      data?.data?.map(({ iso, nicename }) => ({
        label: nicename,
        value: iso,
      })) ?? [];

    const currentField = addCompanyForms[2].fields[0];
    currentField.itemsArray = items;
    currentField.value = data?.data[0].iso;

    setFieldData([...addCompanyForms]);
    setValue(currentField.name, '');
  }, [data]);

  const onSkip = () => {
    replace(routesPaths.home);
  };

  const onSubmit = async ({
    name,
    address,
    country_iso,
    region,
    city,
    postal_code,
  }: AddCompanyFormType) => {
    const requestData = {
      name,
      address,
      country_iso,
      region,
      postal_code,
      city,
    };
    try {
      setLoading(true);
      {
        firstCompany || fromModal === undefined || !fromModal
          ? await registerCompany(requestData)
          : await createCompany(requestData);
      }

      swrMutate(endpoints.getCompanies);
      ajax.get(Api.endpoints.siderAllCompanies).then((response) => {
        siderActions.setCompanies(response.data);
      });
      if (fromModal || firstCompany) {
        onClose?.();
        swrMutate(endpoints.siderTree);
      } else {
        replace('/invite-users');
      }
    } catch (error) {
      console.log('err', error);
      validateAxiosErrorInForms<RegistrationFormType>(error, setError);
    } finally {
      setLoading(false);
    }
  };

  return {
    user: state.user,
    onSubmit,
    handleSubmit,
    control,
    errors,
    fontFamilies,
    onSkip,
    watch,
    setValue,
    fieldData,
    loading,
  };
};

export default useAddCompanyPage;
