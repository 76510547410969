import { Col } from 'antd';
import styled, { css } from 'styled-components';

type TableHeadSortableItemStyledPropsType = {
  $active: boolean;
  $sortingOrderIsAsc: boolean;
};
export const TableHeadSortableItemStyled = styled(Col)<TableHeadSortableItemStyledPropsType>`
  .text-wrapper {
    cursor: pointer;
    p {
      margin-right: 0.4rem;
    }
    svg path {
      fill: #7d8ca2;
    }
  }

  ${({ $active, $sortingOrderIsAsc }) =>
    $active &&
    css`
      .text-wrapper {
        p {
          color: #4b4dc8;
        }
        svg {
          transform: rotateX(${$sortingOrderIsAsc ? 180 : 0}deg);
          path {
            fill: #4b4dc8;
          }
        }
      }
    `}
  .sort-title {
    font-size: 1.125rem !important;
  }
`;
