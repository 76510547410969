import React, { useMemo, useRef } from 'react';
import { Controller, DeepMap, FieldError } from 'react-hook-form';
import { DynamicFieldsEnum } from 'utils/enums';
import { ReactComponent as LinkSlideConfig } from 'assets/image/svg/link-slide-config.svg';

import BaseInput from './BaseInput';
import BaseRadioGroup from './BaseRadioGroup';
import BaseSelect from './BaseSelect';
import BaseCheckbox from './BaseCheckbox';
import BaseBadgeShapeSelector from './BaseBadgeShapeSelector';
import BaseBadgeColors from './BaseBadgeColors';
import BaseTextArea from './BaseTextArea';
import BaseCopyable from './BaseCopyable';
import BaseTimePicker from './BaseTimePicker';
import BaseDatePicker from './BaseDatePicker';
import { FormPageTypeEnum } from 'utils/enums';
import { Input, Tooltip } from 'antd';
import PermissionToggleItem from '../../views/user/UserDetails/components/PermissionToggleItem';
import Container from './DynamicFormField.styled';

type DynamicFormFieldPropsType<TFormType> = Omit<FormBuilderFieldType, 'id'> & {
  control: any;
  errors: DeepMap<TFormType, FieldError>;
  page?: FormPageTypeEnum;
  setValue?: () => void;
  registrationPage?: boolean;
};

const DynamicFormField = <TFormType extends Record<string, string | boolean | File>>({
  control,
  type,
  name,
  value,
  itemsArray,
  label,
  placeholder,
  errors,
  page,
  urlLabel,
  urlValue,
  disabled,
  formatfield,
  setValue,
  registrationPage,
}: DynamicFormFieldPropsType<TFormType>): React.ReactElement => {
  const ref = useRef<Input>();

  const RenderField = useMemo(() => {
    switch (type) {
      case DynamicFieldsEnum.Input:
        return (
          <BaseInput
            formatfield={formatfield}
            inputTitle={false}
            placeholder={placeholder}
            ref={ref}
            label={label}
            name={name}
            setFormValue={setValue}
            registrationPage={registrationPage}
          />
        );
      case DynamicFieldsEnum.InputTitle:
        return <BaseInput inputTitle={true} inputref={ref} name={name} />;
      case DynamicFieldsEnum.Radio:
        return <BaseRadioGroup itemsArray={itemsArray} name={name} />;
      case DynamicFieldsEnum.Select:
        return <BaseSelect itemsArray={itemsArray} label={label} page={page} size="large" />;
      case DynamicFieldsEnum.Checkbox:
        return <BaseCheckbox label={label} value={value as boolean} />;
      case DynamicFieldsEnum.Switch:
        return (
          <PermissionToggleItem
            text={name}
            value={value as boolean}
            defaultChecked={value as boolean}
            onChange={() => {}}
            disabled={disabled ? disabled : false}
          />
        );
      case DynamicFieldsEnum.BadgeShape:
        return <BaseBadgeShapeSelector itemsArray={itemsArray} />;
      case DynamicFieldsEnum.BadgeColors:
        return <BaseBadgeColors itemsArray={itemsArray} text="Color: " />;
      case DynamicFieldsEnum.TextArea:
        return <BaseTextArea label={label} />;
      case DynamicFieldsEnum.Copyable:
        return <BaseCopyable />;
      case DynamicFieldsEnum.TimePicker:
        return <BaseTimePicker />;
      case DynamicFieldsEnum.DatePicker:
        return <BaseDatePicker />;
      case DynamicFieldsEnum.DownloadUrl:
        return (
          <a className="ads-download" href="/files/ads.txt" download>
            Download File
          </a>
        );
      case DynamicFieldsEnum.InputURL:
        return <BaseInput inputTitle={false} Icon={<LinkSlideConfig />} ref={ref} />;
      case DynamicFieldsEnum.Password:
        return (
          <BaseInput inputTitle={false} placeholder={placeholder} type={'password'} ref={ref} />
        );
      case DynamicFieldsEnum.SelectWithInput:
        return <BaseSelect itemsArray={itemsArray} label={label} input={true} />;
      case DynamicFieldsEnum.HiddenInput:
        return <input type="text" style={{ display: 'none' }} className="test" />;
      case DynamicFieldsEnum.StoryColor:
        return <BaseBadgeColors itemsArray={itemsArray} text="Active story color: " />;
      default:
        return <div>Incorrect Type</div>;
    }
  }, [type, itemsArray, value]);

  return (
    <>
      {urlValue ? (
        <Container>
          <Controller
            disabled={disabled}
            control={control}
            name={name}
            as={RenderField}
            defaultValue={value}
            value={value}
            // rules={
            //   type === DynamicFieldsEnum.Checkbox
            //     ? null
            //     : { required: true, max: maxLength, maxLength }
            // }
            error={errors?.[name] ?? ''}
            onFocus={() => {
              // ref.current.disabled = false;
              ref.current?.focus();
            }}
          />
          {urlValue && (
            <a target={'_blank'} rel="noreferrer" href={urlValue}>
              {urlLabel}
            </a>
          )}
        </Container>
      ) : (
        <Controller
          disabled={disabled}
          control={control}
          name={name}
          as={RenderField}
          defaultValue={value}
          value={value}
          // rules={
          //   type === DynamicFieldsEnum.Checkbox
          //     ? null
          //     : { required: true, max: maxLength, maxLength }
          // }
          error={errors?.[name] ?? ''}
          onFocus={() => {
            // ref.current.disabled = false;
            ref.current?.focus();
          }}
        />
      )}
    </>
  );
};

export default DynamicFormField;
