import styled from 'styled-components';

export const DashboardLayoutStyled = styled.div`
  display: flex;
  height: 100%;
  background-color: #f5f6fb;

  .left-side-wrapper {
    width: 320px;
  }

  .right-side-wrapper {
    width: 160px;

    &:before {
      content: '';
      position: absolute;
      left: 1;
      top: 50%;
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      height: 90%;
      width: 1px;
      background-color: #eaecf2;
    }
  }

  .middle-wrapper {
    flex: 1;
    display: flex;
    overflow: auto;
    flex-direction: column;
    padding-bottom: 30px;

    .dashboard-content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex: 1;
      padding: 0px 30px;
      overflow: overlay;

      ${({ theme }) => theme.scrollable()}
    }
    .dashboard-body {
      height: 100%;
    }
  }

  footer {
    display: flex;
    padding: 20px;
    bottom: 0;
    background: white;
    border-radius: 10px;
    display: none;
    margin: 30px 0px 0px 0px;

    .footer-btn-mobile {
      padding: 5px !important;
      height: 40px;
      margin: initial;
      font-size: 14px;
    }

    .footer_text {
      font-size: 12px;
      text-align: center;
      color: #181818;
      vertical-align: center;
    }
  }

  @media only screen and (max-width: 1900px) {
    .left-side-wrapper {
      width: 255px;
    }
  }

  @media only screen and (min-width: 1025px) and (max-width: 1500px) {
    .left-side-wrapper {
      width: 230px;
    }
  }

  @media only screen and (max-width: 1200px) {
    .right-side-wrapper {
      display: none;
    }
  }

  @media only screen and (max-width: 1000px) {
    .left-side-wrapper {
      display: none;
    }

    .middle-wrapper {
      .dashboard-content {
        padding-top: 30px;
      }
    }
  }

  @media only screen and (max-width: 1000px) {
    .dashboard-content {
      .dashboard-body {
        height: auto;
      }
    }

    footer {
      display: block;
      flex-direction: row;
    }
  }

  @media only screen and (max-width: 680px) {
    .middle-wrapper {
      .dashboard-content {
        padding: 15px 15px 0px 15px;
      }
    }
  }
`;
