import styled from 'styled-components';

export const AddProductComponentStyled = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding-bottom: 30px;
  /* background-color: transparent;
  flex: 1;
  width: 100%;
  .select-fields {
    width: 100%;
  }
  h2 {
    padding: 0 2.5rem;
    padding-bottom: 1rem;
  }
  .content__form-container {
    padding: 0 2.5rem;

    & > div:last-child {
      border-bottom: 0 !important;
    }
  }
  .content__submit-container {
    padding: 0 2.5rem;
    justify-content: flex-end;
    p {
      text-transform: uppercase;
      opacity: 0.5;
      transition: all 0.2s;
      &:hover {
        opacity: 1;
      }
    }
    .btn {
      height: 4rem;
      border-radius: 2rem;
      margin-left: 20%;
      margin-top: 2rem;
      width: auto;
    }
  }
  @media only screen and (max-width: 1370px) {
    h2 {
      padding: 0 2.5rem;
      padding-bottom: 0.5rem;
    }
    .content__form-container {
    }
    .content__submit-container {
      p {
      }
      .btn {
        height: 4rem;
      }
    }
  }
  .mobile_text {
    display: none;
  }
  @media only screen and (max-width: 680px) {
    h2 {
      padding: 1rem 3rem;
    }
    .content__form-container {
      margin: 1.5rem 0rem;
      .mobile_text {
        display: block;
      }
    }
    .ant-row {
      margin: 0.5rem 0rem;
    }
    .content__submit-container {
      .skip-step {
        display: none;
      }
      button {
        width: 100% !important;
        margin: 2rem auto 0.5rem auto !important;
      }
    }
  } */
`;
