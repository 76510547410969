import { Col } from 'antd';

import styled from 'styled-components';

export const HeaderStyled = styled(Col)`
  margin-top: 4vh;
  .top__title {
    .headerWrapper {
      display: flex;
      align-items: center;
      span {
        padding: 0 0 0 40px;
        display: flex;
        alignt-items: center;
        cursor: pointer;
        p {
          padding: 0 0 0 10px;
          font-size: 16px;
        }
      }
      .title-text {
        margin-right: 1.4rem;
        display: flex;
        color: black;

        .border {
          :after {
            content: '';
            background: transparent;
            position: absolute;
            left: 30px;
            top: 32%;
            height: 20px;
            width: 100%;
            border-right: 2px solid #808e9b;
            z-index: 9;
          }
        }
      }
    }

    .btn {
      height: 2.5rem;
      border-radius: 2rem;
      width: 12rem;
      margin-left: 20%;
      span {
        font-size: 1.1rem;
        line-height: 1.9rem;
        letter-spacing: 0px;
        color: #ffffff;
        text-transform: uppercase;
      }
    }
  }

  .top__title-and-search-wrapper {
    margin: 0.5rem 0;
    padding-bottom: 0;
    .top__title-and-search {
      width: 100%;

      .title {
      }

      .search-container {
        border-radius: 2rem;
        background-color: #f6f9fc21;
        width: 100%;
        padding: 0.5rem 0;
        .searchInput {
          width: 100%;
          height: 55px;
          position: relative;
          display: flex;
          align-items: center;
          input {
            width: 100%;
            height: 100%;
            border-radius: 8px;
            padding: 0 15px;
            background: #e8edf8;
            color: #808e9b;
          }
          svg {
            position: absolute;
            right: 15px;
            top: 20px;
            height: 16px;
            width: 16px;
          }
        }

        .input-container {
          border: none;
          background-color: transparent;
          display: flex;
          justify-content: center;
          .ant-input-affix-wrapper {
            display: flex;
            flex-direction: row-reverse;
            .anticon {
              svg {
                width: 1.5rem;
                height: 1.5rem;
                path {
                  fill: #ffffff;
                }
              }
            }
          }
          input {
            height: inherit;
            background-color: transparent;
            color: #ffffff;
            &::placeholder {
              color: #ffffff69;
            }
          }
        }
      }
    }
  }
  @media only screen and (max-width: 900px) {
    .top__title-and-search {
      padding: 0 1rem !important;
      > p {
        display: none;
      }
      .search-container {
        min-width: 100%;
        margin-left: auto;
      }
    }
  }
`;
