import React from 'react';
import { lazyLoadable } from 'utils';
import UsersSkeleton from '../../Skeletons/UsersSkeleton';

const UserRow = lazyLoadable(
  () => import('./UserRow'),
  <>
    <UsersSkeleton />
  </>,
);

export default UserRow;
