import React, { ChangeEvent, useEffect, useState } from 'react';
import { ArchivePageStyled } from './ArchivePage.styled';
import useSortableTable from '../components/useSortableTable';
import { useGetArchivedCompanies } from 'services';
import DashboardLayout from 'views/dashboardLayout/DashboardLayout';
import TableComponentLazy from '../components/TableComponentLazy';
import Header from '../components/Header';
import { TableContainerStyled } from '../components/TableContainer.styled';
import useWindowsize from '../../../hooks/useWindowsize';

export enum CompanyArchiveTableHeadEnum {
  Company = 'Company',
  Product = 'Product',
  Websites = 'Websites',
  Settings = 'Settings',
}

const setWebsiteCurrentSortKey = (
  activeHead: CompanyArchiveTableHeadEnum,
): keyof ArchivedCompanyType => {
  switch (activeHead) {
    case CompanyArchiveTableHeadEnum.Company:
      return 'name';
    case CompanyArchiveTableHeadEnum.Websites:
      return 'countOfWebsites';
    // case CompanyArchiveTableHeadEnum.Product:
    //   return 'product';
    default:
      return 'name';
  }
};

const ArchiveWebsitePage = () => {
  const [searchState, setSearchState] = useState('');
  const windoWidth = useWindowsize()[0];

  const heads = [
    {
      text: windoWidth > 330 ? CompanyArchiveTableHeadEnum.Company : 'Comp.',
      span: 7,
      offset: 0,
      type: CompanyArchiveTableHeadEnum.Company,
    },
    {
      text: windoWidth > 330 ? CompanyArchiveTableHeadEnum.Websites : 'Web.',
      span: 6,
      offset: 0,
      type: CompanyArchiveTableHeadEnum.Websites,
    },
    {
      text: windoWidth > 330 ? CompanyArchiveTableHeadEnum.Settings : 'Stg.',
      span: 0,
      offset: 0,
      type: CompanyArchiveTableHeadEnum.Settings,
    },
  ];

  const handleSearchState = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchState(e.target.value);
  };

  const { data } = useGetArchivedCompanies();
  const { sortedProductItems, onSelectSort, activeHead } = useSortableTable<
    CompanyArchiveTableHeadEnum,
    ArchivedCompanyType
  >({
    setCurrentSortKey: setWebsiteCurrentSortKey,
    items: data?.data ?? [],
    initialActiveHead: CompanyArchiveTableHeadEnum.Company,
  });

  const filterItems = () => {
    const filteredUsers = sortedProductItems.filter((user: ArchivedCompanyType) => {
      return user.name.toLowerCase().indexOf(searchState.toLowerCase()) !== -1;
    });
    return filteredUsers;
  };

  return (
    <DashboardLayout>
      <ArchivePageStyled>
        <Header
          title={'archive'}
          type={'archive'}
          setSearchState={handleSearchState}
          searchState={searchState}
        />
        <TableContainerStyled>
          <TableComponentLazy
            headerItems={heads}
            bodyItems={filterItems()}
            // @ts-ignore: Unreachable code error
            onSelectSort={onSelectSort}
            activeHead={activeHead}
          />
        </TableContainerStyled>
      </ArchivePageStyled>
    </DashboardLayout>
  );
};

export default ArchiveWebsitePage;
