import { useContext, useEffect, useState } from 'react';
import * as Api from 'api';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ThemeContext } from 'styled-components';
import { useHistory, useParams } from 'react-router-dom';

import { AppContext } from 'state/appContext';
import { routesPaths } from 'utils/helpers';
import { validateAxiosErrorInForms } from 'utils/helpers/functions';
import { addCompanyForms } from './const';
import { ajax, editCompany, useGetCountries } from 'services';
import { message } from 'antd';
import { SiderNavigationContext } from 'state/sider/navigation/siderNavigationContext';
import { BaseRightDrawerContext } from 'state/baseComponents/BaseRightDrawerContext';

const schema = Yup.object().shape({
  name: Yup.string().required('The field is required (min 3 char.)').min(3),
  address: Yup.string().required('The field is required (min 3 char.)').min(3),
  country_iso: Yup.string().required(),
  region: Yup.string().required('The field is required (min 3 char.)').min(3),
  city: Yup.string().required('The field is required (min 3 char.)').min(3),
  postal_code: Yup.string()
    .required('The field is required (min 3 char.)')
    .min(3, 'Min length should be 3 characters'),
});

type RouteParamsType = {
  companySlug: string;
  type: string;
};

type HeadersType = {
  method: string;
  mode: string;
  pragma: string;
  cache: string;
};

const useCompanyEdit = (data: CompanyDetailsType) => {
  const { state } = useContext(AppContext);
  const { siderActions } = useContext(SiderNavigationContext);
  const { onClose } = useContext(BaseRightDrawerContext);
  const { fontFamilies } = useContext(ThemeContext);
  const [loading, setLoading] = useState(false);
  const { handleSubmit, control, errors, watch, setValue, setError } = useForm<AddCompanyFormType>({
    resolver: yupResolver(schema),
    shouldFocusError: true,
    defaultValues: {
      ...data,
      country_iso: '',
    },
  });

  const { replace, push } = useHistory();
  const { data: countries } = useGetCountries();

  const { companySlug } = useParams<RouteParamsType>();
  const [fieldData, setFieldData] = useState<FormSectionWrapperType[]>(addCompanyForms);
  const [tmpLogoUrl, setTmpLogoUrl] = useState<string>();
  const [tmpLogoFile, setTmpLogoFile] = useState<File>();

  useEffect(() => {
    const items: SelectItemType[] =
      countries?.data?.map(({ iso, nicename }) => ({
        label: nicename,
        value: iso,
      })) ?? [];

    const currentField = addCompanyForms[3].fields[0];
    currentField.itemsArray = items;
    currentField.value = countries?.data[0].iso;

    setFieldData([...addCompanyForms]);
    setValue(currentField.name, data?.country.iso ?? '');
  }, [countries]);

  const handleLogoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files == null) return;

    const file = e.target.files[0];
    if (file) {
      setTmpLogoFile(file);
      setTmpLogoUrl(URL.createObjectURL(file));
    }
  };

  const onSkip = () => {
    replace(routesPaths.home);
  };

  const onSubmit = async ({
    name,
    address,
    country_iso,
    region,
    city,
    postal_code,
    logo,
  }: AddCompanyFormType) => {
    try {
      setLoading(true);

      const formData = new FormData();
      if (tmpLogoFile == null && logo != null) {
        const headers = {
          method: 'GET',
          mode: 'cors',
          pragma: 'no-cache',
          cache: 'no-store',
        };

        const myRequest = await fetch(logo as string, headers as RequestInit);
        const blob = await myRequest.blob();
        const file = new File([blob], 'image.jpg', { type: blob.type });
        formData.append('logo', file);
      } else if (tmpLogoFile != null) {
        if (tmpLogoFile.size > 2048000) {
          message.error('Error: Logo size should be less than 2MB');
          return;
        } else if (tmpLogoFile.type !== 'image/jpeg' && tmpLogoFile.type !== 'image/png') {
          message.error('Error: Logo should be in jpg or png format');
          return;
        }
        formData.append('logo', tmpLogoFile);
      } else if (tmpLogoFile == null && logo == null) {
        message.error('Please upload a logo');
      }
      formData.append('name', name);
      formData.append('address', address);
      formData.append('country_iso', country_iso);
      formData.append('region', region);
      formData.append('city', city);
      formData.append('postal_code', postal_code);
      formData.append('_method', 'PUT');

      const { data: newData } = await editCompany(
        data.slug,
        formData as unknown as AddCompanyFormType,
      );

      requestAnimationFrame(() =>
        replace(routesPaths.toCompanyOrWebsiteStatisticBySlug(newData.data.slug)),
      );

      ajax.get(Api.endpoints.siderAllCompanies).then((response) => {
        siderActions.setCompanies(response.data);
      });
      onClose();
      message.success('Company updated successfully');
    } catch (error) {
      validateAxiosErrorInForms(error, setError);
    } finally {
      setLoading(false);
    }
  };

  const onCancel = () => {
    push(routesPaths.toCompanyOrWebsiteStatisticBySlug(companySlug));
  };

  return {
    user: state.user,
    onSubmit,
    handleSubmit,
    control,
    errors,
    fontFamilies,
    onSkip,
    watch,
    setValue,
    fieldData,
    loading,
    onCancel,
    handleLogoChange,
    tmpLogoUrl,
  };
};

export default useCompanyEdit;
