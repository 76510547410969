import { Row } from 'antd';
import styled from 'styled-components';

export const TableComponentStyled = styled(Row)`
  margin-top: 1rem;
  padding: 1rem 0 1rem 0;
  justify-content: center;
  .ant-skeleton-header {
    vertical-align: middle;
  }
`;
