type UserPermissionsType = {
  id: number;
  title: string;
  value: boolean;
  children?: {
    id: number;
    title: string;
    value: boolean;
  }[];
};

export const userPermissions: UserPermissionsType[] = [
  {
    id: 1,
    title: 'Super User',
    value: false,
  },
  {
    id: 2,
    title: 'Company',
    value: false,
    children: [
      {
        id: 3,
        title: 'Website',
        value: false,
      },
      {
        id: 4,
        title: 'Website2',
        value: true,
      },
    ],
  },
  {
    id: 3,
    title: 'Company2',
    value: true,
    children: [
      {
        id: 3,
        title: 'Website',
        value: false,
      },
      {
        id: 4,
        title: 'Website2',
        value: true,
      },
    ],
  },
];

export const CompanyUserNull = {
  avatar: '',
  id: 0,
  first_name: '',
  last_name: '',
  email: '',
  phone: '',
  email_verified_at: '',
  initial_company: '',
  created_at: '',
  updated_at: '',
  deleted_at: '',
  company_name: '',
  company_slug: '',
  is_active: false,
  role: '',
  user_companies: [],
  available_companies: [],
};
