/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Spin } from 'antd';
import React from 'react';
import { useGetCompany } from 'services';
import CompanyEditInner from 'views/productContainer/containers/companyDetail/components/CompanyEdit';

interface Props {
  readonly companySlug?: string;
}

const CompanyEdit = ({ companySlug }: Props) => {
  const { data } = useGetCompany(companySlug!);
  if (data == null) {
    return (
      <div
        style={{
          display: 'flex',
          height: '100%',
          width: '100%',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Spin size="large" />
      </div>
    );
  }
  // @ts-ignore TODO: needs to be fixed after refactoring
  return <CompanyEditInner data={data.data} />;
};

export default CompanyEdit;
