import React, { useContext, useEffect, useState } from 'react';
import { ProfilePageStyled } from './ProfilePage.styled';
import { DynamicFormField } from 'components/baseComponents';
import { Col, message, Row, Skeleton } from 'antd';
import { Divider } from 'antd';
import useAuthUserDetailPage from './useAuthUserDetailPage';
import { cache } from 'swr';
import { updateAuthUserImage } from 'services/userServices';
import { ImageTypes } from 'utils/constants';
import { appActions } from 'state/appReducer';
import { AppContext } from 'state/appContext';
import BaseSubmitButton from 'components/baseComponents/BaseSubmitButton';
import { useHistory } from 'react-router-dom';
import DashboardLayout from 'views/dashboardLayout/DashboardLayout';

type PersonalInfo = {
  id: string;
  isDisabled: boolean;
  name: string;
  value: string;
  placeholder: string;
};

const ProfilePage = () => {
  const { dispatch } = useContext(AppContext);
  const { fields, authUser, avatar, control, errors, onSubmit, is_active } =
    useAuthUserDetailPage();
  const history = useHistory();

  const { first_name, last_name, id } = authUser;
  const [personalInfo, setPersonalInfo] = useState<PersonalInfo[]>(fields);
  const [userImage, setUserImage] = useState({
    type: 'image.png',
    url: '',
  });

  useEffect(() => {
    if (avatar) {
      setUserImage({ ...userImage, url: avatar });
    }
  }, [avatar]);

  useEffect(() => {
    setPersonalInfo(fields);
  }, [fields[0].value, fields[1].value, is_active, fields[2].value]);

  const renderPersonalInfo = () => {
    if (first_name.length === 0) {
      return (
        <div>
          <Skeleton
            active
            title={{ style: { height: '25px', marginBottom: '1.25rem' } }}
            paragraph={false}
          />

          <Skeleton
            active
            title={{ style: { height: '25px', marginBottom: '1.25rem' } }}
            paragraph={false}
          />
          <Skeleton
            active
            title={{ style: { height: '25px', marginBottom: '1.25rem' } }}
            paragraph={false}
          />
          <Skeleton active title={{ style: { height: '25px' } }} paragraph={false} />
        </div>
      );
    } else {
      if (personalInfo[0].value.length > 0) {
        return (
          <>
            {personalInfo.map(({ id, isDisabled, name, value, placeholder }) => (
              <DynamicFormField
                key={id}
                name={name}
                value={value}
                label={placeholder}
                disabled={isDisabled}
                type="Input"
                control={control}
                errors={errors}
              />
            ))}
          </>
        );
      }
    }
  };

  const handleUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files == null || e.target.files.length > 1) return;
    const uploadedFile = e.target.files[0];
    if (!ImageTypes.some((t) => t === uploadedFile.type)) {
      message.error('Please upload JPG, PNG or JPEG type files');
      return;
    }
    if (uploadedFile.size > 2097152) {
      message.error('File is larger than 2mb');
      return;
    }
    const imageUrl = URL.createObjectURL(uploadedFile);

    setUserImage({
      type: uploadedFile.type,
      url: imageUrl,
    });

    const formData = new FormData();
    formData.append('avatar', uploadedFile);
    formData.append('_method', 'PUT');
    try {
      updateAuthUserImage(Number(id), formData);
      message.success('Image was successfully uploaded.');
      dispatch(appActions.setUser({ ...authUser, avatar: imageUrl }));
    } catch (e) {
      console.error('There was an error while uploading image please try again later.');
    }
  };

  return (
    <DashboardLayout>
      <ProfilePageStyled>
        <h1>User Management</h1>
        <Divider style={{ marginBottom: '7px' }}></Divider>
        <form onSubmit={onSubmit}>
          <Row align="middle" justify="center">
            <Col xs={24} className={'config__left-part'}>
              <div className="user-image-container">
                <div className="user-image-box">
                  <label>
                    {avatar ? (
                      <>
                        <img
                          width={'124px'}
                          height={'124px'}
                          src={userImage.url}
                          alt={`${first_name}'s profile image`}
                          className="user_image"
                        />
                        <input
                          type={'file'}
                          id={'imageUpload'}
                          name={'userImage'}
                          accept=".jpeg, .png, .jpg"
                          hidden={true}
                          onChange={handleUpload}
                        />
                      </>
                    ) : (
                      <Skeleton.Button
                        style={{ width: '124px', height: '124px', borderRadius: '10px' }}
                        active
                      />
                    )}
                  </label>

                  <div className="user-details">
                    <h3>{`${first_name} ${last_name}`}</h3>
                    <Divider
                      style={{ borderTop: '2px solid #DDE3EF', margin: '15px 0px' }}
                    ></Divider>
                    <p className="user-image-feedback">Minimum portrait dimensions 300 x 300px</p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <h3 style={{ marginTop: '60px' }}>Change Details</h3>

          <Divider style={{ margin: '30px 0px' }}></Divider>
          <Row className="personal-info-container">
            <Col xxl={8} sm={12} xs={24}>
              {renderPersonalInfo()}
            </Col>
          </Row>

          <Divider style={{ margin: '60px 0px', marginTop: '30px' }}></Divider>
          <Row>
            <Col xs={24} className={'submit-container'}>
              <BaseSubmitButton
                title={'Cancel'}
                className={'submit-btn'}
                backgroundColor={'#808E9B'}
                style={{ width: '284px' }}
                type={'reset'}
                onClick={() => {
                  history.goBack();
                  return false;
                }}
              />

              {is_active && (
                <BaseSubmitButton
                  title={'Save Changes'}
                  withArrow
                  className={'submit-btn'}
                  backgroundColor={'#4B4DC8'}
                  style={{ width: '284px' }}
                  onClick={() => {
                    cache.clear();
                  }}
                />
              )}
            </Col>
          </Row>
        </form>
      </ProfilePageStyled>
    </DashboardLayout>
  );
};

export default ProfilePage;
