import React, { FC, useState, useEffect, ChangeEvent, useContext } from 'react';
import DashboardLayout from 'views/dashboardLayout/DashboardLayout';
import TableHead from './components/TableHead';
import UserRowLazy from './components/UserRowLazy';
import { UsersPageStyled } from './UsersPageStyled.styled';
import useUsersPage from './useUsersPage';
import { AppContext } from 'state/appContext';
import { SearchOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { ReactComponent as SearchBold } from 'assets/image/svg/search-bold.svg';
import { BaseRightDrawerContext } from 'state/baseComponents/BaseRightDrawerContext';
import InviteUsers from '../components/InviteUsers';
import SiderMobile from 'components/sider/SiderMobile';

const UsersPage: FC = () => {
  const [searchState, setSearchState] = useState('');
  const {
    state: { isSiderOpen },
  } = useContext(AppContext);
  const { showDrawer, visible: drawerVisible, onClose } = useContext(BaseRightDrawerContext);

  const handleSearchState = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchState(e.target.value);
  };

  useEffect(() => {
    setSearchState('');
  }, [location.pathname]);

  const { activeHead, sortedProductItems, onSelectSort, sortingOrderIsAsc } = useUsersPage();

  const filterItems = () => {
    const filteredUsers = sortedProductItems
      .map((user: ListUserDataType) => ({
        ...user,
        userName: user.first_name + ' ' + user.last_name,
      }))
      .filter((user: ListUserDataType) => {
        return (
          user.userName.toLowerCase().indexOf(searchState.toLowerCase()) !== -1 ||
          user.email.toLowerCase().indexOf(searchState.toLowerCase()) !== -1 ||
          user.company_name?.toLowerCase().indexOf(searchState.toLowerCase()) !== -1
        );
      });
    return filteredUsers;
  };

  return (
    <DashboardLayout>
      <UsersPageStyled>
        <div className="pageTitle">
          <span>User Management</span>
        </div>
        <SiderMobile style={{ marginTop: '30px', marginBottom: '30px' }} />
        <div className="search">
          <div className="searchLeftContent">
            <input
              onChange={(e) => handleSearchState(e)}
              value={searchState}
              placeholder={'Search by name, email or company'}
            />
          </div>
          <div className="searchRightContent">
            <SearchBold />
            <div className="divider"></div>
            <button
              className="addUser"
              onClick={() => {
                showDrawer({
                  _content: <InviteUsers />,
                  _title: 'Invite Users',
                });
              }}
            >
              <div>Add a User</div>
              <ArrowRightOutlined />
            </button>
          </div>
        </div>

        <div className="usersList">
          <TableHead
            activeHead={activeHead}
            sortingOrderIsAsc={sortingOrderIsAsc}
            onSelectSort={onSelectSort}
          />
          {filterItems().map((data: ListUserDataType) => (
            <UserRowLazy key={data.id} {...data} onChange={() => {}} />
          ))}
        </div>
      </UsersPageStyled>
    </DashboardLayout>
  );
};

export default UsersPage;
