import React, { useContext, useEffect } from 'react';
import { cache } from 'swr';
import { SiderNavigationContext } from 'state/sider/navigation/siderNavigationContext';
import SiderNavigation from './SiderNavigation';
import { SiderMobileStyled } from './SiderMobile.styled';
import { Collapse } from 'antd';
import { ArrowIcon } from 'components/svg';

interface props {
  readonly style?: React.CSSProperties;
}

const SiderMobile = ({ style }: props) => {
  const {
    siderState,
    filteredCompanies,
    collapsed,
    companySearchKey,
    setCompanySearchKey,
    setFilteredCompanies,
  } = useContext(SiderNavigationContext);

  const { Panel } = Collapse;

  useEffect(() => {
    return () => cache.clear();
  }, []);

  return (
    <SiderMobileStyled style={style}>
      <Collapse expandIconPosition={'right'} expandIcon={ArrowIcon}>
        <Panel header="Companies" key="1">
          <input
            className="companiesFilter"
            placeholder="Company Name"
            type="text"
            onChange={(e) => {
              setCompanySearchKey(e.target.value);
              setFilteredCompanies(
                siderState.companies.filter((company) =>
                  company.name.toLowerCase().includes(e.target.value.toLowerCase()),
                ),
              );
            }}
          />
          <div className={'sider__tree-container'}>
            <SiderNavigation
              filteredCompanies={filteredCompanies}
              collapsed={collapsed}
              companySearchKey={companySearchKey}
            />
          </div>
        </Panel>
      </Collapse>
    </SiderMobileStyled>
  );
};

export default SiderMobile;
