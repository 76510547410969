import ajax from './ajax';
import endpoints from './endpoints';
import useRequest from './useRequest';

type RegisterPropsType = {
  first_name: string;
  email: string;
  password: string;
  password_confirmation: string;
  last_name: string;
  phone: string;
};

export const register = (data: RegisterPropsType) =>
  ajax.post<RegisterResponseType>('api/v1/user/register/user', data);

type loginPropsType = {
  email: string;
  password: string;
  timezone: string;
};

export const login = (data: loginPropsType) =>
  ajax.post<RegisterResponseType>('api/v1/user/login', data);

export const resetPassword = ({
  password,
  passwordConfirmation,
  email,
  token,
}: PasswordResetRequestType) =>
  ajax.post<MessageResponseType>(
    `api/v1/password/reset?email=${email}&token=${token}&password=${password}&password_confirmation=${passwordConfirmation}`,
  );

export const getLoggedUserInfo = () => useRequest<LoggedUserInfo>(endpoints.getLoggedUser);

export const deleteUser = (action: string, userId: number, inheritedUser: number) =>
  ajax.delete<AddUserResponseObjectType>(`api/v1/users/${userId}`, {
    data: { action, user_id: inheritedUser },
  });

export const listUsers = () => useRequest<ListUsersResponseDataType>(endpoints.getAllUsers);

export const useGetDeletedUsers = () =>
  // needs good endpoint
  useRequest<any>(endpoints.getDeletedUsers, {
    refreshInterval: 0,
    revalidateOnFocus: false,
  });

export const showCompanyUser = (companySlug: string, id: number) =>
  useRequest<CompanyUserItemDataType>(endpoints.getCompanyUser(companySlug, id));

export const restoreUser = (companySlug: string, id: number) =>
  ajax.post<MessageResponseType>(`api/v1/companies/${companySlug}/users/${id}/restore`);

export const updateUser = (companySlug: string, id: number, data: UserSubmitInfo) =>
  ajax.put<MessageResponseType>(`api/v1/companies/${companySlug}/users/${id}`, data);

export const updateAuthUser = (id: number, data: Omit<UserSubmitInfo, 'companies'>) =>
  ajax.put(`api/v1/users/${id}`, data);

export const moveUser = (id: number, newCompany: number) =>
  ajax.put<MessageResponseType>(`api/v1/users/${id}/move`, {
    user_id: newCompany,
  });

export const forgotPassword = (email: string) =>
  ajax.post<MessageResponseType>(`api/v1/password/email`, { email });

export const getUsersExcept = (id: string) =>
  useRequest<UserPrimitiveData>(`api/v1/users/except/${id}`);

export const resendUserInvite = (email: string) =>
  ajax.post<MessageResponseType>(`api/v1/company/resend-invite`, { email });

export const updateAuthUserImage = (id: number, file: FormData) => {
  ajax.post<MessageResponseType>(`api/v1/users/${id}/avatar`, file);
};
