import { Button, Col, Row, Divider } from 'antd';
import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { Controller } from 'react-hook-form';

import { UserAddFormStyled } from './UserAddForm.styled';
import { BaseSecondaryButton, BaseSelect } from 'components/baseComponents';
import BaseSubmitButton from 'components/baseComponents/BaseSubmitButton';
import UserAddFieldsRow from './UserAddFieldsRow';
import { Text } from 'components/styles';
//import { ReactComponent as BulkUserAdd } from 'assets/image/svg/bulk-user-add.svg';
import { UploadOutlined } from '@ant-design/icons';
import { FormPageTypeEnum } from 'utils/enums';
import useUserAddForm from './useUserAddForm';
import { ReactComponent as BulkUpload } from 'assets/image/svg/bulk-upload-icon.svg';
//import { LoaderLine } from '../../userMove/index';

type UserAddFormPropsType = {
  fromModal?: boolean;
  onUploadMethodSwith: () => void;
  setUsersCount: (count: number) => void;
  onClose?: () => void;
  CSVData: any[];
};

const UserAddForm = ({
  onUploadMethodSwith,
  setUsersCount,
  fromModal,
  onClose,
  CSVData,
}: UserAddFormPropsType): React.ReactElement => {
  const { fontFamilies } = useContext(ThemeContext);
  const {
    handleSubmit,
    control,
    errors,
    onSubmit,
    onSkip,
    getCompanies,
    getWebsites,
    fields,
    getRoles,
    loading,
    isWebsiteAdmin,
  } = useUserAddForm({
    setUsersCount,
    fromModal,
    onClose,
    CSVData,
  });

  return (
    <UserAddFormStyled onSubmit={handleSubmit(onSubmit)}>
      <Row className={'invite-user'}>
        <Col className={'invite-user__content'}>
          <div className="scrollable">
            <Controller
              control={control}
              name={'company_slug'}
              label={'Company'}
              as={<BaseSelect size={'large'} />}
              itemsArray={getCompanies}
              page={FormPageTypeEnum.addUsers}
            />
            {fields.map((item, index) => (
              <UserAddFieldsRow<InviteUserFormType>
                key={item.id}
                item={item}
                index={index}
                control={control}
                errors={errors}
                itemsArray={getRoles}
              />
            ))}
            {isWebsiteAdmin && (
              <Controller
                disabled={false}
                control={control}
                name="website_slug"
                as={
                  <BaseSelect
                    // onChange={() => {}}
                    size={'large'}
                    label={'Website name'}
                    itemsArray={getWebsites}
                  />
                }
                error={errors?.['website_slug']}
              />
            )}
          </div>
        </Col>
        <Col className={'invite-user__footer'}>
          <Divider />
          <Button
            type={'text'}
            onClick={onUploadMethodSwith}
            className={'invite-user__bulk-upload'}
            icon={<BulkUpload />}
          >
            <Text fontFamily={fontFamilies.regular} color={'#4B4DC8'}>
              Switch to Bulk Upload
            </Text>
          </Button>
          {/* <BaseSecondaryButton
            title={'Next'}
            className={'btn--fill'}
            backgroundColor={'#5C5BC1'}
            htmlType={'submit'}
            withArrow={true}
          /> */}
          <BaseSubmitButton
            title={'Add User'}
            className={'btn--fill'}
            backgroundColor={'#4B4DC8'}
            withArrow
          />
        </Col>
      </Row>
    </UserAddFormStyled>
  );
};

export default UserAddForm;
