import { useContext, useEffect, useMemo, useState } from 'react';
import * as Api from 'api';
import { useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import { routesPaths } from 'utils/helpers';
import { addWebsite, ajax, endpoints } from 'services';
import { isEqualArray, validateAxiosErrorInForms } from 'utils/helpers/functions';
import { message } from 'antd';
import { trigger as mutate } from 'swr';
import { WebsiteNames } from 'utils/constants';
import { SiderNavigationContext } from 'state/sider/navigation/siderNavigationContext';
import { StatisticPageCompanyContext } from 'state/productContainer/statisticPage/company/statisticPageCompanyContext';
import { BaseRightDrawerContext } from 'state/baseComponents/BaseRightDrawerContext';

const arrayFieldsSchema = Yup.array<AddWebsiteFormType>().of(
  Yup.object().shape({
    name: Yup.string()
      .required('The field is required (min 3 char.)')
      .min(3, 'Min length should be 3 characters'),
    url: Yup.string()
      .required('The field is required (min 3 char.)')
      .url('Please enter valid URL (url must start with: http:// or https://)')
      .min(3, 'Min length should be 3 characters'),
  }),
);

const schema = Yup.object().shape({
  company_id: Yup.string().required('Please choose a valid company'),
  websites: arrayFieldsSchema,
});

type useWebsiteAddFormPropsType = {
  setWebsiteCount: (count: number) => void;
  fromModal?: boolean;
  onClose?: () => void;
  CSVData: any[];
  parentSlug: string;
};

export const rowField = {
  name: '',
  url: '',
};

const useWebsiteAddForm = ({ setWebsiteCount, CSVData }: useWebsiteAddFormPropsType) => {
  const { replace } = useHistory();
  const [loading, setLoading] = useState(false);
  const { siderState, siderActions } = useContext(SiderNavigationContext);
  const { range, setCompany, company } = useContext(StatisticPageCompanyContext);
  const { onClose } = useContext(BaseRightDrawerContext);

  const { handleSubmit, control, errors, setValue, setError } = useForm<AddWebsitesFormType>({
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
    mode: 'onSubmit',
    shouldFocusError: true,
    defaultValues: {
      company_id: '',
      websites: [rowField],
    },
  });

  const { fields, append, remove } = useFieldArray<AddWebsiteFormType>({
    control,
    name: 'websites',
  });

  useEffect(() => {
    setWebsiteCount(fields.length - (errors.websites?.length ?? 0));

    return () => {
      setWebsiteCount(-1);
    };
  }, [fields, errors]);

  useEffect(() => {
    if (!CSVData.length) return;
    const websites: AddWebsiteFormType[] = [];
    if (!isEqualArray(CSVData[0], WebsiteNames)) {
      message.error('Please upload correct type CSV');
      return;
    }
    CSVData.forEach((val, index) => {
      if (!index) return;
      if (val[0].length < 1 || val[1].length < 1) return;
      websites.push({
        url: val[0],
        name: val[1],
        notes: val[2] || null,
      });
    });
    remove();
    requestAnimationFrame(() => {
      append(websites);
    });
  }, [CSVData]);

  const getCompanies: SelectItemType[] = useMemo(
    () =>
      siderState.companies.map(({ name, slug }) => ({
        label: name,
        value: slug,
      })) ?? [],
    [siderState.companies],
  );

  useEffect(() => {
    if (getCompanies?.length) {
      setValue('company_id', getCompanies[0].value);
    }
  }, [getCompanies]);

  const onSkip = () => {
    replace(routesPaths.home);
  };

  const onSubmit = async (data: AddWebsitesFormType) => {
    const { company_id } = data;
    const accessLevel = siderState.companies.filter((tree) => tree.slug === company_id);
    if (accessLevel[0].user_role === 4) {
      message.error("You don't have required access in this company.");
      return;
    }
    try {
      setLoading(true);
      await addWebsite(data.company_id, {
        websites: data.websites.map((val) => ({ ...val, notes: '' })),
      });

      message.success('Website(s) added successfully.');
      mutate(endpoints.getWebsites);
      mutate(endpoints.siderTree);
      mutate(endpoints.getCompany(data.company_id));
      ajax.get(Api.endpoints.siderAllWebsites(data.company_id)).then((response) => {
        siderActions.setWebsites(response.data, data.company_id);
      });
      Api.getStatisticPageCompany({
        args: {
          companySlug: data.company_id,
          startDate: range.range.start,
          endDate: range.range.end,
        },
      }).then((res) => {
        setCompany({
          ...company,
          isLoading: false,
          data: res.data,
        });
      });
      onClose();
    } catch (error) {
      validateAxiosErrorInForms<AddWebsitesFormType>(error, setError);
    } finally {
      setLoading(false);
    }
  };

  return {
    handleSubmit,
    control,
    errors,
    onSubmit,
    onSkip,
    getCompanies,
    fields,
    loading,
    setValue,
  };
};

export default useWebsiteAddForm;
