import React, { ReactNode, useContext, useEffect, useReducer, useState, useMemo } from 'react';
import { reducer, DefaultState } from './reducer';
import { PluginItemSortingByStatusEnum } from 'utils/enums';
import { trigger } from 'swr';
import { lowerCaseFirstLetter } from 'utils/helpers/functions';
import {
  createAdvertSettings,
  getProductPageSettings,
  useGetProduct,
  getEmbedLink,
  createProductSlideSettings,
  useGetSlides,
  pinSlide,
  endpoints,
} from 'services';
import { StringParam, useQueryParam } from 'use-query-params';
import { message } from 'antd';

export type EditSlideObjectType = Pick<
  SlideObjectType,
  | 'url'
  | 'time'
  | 'publication_date'
  | 'slideTypeSlug'
  | 'image_path'
  | 'duration'
  | 'description'
  | 'title'
>;

interface ContextState extends DefaultState {
  updatePin: (productId: number, slug: string, pinned: boolean, order: number) => void;
  editSlide: (payload: EditSlideObjectType) => void;
  updateAdvertsRequirements: (payload: AdverSettingsType) => void;
  updateProductPageSettings: (payload: ProductSlideSettingsFormType) => void;
  deleteSlideObj: (slug: string, order: number) => void;
  updateSlidesOnDrop: (payload: SlideDropObject) => void;
  sortData: (payload: { sortHeader: string | null; reverse: boolean }) => void;
  clearSlides: () => void;
}

export const FILTER_BY_STATUS = 'filter-by-status';

export type ParamsType = {
  companySlug: string;
  websiteSlug: string;
  productSlug: string;
  slideSlug: string;
};

const defaultState: DefaultState = {
  filteredSlides: [],
  allSlides: [],
  slides_status: {
    all: 0,
    expired: 0,
    published: 0,
    scheduled: 0,
  },
  slideData: {
    published_at: '',
    created_at: '',
    deleted_at: '',
    updated_at: '',
    description: '',
    duration: 0,
    expire_at: '',
    force_expire: 0,
    id: 0,
    image_path: '',
    video_path: '',
    is_video: false,
    pinned: false,
    product_website_id: 0,
    publication_date: '',
    slide_type_id: 0,
    slug: '',
    impressions: 0,
    clicks: 0,
    status: '',
    time: '',
    title: '',
    url: '',
    order: 0,
    slideTypeSlug: '',
  },
  filterStatus: PluginItemSortingByStatusEnum.All,
  productData: {
    name: '',
    company_name: '',
    product_type_id: 0,
    slug: '',
    website_name: '',
  },
  productPageSettings: {
    badge_form: '',
    badge_position: '',
    enable_pausing: false,
    mute_videos: false,
    sliding_period: '',
    sticky_badge: false,
    visible_stories_limit: '',
    colors: '',
  },
  advertsSettings: {
    adds: true,
    // sellers: true,
  },
  embedLink: null,
  sortState: {
    sortHeader: null,
    reverse: false,
  },
};

const ProductContainerContext = React.createContext<ContextState>({} as ContextState);

export const ProductContainerContextProvider = ({ children }: { children: ReactNode }) => {
  const [filterByStatus] = useQueryParam(FILTER_BY_STATUS, StringParam);
  const [state, dispatch] = useReducer(reducer, defaultState);
  // const { companySlug, websiteSlug, productSlug } = useParams<ParamsType>();
  const companySlug = window.location.pathname.split('/')[2];
  const websiteSlug = window.location.pathname.split('/')[3];
  const productSlug = 'story-creator';

  const { data: slides } = useGetSlides(websiteSlug, productSlug);
  const { data: product } = useGetProduct(companySlug, websiteSlug, productSlug);
  const { data: productPageSettings } = getProductPageSettings(websiteSlug, productSlug);
  const { data: embedLink } = getEmbedLink(websiteSlug, productSlug);

  useEffect(() => {
    if (embedLink)
      dispatch({
        type: 'FETCH_EMBED_LINK',
        payload: embedLink.embed_plugin_link,
      });
  }, [embedLink]);

  useEffect(() => {
    if (productPageSettings) {
      // const sellers = productSlideSetting.data.product_slide_settings.sellers;
      const adds = productPageSettings.data.product_slide_settings.adds;
      const plugin_header = productPageSettings.data.product_slide_settings.plugin_header;
      const plugin_sidebar = productPageSettings.data.product_slide_settings.plugin_sidebar;
      const active_adds = productPageSettings.data.product_slide_settings.active_adds;
      if (adds) {
        const payload: AdverSettingsType = {
          adds,
          // sellers,
          plugin_header,
          plugin_sidebar,
          active_adds,
        };
        dispatch({ type: 'UPDATE_ADVERTS_REQUIREMENTS', payload });
      }
      const {
        visible_stories_limit,
        sticky_badge,
        sliding_period,
        mute_videos,
        enable_pausing,
        badge_text_color,
        badge_position,
        badge_form,
        badge_border_color,
        badge_background_color,
      } = productPageSettings.data.product_slide_settings;

      const colors = badge_background_color + '&' + badge_border_color + '&' + badge_text_color;
      const payload = {
        visible_stories_limit,
        badge_form: lowerCaseFirstLetter(badge_form)!,
        colors,
        badge_position,
        enable_pausing,
        mute_videos,
        sliding_period,
        sticky_badge,
      };
      dispatch({ type: 'UPDATE_PRODUCT_SLIDE_SETTINGS', payload });
    }
  }, [productPageSettings]);

  useEffect(() => {
    dispatch({ type: 'UPDATE_FILTER_STATUS', payload: filterByStatus });
    dispatch({ type: 'FILTER_SLIDES' });
    dispatch({ type: 'SORT_SLIDES' });
  }, [filterByStatus]);

  useEffect(() => {
    dispatch({ type: 'FILTER_SLIDES' });
  }, [state.allSlides]);

  useEffect(() => {
    if (product?.data) {
      dispatch({ type: 'SET_PRODUCT_DATA', payload: product.data });
      dispatch({ type: 'FILTER_SLIDES' });
    }
    if (slides?.data) {
      dispatch({ type: 'LOAD_SLIDES', payload: slides });
    }
  }, [product?.data, slides?.data]);

  const clearSlides = () => {
    dispatch({
      type: 'CLEAR_SLIDES',
    });
  };

  const updatePin = async (productId: number, slug: string, pinned: boolean, order: number) => {
    try {
      await pinSlide(websiteSlug, productSlug, slug);
      dispatch({
        type: 'UPDATE_PIN_STATUS',
        payload: {
          slug,
          id: productId,
          pinned,
          order,
        },
      });
    } catch (error) {
      message.error(`Unexpected Error Occured`);
      console.log(error);
    }
  };

  const editSlide = (payload: EditSlideObjectType) => {
    dispatch({ type: 'EDIT_SLIDE', payload });
  };

  const deleteSlideObj = (slug: string, order: number) => {
    dispatch({
      type: 'DELETE_SLIDE',
      payload: {
        slug,
        order,
      },
    });
  };

  const sortData = (payload: { sortHeader: string | null; reverse: boolean }) => {
    dispatch({ type: 'SORT_DATA', payload });
  };

  const updateProductPageSettings = async (payload: ProductSlideSettingsFormType) => {
    const {
      colors,
      badge_form,
      sticky_badge,
      visible_stories_limit,
      mute_videos,
      enable_pausing,
      sliding_period,
      badge_position,
    } = payload;

    const color = colors.split('&');
    const product_settings = {
      product_settings: {
        sliding_period,
        enable_pausing,
        mute_videos,
        sticky_badge,
        visible_stories_limit,
        badge_form,
        badge_position,
        badge_background_color: color[0],
        badge_border_color: color[1],
        badge_text_color: color[2],
      },
    };
    try {
      await createProductSlideSettings(websiteSlug, productSlug, product_settings);
      dispatch({ type: 'UPDATE_PRODUCT_SLIDE_SETTINGS', payload });
    } catch (error) {
      console.log(error);
    }
  };

  const updateAdvertsRequirements = async (payload: AdverSettingsType) => {
    let advertSettigs = {
      advert_settings: {
        // sellers: payload.sellers,
        adds: payload.adds,
        plugin_header: payload.plugin_header,
        plugin_sidebar: payload.plugin_sidebar,
        active_adds: payload.active_adds,
      },
    };

    if (payload.active_adds !== null)
      advertSettigs = {
        advert_settings: {
          ...advertSettigs.advert_settings,
          active_adds: payload.active_adds,
        },
      };

    try {
      await createAdvertSettings(websiteSlug, productSlug, advertSettigs);
      dispatch({ type: 'UPDATE_ADVERTS_REQUIREMENTS', payload });
      trigger(endpoints.getEmbedLink(websiteSlug, productSlug));
    } catch (error) {
      message.error('Unexpected Error Occured');
      console.log(error);
    }
  };

  const updateSlidesOnDrop = (payload: SlideDropObject) => {
    dispatch({ type: 'UPDATE_SLIDES_ON_DROP', payload });
  };

  return (
    <ProductContainerContext.Provider
      value={{
        ...state,
        updatePin,
        editSlide,
        updateAdvertsRequirements,
        updateProductPageSettings,
        deleteSlideObj,
        updateSlidesOnDrop,
        sortData,
        clearSlides,
      }}
    >
      {children}
    </ProductContainerContext.Provider>
  );
};

export const useProductContainerContext = () => useContext(ProductContainerContext);
