import * as Api from 'api';
import { message, Row } from 'antd';
import { BaseSelect } from 'components/baseComponents';
import BaseRadioGroup from 'components/baseComponents/BaseRadioGroup';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { trigger } from 'swr';
import {
  deleteCompany,
  deleteUser,
  deleteWebsite,
  deleteProduct,
  endpoints,
  getUsersExcept,
  getCompaniesExcept,
  getWebsitesExcept,
  ajax,
} from 'services';
import { mutate } from 'swr';
import { routesPaths } from 'utils/helpers';
import { RemoveStyled } from './Remove.styled';
import { validateAxiosErrorInForms } from '../../../../utils/helpers/functions';
import { AppContext } from 'state/appContext';
import { SiderNavigationContext } from 'state/sider/navigation/siderNavigationContext';
import { StatisticPageCompanyContext } from 'state/productContainer/statisticPage/company/statisticPageCompanyContext';
import { StatisticPageWebsiteContext } from 'state/productContainer/statisticPage/website/statisticPageWebsiteContext';
import BaseSubmitButton from 'components/baseComponents/BaseSubmitButton';
import { Text } from 'components/styles';
import { ReactComponent as GrayWarningIcon } from 'assets/image/svg/gray-warning.svg';
import { BaseRightDrawerContext } from 'state/baseComponents/BaseRightDrawerContext';

const radioTexts = {
  Company: {
    deleteAll: 'Delete the company and all its child entities',
    moveIt: `Delete the company and assign all its child entities to another company`,
  },
  Website: {
    deleteAll: 'Delete the website and all its products',
    moveIt: `Delete the website and assign all its child entities to another Website`,
  },
  User: {
    deleteAll: 'Do nothing with the entities they manage',
    moveIt: `Re-assign all entities to`,
  },
  Product: {
    deleteAll: 'Delete the Product',
    moveIt: 'Assign the product to a different website',
  },
};
type RemoveFormFieldsType = {
  deleteAll: string;
  companySlug: string;
};

const Remove = ({ type, onClose, slug, companySlug, websiteSlug }: RemoveProductPropsType) => {
  const { siderActions } = useContext(SiderNavigationContext);
  const { onClose: drawerClose } = useContext(BaseRightDrawerContext);
  const { range: rangeCompany, setCompany, company } = useContext(StatisticPageCompanyContext);
  const { range: rangeWebsite, setWebsite, website } = useContext(StatisticPageWebsiteContext);
  const {
    state: { userTree },
  } = useContext(AppContext);
  const [organization, setOrganization] = useState<SiderTreeBeardTreeType | null>(null);
  // eslint-disable-next-line
  let companies: any, websites: any, users: any;
  switch (type) {
    case 'Company':
      companies = getCompaniesExcept(slug as string).data;
      break;
    case 'Website':
      websites = getWebsitesExcept(`${slug}?include_same_product_websites=false` as string).data;
      break;
    case 'User':
      users = getUsersExcept(slug as string).data;
      break;
  }
  const { replace, push } = useHistory();
  const { handleSubmit, control, watch, setValue, setError } = useForm({});

  useEffect(() => {
    if (companies || websites || users) {
      if (type === 'Company') {
        companies.data.length > 0 ? setValue('companySlug' as never, companies.data[0].slug) : null;
      } else if (type === 'Website') {
        websites.data.length > 0 ? setValue('companySlug' as never, websites.data[0].slug) : null;
      } else setValue('companySlug' as never, users.length && users.data[0].id);
    }
  }, [companies, websites, users]);

  useEffect(() => {
    const organization = userTree.find((i) => i.is_parent === true);
    setOrganization(organization as SiderTreeBeardTreeType);
  }, [userTree]);

  const { deleteAll } = watch();
  const onCancel = () => {
    if (onClose == null) return;
    onClose();
  };

  const getCompanies: SelectItemType[] = useMemo(
    () =>
      companies?.data.map(({ name, slug }: NameSlugType) => ({
        label: name,
        value: slug,
      })) ?? [],
    [companies],
  );

  const getWebsites: SelectItemType[] = useMemo(
    () =>
      websites?.data.map(({ name, slug }: NameSlugType) => ({
        label: name,
        value: slug,
      })) ?? [],
    [websites],
  );

  const getUsers: SelectItemType[] = useMemo(
    () =>
      users?.data.map(({ first_name, last_name, id }: UserPrimitive) => ({
        label: first_name + ' ' + last_name,
        value: id,
      })) ?? [],
    [users],
  );

  const renderItems = () => {
    if (type === 'Company') return getCompanies;
    if (type === 'Website') return getWebsites;
    return getUsers;
  };

  const onSubmit = async (data: RemoveFormFieldsType) => {
    const { deleteAll } = data;
    let { companySlug: inheritmantEntity } = data;
    if (deleteAll === 'delete') {
      inheritmantEntity = '';
    }
    try {
      switch (type) {
        case 'Company':
          if (slug === organization?.slug) {
            message.error('You are not allowed to delete the Organization!');
            return;
          }
          await deleteCompany(deleteAll, slug as string, inheritmantEntity);
          trigger(endpoints.getCompanies);
          trigger(endpoints.siderTree);
          replace(routesPaths.home);
          ajax.get(Api.endpoints.siderAllCompanies).then((response) => {
            siderActions.setCompanies(response.data);
          });
          ajax.get(Api.endpoints.siderAllWebsites(inheritmantEntity)).then((response) => {
            siderActions.setWebsites(response.data, inheritmantEntity);
          });
          break;
        case 'Website':
          if (companySlug == null) return;
          if (renderItems().length === 0 && deleteAll !== 'delete') {
            message.error('Unable to assign child entities');
            return;
          }
          await deleteWebsite(deleteAll, companySlug, slug as string, inheritmantEntity);
          mutate(endpoints.getWebsitesOfCompany(companySlug));
          mutate(endpoints.getCompany(companySlug));
          mutate(endpoints.getWebsites);
          mutate(endpoints.siderTree);
          ajax.get(Api.endpoints.siderAllWebsites(companySlug)).then((response) => {
            siderActions.setWebsites(response.data, companySlug);
          });
          ajax
            .get(Api.endpoints.siderAllProducts(companySlug, inheritmantEntity))
            .then((response) => {
              siderActions.setProducts(response.data, inheritmantEntity);
            });
          Api.getStatisticPageCompany({
            args: {
              companySlug,
              startDate: rangeCompany.range.start,
              endDate: rangeCompany.range.end,
            },
          })
            .then((res) => {
              setCompany({
                ...company,
                isLoading: false,
                data: res.data,
              });
            })
            .then(() => {
              push(routesPaths.toCompanyOrWebsiteStatisticBySlug(companySlug));
              drawerClose();
            });
          message.success('Website deleted successfully!');
          break;
        case 'User':
          await deleteUser(deleteAll, slug as number, Number(inheritmantEntity));
          // replace(routesPaths.user);
          trigger(endpoints.getAllUsers);

          trigger(endpoints.getCompanyUser(companySlug as string, slug as number));
          mutate(endpoints.siderTree);
          break;
        case 'Product':
          if (companySlug == null) return;
          await deleteProduct(companySlug, websiteSlug, slug as string);
          mutate(endpoints.siderTree);
          ajax.get(Api.endpoints.siderAllProducts(companySlug, websiteSlug)).then((response) => {
            siderActions.setProducts(response.data, websiteSlug);
          });
          Api.getStatisticPageWebsite({
            args: {
              companySlug,
              websiteSlug,
              startDate: rangeWebsite.range.start,
              endDate: rangeWebsite.range.end,
            },
          }).then((res) => {
            setWebsite({
              ...website,
              isLoading: false,
              data: res.data,
            });
          });
          break;
        default:
          message.error('unknown type');
          return;
      }

      onCancel();
    } catch (error) {
      validateAxiosErrorInForms(error, setError);
    }
  };

  return (
    <RemoveStyled onSubmit={handleSubmit(onSubmit)}>
      <Controller
        as={BaseRadioGroup}
        control={control}
        name={'deleteAll'}
        defaultValue={'delete'}
        style={type === 'Product' ? { display: 'none' } : {}}
        itemsArray={
          type !== 'Product'
            ? [
                { label: radioTexts[type].deleteAll, value: 'delete' },
                { label: radioTexts[type].moveIt, value: 'assign' },
              ]
            : [{ label: radioTexts[type].deleteAll, value: 'delete' }]
        }
      />
      {type === 'Product' && (
        <>
          <Text color="black" fontSize="21" fontFamily="Noir Pro Medium">
            Are you sure you want to delete Storyline?
          </Text>
          <Text
            fontSize="16"
            fontFamily="Noir Pro Light"
            color="#808E9B"
            style={{ marginTop: '5px' }}
          >
            You may lose data
          </Text>
          <GrayWarningIcon style={{ margin: 'auto', marginTop: 'auto', marginBottom: '0' }} />
        </>
      )}
      {type !== 'Product' && (
        <Controller
          disabled={deleteAll === 'delete' || renderItems().length === 0}
          as={<BaseSelect size={'large'} />}
          control={control}
          name={'companySlug'}
          itemsArray={renderItems()}
        />
      )}
      <Row align={'middle'} className={'content__submit-container'}>
        <BaseSubmitButton
          title={type !== 'Product' ? `Delete ${type}` : 'Remove'}
          className={'btn--fill'}
          backgroundColor={'#4B4DC8'}
          withArrow
        />
      </Row>
    </RemoveStyled>
  );
};

export default Remove;
