import { DynamicFieldsEnum } from 'utils/enums';

export const EditWebsiteForms: FormSectionWrapperType[] = [
  {
    id: 123,
    title: 'Url',
    fields: [
      {
        id: 21,
        type: DynamicFieldsEnum.Input,
        value: '',
        name: 'url',
        placeholder: 'url of the website',
        label: 'URL',
      },
    ],
  },
  {
    id: 321,
    title: 'Name',
    fields: [
      {
        id: 2,
        type: DynamicFieldsEnum.Input,
        value: '',
        name: 'name',
        placeholder: 'Display name',
        label: 'Display name',
      },
    ],
  },
  {
    id: 314,
    title: 'Note',
    fields: [
      {
        id: 2432,
        type: DynamicFieldsEnum.TextArea,
        value: '',
        name: 'notes',
        placeholder: 'note',
        label: 'Note',
      },
    ],
  },
];

export const moveWebsiteForms: FormSectionWrapperType[] = [
  {
    id: 123,
    title: 'receiving Company',
    fields: [
      {
        id: 2,
        type: DynamicFieldsEnum.Select,
        value: '',
        name: 'company_slug',
        itemsArray: [],
        label: 'Company name',
      },
    ],
  },
  {
    id: 314,
    title: 'Note',
    fields: [
      {
        id: 2432,
        type: DynamicFieldsEnum.TextArea,
        value: '',
        name: 'notes',
        placeholder: 'note',
        label: 'Note',
      },
    ],
  },
];
