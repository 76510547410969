import { TimeRangeProps } from 'api/statisticPage/website/types';
import { BaseDateRangeSelector } from 'components/baseComponents';
import React from 'react';
import { SelectTimeRangeStyled } from './SelectTimeRange.styled';

const SelectTimeRange: React.FC<TimeRangeProps> = ({ range, setRange }) => {
  return (
    <SelectTimeRangeStyled>
      <BaseDateRangeSelector range={range} setRange={setRange} />
    </SelectTimeRangeStyled>
  );
};

export default SelectTimeRange;
