import React from 'react';
import Faq from 'views/faqContainer/components/Faq';
import BaseContentHeader from 'components/baseComponents/BaseContentHeader';
import DashboardLayout from 'views/dashboardLayout/DashboardLayout';
import SiderMobile from 'components/sider/SiderMobile';

export default function FrequentQuestions() {
  return (
    <DashboardLayout>
      <BaseContentHeader>
        <h1>FAQ</h1>
      </BaseContentHeader>
      <SiderMobile style={{ marginTop: '30px', marginBottom: '30px' }} />
      <Faq />
    </DashboardLayout>
  );
}
