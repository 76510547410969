import React from 'react';
import { BaseTextAreaStyled } from './BaseTextArea.styled';
import { TextAreaProps } from 'antd/lib/input';
import BaseFloatingLabel from './BaseFloatingLabel';

type BaseTextAreaProps = TextAreaProps & {
  label?: string;
};

const BaseTextArea = (props: BaseTextAreaProps) => {
  return (
    <BaseFloatingLabel textAreaType label={props.label ?? ''} value={props.value}>
      <BaseTextAreaStyled $label={props.label} {...props} />
    </BaseFloatingLabel>
  );
};

export default BaseTextArea;
