import { Row } from 'antd';
import styled from 'styled-components';
import check from 'assets/image/svg/check-white.svg';

export const BaseBadgeShapeSelectorStyled = styled(Row)`
  width: 100%;
  .baseBadgeShape {
    display: flex;
    flex-direction: row;
    flex-flow: row nowrap;
    p {
      padding-left: 2rem;
      position: relative;
      margin-right: 1rem;
      color: #000;
      cursor: pointer;
      &::before {
        content: '';
        background-image: url(${check});
        background-size: contain;
        background-repeat: no-repeat;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        width: 1.5rem;
        height: 1.5rem;
        border: 2px solid #53536d69;
        left: 0;
      }

      &.square {
        &::before {
          border-radius: 3px;
        }
      }
      &.circle {
        &::before {
          border-radius: 50%;
        }
      }

      &.active {
        &::before {
          border-color: #5c5cc2;
          background-color: #5c5cc2;
        }
      }
    }
  }
`;
