import styled from 'styled-components';

export const BulkUserAddStyled = styled.form`
  .content__form-container {
    padding-left: 2.5rem;
    padding-right: 4rem;
    .sub {
      padding-right: 0;
    }
    .form-container__company-select {
      margin-bottom: 2.1rem;
    }
  }

  .file-drop-zone-container {
    padding-left: 2.5rem;
    padding-right: 4rem;
    .file-drop {
      background-color: red;
      background: #f6f9fc 0% 0% no-repeat padding-box;
      border-radius: 12px;
      padding: 1.4rem 2rem;
      cursor: pointer;
      svg {
        margin-right: 5%;
      }
      .drop-zone__title {
        text-transform: uppercase;
        margin-bottom: 0.2rem;
      }
    }
  }

  .content__submit-container {
    padding-top: 1rem;
    justify-content: space-between;
    .switch-to-manual {
      padding-left: 0;
    }
    .wrapper {
      justify-content: flex-end;
      display: flex;
      .invite-user__download-sample {
        flex-wrap: nowrap;
        align-items: center;
        p {
          margin-left: 1rem;
        }
      }
    }

    .btn {
      height: 4rem;
      border-radius: 2rem;
      width: 15rem;
      margin-left: 1rem;
    }
  }
  @media only screen and (max-width: 550px) {
    .file-drop-zone-container {
      .ant-col {
        margin: 0 auto;
        .file-drop {
          padding: 0;
          .ant-row {
            align-items: center;
            justify-content: center;
            text-align: center;
            svg {
              margin: 0 0 1rem 0;
            }
          }
        }
      }
    }
    .content__form-container {
      .content__submit-container {
        margin: 1rem 0 0 0;
        * {
          margin: 0.25rem auto;
          padding: 0;
        }
      }
    }
  }
`;
