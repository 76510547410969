import { Switch } from 'antd';
import { Text } from 'components/styles';
import React, { useContext, forwardRef } from 'react';
import { ThemeContext } from 'styled-components';
import PermissionToggleItemStyled from './PermissionToggleItem.styled';

type PermissionToggleItemPropsType = {
  text: string;
  onChange: (val: boolean) => void;
  onClick?: (val: boolean) => void;
  value: boolean;
  disabled: boolean;
  defaultChecked?: boolean;
  collapseHandler?: (val: boolean) => void;
};
// eslint-disable-next-line react/display-name
const PermissionToggleItem = forwardRef(
  (
    {
      text,
      onChange,
      defaultChecked,
      value,
      onClick,
      collapseHandler,
      disabled,
    }: PermissionToggleItemPropsType,
    ref: React.ForwardedRef<HTMLElement | null>,
  ) => {
    const { fontFamilies } = useContext(ThemeContext);

    return (
      <PermissionToggleItemStyled align={'middle'} justify={'space-between'}>
        <Text
          fontFamily={fontFamilies.semiBold}
          color={'#000000'}
          onClick={collapseHandler}
          style={{ width: 'calc(100% - 50px)' }}
        >
          {text}
        </Text>
        <Switch
          disabled={disabled}
          defaultChecked={defaultChecked}
          onChange={onChange}
          onClick={onClick}
          checked={value}
          ref={ref}
        />
      </PermissionToggleItemStyled>
    );
  },
);

export default PermissionToggleItem;
