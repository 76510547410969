import React, { useMemo } from 'react';
import { Row, Col } from 'antd';
import { AddProductComponentStyled } from './AddProduct.Styled';
import BaseSubmitButton from 'components/baseComponents/BaseSubmitButton';
import useAddProduct from './useAddProduct';
import { Controller } from 'react-hook-form';
import { BaseSelect } from 'components/baseComponents';
import Flex from 'atoms/Flex';
import { useGetCompanies } from '../../../services';
import BaseNotice from 'components/baseComponents/BaseNotice';

const PRODUCTS = [{ value: '1', label: 'Storyline' }];

const AddProduct = ({ slugs }: CommonModalPropsType): React.ReactElement => {
  const { data: companies } = useGetCompanies();
  const { onSubmit, handleSubmit, control, errors, getWebsites } = useAddProduct();

  const getCompanies: SelectItemType[] = useMemo(
    () =>
      (companies?.data as RequiredCompanyType[])?.map(({ name, slug }: NameSlugType) => ({
        label: name,
        value: slug,
      })) ?? [],
    [companies],
  );

  return (
    <AddProductComponentStyled onSubmit={handleSubmit(onSubmit)}>
      <Flex direction="column">
        <BaseNotice />

        <Controller
          disabled={true}
          control={control}
          name="product"
          label="Product"
          as={<BaseSelect size="large" itemsArray={PRODUCTS} />}
          error={errors?.['product']}
          style={{ width: '100%' }}
        />

        <Controller
          disabled={false}
          control={control}
          name="company"
          as={
            <BaseSelect
              size="large"
              onChange={() => {}}
              label={'Company name'}
              itemsArray={getCompanies}
              defaultValue={slugs?.companySlug}
              value={slugs?.companySlug}
            />
          }
          error={errors?.['company']}
        />

        <Controller
          disabled={false}
          control={control}
          name="website"
          as={
            <BaseSelect
              size="large"
              onChange={() => {}}
              label={'Website name'}
              itemsArray={getWebsites}
              defaultValue={slugs?.websiteSlug}
              value={slugs?.websiteSlug}
            />
          }
          error={errors?.['website']}
        />
      </Flex>
      <Row align={'middle'} justify={'space-between'} className={'content__submit-container'}>
        <Col span={24}>
          <BaseSubmitButton
            title={'Add Storyline'}
            className={'btn--fill'}
            backgroundColor={'#4B4DC8'}
            style={{ marginTop: '12px' }}
            withArrow
          />
        </Col>
      </Row>
    </AddProductComponentStyled>
  );
};

export default AddProduct;
