import styled from 'styled-components';
import { svg } from 'assets/image';

export const BaseNoticeStyled = styled.div`
  background-color: #eae6ff;
  position: relative;
  border-radius: 6px;
  margin: 20px 0;
  padding: 30px;
  display: flex;
  justify-content: flex-start;

  /* &::before {
    content: url(${svg.disclaimer});
    transform: scale(0.7);
    position: absolute;
    left: 15px;
    top: 12px;

} */
`;
