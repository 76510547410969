import React, { createContext, useState } from 'react';
import { Dispatch } from 'react';

interface TypesStarterContainerContext {
  readonly storylines: IStorylines[] | null;
  readonly setStorylines: Dispatch<React.SetStateAction<IStorylines[] | null>>;
  readonly isLoading: boolean;
  readonly setIsLoading: Dispatch<React.SetStateAction<boolean>>;
}

interface Props {
  readonly children: React.ReactNode;
}

const StarterContainerContext = createContext({} as TypesStarterContainerContext);

const StarterContainerProvider = ({ children }: Props) => {
  const [storylines, setStorylines] = useState<IStorylines[] | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  return (
    <StarterContainerContext.Provider
      value={{
        storylines,
        setStorylines,
        isLoading,
        setIsLoading,
      }}
    >
      {children}
    </StarterContainerContext.Provider>
  );
};

export { StarterContainerContext, StarterContainerProvider };
