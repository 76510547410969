import styled from 'styled-components';

export const DashboardChartStyled = styled.div`
  margin-top: 30px;
  .title-row {
    p {
      color: #181818;
    }
  }
  .select-company {
    font-family: 'Noir Pro Regular';
    height: 40px;
    width: 142px;
    padding: 0 5% 0 15px;
    border: 1px solid #9fa2af4d;
    border-radius: 10px;
    margin-right: 20px;
    font-size: 16px;
    color: black;
    background: white;
    cursor: pointer;

    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url("data:image/svg+xml;utf8,<svg width='14' height='8' viewBox='0 0 12 7' xmlns='http://www.w3.org/2000/svg' transform='rotate(180)'><path fill='gray' clipRule='evenodd' d='M6.00122 2.70217L9.971 6.67698C10.2648 6.97095 10.74 6.97095 11.0307 6.67698C11.3245 6.38301 11.3213 5.90766 11.0307 5.61369L6.5326 1.11037C6.24815 0.825784 5.79178 0.819531 5.49796 1.08848L0.96866 5.61057C0.821751 5.75755 0.74985 5.95144 0.74985 6.14222C0.74985 6.33298 0.82174 6.52687 0.96866 6.67386C1.26249 6.96783 1.73761 6.96783 2.02831 6.67386L6.00122 2.70217Z' /></svg>");
    background-repeat: no-repeat;
    background-position-x: 119px;
    background-position-y: 53%;
    border: 1px solid #dfdfdf;

    &:focus {
      border: 1px solid #4b4dc8;
    }

    option {
      padding: 20px;
    }
    &::after {
      display: none;
    }
    &:focus-visible {
      outline: none;
    }
  }

  @media only screen and (max-width: 1330px) {
    height: 30px;
    width: 110px;
    background-position-x: 90%;
    margin-right: 5px;
    padding: 0 7% 0 10px;
    font-size: 14px;
  }

  @media only screen and (max-width: 1000px) {
    font-size: 14px;
    width: 100px;
    padding: 0 20px 0 12px;
  }

  @media only screen and (max-width: 720px) {
    width: 80px;
  }
  @media only screen and (max-width: 680px) {
    width: 100%;
    margin-bottom: -10px;
    margin-top: 10px;
    margin-right: 0;
    padding: 0 16%;
    background-position-x: 84%;
  }
  @media only screen and (max-width: 500px) {
    padding: 0 10%;
    background-position-x: 84%;
  }
  @media only screen and (max-width: 500px) {
    padding: 0 10%;
    background-position-x: 88%;
  }
  @media only screen and (max-width: 320px) {
    padding: 0 16px;
    background-position-x: 94%;
  }
`;
