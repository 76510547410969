import React, { createContext, useState, useMemo } from 'react';

interface SearchContainerTypes {
  readonly searchValue: string;
  readonly setSearchValue: (x: string) => void;
  readonly onChange: (e: React.FormEvent<HTMLInputElement>) => void;
  readonly searchOverlay: boolean;
  readonly setSearchOverlay: React.Dispatch<React.SetStateAction<boolean>>;
  readonly searchResults: ISearchData | Record<string, never>;
  readonly setSearchResults: React.Dispatch<
    React.SetStateAction<ISearchData | Record<string, never>>
  >;
  readonly websites: WebsiteWithStat[] | [];
}

interface Props {
  readonly children: React.ReactNode;
}

const SearchContainerContext = createContext({} as SearchContainerTypes);

const SearchContainerProvider = ({ children }: Props) => {
  const [searchValue, setSearchValue] = useState('');
  const [searchOverlay, setSearchOverlay] = useState(false);
  const [searchResults, setSearchResults] = useState<ISearchData | Record<string, never>>({});

  const websites = useMemo(
    () =>
      searchResults?.companies?.reduce<WebsiteWithStat[]>(
        (acc, curr) => [...acc, ...curr.websites.map((item) => ({ ...item }))],
        [],
      ),
    [searchResults],
  );

  const onChange = (e: React.FormEvent<HTMLInputElement>) => {
    setSearchValue(e.currentTarget.value);
  };
  return (
    <SearchContainerContext.Provider
      value={{
        searchValue,
        setSearchValue,
        onChange,
        searchOverlay,
        setSearchOverlay,
        searchResults,
        setSearchResults,
        websites,
      }}
    >
      {children}
    </SearchContainerContext.Provider>
  );
};

export { SearchContainerContext, SearchContainerProvider };
