import { Col } from 'antd';
import styled from 'styled-components';

export const ProfilePageStyled = styled(Col)`
  max-height: 100%;
  height: 100%;
  width: 100%;

  h1 {
    font-family: 'Noir Pro Bold';
    font-size: 34px;
    margin-bottom: 15px;
  }

  h3 {
    font-family: 'Noir Pro Bold';
    font-size: 21px;
  }

  .input-container:not(:last-child) {
    margin-bottom: 30px;
  }

  .ant-divider {
    border-top: 1px solid #eaecf2;
  }

  .user-image-feedback {
    font-family: 'Noir Pro Light';
    font-size: 16px;
    color: #808e9b;
  }

  .submit-container {
    display: flex;
    justify-content: space-between;
  }

  .user-image-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: #e8edf8;
    border-radius: 10px;
    padding: 10px;
    height: 145px;

    .user-image-box {
      height: 100%;
      display: flex;
    }

    .user-details {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      padding: 0px 15px;
    }

    img {
      height: 100%;
      border-radius: 10px;
      cursor: pointer;
    }
  }

  @media (max-width: 1025px) {
    .submit-btn {
      height: 40px;
      width: 196px;

      svg {
        top: 30%;
      }
    }

    h1 {
      margin-top: 20px;
    }
  }

  @media (max-width: 768px) {
    h1 {
      font-size: 21px;
    }

    h3 {
      font-size: 16px;
    }

    .user-image-feedback {
      font-size: 14px;
    }

    .submit-btn {
      width: 156px;
    }
  }

  @media (max-width: 576px) {
    h1 {
      font-size: 18px;
    }

    h3 {
      font-size: 14px;
    }

    .user-image-feedback {
      font-size: 12px;
    }

    .submit-container {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 20px;
    }
  }
`;
