import styled from 'styled-components';

export const PublicHeaderStyled = styled.header`
  width: 100%;
  display: flex;
  justify-content: space-between;
  z-index: 100;
  margin-bottom: 0;

  .landing_navbar {
    position: sticky;
    top: 0;
    z-index: 200;
    background-color: white;
    padding-bottom: 25px;
    padding-top: 25px;
    margin-bottom: 0px;
    @media only screen and (min-width: 576px) and (max-width: 992px) {
      padding: 0 20px !important;
    }
  }

  .navbar-links {
    display: flex;
    justify-content: center;
    align-items: center;

    .faq-link {
      font-family: 'Noir Pro Medium' !important;
      color: #181818;
      margin-right: 20px;
      font-size: 19px;

      &:hover {
        color: #01ae92;
      }
    }
    .btn--add-new {
      height: 60px;
      width: 180px;
      span {
        font-size: 21px;
        margin-top: -2px;
      }
    }
  }

  button {
    margin-left: 0px !important;
    padding: 20px 15px !important;
    border-radius: 50px !important;

    span {
      text-transform: unset !important;
    }

    &:hover {
      background-color: #01ae92;
    }
  }

  @media only screen and (max-width: 1366px) {
    .logo {
      width: 108px;
      height: 40px;
    }
    .navbar-links {
      .faq-link {
        margin-right: 20px;
        font-size: 16px;

        &:hover {
          color: #01ae92;
        }
      }
      .btn--add-new {
        height: 40px;
        width: 150px;
        span {
          font-size: 18px;
          margin-top: 0;
        }
      }
    }
  }

  @media only screen and (max-width: 576px) {
    .navbar-links {
      .faq-link {
        margin-right: 10px;
        font-size: 12px;
        display: none;

        &:hover {
          color: #01ae92;
        }
      }
      .btn--add-new {
        height: 40px;
        width: 120px;
        span {
          font-size: 14px;
          margin-top: 2px;
        }
      }
    }
  }
`;
