import { css } from 'styled-components';

export const themePrimary = {
  fontFamilies: {
    bold: 'Noir Pro Bold',
    regular: 'Noir Pro Regular',
    light: 'Noir Pro Light',
    semiBold: 'Segoe UI SemiBold',
    italic: 'Segoe UI Italic',
    consolas: 'Consolas normal',
  },
  colors: {
    primaryTitle: '#343D47',
    primaryText: '#787D88',
    primaryWhite: '#FFFFFF',
    primaryGreen: '#66C3AD',
    primaryGray: '#E0E2E666',
    primaryColor: '#5C5CC2',
    primaryBackground: '#F6F9FC',
    secondaryGray: '#E9EEF5',
  },
  scrollable: () => {
    return css`
      &::-webkit-scrollbar {
        width: 0.3rem;

        height: 1.125rem;
      }

      &::-webkit-scrollbar-thumb {
        background-clip: padding-box;

        -webkit-border-radius: 0.4375rem;

        background-color: rgba(0, 0, 0, 0.15);

        border-radius: 1rem;
      }

      &::-webkit-scrollbar-track {
        margin: 8px 0;
      }

      &::-webkit-scrollbar-button {
        width: 0;

        height: 0;

        display: none;
      }

      &::-webkit-scrollbar-corner {
        background-color: transparent;
      }
    `;
  },
};
