import React, { useContext, useEffect, useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import debounce from 'lodash/debounce';
import useDropdown from 'hooks/useDropdown';
import { SearchContainerStyled } from './SearchContainer.styled';
import { Row, Col } from 'antd';
import { getSearchResults } from 'api/search';
import TableHead from 'views/productContainer/containers/statisticPage/components/TableHead';
import ProductItem from 'views/productContainer/containers/statisticPage/components/ProductItem';
import { StatisticPageCompany } from 'api/statisticPage/company/types';
import useSortStatisticPage from 'hooks/useSortStatisticPage';
import { SearchContainerContext } from 'state/searchContainer/SearchContainerContext';
import Storyline from 'views/starterContainer/components/Storyline/Storyline';
import closeIcon from 'assets/image/svg/close.svg';

const SearchContainer: React.FC = () => {
  const {
    searchValue,
    setSearchValue,
    onChange,
    setSearchOverlay,
    searchResults,
    setSearchResults,
    websites,
  } = useContext(SearchContainerContext);
  const { isSelected, toggleDropdown } = useDropdown();
  const {
    items: sortedWebsites,
    sort: sortWebsites,
    config: configWebsites,
  } = useSortStatisticPage({ data: { websites } });

  //Load More button functionality
  const [elementNumber, setElementNumber] = useState(2);
  const loadMore = () => {
    setElementNumber(searchResults?.companies.length);
  };
  const slicedCompanies = searchResults?.companies?.slice(0, elementNumber);

  //Close search overlay
  const onClose = () => {
    setSearchOverlay(false);
    setSearchValue('');
  };

  //Search data call with debounce
  const debouncedGetSearchResults = useCallback(
    debounce(async (query: string) => {
      const res = await getSearchResults(query);
      setSearchResults(res.data.data);
    }, 500),
    [],
  );

  useEffect(() => {
    if (searchValue.length > 2) {
      debouncedGetSearchResults(searchValue);
    } else {
      setSearchResults({});
      debouncedGetSearchResults.cancel();
    }
  }, [searchValue]);

  const history = useHistory();

  useEffect(() => {
    return history.listen((location) => {
      setSearchOverlay(false);
    });
  }, []);

  return (
    <SearchContainerStyled className="scrollable">
      <div className="container">
        <div className="search-input-container">
          <input
            autoFocus
            className="search"
            type="text"
            placeholder="Search"
            value={searchValue}
            onChange={onChange}
          />
          <button className="search-close-btn" onClick={onClose}>
            <img src={closeIcon} alt="close" />
          </button>
        </div>

        {searchResults.companies ? (
          <>
            <div className="search-results">
              <p>Search results:</p>
              <span>{searchResults.counts.total_count}</span>
            </div>
            <div className="search-count">
              <p>Live Storylines ({searchResults.counts.companies_count})</p>
            </div>
            <Row gutter={[30, 20]}>
              {slicedCompanies.map((company) => (
                <Col key={company.id} md={{ span: 12 }} span={24}>
                  <Storyline
                    customStyle={{ marginLeft: '0px' }}
                    slug={company.slug}
                    logo={company.logo}
                    name={company.name}
                    activeStories={company.stats.active_stories}
                    impressions={company.stats.impressions}
                    clicks={company.stats.clicks}
                  />
                </Col>
              ))}
            </Row>
            {slicedCompanies.length < 3 && searchResults.companies.length > 2 ? (
              <button className="load-more-btn" onClick={() => loadMore()}>
                Load more
              </button>
            ) : null}

            <div className="search-count">
              <p>Company websites ({searchResults.counts.websites_count})</p>
            </div>

            <div className={`container__content`}>
              <TableHead sort={sortWebsites} config={configWebsites} />
              <div className={'scrollable'}>
                {(sortedWebsites as StatisticPageCompany).data.websites.map((sortedWebsite) => (
                  <ProductItem
                    key={sortedWebsite.id}
                    {...sortedWebsite}
                    companySlug={sortedWebsite.company_slug}
                    isSelected={isSelected}
                    toggleDropdown={toggleDropdown}
                  />
                ))}
              </div>
            </div>
          </>
        ) : null}
      </div>
    </SearchContainerStyled>
  );
};

export default SearchContainer;
