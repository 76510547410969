import styled from 'styled-components';

export const BaseRightDrawerStyled = styled.div`
  position: relative;
  .ant-drawer-content-wrapper {
    width: 504px !important;
  }

  .ant-drawer-header {
    padding: 30px;
    background-color: #f6f7fc;
  }

  .ant-drawer-title {
    font-family: 'Noir Pro Bold';
    font-size: 34px;
  }

  .ant-drawer-close {
    padding: 30px;
  }

  .ant-drawer-content {
    background-color: #f6f7fc;
  }

  .ant-drawer-body {
    padding: 30px;
    padding-bottom: 0px;
    background-color: #f6f7fc;
    overflow: overlay !important;
    ${({ theme }) => theme.scrollable()}
  }

  .noPadding .ant-drawer-body {
    padding: 0px !important;
  }

  // Adjusting styles for new company drawer

  .new-company-drawer {
    .ant-drawer-body {
      padding-top: 10px !important;
    }
  }

  // Adjusting styles for new story drawer

  .storyDrawer {
    .ant-drawer-body {
      padding: 0 !important;
    }
  }
  .redHeadingColor {
    .ant-drawer-header {
      span {
        color: #ee5253 !important;
      }
    }
  }

  // For adjusting to walktrhough overlay
  .mobile-add-drawer {
    .drawer-walkthrough-overlay {
      z-index: 100;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(24, 24, 24, 0.7);
    }
  }

  @media (max-width: 768px) {
    .ant-drawer-title {
      font-size: 18px;
    }
  }

  @media only screen and (max-width: 576px) {
    .ant-drawer-content-wrapper {
      width: 100% !important;
    }
  }
`;
