import { Button } from 'antd';
import styled from 'styled-components';

export const BaseSidebarButtonStyled = styled(Button)`
  background-color: #f6f7fc;
  color: #181818;
  font-size: 18px;
  padding: 30px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 30px 0px;
  border-radius: 10px !important;
  gap: 20px;

  img {
    margin-right: 15px;
  }
  &:hover,
  &:active,
  &:focus {
    background-color: #f6f7fc;
    color: #181818;
  }
`;
