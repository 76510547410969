import React, { forwardRef, useCallback, useContext } from 'react';
import { BaseCheckboxStyled } from './BaseCheckbox.styled';
import { CheckboxProps } from 'antd/lib/checkbox';
import { Text } from 'components/styles';
import { ThemeContext } from 'styled-components';

// eslint-disable-next-line react/display-name
const BaseCheckbox = forwardRef(
  (
    { value, label, ...props }: CheckboxProps & { label?: string },
    ref: React.Ref<HTMLInputElement> | undefined,
  ) => {
    const { fontFamilies } = useContext(ThemeContext);

    const onChange = useCallback(
      (e) => {
        props.onChange?.(e.target.checked);
      },
      [props.onChange],
    );
    return (
      <>
        {props.name === 'mute_videos' && (
          <p style={{ fontSize: '1.125rem', fontFamily: 'Noir Pro Regular', marginRight: '15px' }}>
            Mute videos:{' '}
          </p>
        )}
        <BaseCheckboxStyled
          {...props}
          className={`flex flex-center flex-row checkbox ${
            props.name === 'enable_pausing' ? 'checkbox-pausing' : ''
          } ${props.name === 'sticky_badge' ? 'checkbox-sticky' : ''}`}
          defaultChecked={value}
          value={value}
          checked={value}
          onChange={onChange}
          ref={ref}
          name={props.name}
        >
          <Text color={'#000000'} fontSize={18} fontFamily={fontFamilies.regular}>
            {label ?? 'check'}{' '}
          </Text>
        </BaseCheckboxStyled>
      </>
    );
  },
);

export default BaseCheckbox;
