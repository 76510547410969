import { message } from 'antd';
import { AxiosError } from 'axios';
import { set, get } from 'local-storage';
import { ErrorOption } from 'react-hook-form';
import { ajax, formDataAjax } from 'services';
import { updateOrder } from 'services/slideServices';
import { UserRoles } from '../constants';

export const setAjaxHeader = (key: string, value: string | undefined): void => {
  if (value) {
    ajax.defaults.headers.common[key] = value;
    formDataAjax.defaults.headers.common[key] = value;
  } else {
    delete ajax.defaults.headers.common[key];
    delete formDataAjax.defaults.headers.common[key];
  }
};

export const saveJWTInLocalStorage = (token: string | null): void => {
  set('token', token);
};

export const getJWTFromLocalStorage = (): string => {
  return get('token');
};

export const setUser = (payload: RegisterResponseType | null): void => {
  set('user', JSON.stringify(payload ?? null));
};

export const logOut = (): void => {
  set('token', null);
  set('email', null);

  setAjaxHeader('Authorization', undefined);
  // remove('activity_session');
};

export const validateAxiosErrorInForms = (
  error: any,
  setError: (name: string, error: ErrorOption) => void,
): void => {
  if (error.isAxiosError) {
    const axiosError: AxiosError = error;
    if (error.response?.status === 422) {
      const errors = axiosError.response?.data.errors;
      if (errors)
        setError(Object.keys(errors)[0] as string, {
          message: errors[Object.keys(errors)[0]][0],
          shouldFocus: true,
        });
      else message.error(`Error: ${error.response.data.message}`);
    } else if (error.response?.status === 401) {
      message.error('User is unauthenticated, please login');
    } else if (error.response.status === 500) {
      message.error(error.response.data.message);
    } else {
      message.error(`Error: ${error.response.data.message}`);
    }
  } else message.error('something went wrong');
};

export const capitalize = (str: string) => {
  return str!.charAt(0).toUpperCase()! + str!.slice(1);
};

export const lowerCaseFirstLetter = (str: string) => {
  if (!str) return;
  return str!.charAt(0).toLowerCase()! + str!.slice(1);
};

export const isValidFile = (data: any) => {
  return data?.[0].type === 'text/csv' || (data[0].name as string).match('csv');
};

export const isOneOf = (value: string, oneOfArray: string[]) => {
  return oneOfArray.includes(value);
};

export const isEqualArray = (arrayOne: string[], arrayTwo: string[]) => {
  return (
    arrayOne.length === arrayTwo.length &&
    arrayOne.every((value, index) => value === arrayTwo[index])
  );
};

export const isHighPrivilage = (privilage: string): boolean => {
  return privilage === UserRoles.superAdmin || privilage === UserRoles.organizationAdmin;
};

export const canAddWebsite = (privilage?: string, type?: string, role?: 3 | 4 | null): boolean => {
  let result = false;
  if (
    (type === 'Company' && privilage === UserRoles.organizationAdmin) ||
    privilage === UserRoles.superAdmin
  ) {
    result = true;
  }
  if (type === 'Company' && role === 3) {
    result = true;
  }
  if (type === 'Website') result = false;
  return result;
};

export const canAddProduct = (privilage?: string, websiteSlug?: string): boolean => {
  return (
    websiteSlug != null &&
    (privilage === UserRoles.superAdmin ||
      privilage === UserRoles.organizationAdmin ||
      privilage === UserRoles.companyAdmin ||
      privilage === UserRoles.websiteAdmin)
  );
};

export const showDeleteEdit = (privilage?: string, type?: string, role?: 3 | 4 | null): boolean => {
  let result = false;
  if (privilage === UserRoles.organizationAdmin || privilage === UserRoles.superAdmin) {
    result = true;
  }
  if (type === 'Website' && role === 3) {
    result = true;
  }
  if (
    (type === 'Company' && role === 3 && privilage === UserRoles.superAdmin) ||
    privilage === UserRoles.organizationAdmin
  ) {
    result = true;
  }
  return result;
};

export const sendSlideOrders = async (
  slides: SlideObjectType[],
  websiteSlug: string,
  productSlug: string,
) => {
  try {
    updateOrder(websiteSlug, productSlug, { slides });
  } catch (e) {
    console.error(e);
  }
};

type SlideParameters = {
  sourceSlide: {
    slug: string;
    order: number;
  };
  destionationSlide: {
    slug: string;
    order: number;
  };
  productSlug?: string;
  websiteSlug?: string;
  slides: SlideObjectType[];
};

export const orderSlidesOnDragDown = (payload: SlideParameters) => {
  const { sourceSlide, destionationSlide, slides } = payload;
  const tempSlides = [...slides];
  tempSlides.map((s) => {
    if (sourceSlide.slug === s.slug) {
      s.order = destionationSlide.order;
      s.updated_at = new Date();
    }
    if (
      s.order <= destionationSlide.order &&
      s.order > sourceSlide.order &&
      sourceSlide.slug !== s.slug
    ) {
      s.order -= 1;
      s.updated_at = new Date();
    }
    return s;
  });
  return tempSlides;
};

export const orderSlidesOnDragUp = (payload: SlideParameters) => {
  const { sourceSlide, destionationSlide, slides } = payload;
  const tempSlides = [...slides];
  tempSlides.map((s) => {
    if (sourceSlide.slug === s.slug) {
      s.order = destionationSlide.order;
      s.updated_at = new Date();
    }
    if (
      s.order >= destionationSlide.order &&
      s.slug !== sourceSlide.slug &&
      s.order < sourceSlide.order
    ) {
      s.order += 1;
      s.updated_at = new Date();
    }
    return s;
  });
  return tempSlides;
};

type SlidePinParameters = {
  slug: string;
  order: number;
  slides: SlideObjectType[];
  filteredSlides?: SlideObjectType[];
};

export const orderSlidesOnPin = (payload: SlidePinParameters) => {
  const { slides, order, slug } = payload;
  const tempSlides = [...slides];
  tempSlides.map((s) => {
    if (s.slug === slug) {
      s.pinned = true;
      s.order = 0;
      s.updated_at = new Date();
    }
    if (s.order < order && slug !== s.slug) {
      s.order += 1;
      s.updated_at = new Date();
    }
    return s;
  });
  return tempSlides;
};

export const orderSlidesOnUnpin = (payload: SlidePinParameters) => {
  const { slides, order, slug, filteredSlides } = payload;
  const tempSlides = [...slides];
  tempSlides.map((s) => {
    if (slug === s.slug) {
      s.order = filteredSlides!.length - 1;
      s.pinned = false;
      s.updated_at = new Date();
    }
    if (s.order > order && slug !== s.slug) {
      s.order -= 1;
      s.updated_at = new Date();
    }
    return s;
  });
  return tempSlides;
};
