import React from 'react';
import { lazyLoadable } from 'utils';
import { HomePageTableItem, HomePageTableHead } from 'components/skeletons';

export const LoginRegisterPageLazy = lazyLoadable(
  () => import('./LoginRegister'),
  <>
    <HomePageTableHead />
    <HomePageTableItem />
  </>,
);
