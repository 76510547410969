import React, { useContext } from 'react';
import { DashboardLayoutStyled } from './DashboardLayout.styled';
import { Row, Col } from 'antd';
import { Sider } from 'components';
import RightSideNavigation from './components/RightSideNavigation';
import DashboardHeader from './components/DashboardHeader';
import BaseRightDrawer from 'components/baseComponents/BaseRightDrawer';
import BaseSidebarButton from 'components/baseComponents/BaseSidebarButton';
import Question from 'assets/image/svg/patch-question.svg';
import Archive from 'assets/image/svg/archive.svg';
import { useHistory } from 'react-router-dom';
import SearchContainer from './containers/searchContainer/SearchContainer';
import { SearchContainerContext } from 'state/searchContainer/SearchContainerContext';
import WalkthroughOverlay from 'views/starterContainer/components/WalkthroughOverlay/WalkthroughOverlay';
import { AppContext } from 'state/appContext';

const DashboardLayout = ({ children }: any) => {
  const { searchOverlay } = useContext(SearchContainerContext);
  const history = useHistory();
  const {
    state: { user },
  } = useContext(AppContext);

  return (
    <>
      {searchOverlay ? (
        <>
          <SearchContainer />
          <BaseRightDrawer />
        </>
      ) : (
        <DashboardLayoutStyled>
          <div className="left-side-wrapper">
            <Sider />
          </div>
          <div className="middle-wrapper">
            <div className="dashboard-header">
              <DashboardHeader />
            </div>
            <div id="walkthrough-scrollable" className="dashboard-content">
              <div className="dashboard-body">{children}</div>

              <footer>
                <Row gutter={[15, 15]}>
                  <Col sm={{ span: 7 }} xs={{ span: 24 }}>
                    <BaseSidebarButton
                      className={'footer-btn-mobile'}
                      text={'F.A.Q.'}
                      icon={Question}
                      onClick={() => history.push('/frequent-questions')}
                    />
                  </Col>
                  <Col sm={{ span: 7 }} xs={{ span: 24 }}>
                    <BaseSidebarButton
                      className={'footer-btn-mobile'}
                      text={'Archive'}
                      icon={Archive}
                      onClick={() => history.push('/archive')}
                    />
                  </Col>
                  <Col
                    sm={{ span: 10 }}
                    xs={{ span: 24 }}
                    style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                  >
                    <p className="footer_text">Powered by Flat Rock Technology Ltd</p>
                  </Col>
                </Row>
              </footer>
            </div>
          </div>
          <div className="right-side-wrapper">
            <RightSideNavigation />
          </div>
          <BaseRightDrawer />
        </DashboardLayoutStyled>
      )}
      {user && user.walkthrough ? <WalkthroughOverlay /> : null}
    </>
  );
};

export default DashboardLayout;
