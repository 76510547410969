import React from 'react';
import { lazyLoadable } from 'utils';

export const WebsiteDetailPageLazy = lazyLoadable(
  () => import('./WebsiteDetailPage'),
  // TODO: needs proper skeleton
  <>
    {/* <HomePageTableHead />
    <HomePageTableItem /> */}
  </>,
);
