import { Row } from 'antd';
import styled from 'styled-components';

type UserAddFieldsRowStyledType = {
  $hasErrorAtLeft: boolean;
};
export const UserAddFieldsRowStyled = styled(Row)<UserAddFieldsRowStyledType>`
  margin: 1.125rem 0;
  .form-item__name-container {
    background-color: #f6f9fc;
    border-radius: 6px;
    position: relative;
    &:after {
      content: ' ';
      position: absolute;
      height: 70%;
      width: 1px;
      background-color: ${({ $hasErrorAtLeft }) => ($hasErrorAtLeft ? 'transparent' : '#e9eef5')};
      left: 50%;
      top: 15%;
    }
    & .ant-row {
      & > div {
        width: 50%;
        &:nth-child(1) {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
        &:nth-child(2) {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
      }
    }
  }
  .form-item__email-container {
    padding: 0 0.92rem;
  }
`;
