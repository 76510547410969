import { PluginItemSortingByStatusEnum } from 'utils/enums';
import { AppActions } from './actions';
import {
  capitalize,
  orderSlidesOnDragUp,
  orderSlidesOnPin,
  orderSlidesOnUnpin,
} from 'utils/helpers/functions';
import { sendSlideOrders } from 'utils/helpers/functions';
import { orderSlidesOnDragDown } from 'utils/helpers/functions';
import collect from 'collect.js';

export interface DefaultState {
  filteredSlides: SlideObjectType[];
  allSlides: SlideObjectType[];
  slides_status: SlidesStatusType;
  filterStatus: PluginItemSortingByStatusEnum;
  productData: ProductsType;
  slideData: SlideObjectType;
  advertsSettings: AdverSettingsType;
  productPageSettings: ProductSlideSettingsFormType;
  embedLink: string | null;
  sortState: {
    sortHeader: string | null;
    reverse: boolean;
  };
}

export const reducer = (state: DefaultState, action: AppActions): DefaultState => {
  if (action.type === 'SET_PRODUCT_DATA') {
    return {
      ...state,
      productData: action.payload,
    };
  }

  if (action.type === 'LOAD_SLIDES') {
    const sortedSlides = collect<SlideObjectType>(action.payload.data.slides).sortBy(
      'order',
    ) as unknown as { items: SlideObjectType[] };
    return {
      ...state,
      allSlides: sortedSlides.items,
      slides_status: action.payload.data.slides_status,
    };
  }

  if (action.type === 'SORT_SLIDES') {
    const sortedSlides = collect<SlideObjectType>(state.filteredSlides).sortBy(
      'order',
    ) as unknown as { items: SlideObjectType[] };

    const allSortedSlides = collect<SlideObjectType>(state.allSlides).sortBy(
      'order',
    ) as unknown as { items: SlideObjectType[] };

    return {
      ...state,
      filteredSlides: sortedSlides.items,
      allSlides: allSortedSlides.items,
    };
  }

  if (action.type === 'UPDATE_PIN_STATUS') {
    const { pinned, slug, order } = action.payload;
    let tempSlides = [...state.filteredSlides];
    let allTempSlides = [...state.allSlides];
    const filterStatus = state.filterStatus;

    if (filterStatus !== 'All') {
      tempSlides = [...state.allSlides];
      if (pinned === false) {
        // PIN CASE WHEN NOT ALL SLIDES ARE AVAILABLE ON SCREEN
        tempSlides.map((s) => {
          if (s.slug === slug) {
            s.pinned = true;
            s.order = 0;
            s.updated_at = new Date();
          }
          if (s.order < order && slug !== s.slug) {
            s.order += 1;
            s.updated_at = new Date();
          }
          return s;
        });
        allTempSlides = tempSlides;
        tempSlides = tempSlides.filter((slide) => capitalize(slide.status) === filterStatus);
      } else if (pinned === true) {
        // UNPIN CASE WHEN NOT ALL SLIDES ARE AVAILABLE ON SCREEN
        tempSlides.map((s) => {
          if (slug === s.slug) {
            s.order = state.allSlides.length - 1;
            s.pinned = false;
            s.updated_at = new Date();
          }
          if (s.order > order && slug !== s.slug) {
            s.order -= 1;
            s.updated_at = new Date();
          }
          return s;
        });
        allTempSlides = tempSlides;
        tempSlides = tempSlides.filter((slide) => capitalize(slide.status) === filterStatus);
      }
    }

    if (filterStatus === 'All') {
      if (pinned === false) {
        tempSlides = orderSlidesOnPin({ slug, order, slides: tempSlides });
      } else if (pinned === true) {
        tempSlides = orderSlidesOnUnpin({
          order,
          slides: tempSlides,
          slug,
          filteredSlides: state.filteredSlides,
        });
      }
    }

    const sortedSlides = collect<SlideObjectType>(tempSlides).sortBy('order') as unknown as {
      items: SlideObjectType[];
    };

    const allSortedSlides = collect<SlideObjectType>(allTempSlides).sortBy('order') as unknown as {
      items: SlideObjectType[];
    };

    return {
      ...state,
      filteredSlides: sortedSlides.items,
      allSlides: allSortedSlides.items,
    };
  }

  if (action.type === 'DELETE_SLIDE') {
    const { order, slug } = action.payload;
    let tempSlides = [...state.filteredSlides].filter((s) => s.slug !== slug);

    tempSlides = tempSlides.map((s) => {
      if (s.order > order && s.slug !== slug) {
        s.order -= 1;
        s.updated_at = new Date();
      }
      return s;
    });

    const sortedSlides = collect<SlideObjectType>(tempSlides).sortBy('order') as unknown as {
      items: SlideObjectType[];
    };

    return {
      ...state,
      filteredSlides: sortedSlides.items,
      allSlides: sortedSlides.items,
    };
  }

  if (action.type === 'UPDATE_SLIDES_ON_DROP') {
    const { sourceSlide, destionationSlide, productSlug, websiteSlug } = action.payload;
    let tempSlides = [...state.allSlides];
    const slidesBetweenSrcAndDest = state.allSlides
      .filter((i) => {
        if (
          (sourceSlide.order > i.order && destionationSlide.order < i.order) ||
          (sourceSlide.order < i.order && destionationSlide.order > i.order)
        ) {
          return i;
        }
      })
      .map((i) => i.status);

    if (state.filterStatus !== 'All') {
      if (
        // SWAP IF SLIDES ARE NEXT TO EACH OTHER
        sourceSlide.order + 1 === destionationSlide.order ||
        sourceSlide.order - 1 === destionationSlide.order
      ) {
        tempSlides.map((s) => {
          if (sourceSlide.slug === s.slug) {
            s.order = destionationSlide.order;
            s.updated_at = new Date();
          }
          if (destionationSlide.slug === s.slug) {
            s.order = sourceSlide.order;
            s.updated_at = new Date();
          }
          return s;
        });
      } else if (
        slidesBetweenSrcAndDest.length > 0 &&
        slidesBetweenSrcAndDest.indexOf(sourceSlide.status) === -1
      ) {
        //SWAP IF SLIDES ARE NOT NEXT TO EACH OTHER AND WHEN ALL SLIDES BETWEEN THEM HAVE DIFFERENT STATUS
        tempSlides.map((s) => {
          if (sourceSlide.slug === s.slug) {
            s.order = destionationSlide.order;
            s.updated_at = new Date();
          }
          if (destionationSlide.slug === s.slug) {
            s.order = sourceSlide.order;
            s.updated_at = new Date();
          }
          return s;
        });
      } else {
        if (sourceSlide.order > destionationSlide.order) {
          tempSlides = orderSlidesOnDragUp({
            slides: tempSlides,
            destionationSlide,
            sourceSlide,
          });
        } else if (sourceSlide.order < destionationSlide.order) {
          tempSlides = orderSlidesOnDragDown({
            slides: tempSlides,
            destionationSlide,
            sourceSlide,
          });
        }
      }
    }

    if (state.filterStatus === 'All') {
      if (sourceSlide.order > destionationSlide.order) {
        tempSlides = orderSlidesOnDragUp({
          slides: tempSlides,
          destionationSlide,
          sourceSlide,
        });
      } else if (sourceSlide.order < destionationSlide.order) {
        tempSlides = orderSlidesOnDragDown({
          slides: tempSlides,
          destionationSlide,
          sourceSlide,
        });
      }
    }

    const sortedSlides = collect<SlideObjectType>(tempSlides).sortBy('order') as unknown as {
      items: SlideObjectType[];
    };

    sendSlideOrders(sortedSlides.items, websiteSlug, productSlug);

    return {
      ...state,
      filteredSlides: sortedSlides.items,
      allSlides: sortedSlides.items,
    };
  }

  if (action.type === 'UPDATE_FILTER_STATUS') {
    const status = action.payload;
    return {
      ...state,
      filterStatus: status as PluginItemSortingByStatusEnum,
    };
  }

  if (action.type === 'FILTER_SLIDES') {
    const { filterStatus } = state;

    if (filterStatus && filterStatus !== 'All') {
      const tempSlides = state.allSlides.filter((item) => capitalize(item.status) === filterStatus);
      return {
        ...state,
        filteredSlides: tempSlides,
      };
    } else {
      return {
        ...state,
        filteredSlides: state.allSlides,
      };
    }
  }

  if (action.type === 'EDIT_SLIDE') {
    return {
      ...state,
      slideData: action.payload as SlideObjectType,
    };
  }

  if (action.type === 'UPDATE_ADVERTS_REQUIREMENTS') {
    const { adds, plugin_header, plugin_sidebar, active_adds } = action.payload;
    return {
      ...state,
      advertsSettings: {
        ...state.advertsSettings,
        adds,
        plugin_sidebar,
        plugin_header,
        active_adds,
      },
    };
  }

  if (action.type === 'UPDATE_PRODUCT_SLIDE_SETTINGS') {
    return {
      ...state,
      productPageSettings: action.payload,
    };
  }

  if (action.type === 'UPDATE_PRODUCT_SLIDE_DATA') {
    return {
      ...state,
      slideData: action.payload,
    };
  }

  if (action.type === 'FETCH_EMBED_LINK') {
    return {
      ...state,
      embedLink: action.payload,
    };
  }

  if (action.type === 'CLEAR_SLIDES') {
    return {
      ...state,
      filteredSlides: [],
      slides_status: {} as SlidesStatusType,
    };
  }

  if (action.type === 'SORT_DATA') {
    return {
      ...state,
      sortState: action.payload,
    };
  }

  return {
    ...state,
  };
};
