import { Row } from 'antd';
import styled from 'styled-components';

export const EmbedPageStyled = styled(Row)`
  height: 6.125rem;
  padding: 1rem 1.4rem 1rem 1.25rem;
  .ant-skeleton-header {
    vertical-align: middle;
  }
`;
