import React, { useContext } from 'react';
import { NavigationMenuStyled } from './NavigationMenu.styled';
import { Link } from 'react-router-dom';
import { logOut } from 'utils/helpers/functions';
import { AppContext } from 'state/appContext';
import { appActions } from 'state/appReducer';
import { svg } from 'assets/image';
import { Skeleton } from 'antd';
import { SiderNavigationContext } from 'state/sider/navigation/siderNavigationContext';
import { WalkthroughContext } from 'state/walkthrough/walkthroughContext';

export default function NavigationMenu() {
  const {
    dispatch,
    state: { user },
  } = useContext(AppContext);
  const { dispatch: dispatchSider } = useContext(SiderNavigationContext);
  const { setUserStats } = useContext(WalkthroughContext);

  const onLogOut = () => {
    logOut();
    dispatch(appActions.toggleUserLoginStatus(false));
    setUserStats(null);
    dispatchSider({ type: 'clear' });
  };

  return (
    <NavigationMenuStyled>
      <div className="navigation-menu_header">
        {user ? (
          <img src={user.avatar} alt="profile picture" height="50px" width="50px" />
        ) : (
          <Skeleton.Button style={{ width: '50px', height: '50px' }} active />
        )}
        <div style={{ marginLeft: '20px' }}>
          <p>Hello</p>
          <span>{`${user?.first_name} ${user?.last_name}`}</span>
        </div>
      </div>
      <nav className="navigation-menu_body">
        <ul>
          <li>
            <Link to={'/user/profile'}>My Profile</Link>
          </li>
          <li>
            <Link to={'/privacy-policy'}>Privacy Policy</Link>
          </li>
          <li>
            <Link to={'/terms-of-use'}>Terms of Use</Link>
          </li>
          <li>
            <Link onClick={onLogOut}>
              Log Out <img src={svg.logoutIcon} alt="" />
            </Link>
          </li>
        </ul>
      </nav>
      <div className="navigation-menu_footer">
        <Link to={'/home'}>
          <img src={svg.logo} alt="logo" />
        </Link>
      </div>
    </NavigationMenuStyled>
  );
}
