import React, { useEffect, useState } from 'react';
import { BaseDatePickerStyled } from './BaseDatePicker.styled';
import moment from 'moment-timezone';
import { constants } from 'utils';
import { ReactComponent as CalendarSlideConfig } from 'assets/image/svg/calendar-slide-config.svg';

type BaseTimePickerProps = {
  value?: string;
  format?: string;
  noIcon?: boolean;
  // eslint-disable-next-line no-unused-vars
  onChange?: (val: string) => void;
};

const BaseDatePicker = ({ value, onChange, format, noIcon, ...props }: BaseTimePickerProps) => {
  const disabledDate = (current: moment.Moment) => {
    return current && current < moment().subtract(1, 'days');
  };
  const [date, setDate] = useState<string | undefined>();
  useEffect(() => {
    setDate(value);
  }, [value]);
  return (
    <BaseDatePickerStyled
      bordered={false}
      format={format || constants.DATE_FORMAT_CLIENT}
      {...props}
      defaultValue={moment()}
      value={date ? moment(date) : moment()}
      onChange={(value: moment.Moment | null) => {
        // eslint-disable-next-line react/prop-types
        onChange?.(value?.format(format || constants.DATE_FORMAT).toString() ?? '');
      }}
      disabledDate={disabledDate}
      suffixIcon={!noIcon && <CalendarSlideConfig />}
      clearIcon={false}
      inputReadOnly
    />
  );
};

export default BaseDatePicker;
