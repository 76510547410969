import { Col, Divider, Row } from 'antd';
import { Text } from 'components/styles';
import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { FormFieldSectionStyled } from './FormFieldSection.styled';

type FormFieldSectionProps = {
  title: string;
  bottomText?: string;
  description?: string;
  children: React.ReactChild;
};
const FormFieldSection = ({ title, bottomText, children, description }: FormFieldSectionProps) => {
  const { fontFamilies } = useContext(ThemeContext);

  return (
    <FormFieldSectionStyled align={'middle'} className="field-styled">
      {/* <Col span={description ? 5 : 8} className={'setup-item__left-side'}>
        <Text
          className={'left-side__title'}
          fontSize={16}
          lineHeight={21}
          fontFamily={fontFamilies.regular}
          color={'#C1C9D6'}
        >
          {title}
        </Text>
        {bottomText && (
          <Text
            className={'left-side__bottom-text'}
            fontSize={14}
            lineHeight={19}
            fontFamily={fontFamilies.regular}
            color={'#7D8CA2'}
          >
            {bottomText}
          </Text>
        )}
      </Col> */}
      <Col
        // span={description ? 11 : 16}
        style={{ width: '100%' }}
        className="field-col"
      >
        <Row>{children}</Row>
      </Col>
      {description && (
        <Col span="24">
          <Text
            className={'setup-item__right-side__text'}
            fontSize={13}
            lineHeight={20}
            fontFamily={fontFamilies.light}
            color={'#7D8CA2'}
            style={title === 'Mute videos' ? { marginTop: '1rem' } : {}}
          >
            {description}
          </Text>
          {title !== 'story color' && (
            <Divider
              style={
                title === 'Sliding period' || title === 'Badge Position'
                  ? { marginTop: '4rem' }
                  : {}
              }
            />
          )}
        </Col>
      )}
    </FormFieldSectionStyled>
  );
};

export default React.memo(FormFieldSection);
