import { useContext, useEffect, useState } from 'react';
import { message } from 'antd';
import { validateAxiosErrorInForms } from 'utils/helpers/functions';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useProductContainerContext } from 'state/productContainer/state/context';
import { productSetting } from 'state/productContainer/settingPage/const';
import { BaseRightDrawerContext } from 'state/baseComponents/BaseRightDrawerContext';

const schema = Yup.object().shape({
  // title: Yup.string().required('The field is required (min 3 char.)').min(3),
  //duration: Yup.number(),
});

const useSettingsPage = () => {
  const { onClose } = useContext(BaseRightDrawerContext);
  const { updateProductPageSettings, productPageSettings } = useProductContainerContext();

  const { handleSubmit, control, watch, setValue, errors, setError } =
    useForm<ProductSlideSettingsFormType>({
      resolver: yupResolver(schema),
    });

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (productPageSettings) {
      for (const [key, value] of Object.entries(productPageSettings)) {
        // @ts-ignore: Unreachable code error
        setValue(key, value);
      }

      setValue('mute_videos', Number(productPageSettings.mute_videos));
      setValue('enable_pausing', Number(productPageSettings.enable_pausing));
      setValue('sticky_badge', Number(productPageSettings.sticky_badge));
    }
  }, [productPageSettings]);

  const onSubmit = async (productSlideSettings: ProductSlideSettingsFormType) => {
    try {
      setLoading(true);
      updateProductPageSettings(productSlideSettings);
      onClose();
      message.success('Settings updated!');
    } catch (error) {
      // @ts-ignore: Unreachable code error
      validateAxiosErrorInForms(error, setError);
    } finally {
      setLoading(false);
    }
  };

  return {
    onSubmit,
    handleSubmit,
    control,
    errors,
    watch,
    setValue,
    fieldData: productSetting,
    loading,
    productPageSettings,
  };
};

export default useSettingsPage;
