import styled from 'styled-components';
import SearchIcon from 'assets/image/svg/feather-search.svg';

export const SiderMobileStyled = styled.div`
  width: 100%;
  display: none;

  //Ant collapse styles
  .ant-collapse-icon-position-right {
    border: none;
  }
  .ant-collapse-item {
    max-height: 450px;
    height: auto;
    overflow: auto;
    ${({ theme }) => theme.scrollable()}
    border: 1px solid #e2e3e7;
    border-radius: 10px !important;
    padding: 0px 10px;
    background-color: white;
  }

  .ant-collapse-item-active {
    border: 1px solid #4b4dc8;
  }
  .ant-collapse-header {
    background-color: white;
    font-size: 18px;
    border-radius: 10px !important;

    svg {
      fill: #808e9b;
      transform: rotate(180deg) !important;
    }

    &[aria-expanded='true'] {
      svg {
        fill: #4b4dc8;
        transform: rotate(0deg) !important;
      }
    }
  }

  .ant-collapse-content {
    padding: 16px;
    padding-top: 0px;
    border-top: none;
    border-radius: 10px !important;
  }
  .ant-collapse-content-box {
    padding: 0;
    padding-top: 25px;
    border-top: 1px solid #f2f3f5;
  }

  //Navigation styles
  .sider-navigation {
    padding: 0;
  }

  .companiesFilter {
    background: #f6f7fc url(${SearchIcon}) no-repeat 20px center;
    width: 100%;
    height: 40px;
    padding-left: ${55 / 16}rem;
    font-size: ${18 / 16}rem;
    border-radius: 10px;
    margin-bottom: 30px;
    &::placeholder {
      color: #7d8ca2;
      opacity: 0.3;
    }

    .ant-skeleton {
      padding: 13px 13px 0 0;
    }
    .ant-layout-sider-children {
      display: flex;
      flex-direction: column;
      position: relative;
      background: white;
      box-shadow: 0px 10px 30px #1818180d;
      border-radius: 10px;
    }
    /* height: 100%; */
    .ant-divider.ant-divider-horizontal {
      border-color: ${({ theme: { colors } }) => colors.secondaryGray};
      margin-bottom: 0;
    }
    .sider__tree-container {
      flex: 1;
      display: flex;
      overflow-y: overlay;
      overflow-x: hidden;
      flex-direction: column;
      justify-content: flex-start;
      position: relative;
      & .organization_icon {
        width: 1.5rem;
        height: 1.5rem;
        top: 2rem;
        right: 1.3rem;
        position: absolute;
      }
      &::-webkit-scrollbar {
        width: 0.3rem;
        height: 6px;
        opacity: 0;
        cursor: pointer;
      }
      &::-webkit-scrollbar-track {
        background: white;
      }
      &::-webkit-scrollbar-thumb {
        background: #ddd;
        border-radius: 0.4375rem;
      }
    }
    .mobile_profile {
      display: none;
    }
  }

  @media only screen and (max-width: 1000px) {
    display: block;
  }
`;
