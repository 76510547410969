import styled, { css } from 'styled-components';

type BaseInputStyledProps = {
  $inputTitle: boolean;
  $error: boolean;
  $label?: string;
  $borderNeeded?: boolean;
  characterCount?: boolean;
};
export const BaseInputStyled = styled.div<BaseInputStyledProps>`
  width: 100%;
  min-height: 3rem;
  position: relative;
  border-radius: 6px;
  ${({ characterCount }) =>
    characterCount &&
    css`
      margin: 0.5rem 0 0 !important;
    `}
  .ant-input-affix-wrapper {
    height: 100%;
  }
  .ant-input {
    border-radius: 10px;
    background-color: white;
    height: 3rem - 1px;
    /* font-size: ${({ $inputTitle }) => ($inputTitle ? 1.875 : 1.2)}rem; */
    font-size: 18px;
    width: 100%;
    height: 100%;
    padding: ${({ $label }) => ($label ? '24px 24px 14px 30px;' : '20px')};
    cursor: text;
    border: 1px solid #8085da33;
    &.ant-input-prefix {
      margin-right: 0.5rem;
      cursor: text;
    }
    ::placeholder {
      color: #c1c9d6;
      text-transform: capitalize;
    }
    ::placeholder-shown {
      text-transform: capitalize;
    }
    &:focus {
      border: ${({ $error, $label }) => ($error && $label ? 'none' : '1px solid #4B4DC8')};
    }
  }

  .input__input-error-container {
    position: absolute;
    right: 0;
    height: 100%;
    top: 0;
    padding-right: 0.4rem;
    display: none;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    .input-error__text {
      color: #de225e;
      width: 0;
      white-space: nowrap;
      overflow: hidden;
      text-align: right;
      transition: all 0.2s ease-out;
      margin-right: 0.4rem;
    }
    svg {
      cursor: pointer;
      width: 1.3rem;
      min-width: 1.3rem;
      height: 1.3rem;
      min-height: 1.3rem;
      display: block;
      path {
        fill: #de225e;
      }
    }
  }
  ${({ $error }) =>
    $error &&
    css`
      border: 1px solid #de225e;
      border-radius: 10px;

      .ant-input {
        opacity: 0.5;
      }
      .input__input-error-container {
        display: flex;
        z-index: 999;
        .input-error__text {
          font-weight: bold;
          color: white;
        }
        &:hover {
          width: 100%;
          background-color: rgba(255, 0, 0, 0.4);
          border-radius: 10px;
          .input-error__text {
            width: 100%;
          }
        }
      }
    `}
`;
