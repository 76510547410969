import React, { ChangeEvent, useState } from 'react';

import { ArchiveWebsitePageStyled } from './ArchiveWebsitePage.styled';

import { useParams } from 'react-router-dom';
import useSortableTable from '../components/useSortableTable';
import { useGetArchivedWebsites } from 'services';
import DashboardLayout from 'views/dashboardLayout/DashboardLayout';
import TableComponentLazy from '../components/TableComponentLazy';
import Header from '../components/Header';
import { TableContainerStyled } from '../components/TableContainer.styled';

// eslint-disable-next-line no-unused-vars
enum WebsiteTableHeadEnum {
  Website = 'Website',
  Product = 'Product',
  Settings = 'Settings',
}

const heads = [
  {
    text: WebsiteTableHeadEnum.Website,
    span: 7,
    offset: 0,
    type: WebsiteTableHeadEnum.Website,
  },
  {
    text: WebsiteTableHeadEnum.Product,
    span: 6,
    offset: 0,
    type: WebsiteTableHeadEnum.Product,
  },
  {
    text: WebsiteTableHeadEnum.Settings,
    span: 0,
    offset: 0,
    type: WebsiteTableHeadEnum.Settings,
  },
];

const setCurrentSortKey = (activeHead: WebsiteTableHeadEnum): keyof ArchivedWebsiteDataType => {
  switch (activeHead) {
    case WebsiteTableHeadEnum.Website:
      return 'name';
    case WebsiteTableHeadEnum.Product:
      return 'countOfProducts';
    default:
      return 'name';
  }
};

type RouteParamsType = {
  type: 'archive';
  companySlug: string;
};
const ArchiveWebsitePage = () => {
  const [searchState, setSearchState] = useState('');
  const handleSearchState = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchState(e.target.value);
  };

  const { companySlug } = useParams<RouteParamsType>();

  const { data: websites } = useGetArchivedWebsites(companySlug);

  const { activeHead, sortedProductItems, onSelectSort } = useSortableTable<
    WebsiteTableHeadEnum,
    ArchivedWebsiteDataType
  >({
    setCurrentSortKey,
    items: websites?.data ?? [],
    initialActiveHead: WebsiteTableHeadEnum.Website,
  });

  const filterWebsites = () => {
    const filteredWebsites = sortedProductItems.filter((website: ArchivedWebsiteDataType) => {
      return website.name.toLowerCase().indexOf(searchState.toLowerCase()) !== -1;
    });
    return filteredWebsites;
  };
  return (
    <DashboardLayout>
      <ArchiveWebsitePageStyled>
        <Header
          title={'archive'}
          type={'archive'}
          setSearchState={handleSearchState}
          searchState={searchState}
        />
        <TableContainerStyled>
          <TableComponentLazy
            activeHead={activeHead}
            // @ts-ignore: Unreachable code error
            bodyItems={filterWebsites()}
            headerItems={heads}
            // @ts-ignore: Unreachable code error
            onSelectSort={onSelectSort}
          />
        </TableContainerStyled>
      </ArchiveWebsitePageStyled>
    </DashboardLayout>
  );
};

export default ArchiveWebsitePage;
