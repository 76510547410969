import { Row } from 'antd';
import styled, { css } from 'styled-components';

export const BaseBadgeColorsStyled = styled(Row)`
  display: flex;
  width: 100%;
  p {
    padding-left: 0.5rem;
    position: relative;
    margin-right: 1rem;
    color: #000;
    margin-top: 2px;
    cursor: pointer;
  }
`;

// Over 200 classes were generated for component BaseBadgeColorsstyled__ColorsShapeStyled with the id of "BaseBadgeColorsstyled__ColorsShapeStyled-rlic55-1".
// Consider using the attrs method, together with a style object for frequently changed styles.
// Example:
//   const Component = styled.div.attrs(props => ({
//     style: {
//       background: props.background,
//     },
//   }))`width: 100%;`

export const ColorsShapeStyled = styled.div.attrs((props) => ({
  style: {
    border: props.className == 'Border' ? `2px solid ${props.color}` : '2px solid white',
    backgroundColor: props.className == 'Background' ? props.color : 'white',
    color: props.className == 'Text' ? props.color : 'white',
  },
}))`
  width: 1.125rem;
  height: 1.125rem;
  border-radius: 0.75rem;
  margin: 0.3rem 0;
  &.Background {
    background-color: inherit;
  }
  &.Text {
    border: 2px solid transparent;
    background-color: transparent;
    position: relative;
    &::after {
      content: 'T';
      width: 100%;
      height: 100%;
      color: inherit;
      font-style: italic;
      font-weight: 600;
      font-size: 1.5rem;
      vertical-align: top;
      position: absolute;
      top: -0.7rem;
      text-align: right;
    }
  }
`;
