import styled from 'styled-components';
import { svg } from 'assets/image';

export const FaqStyled = styled.div`
  img {
    max-width: 100%;
  }
  .ant-collapse {
    background-color: transparent;
    border: none;
  }

  .ant-collapse-header {
    font-family: 'Noir Pro Regular';
    font-size: 21px;
  }

  .ant-collapse-item {
    padding: 15px 30px;
    background-color: white;
    margin-bottom: 15px;
    border-radius: 10px !important;

    p {
      font-family: 'Noir Pro Light';
      color: #808e9b;
    }
  }

  strong,
  em {
    font-family: 'Noir Pro Light';
  }

  .ant-collapse-item-active {
    border: 2px solid #01ae92;
  }

  ul {
    list-style-image: url('${svg.faqBullet}');
    padding-inline-start: 20px;
    margin-bottom: 10px;
  }

  ol {
    padding-inline-start: 20px;
    margin-bottom: 10px;
    > li {
      list-style-type: decimal;
      font-family: 'Noir Pro Light';

      em {
        font-family: 'Noir Pro Light';
      }
    }
  }

  .ant-collapse-header {
    padding-left: 0 !important;
    padding-right: 50px !important;
    font-size: 21px;
    padding-top: 0px !important;
  }

  .ant-collapse-content {
    border-top: 1px solid #f2f3f5;
  }

  .ant-collapse-content-box {
    padding-left: 0;
    padding-right: 0;
  }

  @media (max-width: 768px) {
    .ant-collapse-header {
      font-size: 14px;
    }
  }
`;
