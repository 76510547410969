import React from 'react';
import { FaqContainerStyled } from './FaqContainer.styled';
import Faq from './components/Faq';
import PublicHeader from 'components/header/PublicHeader';
import {
  Container,
  ContainerGrey,
  InnerContainer,
} from '../landingPage/components/Container.styled';
import images, { svg } from 'assets/image';
import { Col, Row } from 'antd';
import { Text, Title } from 'components/styles';

export default function FaqContainer() {
  return (
    <FaqContainerStyled>
      <Container>
        <InnerContainer className="faq_navbar">
          <PublicHeader />
        </InnerContainer>
        <div className="faq-banner">
          <p>Have any questions ?</p>
          <button onClick={() => history.back()}> &#8592; Back</button>
        </div>
        <ContainerGrey className="faq_container">
          <Row gutter={30} style={{ width: '100%' }}>
            <Col className="gutter-row" lg={{ span: 18, order: 1 }} xs={{ span: 24, order: 1 }}>
              <Title.H2 fontSize={36}>
                Frequently asked <span>questions</span>
              </Title.H2>
              <Faq />
            </Col>
            <Col
              className="gutter-row faq-video"
              lg={{ span: 6, order: 2 }}
              xs={{ span: 24, order: 2 }}
            >
              <Title.H2 fontSize={36}>
                Contact <span>Us</span>
              </Title.H2>

              {/* <video poster={images.faqThumbnail} controls>
                <source
                  src="https://storyhub-web-app-prod.s3.eu-west-2.amazonaws.com/storyhub_video/StoryHub-WidgetImplementationP2.mp4"
                  type="video/mp4"
                />
                Your browser does not support HTML video.
              </video> */}
              {/* <Text>
                If you have any additional questions feel free to{' '}
                <a href="mailto:info@digitalstoryhub.com">Email Us</a>{' '}
                <img style={{ marginLeft: '7px' }} src={svg.chatFill} alt="" />
              </Text> */}
              <div className="contact-us-box">
                <Text style={{ display: 'flex' }}>
                  <img style={{ marginLeft: '-2px' }} src={svg.emailIcon} alt="" />
                  <div>
                    <strong> Email</strong>
                    <br />
                    Send us email{' '}
                  </div>
                </Text>
                <span>info@digitalstoryhub.com </span>
              </div>
            </Col>
          </Row>
        </ContainerGrey>
        <InnerContainer style={{ marginBottom: '0px' }}>
          <footer>
            <img src={svg.copyright} alt="copyright" />
          </footer>
        </InnerContainer>
      </Container>
    </FaqContainerStyled>
  );
}
