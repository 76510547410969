import { Button, Col, Row } from 'antd';
import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { Controller } from 'react-hook-form';
import { WebsiteAddFormStyled } from './WebsiteAddForm.styled';
import { BaseSelect } from 'components/baseComponents';
import BaseSubmitButton from 'components/baseComponents/BaseSubmitButton';
import WebsiteAddFieldsRow from './WebsiteAddFieldsRow';
import { Text } from 'components/styles';
import { ReactComponent as UploadIcon } from 'assets/image/svg/upload-icon-feather.svg';
import { FormPageTypeEnum } from 'utils/enums';
import useWebsiteAddForm from './useWebsiteAddForm';

type WebsiteAddFormPropsType = {
  fromModal?: boolean;
  onUploadMethodSwith: () => void;
  setWebsiteCount: (count: number) => void;
  onClose?: () => void;
  CSVData: any[];
  parentSlug: string;
};

const WebsiteAddForm = ({
  onUploadMethodSwith,
  setWebsiteCount,
  fromModal,
  CSVData,
  parentSlug,
}: WebsiteAddFormPropsType): React.ReactElement => {
  const { fontFamilies } = useContext(ThemeContext);
  const { handleSubmit, control, errors, onSubmit, getCompanies, fields, setValue } =
    useWebsiteAddForm({
      setWebsiteCount,
      fromModal,
      CSVData,
      parentSlug,
    });

  return (
    <WebsiteAddFormStyled onSubmit={handleSubmit(onSubmit)}>
      <Row>
        <Col span={24}>
          <Controller
            control={control}
            name={'company_id'}
            as={<BaseSelect size="large" itemsArray={getCompanies} label={'Company name'} />}
            page={FormPageTypeEnum.addWebsite}
            error={errors.company_id}
          />
        </Col>
        {fields.map(({ id, ...item }, index) => (
          <WebsiteAddFieldsRow<AddWebsiteFormType>
            key={id}
            item={item as AddWebsiteFormType}
            index={index}
            control={control}
            errors={errors}
            setValue={setValue}
          />
        ))}
      </Row>

      <Row>
        <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            type={'text'}
            onClick={onUploadMethodSwith}
            className={'website-add-form__bulk-upload'}
            icon={
              <UploadIcon width={`18px`} height={`18px`} style={{ margin: '0px 15px 5px 15px' }} />
            }
          >
            <Text fontSize={18} fontFamily={fontFamilies.regular} color={'#4B4DC8'}>
              Switch to Bulk Upload
            </Text>
          </Button>
        </Col>

        <Col span={24}>
          <BaseSubmitButton
            title={'Add Website'}
            className={'btn--fill'}
            backgroundColor={'#4B4DC8'}
            style={{ marginTop: '12px' }}
            withArrow
          />
        </Col>
      </Row>
    </WebsiteAddFormStyled>
  );
};

export default WebsiteAddForm;
