import styled from 'styled-components';
import background from 'assets/image/images/statistic-background.jpg';

export const StatisticStyled = styled.div`
  /* background-color: white; */
  /* background-image: url(${background}); */
  background-repeat: no-repeat;
  background-position: bottom;
  background-position: center;
  background-size: cover;
  /* height: 28vh; */
  border-top-left-radius: 1.125rem;
  border-top-right-radius: 1.125rem;
  width: 100%;
  /* padding: 1rem 1.5rem 1rem 3.75rem; */
  .vertical-divider {
    width: 1px;
    height: 30px;
    background: #eaecf2;
  }
  .top-part {
    margin-bottom: 25px;
    .title-row {
      gap: ${35 / 16}rem;
    }
    .title {
      font-size: 21px;
    }
    .grab-all {
      display: flex;
      flex-direction: row-reverse;
      padding: 0;
      margin-top: 2px;
      &:hover {
        background: none;
      }
      svg {
        margin-left: 10px;
      }
    }
  }
  .recharts-surface {
    width: 101.7%;
  }
  .recharts-cartesian-grid-vertical {
    display: none;
  }
  .recharts-cartesian-axis-tick-line {
    stroke: rgba(0, 0, 0, 0.05);
    stroke-width: 1px;
  }
  .chart-container {
    width: 100%;
    height: ${340 / 16}rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    box-shadow: 0px 10px 30px #1818180d;
    background: white;
    padding: 20px;
    padding-right: 25px;
    .loading_icon {
      height: 4rem;
      width: 4rem;
      svg {
        height: 100%;
        width: 100%;
        color: #ffffffa8;
        opacity: 0.8;
      }
    }
  }

  @media only screen and (max-width: 1330px) {
    .top-part {
      .title-row {
        gap: 10px;
      }
    }
  }

  @media only screen and (max-width: 1240px) {
    .top-part {
      .title {
        font-size: 18px;
      }
    }
  }
  @media only screen and (max-width: 720px) {
    .grab-all {
      font-size: 14px;
      .hidden-mobile {
        display: none;
      }
      text-transform: capitalize;
    }
  }
  @media only screen and (max-width: 680px) {
    .chart-container {
      overflow-y: hidden;
      position: relative;
      overflow-x: overlay;
      flex: 1;
      &::-webkit-scrollbar {
        height: 0.3rem;
        display: none;
      }

      &::-webkit-scrollbar-thumb {
        background-clip: padding-box;

        -webkit-border-radius: 0.4375rem;

        background-color: rgba(0, 0, 0, 0.15);

        border-radius: 1rem;
      }

      &::-webkit-scrollbar-track {
        margin: 8px 0;
      }

      &::-webkit-scrollbar-button {
        width: 0;

        height: 0;

        display: none;
      }

      &::-webkit-scrollbar-corner {
        background-color: transparent;
      }
      .recharts-responsive-container {
        height: 90% !important;
        min-width: 600px;
        left: 10px;
        position: absolute;
      }
    }
    .select-range-row {
      width: 100%;
    }
    .top-part {
      .title-row {
        /* gap: 0; */
        /* justify-content: space-between; */
        flex-grow: 1;
      }
      .grab-all {
        .hidden-mobile {
          display: none;
        }
        text-transform: capitalize;
      }
    }
  }
`;
