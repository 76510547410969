import React, { useState, useContext, useEffect } from 'react';
import Slider from 'react-slick';
import Storyline, { StorylineEmpty } from '../Storyline/Storyline';
import { StorylinesStyled } from './Storylines.styled';
import * as Api from 'api';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { StarterContainerContext } from 'state/starterContainer/starterContainerContext';
import arrowHeader from 'assets/image/svg/arrow-header.svg';

export default function Storylines() {
  const [sliderRef, setSliderRef] = useState<Slider | null>(null);
  const { storylines, setStorylines } = useContext(StarterContainerContext);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    Api.getStorylines()
      .then((res) => {
        setStorylines(res.data.data);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const settings = {
    arrows: false,
    dots: false,
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 3,

    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 578,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <StorylinesStyled>
      <div className="storylines-controls">
        <h2>Live Storylines</h2>
        <div>
          <button onClick={sliderRef?.slickPrev}>
            <img style={{ transform: 'rotate(90deg)' }} src={arrowHeader} alt="" />
          </button>
          <button onClick={sliderRef?.slickNext}>
            <img style={{ transform: 'rotate(-90deg)' }} src={arrowHeader} alt="" />
          </button>
        </div>
      </div>
      {!isLoading && (
        <Slider ref={setSliderRef} {...settings}>
          {storylines?.map((storyline) => (
            <Storyline
              key={storyline.slug}
              slug={storyline.slug}
              logo={storyline.logo}
              name={storyline.name}
              activeStories={storyline.stats.active_stories}
              impressions={storyline.stats.impressions}
              clicks={storyline.stats.clicks}
            />
          ))}
          {storylines?.length < 6
            ? [...new Array(6 - storylines?.length)].map((item, index) => (
                <StorylineEmpty key={index} />
              ))
            : null}
        </Slider>
      )}
    </StorylinesStyled>
  );
}
