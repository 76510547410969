export const TIME_FORMAT = 'hh:mm a';
export const DATE_FORMAT = 'ddd YYYY-MM-DD';
export const DATE_FORMAT_CLIENT = 'DD-MM-YYYY';
export const SLIDE_DATE_FORMAT = 'mmm d yyyy';

export const InvitedUserRoles = ['Company Admin', 'Website Admin'];

export const UserNames = ['First name', 'Last name', 'Email', 'Role'];

export const WebsiteNames = ['Url', 'name'];

export enum UserRoles {
  superAdmin = 'Super Admin',
  organizationAdmin = 'Organization Admin',
  companyAdmin = 'Company Admin',
  websiteAdmin = 'Website Admin',
}

export const ImageTypes = ['image/jpeg', 'image/jpg', 'image/png'];

export const VideoTypes = ['video/quicktime', 'video/mp4'];
