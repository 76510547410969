import { FC } from 'react';
import { message, Row, Col } from 'antd';
import BaseSubmitButton from 'components/baseComponents/BaseSubmitButton';
import { Text } from 'components/styles';
import React, { useContext } from 'react';
import { endpoints, restoreUser } from 'services';
import { ThemeContext } from 'styled-components';
import { trigger } from 'swr';
import { BaseRightDrawerContext } from 'state/baseComponents/BaseRightDrawerContext';
import { RestoreUserStyled } from './RestoreUser.styled';

const RestoreUser: FC<{ id: number; companySlug: string; name: string }> = ({
  id,
  companySlug,
  name,
}) => {
  const { onClose: drawerClose } = useContext(BaseRightDrawerContext);
  const { fontFamilies } = useContext(ThemeContext);
  const onActivate = async () => {
    try {
      await restoreUser(companySlug, Number(id));
      trigger(endpoints.getAllUsers);
      trigger(endpoints.siderTree);
      trigger(endpoints.getCompanyUser(companySlug, Number(id)));
      message.success('User Restored Succesfuly');
      drawerClose();
    } catch (error) {
      message.error(`Error: ${error.response.data.message}`);
    }
  };

  return (
    <RestoreUserStyled>
      <Col>
        <Row>
          <Col>
            <Text
              className={'text'}
              fontFamily={fontFamilies.light}
              color={'#000000'}
              fontSize={21}
            >
              <span>Restoring user:</span>
            </Text>
          </Col>
          <Col style={{ paddingLeft: '15px' }}>
            <Text
              className={'text'}
              fontFamily={fontFamilies.light}
              color={'#808E9B'}
              fontSize={21}
            >
              <span>{name}</span>.
            </Text>
          </Col>
        </Row>
      </Col>
      <Col className={'user__activate'}>
        <BaseSubmitButton
          title={'Activate'}
          className={'btn--fill'}
          backgroundColor={'#4B4DC8'}
          withArrow
          onClick={onActivate}
        />
      </Col>
    </RestoreUserStyled>
  );
};

export default RestoreUser;
