import styled from 'styled-components';

import {
  space,
  SpaceProps,
  layout,
  LayoutProps,
  flexbox,
  FlexboxProps,
  typography,
  TypographyProps,
  color,
  ColorProps,
} from 'styled-system';

type SystemProps = SpaceProps & LayoutProps & TypographyProps & ColorProps & FlexboxProps;
interface Props extends SystemProps {
  fontSize: string | number;
  color: string;
  hover?: boolean;
  [x: string]: any;
}

const StyledContainer = styled.p<Props>`
  &:hover {
    color: ${({ hover }) => (hover ? '#000' : null)};
  }
  ${space};
  ${layout};
  ${typography};
  ${color};
  ${flexbox};
`;

const StyledSpan = styled.span<Props>`
  line-height: 100%;
  &:hover {
    color: ${({ hover }) => (hover ? '#000' : null)};
  }
  ${space};
  ${layout};
  ${typography};
  ${color};
  ${flexbox};
`;

// StyledContainer.defaultProps = {
//   color: 'primaryTxt2'
// };

export { StyledContainer, StyledSpan };
