import React from 'react';
import { lazyLoadable } from 'utils';
import { HomePageTableHead } from 'components/skeletons';

export const AddCompanyPageLazy = lazyLoadable(
  () => import('./AddCompanyPage'),
  <>
    <HomePageTableHead />
  </>,
);
