import { Input } from 'antd';
import styled from 'styled-components';

interface BaseTextAreaProps {
  $label?: string;
}

export const BaseTextAreaStyled = styled(Input.TextArea)<BaseTextAreaProps>`
  border-radius: 0.375rem;
  background-color: ${({ theme }) => theme.colors.primaryWhite}!important;
  font-size: 1rem;
  width: 100%;
  resize: none;
  line-height: 1.25rem;
  padding: 0.5rem 1rem;
  padding-bottom: 2.5375rem;
  border: 1px solid #8085da33;
  border-radius: 10px;
  padding: ${({ $label }) => ($label ? '24px 24px 30px 30px;' : '20px')};
  &:focus {
    border-color: #4b4dc8 !important;
  }
`;
