import React from 'react';

const PlusIcon = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13.333"
      height="13.333"
      viewBox="0 0 13.333 13.333"
      {...props}
    >
      <path
        id="Icon_awesome-plus"
        data-name="Icon awesome-plus"
        d="M12.381,7.488H8.1V3.2a.953.953,0,0,0-.952-.952H6.19a.952.952,0,0,0-.952.952V7.488H.952A.952.952,0,0,0,0,8.44v.952a.952.952,0,0,0,.952.952H5.238v4.286a.953.953,0,0,0,.952.952h.952a.953.953,0,0,0,.952-.952V10.345h4.286a.952.952,0,0,0,.952-.952V8.44A.952.952,0,0,0,12.381,7.488Z"
        transform="translate(0 -2.25)"
        fill="#4b4dc8"
      />
    </svg>
  );
};

export default PlusIcon;
