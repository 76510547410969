import React, { useContext } from 'react';
import { AddButtonsMobileStyled, AddButtonsMobileDrawerStyled } from './AddButtonsMobile.styled';
import BaseAddButton from 'components/baseComponents/BaseAddButton';
import { BaseRightDrawerContext } from 'state/baseComponents/BaseRightDrawerContext';
import AddCompanyDrawer from 'components/common/addCompany/AddCompanyDrawer';
import AddWebsite from 'components/modal/modalTypes/addWebsite/AddWebsite';
import AddProduct from 'components/common/addProduct/AddProduct';
import { WalkthroughContext } from 'state/walkthrough/walkthroughContext';
import { AppContext } from 'state/appContext';
import { UsersMobileDrawerStyled } from './AddButtonsMobile.styled';
import { AddIcon } from 'components/svg';
import { useHistory } from 'react-router-dom';
import InviteUsers from 'views/user/components/InviteUsers';
import WalkthroughArrowWrapper from 'views/starterContainer/components/WalkthroughArrowWrapper/WalkthroughArrowWrapper';
import { UserManagementIcon } from 'components/svg/UserManagementIcon';

function AddButtonsMobile() {
  const { showDrawer } = useContext(BaseRightDrawerContext);
  const { userStats } = useContext(WalkthroughContext);
  const {
    state: { user },
  } = useContext(AppContext);

  return (
    <AddButtonsMobileStyled className="add-buttons-container">
      {user?.role && (
        <>
          <WalkthroughArrowWrapper isActive={user.walkthrough && !userStats?.products}>
            <BaseAddButton
              backgroundColor={'#01AE92'}
              borderColor={'#01AE92'}
              color={'white'}
              onClick={() => {
                showDrawer({
                  _content: <AddButtonsMobileDrawer />,
                  _title: 'Add Element',
                  _className: user.walkthrough ? 'mobile-add-drawer' : '',
                });
              }}
            >
              <AddIcon stroke="#01AE92" fill="#01AE92" />
              Add <br /> Element
            </BaseAddButton>
          </WalkthroughArrowWrapper>

          {['Organization Admin', 'Super Admin'].includes(user.role[0]) && (
            <BaseAddButton
              backgroundColor={'#808E9B'}
              borderColor={'#808E9B'}
              color={'white'}
              onClick={() => {
                showDrawer({
                  _content: <UsersMobileDrawer />,
                  _title: 'Invite Users',
                });
              }}
            >
              <AddIcon stroke="#808E9B" fill="#808E9B" />
              Users
            </BaseAddButton>
          )}
        </>
      )}
    </AddButtonsMobileStyled>
  );
}

function AddButtonsMobileDrawer() {
  const { showDrawer } = useContext(BaseRightDrawerContext);
  const { userStats } = useContext(WalkthroughContext);
  const {
    state: { user },
  } = useContext(AppContext);
  return (
    <AddButtonsMobileDrawerStyled>
      {user?.role && (
        <>
          {['Organization Admin', 'Super Admin'].includes(user.role[0]) && (
            <WalkthroughArrowWrapper isActive={user?.walkthrough && !userStats?.companies}>
              <BaseAddButton
                borderColor="#C4E1C8"
                backgroundColor="#EDFFF0"
                hoverBackgroundColor="#639B6B"
                hoverColor="white"
                color="#639B6B"
                onClick={() => {
                  showDrawer({
                    _content: <AddCompanyDrawer />,
                    _title: 'New Company',
                    _className: 'overwrite-overlay',
                  });
                }}
              >
                <AddIcon fill="#639B6B" stroke="#639B6B" />
                <p> Add Company</p>
              </BaseAddButton>
            </WalkthroughArrowWrapper>
          )}

          <WalkthroughArrowWrapper
            isActive={user?.walkthrough && !userStats?.websites && userStats?.companies}
          >
            <BaseAddButton
              borderColor="#C7C3DC"
              backgroundColor="#F5F2FF"
              hoverBackgroundColor="#5F578C"
              hoverColor="white"
              color="#5F578C"
              onClick={() => {
                showDrawer({
                  _content: <AddWebsite />,
                  _title: 'New Website',
                  _className: 'overwrite-overlay',
                });
              }}
            >
              <AddIcon fill="#5F578C" stroke="#5F578C" />
              <p> Add Website</p>
            </BaseAddButton>
          </WalkthroughArrowWrapper>

          <WalkthroughArrowWrapper
            isActive={user?.walkthrough && !userStats?.products && userStats?.websites}
          >
            <BaseAddButton
              borderColor="#DFCDC2"
              backgroundColor="#FFF3EC"
              hoverBackgroundColor="#957461"
              hoverColor="white"
              color="#957461"
              onClick={() => {
                showDrawer({
                  _content: <AddProduct />,
                  _title: 'New Storyline',
                  _className: 'overwrite-overlay',
                });
              }}
            >
              <AddIcon fill="#957461" stroke="#957461" />
              <p> Add Storyline</p>
            </BaseAddButton>
          </WalkthroughArrowWrapper>
        </>
      )}
    </AddButtonsMobileDrawerStyled>
  );
}

function UsersMobileDrawer() {
  const { showDrawer } = useContext(BaseRightDrawerContext);
  const history = useHistory();

  return (
    <UsersMobileDrawerStyled>
      <BaseAddButton
        borderColor="#f0f0f0"
        backgroundColor="#ffffff"
        hoverBackgroundColor="#4B4DC8"
        color="#4B4DC8"
        hoverColor="#ffffff"
        onClick={() => history.push('/user')}
      >
        <UserManagementIcon stroke="#4B4DC8" />
        <p>User Management</p>
      </BaseAddButton>
      <BaseAddButton
        borderColor="#f0f0f0"
        backgroundColor="#ffffff"
        hoverBackgroundColor="#4B4DC8"
        color="#4B4DC8"
        hoverColor="#ffffff"
        onClick={() => {
          showDrawer({
            _content: <InviteUsers />,
            _title: 'Invite Users',
          });
        }}
      >
        <AddIcon fill="#4B4DC8" stroke="#4B4DC8" />
        <p>Add Users</p>
      </BaseAddButton>
    </UsersMobileDrawerStyled>
  );
}

export default AddButtonsMobile;
