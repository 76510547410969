import { Layout } from 'antd';
import styled from 'styled-components';
import images from 'assets/image';

export const LoginContainerRouterStyled = styled(Layout)`
  background-color: blue;
`;

type LayoutWrapperStyledPropsType = { $backgroundimage: string };

export const LayoutWrapperStyled = styled(Layout)<LayoutWrapperStyledPropsType>`
  height: auto;
  min-height: 100%;
  flex-direction: column !important;
  background-color: white;
  padding-bottom: 30px;

  .setup-item__left-side {
    display: none;
  }

  label {
    > p {
      display: none;
    }
  }

  .field-styled {
    border: none;
  }

  .field-styled + .field-styled {
    /* margin-top: 20px; */
  }

  .ant-select {
    background-color: white;
    border-radius: 60px;
    height: 60px;
    .ant-select-arrow {
      right: 20px;
    }
  }

  .field-col {
    max-width: 100%;
    flex: 100%;
  }

  .btn--fill {
    background-color: #4b4dc8;
    height: 60px !important;
  }

  .back_to_login {
    color: #4b4dc8;
  }

  .labels_wraper {
    a {
      color: #181818;
    }
  }

  .skip-step {
    background-color: transparent;
    p {
      color: #4b4dc8 !important;
      font-size: 18px;
      text-decoration: underline;
    }
  }

  img {
    max-width: 100%;
  }

  .auth_background {
    padding: 200px 80px 300px;
    background-image: url(${images.cloudDesktop});
    background-position: center center;
    background-size: cover;
    border-radius: 60px;
    background-repeat: no-repeat;
  }

  .content__submit-container {
    flex-direction: column !important;
  }

  .staylogged-box {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 30px 0px;

    .ant-btn-text {
      background-color: transparent;

      &:hover {
        color: #01ae92;
      }
    }
  }

  .skip-step {
    p {
      opacity: 1 !important;
      color: #4b4dc8 !important;
      font-size: 18px !important;
    }
  }

  .auth_text-box {
    width: 70%;
  }

  h1 {
    font-size: 70px;
    font-weight: bold;

    span {
      color: #01ae92;
    }
  }

  h3 {
    font-size: 34px;
    text-align: center;
    margin: 20px 0px 40px;
  }

  .ant-btn-ghost {
    width: 100% !important;

    &:hover {
      background-color: #01ae92;
    }
  }

  // Hover effects
  .ant-btn-text,
  .skip-step,
  .ant-btn-text p,
  .user__btn-use-different {
    &:hover {
      background: transparent;
      color: #01ae92 !important;
    }
  }

  .password__forgot {
    &:hover {
      text-decoration: underline;
    }
  }

  a {
    background: transparent;
    svg {
      &:hover {
        fill: #01ae92 !important;
      }
    }
  }

  .back_to_login {
    &:hover {
      background: transparent;
      color: #01ae92;
    }
  }

  /* footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 65px;

    span {
      padding: 10px 0px;
      color: #fff;

      border-top-left-radius: 1.25rem;
      border-top-right-radius: 1.25rem;
      opacity: 0.8;
      font-size: 0.9rem;
      font-family: Segoe UI Regular;
    }
  }

  .content-wrapper {
    background-image: url(${({ $backgroundimage }) => $backgroundimage});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    .login__top-text {
      margin-top: 4vh;
      padding: 0 21%;
      flex: 0;

      @media only screen and (max-width: 1000px) {
        h2 {
          font-size: 40px !important;
        }
      }

      @media only screen and (max-width: 760px) {
        padding: 0 4rem;
        h2 {
          font-size: 30px !important;
          line-height: 40px !important;
        }
      }
      p {
        font-size: 1.4rem;
        line-height: 1.6rem;
      }
      h2 {
        font-size: 4rem;
        line-height: 5.5rem;
      }
    }
  }

  @media only screen and (max-width: 1370px) {
    .content-wrapper {
      justify-content: space-between;
      .login__top-text {
        margin-top: 2vh;
      }
    }
  }

  @media only screen and (max-width: 1000px) {
    footer {
      justify-content: center;
    }
  } */

  @media (max-width: 992px) {
    .landing_navbar {
      margin-bottom: 20px;
    }

    .auth_text-box {
      width: 100%;
    }
    h1 {
      font-size: 34px;
    }

    h2 {
      font-size: 30px !important;
      line-height: 40px !important;
    }

    .auth_background {
      padding: 60px 80px 60px !important;
      border-radius: 0px !important;
      margin-left: -70px !important;
      margin-right: -70px !important;
    }
  }

  @media (max-width: 568px) {
    h3,
    h2 {
      font-size: 21px !important;
    }

    .staylogged-box {
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .labels_wraper {
        flex-direction: row !important;
      }
    }
  }
`;
