import { Row } from 'antd';
import styled from 'styled-components';

export const TabBarHeaderStyled = styled(Row)`
  display: flex;
  padding: 0 2.5rem;

  a {
    width: 50%;
    display: flex;
    justify-content: center;
    color: #b3b3b7;
    padding: 30px;
    font-weight: bold;
    border-bottom: 3px solid #e1e1f1;

    &:hover {
      color: #c9c9ee;
    }
  }

  .active-tab {
    color: #4b4dc8;
    border-bottom: 3px solid #4b4dc8;

    &:hover {
      color: #4b4dc8;
    }
  }

  /* height: 4.6rem;
  border-top-left-radius: 1.25rem;
  border-top-right-radius: 1.25rem;
  padding: 0 3rem;
  margin: 0 1rem;
  @media only screen and (max-width: 375px) {
    padding: 0 1rem;
    justify-content: center;
  }
  @media only screen and (max-width: 1370px) {
    height: 4rem;
  } */
`;
