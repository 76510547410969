import styled from 'styled-components';

export const WebsiteEditStyled = styled.form`
  display: flex;
  flex-direction: column;
  background-color: transparent;
  flex: 1;
  width: 100%;
  height: 100%;
  padding-bottom: 2rem;
  .input-container {
    margin: 0.5rem 0;
    .ant-input::placeholder {
      visibility: hidden;
    }
  }
  textarea {
    margin: 0.5rem 0;
    font-size: 18px;
  }
  .content__form-container {
    & > div:last-child {
      border-bottom: 0 !important;
    }
  }
  .content__submit-container {
    margin-top: auto;
    p {
      text-transform: uppercase;
      opacity: 0.5;
      transition: all 0.2s;
      &:hover {
        opacity: 1;
      }
    }
    .btn {
      height: 4rem;
      border-radius: 2rem;
      width: 20rem;
      margin-left: 1rem;
    }
  }
  @media only screen and (max-width: 1370px) {
    h2 {
      padding: 0 2.5rem;
      padding-bottom: 0.5rem;
    }

    .content__submit-container {
      .btn {
        height: 4rem;
      }
    }
  }
  @media only screen and (max-width: 680px) {
    .content__form-container {
      .ant-row {
        flex-direction: column;
        p {
          font-size: 1.5rem;
        }
        .ant-col {
          margin: 1rem 0rem;
          width: 100%;
          min-width: 100%;
          .ant-row {
            width: 100%;
            padding: 0;
          }
        }
      }
    }
    .content__submit-container {
      flex-direction: column-reverse;
    }
  }
  @media only screen and (max-width: 550px) {
    .line-loader-container {
      display: none;
    }
  }
`;
