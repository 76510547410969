import { Col, Divider, Row } from 'antd';
import { ColProps } from 'antd/lib/col';
import { Text } from 'components/styles';
import React from 'react';
import { TableHeadSortableItemStyled } from './TableHeadSortableItem.styled';
import { ReactComponent as ArrowsUpAndDown } from 'assets/image/svg/arrows-up-and-down.svg';
import { ReactComponent as ArrowsUpAndDownAsc } from 'assets/image/svg/arrows-up-and-down-asc.svg';
import { ReactComponent as ArrowsUpAndDownDesc } from 'assets/image/svg/arrows-up-and-down-desc.svg';

type TableHeadSortableItemPropsType = {
  text: string;
  index: number;
  arrayLength: number;
  onSelect: () => void;
  $active: boolean;
  $sortingOrderIsAsc: boolean;
} & ColProps;

const TableHeadSortableItem = ({
  text,
  onSelect,
  index,
  arrayLength,
  ...props
}: TableHeadSortableItemPropsType) => {
  return (
    <>
      <Col lg={9} md={9} sm={9} xs={9}>
        <TableHeadSortableItemStyled {...props}>
          <div
            className={'flex flex-row flex-center justify-start text-wrapper nowrap'}
            onClick={onSelect}
          >
            <Text fontSize={16}>{text}</Text>
            {!props.$active ? (
              <ArrowsUpAndDown />
            ) : props.$sortingOrderIsAsc ? (
              <ArrowsUpAndDownDesc />
            ) : (
              <ArrowsUpAndDownAsc />
            )}
          </div>
        </TableHeadSortableItemStyled>
      </Col>
      {index !== arrayLength - 1 && (
        <Col lg={1} md={1} sm={1} xs={1}>
          <Row align={'middle'} justify={'center'}>
            <Divider type={'vertical'} className={'divider'} />
          </Row>
        </Col>
      )}
    </>
  );
};

export default TableHeadSortableItem;
