import { UserAdd } from 'components/common/userMove';
import React from 'react';
import { AddUsersStyled } from './AddUsers.styled';

type AddUsersPropsType = {
  onClose: () => void;
};

const AddUsers = ({ onClose }: AddUsersPropsType) => {
  return (
    <AddUsersStyled>
      <UserAdd fromModal={true} onClose={onClose} />
    </AddUsersStyled>
  );
};

export default AddUsers;
