import React, { useState, createContext } from 'react';
import { message } from 'antd';
import * as Yup from 'yup';

import { validateAxiosErrorInForms } from 'utils/helpers/functions';
import { useProductContainerContext } from '../state/context';
import { productSetting } from './const';
import { ErrorOption } from 'react-hook-form';
interface TypesSettingPageContext {
  readonly loading: boolean;
  readonly onSubmit: (
    setError: (name: string, error: ErrorOption) => void,
  ) => (productSlideSettings: ProductSlideSettingsFormType) => Promise<void>;
  readonly productSlideSettings: ProductSlideSettingsFormType;

  readonly fieldData: SetupPropType[];
  // eslint-disable-next-line @typescript-eslint/ban-types
  readonly schema: Yup.ObjectSchema<
    // eslint-disable-next-line @typescript-eslint/ban-types
    Yup.Shape<object | undefined, { title: string; duration: unknown }>,
    // eslint-disable-next-line @typescript-eslint/ban-types
    object
  >;
}

interface Props {
  readonly children: React.ReactNode;
}

const SettingPageContext = createContext({} as TypesSettingPageContext);

const SettingPageProvider = ({ children }: Props) => {
  const { updateProductSlideSettings, productSlideSettings } = useProductContainerContext();
  const [loading, setLoading] = useState(false);

  const schema = Yup.object().shape({
    title: Yup.string().required('The field is required (min 3 char.)').min(3),
    duration: Yup.number(),
  });

  const onSubmit = (
    setError: (name: string, error: ErrorOption) => void,
  ): ((productSlideSettings: ProductSlideSettingsFormType) => Promise<void>) => {
    return async (productSlideSettings: ProductSlideSettingsFormType) => {
      try {
        setLoading(true);
        updateProductSlideSettings(productSlideSettings);
        message.success('Settings updated!');
      } catch (error) {
        validateAxiosErrorInForms(error, setError);
      } finally {
        setLoading(false);
      }
    };
  };

  return (
    <SettingPageContext.Provider
      value={{
        onSubmit,
        loading,
        productSlideSettings,
        fieldData: productSetting,
        schema,
      }}
    >
      {children}
    </SettingPageContext.Provider>
  );
};

export { SettingPageContext, SettingPageProvider };
