import React, { createContext, useReducer, Dispatch } from 'react';
import { appReducer, AppActions } from './appReducer';
import { SiderNavigationProvider } from './sider/navigation/siderNavigationContext';

export const initialState: InitialAppStateType = {
  user: {
    first_name: '',
    last_name: '',
    avatar: '',
    email: '',
    id: '',
    role: [],
    initial_company: '',
  },
  appReady: false,
  hasToken: false,
  userTree: [],
  termsVisible: false,
  isSiderOpen: false,
  mobileStats: {
    statsVisible: false,
    screenFeedback: false,
  },
};

const AppContext = createContext<{
  state: InitialAppStateType;
  dispatch: Dispatch<AppActions>;
}>({
  state: initialState,
  dispatch: () => null,
});

const mainReducer = (state: InitialAppStateType = initialState, action: AppActions) =>
  appReducer(state, action);

const AppProvider: React.FC = ({ children }: { children?: React.ReactNode }) => {
  const [state, dispatch] = useReducer(mainReducer, initialState);

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      <SiderNavigationProvider>{children}</SiderNavigationProvider>
    </AppContext.Provider>
  );
};

export { AppProvider, AppContext };
