import React, { useContext, useEffect } from 'react';
import { Progress, message } from 'antd';
import { WalkthroughOverlayStyled } from './WalkthroughOverlay.styled';
import { useHistory } from 'react-router-dom';
import { routesPaths } from 'utils/helpers';
import { SiderNavigationContext } from 'state/sider/navigation/siderNavigationContext';
import * as Api from 'api';
import { ajax } from 'services';
import { WalkthroughContext } from 'state/walkthrough/walkthroughContext';
import { AppContext } from 'state/appContext';

const WalkthroughOverlay = () => {
  const { siderState } = useContext(SiderNavigationContext);
  const {
    state: { user },
  } = useContext(AppContext);

  const {
    percent,
    setPercent,
    walkthroughText,
    setWalkthroughText,
    step,
    setStep,
    handleWalkthroughSkip,
    userStats,
    setUserStats,
    setIsLoading,
  } = useContext(WalkthroughContext);

  useEffect(() => {
    Api.getUserStats()
      .then((res) => {
        setUserStats(res.data.data);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [siderState]);

  const history = useHistory();

  const setUrl = async () => {
    const companySlug = siderState?.companies[0].slug;
    try {
      const websites = await ajax.get(Api.endpoints.siderAllWebsites(companySlug));

      const websiteSlug = websites.data.data[0].slug;

      const products = await ajax.get(Api.endpoints.siderAllProducts(companySlug, websiteSlug));

      const productSlug = products.data.data[0].slug;

      history.push(
        `${routesPaths.toProductSlidesListById(
          companySlug,
          websiteSlug,
          productSlug,
        )}#walkthrough-add-story`,
      );
    } catch (error) {
      message.error(`Error: ${error.response.data.message}`);
    }
  };

  const handleWalkthroughSteps = async () => {
    switch (step) {
      case 'company': {
        setPercent(0);
        setWalkthroughText('Add new company');
        break;
      }
      case 'website': {
        setPercent(25);
        setWalkthroughText('Add new site');
        break;
      }
      case 'product': {
        setPercent(50);
        setWalkthroughText('Add storyline to site');
        break;
      }
      case 'story': {
        await setUrl();
        setPercent(75);
        setWalkthroughText('Add new story');
        break;
      }
      case 'video': {
        setPercent(100);
        break;
      }
      default: {
        break;
      }
    }
  };

  useEffect(() => {
    if (step === 'story') {
      if (siderState?.companies[0]?.slug) {
        handleWalkthroughSteps();
      }
    } else {
      handleWalkthroughSteps();
    }
  }, [step, siderState]);

  useEffect(() => {
    if (!userStats?.companies) {
      setStep('company');
    } else if (!userStats?.websites) {
      setStep('website');
    } else if (!userStats?.products) {
      setStep('product');
    } else if (!userStats?.stories) {
      setStep('story');
    } else if (user?.walkthrough) {
      if (userStats?.stories) setStep('video');
      return;
    }
  }, [userStats]);

  return (
    <WalkthroughOverlayStyled>
      {step === 'video' ? (
        <video className="video-tutorial" controls>
          <source
            src="https://storyhub-web-app-qa.s3.eu-west-2.amazonaws.com/storyhub_video/widget_implementation.mp4"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
      ) : (
        <span className="walkthrough-text">{walkthroughText}</span>
      )}
      <div className="walkthrough-steps">
        <div className="progress-box">
          <Progress
            type="circle"
            strokeColor={'#4B4DC8'}
            trailColor={'#C8C8EE'}
            percent={percent}
          />
          <span>
            Progress to complete <b>company</b>{' '}
          </span>
        </div>
        <button className="skip-btn" onClick={() => handleWalkthroughSkip()}>
          End Walkthrough
        </button>
      </div>
    </WalkthroughOverlayStyled>
  );
};

export default WalkthroughOverlay;
