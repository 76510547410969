import React from 'react';
import { lazyLoadable } from 'utils';

export const CompanyDetailPageLazy = lazyLoadable(
  () => import('./CompanyDetailPage'),
  // TODO: needs skeleton component
  <>
    {/* <HomePageTableHead />
    <HomePageTableItem /> */}
  </>,
);
