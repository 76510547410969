import { Select } from 'antd';
import styled, { css } from 'styled-components';

type BaseSelectStyledPropsType = {
  $fullWidth: boolean;
  $error: boolean;
  $label?: string;
  $defaultValue?: string;
  $CountryInput?: boolean;
};

type ErrorProspType = {
  $error: boolean;
};

const ErrorStyled = styled.div<ErrorProspType>`
  ${({ $error }) =>
    $error &&
    css`
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 94%;
      border-radius: 10px;
      .input__input-error-container {
        display: flex;
        height: 100%;
        border-radius: 6px;
        padding-right: 0.5rem;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
      }

      .input-error__text {
        color: white;
        font-weight: bold;
        font-size: 0.75rem;
        font-family: Noir Pro Regular;
        white-space: nowrap;
        overflow: hidden;
        text-align: right;
        transition: all 0.5s ease-out;
        margin-right: 0.4rem;
      }

      svg {
        cursor: pointer;
        width: 1.3rem;
        min-width: 1.3rem;
        height: 1.3rem;
        min-height: 1.3rem;
        display: block;
        path {
          fill: #de225e;
        }
      }

      &:hover {
        background-color: rgba(255, 0, 0, 0.4);
        left: 0;
        width: 100%;
        .input-error__text {
          width: 100%;
        }
      }
    `}
`;
const BaseSelectStyled = styled(Select)<BaseSelectStyledPropsType>`
  background-color: white;
  border: 1px solid #8085da33;
  font-size: 1.3rem;
  min-width: 6rem;
  position: relative;
  border-radius: 10px;
  padding: 30px 0 20px 0;

  ${({ $CountryInput }) =>
    $CountryInput &&
    css`
      padding: 14px 0;
    `}

  ${({ $fullWidth }) =>
    $fullWidth &&
    css`
      width: 100%;
    `}
  
  
  &:not(.ant-select-disabled).ant-select-open {
    border: 1px solid #4b4dc8;
  }
  /* width: 100%; */
  .openLabel {
    label {
      top: 25%;
      font-size: 10px;
    }
  }
  .ant-select-selector {
    position: absolute;
    padding: ${({ $label }) => ($label ? '0 30px !important' : '')};
  }
  &.ant-select-lg {
    padding: 30px;
    .ant-select-selector {
      position: absolute;
      padding: ${({ $label }) => ($label ? '0 30px !important' : '0 40px !important')};
      .ant-select-selection-item {
        padding: ${({ $label }) => ($label ? '5px 0 !important' : '0')};
      }
    }
  }
  ${({ $fullWidth }) =>
    $fullWidth &&
    css`
      width: 100%;
    `}
  .ant-select-single.ant-select-open .ant-select-selection-item {
    color: rgba(128, 142, 155, 0.6);
  }
  .ant-select-selection-item {
    padding-right: 1.3rem;
    font-weight: normal;
    font-size: 18px;
  }
  .ant-select-arrow {
    right: 30px;
  }
  .ant-select-selection-placeholder {
    color: #00001e;
  }
  .ant-select-dropdown::-webkit-scrollbar {
    display: block;
  }
  .rc-virtual-list-scrollbar {
    display: block !important;
  }
  ${({ $error }) =>
    $error &&
    css`
      border: 1px solid #de225e;
      .ant-select-selection-placeholder {
        opacity: 0.1;
      }
    `}
`;

export { BaseSelectStyled, ErrorStyled };
