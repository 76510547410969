import styled from 'styled-components';

export const SkippedAddButtonStyled = styled.button`
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='%23333' stroke-width='1' stroke-dasharray='10' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 10px;

  width: 440px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;

  svg {
    margin-bottom: 10px;
  }

  p {
    font-size: 21px;
    font-family: Noir Pro Bold;
    color: #808e9b;
  }

  span {
    font-size: 16px;
    font-family: Noir Pro Light;
    color: #808e9b;
  }

  @media only screen and (min-width: 577px) and (max-width: 1000px) {
    margin: 150px 0px;
  }

  @media (max-width: 576px) {
    margin-top: 100px;
  }
`;
