import axios, { AxiosError } from 'axios';

const formDataAjax = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  timeout: 10000,
  headers: {
    // Accept: 'application/json',
    'Content-Type': 'multipart/form-data',
    'Access-Control-Allow-Origin': '*',
  },
});

// Add a request interceptor
formDataAjax.interceptors.request.use(
  function (config) {
    return config;
  },
  function (error) {
    return Promise.reject(error);
  },
);

// Add a response interceptor
formDataAjax.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error: AxiosError) {
    if (error.response?.status === 401) {
      window.history.replaceState({}, '', '/login');
    }
    return Promise.reject(error);
  },
);
export default formDataAjax;
