import { BaseSecondaryButton } from 'components/baseComponents';
import React from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { TabBarHeaderStyled } from './TabBarHeader.styled';

type RouteParamsType = {
  authType: 'login' | 'register';
};

type PropsType = {
  activeteReset?: boolean;
};

const TabBarHeader = ({ activeteReset }: PropsType) => {
  const { authType } = useParams<RouteParamsType>();

  return (
    <TabBarHeaderStyled align={'middle'}>
      {activeteReset ? (
        <BaseSecondaryButton title={'Register'} active={authType === 'register'} />
      ) : (
        <>
          <NavLink to={'/login'} activeClassName="active-tab">
            Login
            {/* <Button title={'Log in'} active={authType === 'login'} /> */}
          </NavLink>
          <NavLink
            to={'/register'}
            activeClassName="active-tab"
            isActive={(match, location) => {
              return ['/register', '/add-company', '/invite-users'].includes(location.pathname);
            }}
          >
            Registration
            {/* <BaseSecondaryButton
              title={'Register'}
              active={authType === 'register'}
            /> */}
          </NavLink>
        </>
      )}
    </TabBarHeaderStyled>
  );
};

export default TabBarHeader;
