import { Moment } from 'moment';

export default {
  siderTree: 'api/v1/ux/tree/companies',
  getCountries: 'api/v1/ux/tree/countries',
  getCompany: (slug: string) => `api/v1/companies/${slug}`,
  getCompaniesByRole: 'api/v1/companies/rolecompanies',
  getWebsite: (companySlug: string, websiteSlug: string) =>
    `api/v1/companies/${companySlug}/websites/${websiteSlug}`,
  getCompanyWithStat: (slug: string, startDate: string | Moment, endDate: string | Moment) =>
    `api/v1/companies/${slug}/stat?startDate=${startDate}&endDate=${endDate}`,
  getRoles: 'api/v1/company/roles',
  getCompanies: 'api/v1/companies',
  getWebsitesOfCompany: (companySlug: string) => `api/v1/companies/${companySlug}/websites`,
  getWebsites: `api/v1/websites`,
  getDeletedUsers: `api/v1/companies/deleted`,

  getAllUsers: `api/v1/users`,
  getLoggedUser: `api/v1/users/authuser`,

  getDeletedCompanies: `api/v1/companies/deleted`,
  getArchivedCompanies: `api/v1/archive`,
  getOwningCompanies: `api/v1/companies/owningcompanies`,

  getArchivedWebsites: (companySlug: string) => `api/v1/archive/${companySlug}/websites`,

  getWebsitesFullInfo: (companySlug: string) => `api/v1/companies/${companySlug}/websites`,

  getCompanyUser: (companySlug: string, id: number) =>
    `api/v1/companies/${companySlug}/users/${id}`,

  getProduct: (companySlug: string, websiteSlug: string, productSlug: string) =>
    `api/v1/companies/${companySlug}/websites/${websiteSlug}/products/${productSlug}`,

  getSlide: (websiteSlug: string, productSlug: string, slideSlug: string) =>
    `api/v1/websites/${websiteSlug}/products/${productSlug}/slides/${slideSlug}`,

  getSlides: (websiteSlug: string, productSlug: string) =>
    `api/v1/websites/${websiteSlug}/products/${productSlug}/slides`,

  getEmbedLink: (websiteSlug: string, productSlug: string) =>
    `api/v1/websites/${websiteSlug}/products/${productSlug}/settings/embedpluginlink`,
};
