import React from 'react';
import { ButtonProps } from 'antd';
import { BaseAddButtonStyled } from './BaseAddButton.styled';
import AddIcon from '../svg/AddIcon';

type BaseAddButtonProps = ButtonProps & {
  className?: string;
  backgroundColor?: string;
  hoverBackgroundColor?: string;
  color?: string;
  hoverColor?: string;
  borderColor?: string;
  hoverBorderColor?: string;
  onClick?: () => void;
};

export default function BaseAddButton({
  backgroundColor,
  hoverBackgroundColor,
  borderColor,
  hoverBorderColor,
  color,
  hoverColor,
  className,
  children,
  onClick,
}: BaseAddButtonProps) {
  return (
    <BaseAddButtonStyled
      className={className}
      backgroundColor={backgroundColor}
      hoverBackgroundColor={hoverBackgroundColor}
      borderColor={borderColor}
      hoverBorderColor={hoverBorderColor}
      color={color}
      hoverColor={hoverColor}
      onClick={onClick}
    >
      {children}
    </BaseAddButtonStyled>
  );
}
