import styled from 'styled-components';
import { svg } from 'assets/image';

export const LandingPageStyled = styled.div`
  //General styles

  width: 100%;
  background-color: white;

  .landing_navbar {
    position: sticky;
    top: 0;
    z-index: 200;
    background-color: white;
    width: 100%;
    height: 126px;
    margin: 0px;
    padding: 0px;
    display: flex;
    box-shadow: rgb(0 0 0 / 10%) 0px 1px 3px;
    align-content: center;
    align-items: center;
    justify-content: center;

    header {
      max-width: 1410px;
    }

    @media only screen and (max-width: 1600px) {
      header {
        max-width: 1270px;
      }
    }

    @media only screen and (max-width: 1366px) {
      height: 88px;

      header {
        max-width: 1169px;
        // max-width: 1030px;
      }
    }

    @media only screen and (max-width: 1224px) {
      header {
        padding: 0px 70px;
      }
    }

    @media only screen and (max-width: 1024px) {
      height: 80px;

      header {
        padding: 0px 20px;
        max-width: 854px;
      }
    }
    @media only screen and (max-width: 768px) {
      height: 80px;

      header {
        max-width: 642px;
      }
    }
    @media only screen and (max-width: 320px) {
      height: 56px;

      header {
        max-width: 280px;
      }
    }
  }

  header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    z-index: 100;
  }

  img {
    max-width: 100%;
  }

  button {
    margin-left: 0px !important;
    padding: 20px 15px !important;
    border-radius: 50px !important;

    span {
      text-transform: unset !important;
    }

    &:hover {
      background-color: #01ae92;
    }
  }

  h1,
  h2 {
    span {
      color: #01ae92;
      font-weight: bold;
      font-family: 'Noir Pro Bold';
    }
    margin-bottom: 20px;
    @media only screen and (max-width: 992px) {
      margin-bottom: 14px;
    }
  }

  .first-row {
    max-width: 1410px;
    padding: 40px 0px;
    margin: 0px auto 180px !important;

    @media (max-width: 1600px) {
      padding: 20px 70px;
    }

    @media (max-width: 768px) {
      padding: 0;
      padding-bottom: 20px;
      margin-bottom: ${100 / 16}rem;
    }
  }

  .gutter-row {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &.left-column {
      @media only screen and (min-width: 1200px) {
        line-height: 66px;
        max-width: 570px;
      }
      @media only screen and (min-width: 992px) and (max-width: 1200px) {
        max-width: 430px;
        line-height: 25px;
      }
      @media only screen and (width: 992px) {
        max-width: 350px;
      }
    }
  }

  .LandingPage__text-box {
    display: flex;
    justify-content: left;
    flex-direction: column;
    padding: 0px 80px;
  }

  .bordered-text {
    background-color: white;
    border-left: 3px solid;
    display: flex;
    align-items: flex-start;
    border-radius: 4px;
    padding: 15px 20px;
    height: 100px;
    font-size: 21px;
    display: flex;
    align-items: center;
    @media only screen and (max-width: 576px) {
      p {
        line-height: 1.5rem;
      }
    }
    @media only screen and (min-width: 992px) and (max-width: 1599px) {
      p {
        line-height: 19px;
      }
    }
  }

  .ant-space-align-center {
    align-items: stretch !important;
  }

  footer {
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
  }

  //SVG & PNG styles

  .story1 {
    max-height: 824px;
  }

  .story2 {
    max-height: 920px;
  }

  // .story1,
  // .story2 {
  //   img {
  //     width: 100%;
  //     @media only screen and (min-width: 992px) and (max-width: 1300px) {
  //       max-height: 500px !important;
  //     }
  //   }
  // }

  .story1 .see-it-for-yourself {
    position: absolute;
    left: 0;
    top: 35%;
    .scroll-text {
      position: absolute;
      left: -50px;
      font-family: 'The Girl Next Door';
      color: #4b4dc8;
      font-size: 21px;
      transform: rotate(-24deg);
    }
    @media only screen and (max-width: 991px) {
      display: none;
    }
    @media only screen and (min-width: 993px) and (max-width: 1400px) {
      transform: scale(0.8);
      left: -5%;
    }
  }

  .scroll-pointer {
    position: inherit;
    line-height: 26px;
  }

  .slogan1 {
    max-width: 690px;
    p {
      position: relative;
    }
    .useful {
      font-family: 'The Girl Next Door' !important;
      position: absolute;
      width: fit-content;
      bottom: -50%;
      left: 40%;
      color: #4b4dc8;
      font-size: ${21 / 16}rem;
      transform: rotate(-15deg);
      @media only screen and (max-width: 450px) {
        bottom: -25%;
        left: 30%;
      }
      @media only screen and (min-width: 1600px) {
        bottom: -58%;
      }
    }
    @media only screen and (min-width: 576px) and (max-width: 992px) {
      width: 80% !important;
    }
  }

  .slogan2 {
    height: 100%;
    display: flex;
    h1 {
      margin-bottom: 0px;
      position: relative;
    }
    .reach-goals {
      font-family: 'The Girl Next Door';
      font-size: ${21 / 16}rem;
      width: max-content;
      position: absolute;
      line-height: 1;
      transform: rotate(-10deg);
      margin-top: -50px;
      font-weight: normal;
      margin-left: -54px;
      @media only screen and (max-width: 768px) {
        transform: rotate(-5deg);
        margin-left: 0;
        font-size: ${20 / 16}rem;
      }
    }
    @media only screen and (min-width: 576px) and (max-width: 992px) {
      padding: 0 20px !important;
    }
    @media only screen and (min-width: 1200px) {
      h1 {
        line-height: 84px !important;
        margin-bottom: 34px;
      }
      p {
        margin-bottom: 34px;
      }
    }
    @media only screen and (min-width: 576px) {
      h1 {
        margin-bottom: 20px;
      }
      p {
        margin-bottom: 20px;
      }
    }
    @media only screen and (min-width: 1200px) and (max-width: 1599px) {
      h1 {
        line-height: 66px !important;
      }
    }
  }

  .slogan3 {
    position: relative;
    .love-it {
      position: absolute;
      font-size: ${30 / 16}rem;
      font-family: 'The Girl Next Door';
      color: #4b4dc8;
      transform: rotate(-15deg);
      bottom: -3rem;
      margin-left: -4rem;
      font-weight: normal;
      @media only screen and (max-width: 992px) {
        bottom: -2.5rem;
        transform: rotate(-5deg);
        font-size: 14px;
      }
    }
    @media only screen and (min-width: 577px) and (max-width: 992px) {
      margin-bottom: 30px !important;
    }
  }

  .slogan4 {
    margin-bottom: 50px;
    position: relative;
    width: fit-content;
    .wow {
      position: absolute;
      font-size: ${30 / 16}rem;
      font-family: 'The Girl Next Door';
      color: #4b4dc8;
      left: 45%;
      line-height: normal;
      margin-top: 0.5rem;
      font-weight: normal;
      @media only screen and (max-width: 576px) {
        font-size: 14px;
      }
    }
  }

  .green-tick {
    &::after {
      content: url(${svg.greenTick});
      transform: scale(0.8);
      width: 2px;
      display: block;
      position: absolute;
      right: 0;
      left: 0;
      margin-left: auto;
      margin-right: auto;
      top: -50px;
    }
  }

  .about-storyhub {
    .purple-plus-div {
      position: relative;
      img {
        z-index: 2;
      }
      .purple-plus {
        position: absolute;
        top: 10%;
        z-index: 1;
        left: -20px;
      }
      .purple-rectangle {
        position: absolute;
        bottom: -5%;
        z-index: 1;
        right: 100px;
      }
      @media only screen and (max-width: 768px) {
        .purple-rectangle {
          height: 78px;
          right: 20%;
        }
        .purple-plus {
          height: 50px;
        }
      }
      @media only screen and (min-width: 993px) and (max-width: 1199px) {
        .purple-rectangle {
          bottom: 0;
        }
      }
    }
    @media only screen and (min-width: 1200px) {
      margin-bottom: 144px;
    }
    @media only screen and (min-width: 1600px) {
      margin-bottom: 175px;
    }
    @media only screen and (min-width: 576px) and (max-width: 991px) {
      img {
        margin-top: 55px;
      }
    }
  }

  .orange-plus {
    position: relative;
    padding-bottom: 80px;

    @media only screen and (min-width: 576px) and (max-width: 992px) {
      margin-bottom: 100px;
    }

    @media only screen and (min-width: 992px) {
      padding: 55px 65px 90px 65px;
    }

    @media only screen and (min-width: 1200px) {
      padding: 90px 135px 135px 135px;
      margin-bottom: 200px;
    }

    h2 {
      text-align: center;
      margin-bottom: 40px;
    }

    &::after {
      content: url(${svg.plus});
      transform: scale(0.8);
      width: 2px;
      display: block;
      position: absolute;
      right: 40px;
      left: 0;
      margin-left: auto;
      margin-right: auto;
      bottom: -50px;
    }
  }

  //Section specific styles

  .purpose {
    position: relative;
    .green-rectangle {
      position: absolute;
      top: -50px;
      left: 250px;
      @media only screen and (max-width: 992px) {
        height: 90px;
        top: -45px;
        left: 10%;
      }
      @media only screen and (max-width: 576px) {
        height: 60px;
        top: -30px;
      }
    }
    .ant-space {
      width: 100%;
      border-radius: 60px;
      overflow: hidden;
      @media only screen and (max-width: 992px) {
        border-radius: 20px;
      }
    }

    .ant-space-item {
      width: 20%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    img {
      width: 48px;
      height: 48px;
    }

    h2 {
      text-align: center;
    }
    p {
      width: 50%;
      text-align: center;
      margin-bottom: 50px;
    }
    @media only screen and (min-width: 1600px) {
      margin-bottom: 100px;
    }
    @media only screen and (min-width: 1200px) and (max-width: 1599px) {
      padding-top: 55px;
      padding-bottom: 30px;
      margin-bottom: 134px;
    }
  }

  .features {
    flex-direction: column;

    img {
      margin-bottom: 15px;
    }

    .gutter-row {
      margin: 50px 0px;
      justify-content: flex-start;
    }

    a {
      display: flex;
      justify-content: center;
      @media only screen and (min-width: 992px) {
        margin-top: 30px;
      }
    }

    @media only screen and (min-width: 576px) and (max-width: 992px) {
      padding: 0 20px !important;
    }

    @media only screen and (max-width: 576px) {
      padding-top: 100px;
      margin-bottom: 40px;
      h1 {
        font-size: 21px !important;
        margin-bottom: 0;
      }
      .slogan3 {
        &:after {
          transform: scale(0.6);
          left: -80px;
          margin-bottom: 10px;
        }
      }
      .gutter-row {
        margin: 30px 0;
      }
    }

    @media only screen and (min-width: 577px) and (max-width: 992px) {
      .gutter-row {
        margin-top: 0;
      }
    }
  }

  .tutorial {
    padding-bottom: 0px;
    margin-bottom: 300px;
    position: relative;

    .green-rectangle {
      z-index: 4;
      position: absolute;
      right: 0px;
      top: -56px;
    }

    .share-your-story {
      margin-top: 50px;
      margin-bottom: -20px;
      display: flex;
      width: 100%;
      justify-content: center;

      @media only screen and (max-width: 1024px) {
        .add-box {
          img {
            max-width: 100px;
          }
        }
        .add-company {
          img {
            max-height: 80px;
          }
        }
      }

      @media only screen and (max-width: 768px) {
        .add-box {
          img {
            max-width: 80px;
          }
        }
      }

      @media only screen and (max-width: 576px) {
        flex-direction: column;
        align-items: center;
        margin-top: 70px;

        .add-box-between {
          svg {
            transform: rotate(90deg) scale(0.8);
          }
        }

        .add-box {
          min-height: 228px;
        }
      }

      .add-box {
        position: relative;
        width: 100%;
        max-width: 360px;
        min-width: auto;
        font-size: 30px;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;

        span {
          display: flex;
          width: 140px;
          height: 150px;
          align-items: center;
          justify-content: space-between;
          margin: 50px 0px;
          text-align: center;
          flex-direction: column;
        }

        @media only screen and (max-width: 1366px) {
          span {
            transform: scale(0.9);
          }
        }

        @media only screen and (max-width: 1024px) {
          span {
            transform: scale(0.6);
            margin: 30px 0px;
          }
        }

        @media only screen and (max-width: 768px) {
          span {
            transform: scale(0.4);
            margin: 0px 0px;
          }
        }
        @media only screen and (max-width: 768px) {
          span {
            transform: scale(0.6);
            margin: 0px 0px;
          }
        }
      }

      .add-box-between.second {
        z-index: 6;
      }

      .add-box-between {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0px -14px 0px -5px;
        z-index: 10;

        svg {
          width: 100%;
          max-width: 140px;
        }

        @media only screen and (max-width: 1366px) {
          svg {
            min-width: 114px;
          }
        }

        @media only screen and (max-width: 1024px) {
          svg {
            min-width: 94px;
          }
        }

        @media only screen and (max-width: 768px) {
          svg {
            min-width: 68px;
          }
        }
      }

      .add-company {
        background-color: #edfff0;
        border: 1px solid #639b6b30;
        color: #639b6b;
        z-index: 12;
        img {
          position: absolute;
          top: -55px;
          left: 20px;
        }
        @media only screen and (max-width: 576px) {
          img {
            max-height: 90px;
          }
        }
        span {
          svg path:nth-child(2) {
            fill: #639b6b;
          }
          svg g rect {
            fill: #639b6b;
          }
        }
      }

      .add-site {
        background-color: #f5f2ff;
        border: 1px solid #5f578c30;
        color: #5f578c;
        z-index: 8;
        img {
          position: absolute;
          bottom: -70px;
          left: 20px;
        }
        @media only screen and (max-width: 576px) {
          img {
            bottom: unset;
            left: unset;
            top: -55px;
            right: 20px;
          }
        }
        span {
          svg path:nth-child(2) {
            fill: #5f578c;
          }
          svg g rect {
            fill: #5f578c;
          }
        }
      }

      .add-storyline {
        background-color: #fff3ec;
        border: 1px solid #95746130;
        color: #957461;
        z-index: 4;
        img {
          position: absolute;
          top: -55px;
          right: 0px;
        }
        @media only screen and (max-width: 576px) {
          img {
            right: unset;
            left: 20px;
            max-width: 100px;
          }
        }
        span {
          svg path:nth-child(2) {
            fill: #957461;
          }
          svg g rect {
            fill: #957461;
          }
        }
      }
    }

    @media only screen and (min-width: 577px) and (max-width: 992px) {
      margin-bottom: 250px;
      p {
        width: 60%;
        text-align: center;
      }
    }

    @media only screen and (min-width: 1200px) and (max-width: 1599px) {
      p {
        width: 45%;
        text-align: center;
      }
    }

    @media only screen and (min-width: 1600px) {
      p {
        width: 38%;
        text-align: center;
      }
    }
  }

  .options {
    align-items: center;
    flex-direction: column;
    .options-title {
      font-family: 'Noir Pro Bold';
      color: #181818;
    }
    h1 {
      text-align: center;
    }

    span {
      font-weight: bold;
      display: block;
    }

    @media only screen and (max-width: 576px) {
      margin-bottom: 50px;
      p {
        font-family: 'Noir Pro Light';
        color: #808e9b;
      }
      .options-title {
        color: #181818;
        font-size: 16px !important;
        margin-bottom: 13px;
        margin-top: 55px;
        font-family: 'Noir Pro Bold';
        &:first-of-type {
          margin-top: 10px;
        }
      }
    }

    @media only screen and (min-width: 577px) and (max-width: 991px) {
      .gutter-row {
        justify-content: flex-start;
        margin-top: 20px;
        p {
          display: flex;
          flex-direction: column;
          gap: 80px;
          .options-title {
            margin-bottom: 13px;
          }
          span p {
            width: 70%;
            margin: auto;
          }
        }
      }
    }

    @media only screen and (min-width: 1200px) {
      margin-bottom: 200px;
      h1 {
        margin-bottom: 80px;
      }
      h1:after {
        margin-bottom: -20px;
      }
      .gutter-row p {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        height: 100%;
      }
    }
  }

  .blocks {
    .left-block {
      position: relative;
      .green-rectangle {
        position: absolute;
        top: -10%;
        left: 12%;
        transform: rotate(220deg);
        @media only screen and (max-width: 992px) {
          height: 90px;
        }
        @media only screen and (max-width: 576px) {
          height: 60px;
        }
      }
    }
    .right-block {
      position: relative;
      img {
        z-index: 2;
      }
      .text-box {
        z-index: 3;
      }
      .purple-hexagon {
        position: absolute;
        top: 35%;
        right: -2%;
        z-index: 1;
      }
    }
    .gutter-row {
      position: relative;
    }

    .text-box {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 68%;
      position: absolute;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
      text-align: center;

      a {
        margin-top: 40px;
      }
    }

    @media only screen and (max-width: 340px) {
      .text-box a {
        margin-top: 0px;
      }
    }

    @media only screen and (max-width: 576px) {
      .img {
        padding: 90px 0;
      }
    }

    @media only screen and (min-width: 1200px) {
      .gutter-row {
        justify-content: flex-start;
      }
      .text-box {
        width: 68%;
        margin-top: 140px;
        p {
          font-family: 'Noir Pro Light';
        }
      }
    }

    @media only screen and (min-width: 1600px) {
      .gutter-row {
        justify-content: flex-start;
      }
      .text-box {
        margin-top: 220px;
      }
    }
  }

  .reverse-on-mobile {
    @media only screen and (min-width: 1600px) {
      margin-top: 150px;
    }
  }

  //Responsive styles

  @media (min-width: 1366px) {
    .try-for-free {
      width: 210px;
      height: 60px;
      span {
        font-size: 21px;
      }
    }
  }

  @media (max-width: 1366px) {
    .try-for-free {
      span {
        font-size: 18px;
      }
    }
  }
  @media (max-width: 576px) {
    .try-for-free {
      width: 100%;
      margin-top: 20px;
      span {
        font-size: 14px;
      }
    }
  }

  @media (max-width: 1600px) {
    .LandingPage__text-box {
      padding: 0px 40px;
    }
  }

  @media (max-width: 1200px) {
    h1 {
      font-size: 55px;
    }

    p {
      font-size: 14px;
    }
  }

  @media (max-width: 992px) {
    h1 {
      font-size: 34px;
      line-height: 40px;
    }
    .reverse-on-mobile {
      flex-flow: row wrap-reverse;
      margin-bottom: -100px;
      @media only screen and (min-width: 576px) and (max-width: 992px) {
        padding: 140px 0;
      }
    }
    .story1,
    .story2 {
      margin-bottom: 20px;

      &::before {
        display: none;
      }
    }

    .purpose {
      padding-top: 50px;
      .ant-space {
        flex-direction: column;
      }

      .ant-space-item {
        display: block;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .options {
      img {
        margin-bottom: 30px;
      }

      p {
        text-align: center;
      }
    }

    .scroll-pointer {
      position: inherit !important;
    }

    .slogan1 {
      line-height: 26px;
    }

    .slogan2 {
      @media only screen and (max-width: 992px) {
        h1 {
          font-size: 34px;
          line-height: 40px;
        }
      }
    }

    .slogan3 {
      font-size: 34px !important;
    }
  }

  @media (max-width: 576px) {
    .story2 {
      margin-top: 55px;
    }

    .LandingPage__text-box {
      margin-top: 50px;
      padding: 0px;
    }

    h1 {
      font-size: 34px;
      line-height: 50px;
    }

    h2 {
      font-size: 21px;
      line-height: 50px;
    }

    .purpose {
      h2,
      p {
        width: 100%;
        text-align: center;
      }
    }
  }

  @media (max-width: 576px) {
    h1 {
      font-size: 21px;
      line-height: 30px;
    }

    h2 {
      font-size: 18px;
      line-height: 30px;
    }

    .tutorial {
      margin-bottom: 100px;
      video {
        margin-bottom: -60px;
        width: 100%;
      }
      h1 {
        width: 70%;
        text-align: center;
      }
      p {
        text-align: center;
        line-height: 26px;
      }
    }

    .green-tick {
      &::after {
        display: none;
      }
    }
  }

  @media (max-width: 320px) {
    h2 {
      font-size: 16px;
      line-height: 20px;
    }
  }
`;
