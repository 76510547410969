import styled from 'styled-components';

export const WalkthroughOverlayStyled = styled.div`
  z-index: 100;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(24, 24, 24, 0.7);

  .walkthrough-text {
    position: absolute;
    top: 50%;
    left: 50%;
    color: white;
    transform: translateX(-50%);
  }

  .video-tutorial {
    height: auto;
    width: 800px;
    border-radius: 10px;
    overflow: hidden;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }

  .walkthrough-steps {
    display: flex;
    justify-content: center;
    align-items: stretch;
    gap: 20px;
    position: absolute;
    bottom: 5%;
    left: 1%;

    .progress-box {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: white;
      border-radius: 10px;
      padding: 10px;

      .ant-progress-inner {
        height: 60px !important;
        width: 60px !important;

        .ant-progress-text {
          font-family: Noir Pro Bold;
          font-size: 14px;

          svg {
            margin-left: -16px;
          }
        }
      }
      span {
        padding: 0px 20px;
      }
    }

    .skip-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      border: 1px solid white;
      border-radius: 10px;
      background: transparent;
      padding: 0px 50px;
    }
  }

  @media only screen and (max-width: 1000px) {
    .video-tutorial {
      width: 600px;
    }
  }

  @media only screen and (max-width: 768px) {
    .walkthrough-text {
      display: none;
    }

    .video-tutorial {
      top: 2%;
      transform: translateX(-50%);
      width: 95%;
      height: auto;
    }
  }

  @media only screen and (max-width: 576px) {
    .walkthrough-steps {
      flex-direction: column;
      width: 100%;
      padding: 0px 15px 0px 5px;

      .progress-box {
        .ant-progress-inner {
          height: 50px !important;
          width: 50px !important;

          .ant-progress-text {
            font-family: Noir Pro Bold;
            font-size: 12px;
            transform: translate(-60%, -50%);
          }
        }
        span {
          padding: 0px 20px;
        }
      }

      .skip-btn {
        height: 70px;
      }
    }
  }
`;
