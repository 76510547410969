import { Layout } from 'antd';
import styled from 'styled-components';
import SearchIcon from 'assets/image/svg/feather-search.svg';

const { Sider } = Layout;

export const SiderStyled = styled(Sider)`
  padding-bottom: 0rem;
  height: 100vh;
  padding: 30px 0 30px 30px;
  background: inherit;
  .companiesFilter {
    background: #f6f7fc url(${SearchIcon}) no-repeat 20px center;
    /* width: 100%; */
    height: 40px;
    padding-left: ${55 / 16}rem;
    font-size: ${18 / 16}rem;
    border-radius: 10px;
    margin: 0 20px;
    margin-bottom: 30px;
    &::placeholder {
      color: #7d8ca2;
      opacity: 0.3;
    }
  }
  .sider_tree_header {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px 0;
    img {
      cursor: pointer;
      max-height: 50px;
    }
  }
  .sider_footer {
    padding: 0 20px;
    .footer_text {
      font-size: 12px;
      text-align: center;
      color: #181818;
      margin: 30px 0;
    }
  }
  .mobile_item {
    display: none;
  }

  .ant-skeleton {
    padding: 13px 13px 0 0;
  }
  .ant-layout-sider-children {
    display: flex;
    flex-direction: column;
    position: relative;
    background: white;
    box-shadow: 0px 10px 30px #1818180d;
    border-radius: 10px;
  }
  /* height: 100%; */
  .ant-divider.ant-divider-horizontal {
    border-color: ${({ theme: { colors } }) => colors.secondaryGray};
    margin-bottom: 0;
  }
  .sider__tree-container {
    flex: 1;
    display: flex;
    overflow-y: overlay;
    overflow-x: hidden;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;
    & .organization_icon {
      width: 1.5rem;
      height: 1.5rem;
      top: 2rem;
      right: 1.3rem;
      position: absolute;
    }
    &::-webkit-scrollbar {
      width: 0.3rem;
      height: 6px;
      opacity: 0;
      cursor: pointer;
    }
    &::-webkit-scrollbar-track {
      background: white;
    }
    &::-webkit-scrollbar-thumb {
      background: #ddd;
      border-radius: 0.4375rem;
    }
  }
  .mobile_profile {
    display: none;
  }

  @media only screen and (max-width: 1900px) {
    padding-left: 20px;
  }

  @media only screen and (min-width: 1501px) and (max-width: 1900px) {
    .companiesFilter {
      /* background: #F6F7FC url(${SearchIcon}) no-repeat 10px center; */
      padding-left: 50px;
      font-size: 16px;
    }
  }

  @media only screen and (min-width: 1440px) and (max-width: 1500px) {
    .companiesFilter {
      background: #f6f7fc url(${SearchIcon}) no-repeat 10px center;
      padding-left: 35px;
      font-size: 16px;
    }
  }
`;
