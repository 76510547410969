import React from 'react';
import { BaseDateRangeSelectorStyled } from './BaseDateRangeSelector.styled';
import moment from 'moment-timezone';
import { TimeRangeProps } from 'api/statisticPage/website/types';

const BaseDateRangeSelector: React.FC<TimeRangeProps> = ({
  setRange,
  range,
}): React.ReactElement<TimeRangeProps> => {
  return (
    <BaseDateRangeSelectorStyled
      bordered={false}
      ranges={{
        Today: [moment(), moment()],
        'last 7 days': [moment().subtract(7, 'days'), moment()],
        'last 30 days': [moment().subtract(1, 'month'), moment()],
        yesterday: [moment().subtract(1, 'day'), moment()],
        YTD: [moment().subtract(2, 'month'), moment()],
      }}
      onChange={(e) => {
        const start = moment(e![0]).format('YYYY-MM-DD');
        const end = moment(e![1]).format('YYYY-MM-DD');
        setRange({
          range: {
            start,
            end,
          },
        });
      }}
      defaultValue={[moment(range.start), moment(range.end)]}
      size={'middle'}
      suffixIcon={<></>}
      separator={null}
      clearIcon={null}
      format={'DD / MM / YYYY'}
    />
  );
};

export default BaseDateRangeSelector;
