import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  & > a {
    margin-top: 0.5rem;
    text-decoration: underline;
  }
  & > div {
    margin: 0 !important;
  }
`;

export default Container;
