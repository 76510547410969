import React, { useContext } from 'react';
import { Col } from 'antd';

import { AppContext } from 'state/appContext';
import { AddNewEntityStyled } from './AddNewEntity.styled';
import { Title } from 'components/styles';
import Text from 'atoms/Text';
import Flex from 'atoms/Flex';
import Company from 'assets/image/svg/companyblue.svg';
import Website from 'assets/image/svg/websiteblue.svg';
import Products from 'assets/image/svg/productsblue.svg';
import { ModalTypeEnum } from '../../modal/ModalCustom';

type AddNewEntityPropsType = {
  fromModal?: boolean;
  onClose?: () => void;
  onChoose: (type: ModalTypeEnum) => void;
};

const AddNewEntity = ({ onChoose }: AddNewEntityPropsType): React.ReactElement => {
  const { state } = useContext(AppContext);

  const handleChoose = (type: ModalTypeEnum) => {
    // onClose;
    onChoose(type);
  };

  const renderAllEntites = () => (
    <>
      <Flex
        onClick={() => handleChoose(ModalTypeEnum.addCompany)}
        direction="column"
        align="center"
        style={{ cursor: 'pointer' }}
      >
        <img src={Company} height="120px" width={100} />
        <Text fontSize={24} color="#000" fontWeight="bold" mt="1.5rem">
          Company
        </Text>
      </Flex>
      <Flex
        onClick={() => handleChoose(ModalTypeEnum.addWebsite)}
        direction="column"
        align="center"
        style={{ cursor: 'pointer' }}
      >
        <img src={Website} height="120px" width={100} />
        <Text fontSize={24} color="#000" fontWeight="bold" mt="1.5rem">
          Website
        </Text>
      </Flex>
      <Flex
        onClick={() => handleChoose(ModalTypeEnum.addProduct)}
        direction="column"
        align="center"
        style={{ cursor: 'pointer' }}
      >
        <img src={Products} height="120px" width={100} />
        <Text fontSize={24} color="#000" fontWeight="bold" mt="1.5rem">
          Product
        </Text>
      </Flex>
    </>
  );

  const renderCompanyWebsiteEntities = () => (
    <>
      <Flex
        onClick={() => handleChoose(ModalTypeEnum.addWebsite)}
        direction="column"
        align="center"
        style={{ cursor: 'pointer' }}
      >
        <img src={Website} height="120px" width={100} />
        <Text fontSize={24} color="#000" fontWeight="bold" mt="1.5rem">
          Website
        </Text>
      </Flex>
      <Flex
        onClick={() => handleChoose(ModalTypeEnum.addProduct)}
        direction="column"
        align="center"
        style={{ cursor: 'pointer' }}
      >
        <img src={Products} height="120px" width={100} />
        <Text fontSize={24} color="#000" fontWeight="bold" mt="1.5rem">
          Products
        </Text>
      </Flex>
    </>
  );
  const renderWebsiteEntity = () => (
    <Flex
      onClick={() => handleChoose(ModalTypeEnum.addProduct)}
      direction="column"
      align="center"
      style={{ cursor: 'pointer' }}
    >
      <img src={Products} height="120px" width={100} />
      <Text fontSize={24} color="#000" fontWeight="bold" mt="1.5rem">
        Products
      </Text>
    </Flex>
  );

  const renderEntities = () => {
    switch (state.user?.role != null && state.user.role[0]) {
      case 'Super Admin':
        return renderAllEntites();
      case 'Organization Admin':
        return renderAllEntites();
      case 'Company Admin':
        return renderCompanyWebsiteEntities();
      case 'Website Admin':
        return renderWebsiteEntity();
    }
  };
  return (
    <AddNewEntityStyled>
      <Title.H2 fontSize={36} color={'#5B59C2'}>
        Add New Business Element
      </Title.H2>
      <Col span={24} className={'content__form-container'} />
      <Flex justify="evenly">{renderEntities()}</Flex>
    </AddNewEntityStyled>
  );
};

export default AddNewEntity;
