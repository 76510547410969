import styled from 'styled-components';

export const RemoveStyled = styled.form`
  display: flex;
  flex-direction: column;
  height: 100%;
  .radio-group-container {
    flex-direction: column;
    label {
      margin-bottom: 1.5rem;
      .ant-radio {
        margin-right: 10px;
      }
    }
  }
  .base-selector-row-container {
    .ant-select {
      width: 100%;
    }
  }
  .content__submit-container {
    margin-top: auto;
    padding: 30px 0;
    p {
      text-transform: uppercase;
      opacity: 0.5;
      transition: all 0.2s;
      &:hover {
        opacity: 1;
      }
    }
    .btn {
      height: 4rem;
      border-radius: 2rem;
      width: 16rem;
      margin-left: 1rem;
    }
  }
  @media only screen and (max-width: 1370px) {
    h2 {
      padding: 0 2.5rem;
      padding-bottom: 0.5rem;
    }
    .content__submit-container {
      .btn {
        height: 4rem;
      }
    }
  }
  @media only screen and (max-width: 680px) {
    .content__submit-container {
      padding: 0;
      .skip-step {
        display: none;
      }
      button {
        width: 100% !important;
        margin: 2rem auto 1rem auto !important;
      }
    }
  }
`;
