import styled from 'styled-components';

export const StarterHeaderStyled = styled.div`
  display: flex;
  padding-bottom: 30px;
  justify-content: space-between;
  border-bottom: 1px solid #eaecf2;
  h1 {
    font-family: 'Noir Pro Bold';
    font-size: 34px;
    margin-bottom: 15px;
  }
  .stats-container {
    display: flex;
    flex-direction: row;
    gap: 30px;

    .stats-box {
      display: flex;
      flex-direction: column;
      max-width: fit-content;
    }

    p {
      font-family: 'Noir Pro Light';
      color: #808e9b;
      font-size: 16px;
      margin-bottom: 10px;
    }

    span {
      font-family: 'Noir Pro Regular';
      font-size: 16px;
    }
  }

  @media (max-width: 768px) {
    h1 {
      font-size: 21px;
    }
  }

  @media (max-width: 576px) {
    padding-bottom: 0px;
    padding-top: 30px;
    flex-direction: column;
    border-top: 1px solid #eaecf2;
    h1 {
      font-size: 18px;
    }
    .stats-container {
      padding-bottom: 20px;
      flex-direction: column;
      gap: 10px;
      border-bottom: 1px solid #eaecf2;

      .stats-box {
        display: flex;
        flex-direction: row;
      }

      span {
        margin-left: 10px;
      }

      p,
      span {
        font-size: 14px;
      }
    }
  }
`;
