import React from 'react';
import { BaseSubmitButtonStyled } from './BaseSubmitButton.styled';
import { ReactComponent as ArrowRight } from 'assets/image/svg/arrow-long-right.svg';
import { SyncOutlined } from '@ant-design/icons';

type BaseSubmitButtonProps = {
  title?: string;
  className?: string;
  backgroundColor?: string;
  color?: string;
  active?: boolean;
  withArrow?: boolean;
  withRefresh?: boolean;
  style?: React.CSSProperties;
  type?: 'submit' | 'reset' | 'button';
  onClick?: () => void;
  disabled?: boolean;
};

const BaseSubmitButton = ({
  title,
  className,
  withArrow,
  withRefresh,
  backgroundColor,
  active,
  style,
  onClick,
  type,
  disabled,
  ...props
}: BaseSubmitButtonProps): React.ReactElement => {
  return (
    <BaseSubmitButtonStyled
      className={`${className ?? ''}`}
      {...props}
      $backgroundColor={backgroundColor}
      $withArrow={withArrow}
      $active={active}
      style={style}
      onClick={onClick}
      type={type}
      disabled={disabled}
    >
      {title}{' '}
      {withArrow && <ArrowRight style={{ position: 'absolute', right: '30px', top: '41%' }} />}
      {withRefresh && <SyncOutlined style={{ position: 'absolute', right: '30px', top: '37%' }} />}
    </BaseSubmitButtonStyled>
  );
};

export default BaseSubmitButton;
