import { Col } from 'antd';
import { Text, Title } from 'components/styles';
import React from 'react';
import { TopTitleAndSelectorStyled } from './TopTitleAndSelector.styled';

type TopTitleAndSelectorPropsType = {
  usersCount: number;
};
const TopTitleAndSelector = ({ usersCount }: TopTitleAndSelectorPropsType) => {
  return (
    <TopTitleAndSelectorStyled className="toptitle-styled">
      <Col span={1}></Col>
      <Title.H2 fontSize={36} color={'#5B59C2'}>
        Invite users
      </Title.H2>
      {usersCount >= 0 && (
        <Col flex={1} className={'user-count-container'}>
          <Text fontSize={18} color={'#7D8CA233'}>
            {usersCount} users
          </Text>
        </Col>
      )}
    </TopTitleAndSelectorStyled>
  );
};

export default TopTitleAndSelector;
