const hasWebsite: RolesTypes[] = [
  {
    id: 'Company Admin',
    name: 'Company Admin',
  },
  {
    id: 'Website Admin',
    name: 'Website Admin',
  },
];

const noWebsite: RolesTypes[] = [
  {
    id: 'Company Admin',
    name: 'Company Admin',
  },
];

const noAdmin: RolesTypes[] = [
  {
    id: 'Website Admin',
    name: 'Website Admin',
  },
];

export default (hasWebsites: number) => {
  if (hasWebsites === 1) return hasWebsite;
  if (hasWebsites === 2) return noAdmin;
  return noWebsite;
};
