import styled from 'styled-components';

const UserMoveStyledContainer = styled.form`
  flex: 1;
  padding: 0px 3rem;
  width: 100%;
  overflow: hidden;
  h2 {
    color: ${(props) => props.theme.colors.primaryColor};
    font-family: ${(props) => props.theme.fontFamilies.bold};
    margin-bottom: 2rem;
  }
  .ant-row.ant-row-middle.form-container__company-select {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .ant-col {
      flex: 1;
      margin: 0rem 0.5rem;
      .ant-row.ant-row-middle.base-selector-row-container {
        display: flex;
        flex-direction: column-reverse;
        .ant-select {
          width: 100%;
          .ant-select-selector {
            span {
              font-family: ${(props) => props.theme.fontFamilies.bold};
              font-weight: bold;
              font-size: 1rem;
            }
          }
        }
        p {
          font-family: ${(props) => props.theme.fontFamilies.bold};
          padding-left: 0.25rem !important;
          align-self: flex-start;
          margin-bottom: 0.5rem;
        }
      }
    }
  }
  .ant-row.ant-row-middle.form-container__checkbox {
    margin: 2.5rem 0rem 1rem 0rem;
    padding-left: 0.5rem;
    label {
      span {
        p {
          font-family: ${(props) => props.theme.fontFamilies.bold};
        }
      }
    }
  }
  .content__submit-container button:nth-child(2) {
    padding: 1rem 6rem;
    border-radius: 2rem;
    :disabled {
      opacity: 0.3;
    }
  }
  div:nth-child(4) {
    padding: 0rem 1.5rem;
    margin-bottom: 2.5rem;
    > div {
      width: 100%;
      > div {
        span {
          font-family: ${(props) => props.theme.fontFamilies.bold};
          font-size: 1rem;
        }
      }
    }
  }
`;

export { UserMoveStyledContainer };
