import { useContext, useEffect, useState } from 'react';
import * as Api from 'api';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ThemeContext } from 'styled-components';
import { useHistory } from 'react-router-dom';
import { message } from 'antd';
import { AppContext } from 'state/appContext';
import { routesPaths } from 'utils/helpers';
import { validateAxiosErrorInForms } from 'utils/helpers/functions';
import { addCompanyForms } from './const';
import { createCompany, endpoints, useGetCountries, ajax } from 'services';
import { trigger as swrMutate } from 'swr';
import { SiderNavigationContext } from 'state/sider/navigation/siderNavigationContext';
import { BaseRightDrawerContext } from 'state/baseComponents/BaseRightDrawerContext';

const schema = Yup.object().shape({
  name: Yup.string().required('The field is required (min 3 char.)').min(3),
  address: Yup.string().required('The field is required (min 3 char.)').min(3),
  country_iso: Yup.string().required('The field is required'),
  region: Yup.string().required('The field is required (min 3 char.)').min(3),
  city: Yup.string().required('The field is required (min 3 char.)').min(3),
  postal_code: Yup.string()
    .required('The field is required (min 3 char.)')
    .min(3, 'Min length should be 3 characters'),
});

const useAddCompanyPage = () => {
  const { siderActions } = useContext(SiderNavigationContext);
  const { onClose } = useContext(BaseRightDrawerContext);
  const { state } = useContext(AppContext);
  const { fontFamilies } = useContext(ThemeContext);
  const [loading, setLoading] = useState(false);
  const [tmpLogoUrl, setTmpLogoUrl] = useState<string>();
  const [tmpLogoFile, setTmpLogoFile] = useState<File>();
  const { handleSubmit, control, errors, watch, setValue, setError } = useForm<AddCompanyFormType>({
    resolver: yupResolver(schema),
    shouldFocusError: true,
  });
  const { replace } = useHistory();
  const { data } = useGetCountries();

  const [fieldData, setFieldData] = useState<FormSectionWrapperType[]>(addCompanyForms);

  useEffect(() => {
    const items: SelectItemType[] =
      data?.data?.map(({ iso, nicename }) => ({
        label: nicename,
        value: iso,
      })) ?? [];

    const currentField = addCompanyForms[2].fields[0];
    currentField.itemsArray = items;
    currentField.value = '';

    setFieldData([...addCompanyForms]);
    setValue(currentField.name, '');
  }, [data]);

  const onSkip = () => {
    replace(routesPaths.home);
  };

  const handleLogoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files == null) return;

    const file = e.target.files[0];
    if (file) {
      setTmpLogoFile(file);
      setTmpLogoUrl(URL.createObjectURL(file));
    }
  };

  const onSubmit = async ({
    name,
    address,
    country_iso,
    region,
    city,
    postal_code,
    logo,
  }: AddCompanyFormType) => {
    const requestData = {
      name,
      address,
      country_iso,
      region,
      postal_code,
      city,
    };
    try {
      setLoading(true);
      const formData = new FormData();

      if (tmpLogoFile != null) {
        if (tmpLogoFile.size > 2048000) {
          message.error('Error: Logo size should be less than 2MB');
          return;
        } else if (tmpLogoFile.type !== 'image/jpeg' && tmpLogoFile.type !== 'image/png') {
          message.error('Error: Logo should be in jpg or png format');
          return;
        }
        formData.append('logo', tmpLogoFile);
      } else if (tmpLogoFile == null && logo == null) {
        message.error('Please upload a logo');
      }

      Object.entries(requestData).forEach(([key, value]) => {
        formData.append(key, value);
      });

      formData.append('_method', 'POST');

      await createCompany(formData);
      onClose();

      swrMutate(endpoints.getCompanies);
      ajax.get(Api.endpoints.siderAllCompanies).then((response) => {
        siderActions.setCompanies(response.data);
      });

      swrMutate(endpoints.siderTree);
    } catch (error) {
      console.log('err', error);
      validateAxiosErrorInForms<RegistrationFormType>(error, setError);
    } finally {
      setLoading(false);
    }
  };

  return {
    user: state.user,
    onSubmit,
    handleSubmit,
    control,
    errors,
    fontFamilies,
    onSkip,
    watch,
    setValue,
    fieldData,
    loading,
    handleLogoChange,
    tmpLogoUrl,
    tmpLogoFile,
  };
};

export default useAddCompanyPage;
