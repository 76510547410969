import React, { createContext, useContext, useState } from 'react';
import { Dispatch } from 'react';
import { message } from 'antd';
import { updateAuthUser } from 'services';
import { AppContext } from 'state/appContext';
import { appActions } from 'state/appReducer';

type IStep = 'company' | 'website' | 'product' | 'story' | 'video' | string;

interface TypesWalkthroughContext {
  readonly storylines: IStorylines[] | null;
  readonly userStats: IUserStats | null;
  readonly setUserStats: Dispatch<React.SetStateAction<IUserStats | null>>;
  readonly setStorylines: Dispatch<React.SetStateAction<IStorylines[] | null>>;
  readonly step: IStep;
  readonly setStep: Dispatch<React.SetStateAction<IStep>>;
  readonly percent: number;
  readonly setPercent: Dispatch<React.SetStateAction<number>>;
  readonly walkthroughText: string;
  readonly setWalkthroughText: Dispatch<React.SetStateAction<string>>;
  readonly handleWalkthroughSkip: () => void;
  readonly isLoading: boolean;
  readonly setIsLoading: Dispatch<React.SetStateAction<boolean>>;
  readonly params: Omit<UserObjectType, 'companies' | 'id' | 'inital_company'>;
}

interface Props {
  readonly children: React.ReactNode;
}

const WalkthroughContext = createContext({} as TypesWalkthroughContext);

const WalkthroughProvider = ({ children }: Props) => {
  const [userStats, setUserStats] = useState<IUserStats | null>(null);
  const [storylines, setStorylines] = useState<IStorylines[] | null>(null);
  const [percent, setPercent] = useState(0);
  const [walkthroughText, setWalkthroughText] = useState('');
  const [step, setStep] = useState('company');
  const [isLoading, setIsLoading] = useState(true);
  const {
    state: { user },
    dispatch,
  } = useContext(AppContext);

  const params: Omit<UserObjectType, 'companies' | 'id' | 'initial_company'> = {
    first_name: user?.first_name,
    last_name: user?.last_name,
    email: user?.email,
    walkthrough: false,
  };

  const handleWalkthroughSkip = async () => {
    await updateAuthUser(Number(user?.id), params);
    message.success('User updated!');

    dispatch(appActions.setUser({ ...user, ...params }));
  };

  return (
    <WalkthroughContext.Provider
      value={{
        storylines,
        setStorylines,
        percent,
        setPercent,
        walkthroughText,
        setWalkthroughText,
        handleWalkthroughSkip,
        step,
        setStep,
        userStats,
        setUserStats,
        isLoading,
        setIsLoading,
        params,
      }}
    >
      {children}
    </WalkthroughContext.Provider>
  );
};

export { WalkthroughContext, WalkthroughProvider };
