import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { AuthContainerRouter } from 'views/authContainer';
import { LoginRegisterWrapperStyled } from './LoginRegister.styled';
import { getJWTFromLocalStorage } from 'utils/helpers/functions';
import { LoginPageLazy, RegistrationPageLazy } from '../index';
import TabBarHeader from '../../components/TabBarHeader';

const LoginRegister = () => {
  const token = getJWTFromLocalStorage();

  return token ? (
    <Redirect to="/home" />
  ) : (
    <AuthContainerRouter>
      <LoginRegisterWrapperStyled>
        <div className={'auth-white-wrapper'}>
          <TabBarHeader />
          <Switch>
            <Route exact path={'/:authType(login)'} component={LoginPageLazy} />
            <Route exact path={'/:authType(register)'} component={RegistrationPageLazy} />
          </Switch>
        </div>
      </LoginRegisterWrapperStyled>
    </AuthContainerRouter>
  );
};

export default LoginRegister;
