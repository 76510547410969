/* eslint-disable no-unused-vars */
import React, { useCallback } from 'react';
import { DynamicFormField } from 'components/baseComponents';
import {
  Control,
  DeepMap,
  DeepPartial,
  FieldError,
  FieldValues,
  LiteralToPrimitive,
  NestedValue,
  SetValueConfig,
  UnpackNestedValue,
} from 'react-hook-form';
import { Text } from 'components/styles';
import FormFieldSection from 'views/productContainer/components/FormFieldSection';
import { FieldRowWrapper } from './FormFieldsBuilder.styled';
import { FormPageTypeEnum } from 'utils/enums';

type FormFieldsBuilderProps<TFormType extends FieldValues> = {
  fieldsData: FormSectionWrapperType[];
  watch: (name: keyof TFormType, defaultValue: string | boolean | File) => string;
  setValue: <TFieldName extends string>(
    name: TFieldName,
    value: TFormType extends NestedValue<infer U>
      ? U
      : UnpackNestedValue<DeepPartial<LiteralToPrimitive<TFormType>>>,
    option?: SetValueConfig,
  ) => void;
  control: Control<TFormType>;
  errors: DeepMap<TFormType, FieldError>;
  page?: FormPageTypeEnum;
  registrationPage?: boolean;
};

const FormFieldsBuilder = <TFormType extends Record<string, string | boolean | File>>({
  fieldsData,
  watch,
  setValue,
  control,
  errors,
  page,
  registrationPage,
}: FormFieldsBuilderProps<TFormType>): React.ReactElement => {
  const showTitleBottomText = useCallback(
    (fields: FormBuilderFieldType[]) => {
      if (fields.length !== 1 || !fields?.[0].maxLength) return undefined;
      const { name, maxLength, value } = fields[0];
      let text = watch(name as keyof TFormType, value ?? '');

      //TODO: bad way of doing resetting
      const leftedCharacters = maxLength - (text?.length ?? 0);
      if (leftedCharacters < 0) {
        text = text.substr(0, maxLength);
        // @ts-expect-error
        setValue(name, text);
      }

      return `${leftedCharacters} characters left`;
    },
    [watch],
  );

  return (
    <>
      {fieldsData.map(({ fields, bottomDescription, ...data }) => (
        <FormFieldSection key={data.id} {...data} bottomText={showTitleBottomText(fields)}>
          <>
            {fields?.map((field, index) => (
              <FieldRowWrapper
                index={index}
                length={fields.length}
                key={field.id}
                $customType={field.customType}
                align={'middle'}
              >
                <DynamicFormField<TFormType>
                  control={control}
                  key={field.id}
                  setValue={setValue}
                  {...field}
                  errors={errors}
                  page={page}
                  registrationPage={registrationPage}
                />
              </FieldRowWrapper>
            ))}
            {bottomDescription && (
              <Text fontSize={12} lineHeight={16} color={'#7D8CA299'} className={'bottom-text'}>
                {bottomDescription}
              </Text>
            )}
          </>
        </FormFieldSection>
      ))}
    </>
  );
};

export default FormFieldsBuilder;
