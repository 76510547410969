import styled from 'styled-components';
import { svg } from 'assets/image';

export const DocumentPageStyled = styled.div`
  h2 {
    font-family: 'Noir Pro Regular';
    font-size: 21px;
    margin: 20px 0px;
  }
  p {
    font-family: 'Noir Pro Light';
    line-height: 20px;
    padding-bottom: 15px;
    color: #808e9b;
  }

  ul {
    padding: 15px 0px;
    list-style-image: url('${svg.faqBullet}');
    padding-inline-start: 20px;
    margin-bottom: 10px;

    li {
      font-family: 'Noir Pro Light';
      padding-bottom: 15px;
      color: #808e9b;
    }
  }
`;
