import React, { useEffect, Suspense } from 'react';
import { ThemeProvider } from 'styled-components';
import TermsAndConditions from './components/modal/TermsModal';
import Routes from 'views';
import { GlobalStyles } from 'components/styles';
import { themePrimary } from 'utils';
import { Helmet } from 'react-helmet';
import useApp from 'useApp';
import { hotjar } from 'react-hotjar';

function App() {
  const { shouldRender } = useApp();



  if (shouldRender)
    return (
      <Suspense fallback={() => <div>Loading...</div>}>
        <ThemeProvider theme={themePrimary}>
          <GlobalStyles />
          <Helmet titleTemplate="%s -StoryHub" defaultTitle="StoryHub">
            <meta name="description" content="A React Boilerplate application" />
          </Helmet>
          <TermsAndConditions />
          <Routes />
        </ThemeProvider>
      </Suspense>
    );
  else return <></>;
}

export default App;
