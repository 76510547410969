import styled from 'styled-components';

type AddButtonStyledProps = {
  backgroundColor?: string;
  hoverBackgroundColor?: string;
  borderColor?: string;
  hoverBorderColor?: string;
  color?: string;
  hoverColor?: string;
};

export const BaseAddButtonStyled = styled.button<AddButtonStyledProps>`
  margin-bottom: 30px;
  padding: 15px;
  border-radius: 10px;
  background-color: ${({ backgroundColor }) => backgroundColor};
  border: 1px solid ${({ borderColor }) => borderColor};
  color: ${({ color }) => color};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition-duration: 0.3s;

  svg {
    margin-bottom: 15px;
  }

  &:hover {
    background-color: ${({ hoverBackgroundColor }) => hoverBackgroundColor};
    border-color: ${({ hoverBorderColor }) => hoverBorderColor};
    color: ${({ hoverColor }) => hoverColor};
  }
`;
