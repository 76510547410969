import { AddCompanyComponent } from 'components/common/userMove';
import React from 'react';
import { AddCompanyComponentModalStyled } from './AddCompanyComponentModal.styled';

const AddCompanyComponentModal = ({
  onClose,
  firstCompany,
  fromModal,
}: CommonModalPropsType): React.ReactElement => {
  return (
    <AddCompanyComponentModalStyled>
      <AddCompanyComponent onClose={onClose} firstCompany={firstCompany} fromModal={fromModal} />
    </AddCompanyComponentModalStyled>
  );
};

export default AddCompanyComponentModal;
