import React from 'react';

const MinusIcon = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13.333"
      height="2.857"
      viewBox="0 0 13.333 2.857"
      {...props}
    >
      <path
        id="Icon_awesome-plus"
        data-name="Icon awesome-plus"
        d="M12.381,7.488H.952A.952.952,0,0,0,0,8.44v.952a.952.952,0,0,0,.952.952H12.381a.952.952,0,0,0,.952-.952V8.44A.952.952,0,0,0,12.381,7.488Z"
        transform="translate(0 -7.488)"
        fill="#01ae92"
      />
    </svg>
  );
};

export default MinusIcon;
