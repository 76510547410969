import { ajax } from 'services';
import * as Api from '../../api';

export const getUserStats = () => {
  return ajax.get(Api.endpoints.userStats);
};

export const getStorylineStats = () => {
  return ajax.get(Api.endpoints.storylineStats);
};

export const getStorylines = () => {
  return ajax.get(Api.endpoints.storylines);
};
