// import { Layout } from 'antd';
import styled from 'styled-components';

export const TableContainerStyled = styled.div`
  height: 100%;
  flex-direction: column !important;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 0px 1.25rem #543fc84f;
`;
