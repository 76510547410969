import styled from 'styled-components';

export const Container = styled.div`
  width: 1680px;
  max-width: 100%;
  padding: 0 20px;
  margin: 0 auto;
`;

export const InnerContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 20px 70px;
  margin-bottom: 100px;

  footer {
    margin: 40px 0 !important;
  }

  @media (max-width: 1600px) {
    padding: 20px 70px;
  }

  @media (max-width: 768px) {
    padding: 20px 0px;
  }
`;

export const ContainerGrey = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  background-color: #f6f6fc;
  border-radius: 60px;
  padding: 60px 20px;
  margin-bottom: 60px;
  @media only screen and (max-width: 992px) {
    border-radius: 30px;
    padding-bottom: 10px;
    margin-bottom: 0px;
  }
`;
