import React from 'react';
import { Row, Col, Button } from 'antd';

import { AddCompanyComponentStyled } from './AddCompanyComponent.styled';
import { BaseSecondaryButton } from 'components/baseComponents';
import { FormFieldsBuilder } from 'components';
import { Text, Title } from 'components/styles';
import useAddCompanyComponent from './useAddCompanyComponent';
import { FormPageTypeEnum } from 'utils/enums';

const AddCompanyComponent = ({
  fromModal,
  onClose,
  firstCompany,
}: CommonModalPropsType): React.ReactElement => {
  const {
    onSubmit,
    handleSubmit,
    control,
    errors,
    fontFamilies,
    onSkip,
    watch,
    setValue,
    fieldData,
  } = useAddCompanyComponent({ fromModal, onClose, firstCompany });

  return (
    <AddCompanyComponentStyled onSubmit={handleSubmit(onSubmit)}>
      <Title.H3>
        {firstCompany || fromModal === undefined ? 'Owner Entity Details' : 'Create New Entity'}
      </Title.H3>
      <Col span={24} className={'content__form-container'}>
        <FormFieldsBuilder<AddCompanyFormType>
          fieldsData={fieldData}
          control={control}
          setValue={setValue}
          watch={watch}
          errors={errors}
          registrationPage={true}
          page={FormPageTypeEnum.addCompany}
        />
      </Col>
      <Row align={'middle'} justify={'space-between'} className={'content__submit-container'}>
        <Button type={'text'} onClick={fromModal ? onClose : onSkip} className={'skip-step'}>
          <Text fontSize={18} fontFamily={fontFamilies.regular} color={'#53536D66'}>
            {fromModal ? 'CANCEL' : 'skip & Go to Dashboard'}
          </Text>
        </Button>
        <BaseSecondaryButton
          title={'Add entity'}
          className={'btn--fill'}
          backgroundColor={'#5C5BC1'}
          htmlType={'submit'}
        />
      </Row>
    </AddCompanyComponentStyled>
  );
};

export default AddCompanyComponent;
