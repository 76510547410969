import React from 'react';

interface BulkUploadIconProps {
  readonly width?: string;
  readonly height?: string;
  readonly fill?: string;
  readonly style?: React.CSSProperties;
}

function BulkUploadIcon({ width, height, fill, style }: BulkUploadIconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 32 40"
      style={style}
    >
      <path
        id="Path_459"
        data-name="Path 459"
        d="M12,30H28v4H12Zm0-8H28v4H12ZM24,2H8A4.012,4.012,0,0,0,4,6V38a4,4,0,0,0,3.98,4H32a4.012,4.012,0,0,0,4-4V14Zm8,36H8V6H22V16H32Z"
        transform="translate(-4 -2)"
        fill={fill}
      />
    </svg>
  );
}

export default BulkUploadIcon;
