import styled from 'styled-components';

export const AddCompanyDrawerStyled = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding-bottom: 30px;

  .file-upload-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='%23333' stroke-width='1' stroke-dasharray='10' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
    padding: 30px;
    border-radius: 10px;
    cursor: pointer;

    img {
      height: 42px;
      width: 42px;
      border-radius: 50px;
      object-fit: cover;
    }
  }

  .input-container {
    margin: 0.5rem 0;
    .ant-input::placeholder {
      visibility: hidden;
    }
  }
`;
