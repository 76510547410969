import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Divider } from 'antd';
import Question from 'assets/image/svg/patch-question.svg';
import Archive from 'assets/image/svg/archive.svg';
import SiderNavigation from './SiderNavigation';
import { SiderStyled } from './Sider.styled';
import { ModalTypeEnum } from 'components/modal/ModalCustom';
import { cache } from 'swr';
import { AppContext } from 'state/appContext';
import { useHistory } from 'react-router-dom';
import { SiderNavigationContext } from 'state/sider/navigation/siderNavigationContext';
import { svg } from 'assets/image';
import BaseSidebarButton from 'components/baseComponents/BaseSidebarButton';

// TODO: After finishing redesign proccess, this component needs to be cleaned up

const Sider = () => {
  const history = useHistory();
  const {
    siderState,
    filteredCompanies,
    collapsed,
    companySearchKey,
    setCompanySearchKey,
    setFilteredCompanies,
    setCollapsed,
  } = useContext(SiderNavigationContext);
  const {
    state: { user },
    dispatch,
  } = useContext(AppContext);

  const [modalVisible, setModalVisible] = useState(false);
  const [modalType, setModalType] = useState<ModalTypeEnum>(ModalTypeEnum.addEntity);
  const [isAddModalVisible, setAddModalVisible] = useState(false);
  const [addModalType, setAddModalType] = useState<ModalTypeEnum>(ModalTypeEnum.addCompany);

  const isSuperOrOrganization: boolean | undefined =
    user?.role?.includes('Super Admin') || user?.role?.includes('Organization Admin');

  const isWebsiteAdmin: boolean | undefined = user?.role?.includes('Website Admin');

  useEffect(() => {
    return () => cache.clear();
  }, []);

  const toggleModal = useCallback(
    (type: ModalTypeEnum) => {
      setModalType(type);
      setModalVisible((prev) => !prev);
    },
    [setModalType, setModalVisible],
  );

  const toggleAddModal = useCallback(
    (type: ModalTypeEnum) => {
      setModalVisible(false);
      setAddModalType(type);
      setAddModalVisible((prev) => !prev);
    },
    [setAddModalType, setAddModalVisible],
  );

  const onRightClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    e.preventDefault();

    setCollapsed(!collapsed);
  };

  return (
    <SiderStyled
      width={'100%'}
      collapsible
      collapsed={collapsed}
      trigger={null}
      className="sider_tree"
    >
      <div className="sider_tree_header">
        <img src={svg.logo} alt="Logo" onClick={() => history.push('/home')} />
      </div>
      <input
        className="companiesFilter"
        placeholder="Company name"
        type="text"
        onChange={(e) => {
          setCompanySearchKey(e.target.value);
          setFilteredCompanies(
            siderState.companies.filter((company) =>
              company.name.toLowerCase().includes(e.target.value.toLowerCase()),
            ),
          );
        }}
      />
      <div className={'sider__tree-container'}>
        <SiderNavigation
          filteredCompanies={filteredCompanies}
          collapsed={collapsed}
          companySearchKey={companySearchKey}
        />
      </div>
      <div className="sider_footer">
        <Divider style={{ color: '#808E9B', height: '1px' }} />
        <BaseSidebarButton
          text={'F.A.Q.'}
          icon={Question}
          style={{ marginBottom: '0px' }}
          onClick={() => history.push('/frequent-questions')}
        />
        {isSuperOrOrganization && (
          <BaseSidebarButton
            text={'Archive'}
            icon={Archive}
            style={{ marginTop: '10px' }}
            onClick={() => history.push('/archive')}
          />
        )}
        <p className="footer_text">Powered by Flat Rock Technology Ltd</p>
      </div>
    </SiderStyled>
  );
};

export default React.memo(Sider);
