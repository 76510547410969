import React from 'react';
import { InfoIcon } from 'components/svg';
import WebsiteBorder from 'assets/image/svg/sider-website-border.svg';
import { useHistory } from 'react-router-dom';
import { endpoints } from 'services';
import { trigger } from 'swr';

type SiderState = {
  readonly companies: readonly ICompany[];
  readonly websites: readonly {
    readonly companySlug: string;
    readonly data: readonly IWebsite[];
  }[];
  readonly products: readonly {
    readonly websiteSlug: string;
    readonly data: readonly IProduct[];
  }[];
};

type ProductOnClick = (company: ICompany, website: IWebsite, product: IProduct) => void;

interface ProductProps {
  readonly siderState: SiderState;
  readonly site: IWebsite;
  readonly company: ICompany;
  readonly productOnClick: ProductOnClick;
  readonly currentUrl: string;
}

const Products = ({ siderState, site, company, productOnClick }: ProductProps): JSX.Element => {
  const history = useHistory();

  const showProducts = (product: IProduct) => {
    productOnClick(company, site, product);
    trigger(endpoints.getSlides(site.slug, product.slug));
  };

  return (
    <>
      {siderState.products
        .filter((item) => item.websiteSlug === site.slug)
        .map((prod) =>
          prod.data.length !== 0 ? (
            prod.data.map((product) => (
              <div
                key={product.slug}
                className="product"
                style={
                  history.location.pathname.includes(product.slug) &&
                  history.location.pathname.includes(site.slug)
                    ? { color: '#4B4DC8' }
                    : { color: '#181818' }
                }
              >
                <p onClick={() => showProducts(product)}>
                  <img src={WebsiteBorder} alt="border" />
                  {product.name}
                </p>
              </div>
            ))
          ) : (
            <p className="not-found" style={{ marginLeft: '3rem' }}>
              No products found
              <InfoIcon
                width="14"
                height="14"
                title={'You have not added any products for this website'}
                wrapperStyle={{ marginBottom: '-2px' }}
              />
            </p>
          ),
        )}
    </>
  );
};

export default Products;
