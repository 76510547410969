import * as Api from 'api';
import moment, { Moment } from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { StatisticPageCompanyContext } from 'state/productContainer/statisticPage/company/statisticPageCompanyContext';
import { SiderNavigationContext } from 'state/sider/navigation/siderNavigationContext';
import Statistic from 'views/productContainer/containers/statisticPage/components/Statistic';
import { DashboardChartStyled } from './DashboardChart.styled';

const DashboardChart = () => {
  const { company, setCompany } = useContext(StatisticPageCompanyContext);
  const { siderState } = useContext(SiderNavigationContext);
  const [companySlug, setCompanySlug] = useState('');
  const [range, setRange] = useState({
    range: {
      start: moment(new Date()).subtract('days', 7).format('YYYY-MM-DD') as string | Moment,
      end: moment(new Date()).format('YYYY-MM-DD') as string | Moment,
    },
  });

  useEffect(() => {
    if (siderState.companies[0] != null) {
      setCompanySlug(siderState.companies[0].slug);
    }
  }, [siderState.companies]);

  useEffect(() => {
    if (companySlug) {
      Api.getStatisticPageCompany({
        args: {
          companySlug,
          startDate: range.range.start,
          endDate: range.range.end,
        },
      }).then((res) => {
        setCompany({
          isLoading: false,
          data: res.data,
          type: 'Company',
        });
      });
    }
  }, [companySlug, range.range]);

  const dashboardChartOnChange = (value: string) => {
    setCompanySlug(value);
  };

  return (
    <DashboardChartStyled>
      {company.data != null && (
        <Statistic
          dashboard
          dashboardChartOnChange={dashboardChartOnChange}
          currentData={company.data}
          range={range.range}
          setRange={setRange}
        />
      )}
    </DashboardChartStyled>
  );
};

export default DashboardChart;
