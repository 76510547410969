import styled from 'styled-components';

export const LoginRegisterWrapperStyled = styled.div`
  .auth-white-wrapper {
    background-color: #f6f6fc !important;
    height: 100% !important;
    width: 100%;
    min-height: 20vh;
    border-radius: 30px;
  }

  height: 100%;

  input {
    width: 100% !important;
    background-color: white !important;
  }

  .input-container {
    border-radius: 60px !important;
    overflow: hidden;
    height: 60px;

    .ant-input {
      border-radius: 60px;
    }

    &:focus-within {
      border: 2px solid;
      border-color: #4b4dc8;
    }
  }

  @media only screen and (max-width: 992px) {
    height: auto;
    .auth-white-wrapper {
      margin: 30px 0px;
    }
  }
`;
