import endpoints from './endpoints';
import useRequest from './useRequest';

// export const useGetProductBySlug = () =>
//   useRequest<ProductType>({ url: '' }, { refreshInterval: 0 });

export const useGetCountries = () =>
  useRequest<CountryListResponseType>(endpoints.getCountries, {
    refreshInterval: 0,
    revalidateOnFocus: false,
  });

export const useSiderTree = () => useRequest<SiderTreeBeardResponseType>(endpoints.siderTree);

export const useGetRoles = () =>
  useRequest<UserRolesResponseType>(endpoints.getRoles, {
    refreshInterval: 0,
    revalidateOnFocus: false,
  });
