import { ButtonProps } from 'antd/lib/button';
import React from 'react';
import { BaseSidebarButtonStyled } from './BaseSidebarButton.styled';

type Props = {
  readonly text?: string;
  readonly className?: string;
  readonly icon?: string;
  readonly styles?: React.CSSProperties;
} & ButtonProps;

const BaseSidebarButton = ({ text, className, icon, styles, ...props }: Props) => {
  return (
    <BaseSidebarButtonStyled style={styles} className={className} {...props}>
      <div>
        <img src={icon} alt={text} />
        {text}
      </div>
    </BaseSidebarButtonStyled>
  );
};

export default BaseSidebarButton;
