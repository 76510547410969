import React from 'react';
import { Col, Row } from 'antd';
import { Link } from 'react-router-dom';
import { LayoutWrapperStyled } from './AuthContainerRouter.styled';
import { Container, InnerContainer } from '../landingPage/components/Container.styled';
import { Text } from 'components/styles';
import images from 'assets/image';
import { svg } from 'assets/image';

function useHideChatSupport() {
  React.useEffect(() => {
    if (window?.Tawk_API?.hideWidget) {
      window.Tawk_API.hideWidget();
    }
    return () => {
      if (window?.Tawk_API?.showWidget) {
        window.Tawk_API.showWidget();
      }
    };
  }, []);
}

const AuthContainerRouter = ({ children }: any) => {
  useHideChatSupport();
  return (
    <LayoutWrapperStyled $backgroundimage={images.storyHubBgr}>
      <Container>
        <InnerContainer className="landing_navbar">
          <header>
            <Link to="/">
              <img src={svg.logo} alt="Logo" className="logo" width="120px" height="50px" />
            </Link>
          </header>
        </InnerContainer>
        <Row gutter={30}>
          <Col className="gutter-row" lg={{ span: 14, order: 1 }} span={24} order={2}>
            <div className="auth_background">
              <div className="auth_text-box">
                <Text>Storytelling for businesses</Text>
                <h1>
                  Your <span>story</span> begins here!
                </h1>
              </div>
            </div>
            {/* <img src={images.cloudDesktop} alt="" /> */}
          </Col>
          <Col className="gutter-row" lg={{ span: 10, order: 2 }} span={24} order={2}>
            {/* <div className={'login__top-text'}>
          <Text
            color={'#fff'}
            fontSize={24}
            fontFamily={fontFamilies.bold}
            lineHeight={32}
          >
            Story telling for businesses
          </Text>
          <Title.H2 color={'#fff'} fontSize={72}>
            Your story begins here!
          </Title.H2>
        </div> */}
            {children}
          </Col>

          {/* <footer>
      <span>Powered by Flat Rock Technology Ltd</span>
    </footer> */}
        </Row>
      </Container>
    </LayoutWrapperStyled>
  );
};

export default AuthContainerRouter;
