import styled from 'styled-components';

export const BaseContentHeaderStyled = styled.div`
  border-bottom: 1px solid #eaecf2;
  padding-bottom: 30px;
  margin-bottom: 30px;

  h1 {
    font-size: 34px;
    font-family: 'Noir Pro Bold';
  }

  @media only screen and (max-width: 768px) {
    h1 {
      font-size: 21px;
    }
  }

  @media only screen and (max-width: 576px) {
    h1 {
      font-size: 18px;
    }
  }
`;
