import React from 'react';
import { TableHeadStyled } from './TableHead.styled';
import TableHeadSortableItem from './TableHeadSortableItem';
import { ConfigKey, TableHeadEnum } from 'hooks/useSortStatisticPage';
import { useParams } from 'react-router-dom';

type TableHeadPropsType = {
  sort: (key: TableHeadEnum) => void;
  config: {
    key: ConfigKey;
    direction: string;
    type: TableHeadEnum;
  } | null;
};

const TableHead = ({ sort, config }: TableHeadPropsType) => {
  const { websiteSlug } = useParams<{ companySlug: string; websiteSlug: string }>();
  const heads = [
    {
      text: websiteSlug ? 'Product/media spot' : 'Websites',
      sm: 10,
      xs: 24,
      offset: 0,
      type: TableHeadEnum.Spot,
    },
    {
      text: 'Impressions',
      type: TableHeadEnum.Impressions,
    },
    {
      text: 'Clicks',
      type: TableHeadEnum.Clicks,
    },
    {
      text: 'Click Rate',
      type: TableHeadEnum.ClickRate,
    },
  ];

  return (
    <TableHeadStyled align={'middle'}>
      {heads.map(({ type, ...rest }, index) => (
        <>
          <TableHeadSortableItem
            type={type}
            key={index}
            {...rest}
            className={`header__col ${type}`}
            onSelect={() => sort(type)}
            $active={type === config?.type}
            $sortingOrderIsAsc={config?.direction === 'ascending'}
          />
        </>
      ))}
    </TableHeadStyled>
  );
};

export default TableHead;
