import { Button, message, Row } from 'antd';
import { BaseSecondaryButton } from 'components/baseComponents';
import { LoaderLine } from 'components/common/userMove';
import { Text, Title } from 'components/styles';
import React, { useContext } from 'react';
import { endpoints, restoreUser } from 'services';
import { ThemeContext } from 'styled-components';
import { trigger } from 'swr';
import { ActivateCompanyModalStyled } from './ActivateCompanyModal.styled';

const ActivateUserModal = ({
  onClose,
  id,
  companySlug,
  name,
}: ActivateUserModalProps): React.ReactElement => {
  const { fontFamilies } = useContext(ThemeContext);
  const onActivate = async () => {
    try {
      await restoreUser(companySlug, Number(id));
      trigger(endpoints.getAllUsers);
      trigger(endpoints.siderTree);
      trigger(endpoints.getCompanyUser(companySlug, Number(id)));
      message.success('User Restored Succesfuly');
      onClose();
    } catch (error) {
      message.error(`Error: ${error.response.data.message}`);
    }
  };

  return (
    <ActivateCompanyModalStyled>
      <Title.H2 color={'#5B59C2'}>Restore User</Title.H2>
      <Text className={'text'} fontFamily={fontFamilies.semiBold} color={'#000000'}>
        Restoring <span>{name}</span>.
      </Text>
      <LoaderLine loading={false} />
      <Row align={'middle'} justify={'end'} className={'content__submit-container'}>
        <Button type={'text'} onClick={onClose} className={'skip-step'}>
          <Text fontSize={18} fontFamily={fontFamilies.regular} color={'#53536D66'}>
            CANCEL
          </Text>
        </Button>
        <BaseSecondaryButton
          title={'activate'}
          className={'btn--fill'}
          backgroundColor={'#5C5BC1'}
          onClick={onActivate}
        />
      </Row>
    </ActivateCompanyModalStyled>
  );
};

export default ActivateUserModal;
