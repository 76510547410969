import { Button, Col, message, Row } from 'antd';
import React, { useContext, useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { ThemeContext } from 'styled-components';
import * as Yup from 'yup';
import { BulkWebsiteAddStyled } from './BulkWebsiteAdd.styled';
import { BaseSelect } from 'components/baseComponents';
import BaseSubmitButton from 'components/baseComponents/BaseSubmitButton';
import { Text } from 'components/styles';
import { ReactComponent as UploadIcon } from 'assets/image/svg/upload-icon-feather.svg';
import BulkUploadIcon from 'components/svg/BulkUploadIcon';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormPageTypeEnum } from 'utils/enums';
import Papa from 'papaparse';
import { useGetCompanies } from 'services';
import { useDropzone } from 'react-dropzone';
import { isValidFile } from 'utils/helpers/functions';
import MediumArrowIcon from 'components/svg/MediumArrowIcon';
import { BaseRightDrawerContext } from 'state/baseComponents/BaseRightDrawerContext';

const schema = Yup.object().shape({
  company_id: Yup.string().required(),
  file: Yup.mixed(),
});

type BulkUploadFomType = {
  company_id: string;
  file: string;
};

const sampleFile = 'data:file/csv;base64,VXJsLG5hbWUKaHR0cHM6Ly93d3cuZXhhcG1sZS5jb20sU25vdw==';

const BulkWebsiteAdd = ({ onUploadMethodSwith, setCSVData }: any) => {
  const { fontFamilies } = useContext(ThemeContext);
  const { data: companies } = useGetCompanies();
  const { onClose } = useContext(BaseRightDrawerContext);

  const { handleSubmit, control, setValue } = useForm<BulkUploadFomType>({
    resolver: yupResolver(schema),
  });
  const onSubmit = (data: any) => {
    if (!isValidFile(data)) {
      message.info('Unsupported format. Please upload the file in CSV format.');
      return;
    }

    Papa.parse(data[0], {
      error: () => {
        message.info('There was an issue.');
      },
      complete: (result, file) => {
        setCSVData(result.data);
        onUploadMethodSwith();
        // onClose();
      },
    });
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: onSubmit,
  });

  // useEffect(() => {
  //   companies?.data[0] &&
  //     setValue(
  //       'company_id',
  //       (companies?.data[0] as RequiredCompanyType).id.toString(),
  //     );
  // }, [companies, setValue]);

  const getCompanies: SelectItemType[] = useMemo(
    () =>
      (companies?.data as RequiredCompanyType[])?.map(({ name, id }) => ({
        label: name,
        value: id.toString(),
      })) ?? [],
    [companies],
  );

  useEffect(() => {
    if (getCompanies?.length) {
      setValue('company_id', getCompanies[0].value);
    }
  }, [getCompanies]);

  return (
    <BulkWebsiteAddStyled onSubmit={handleSubmit(onSubmit)}>
      <Row align={'middle'} className={'form-container__company-select'}>
        <Col span={24}>
          <Controller
            control={control}
            name={'company_id'}
            label={'Company name'}
            as={<BaseSelect size="large" />}
            page={FormPageTypeEnum.addUsers}
            itemsArray={getCompanies}
          />
        </Col>

        <Col span={24}>
          <div {...getRootProps()} className={'file-drop'}>
            <Row align={'middle'}>
              <Col span={4}>
                <BulkUploadIcon width="32px" height="40px" fill="#808E9B" />
              </Col>
              <Col span={20}>
                <input id={'file'} {...getInputProps()} />
                <div>
                  <Text color={'#808E9B'} size={'16px'} fontFamily={fontFamilies.bold}>
                    Bulk upload
                  </Text>
                  <Text color={'#808E9B'} size={'16px'} fontFamily={fontFamilies.light}>
                    Drop a list of users in CSV format to upload it or click to browse to the file
                  </Text>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
        <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            type={'text'}
            className={'bulk-website-add__download-sample flex flex-row '}
            icon={
              <UploadIcon width={`16px`} height={`16px`} style={{ margin: '5px 15px 0px 15px' }} />
            }
            href={sampleFile}
            color="transparent"
            target="_blank"
            download={'sample.csv'}
          >
            <Text fontSize={18} fontFamily={fontFamilies.regular} color={'#181818'}>
              Download a sample CSV
            </Text>
          </Button>
        </Col>
      </Row>

      <Row>
        <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            type={'text'}
            className={'switch-to-manual'}
            onClick={onUploadMethodSwith}
            icon={
              <MediumArrowIcon
                width={`16px`}
                height={`16px`}
                fill={'#01AE92'}
                style={{ marginRight: '15px' }}
              />
            }
          >
            <Text fontSize={18} fontFamily={fontFamilies.regular} color={'#5B59C2'}>
              Switch to Manual Registration
            </Text>
          </Button>
        </Col>
        <Col span={24}>
          <BaseSubmitButton
            title={'Add website'}
            className={'btn--fill'}
            backgroundColor={'#4B4DC8'}
            style={{ marginTop: '12px' }}
            withArrow
          />
        </Col>
      </Row>
    </BulkWebsiteAddStyled>
  );
};

export default BulkWebsiteAdd;
