import React, { useContext, useEffect } from 'react';
import StarterHeader from './components/StarterHeader/StarterHeader';
import { Spin } from 'antd';
import { StarterContainerStyled } from './StarterContainer.styled';
import Storylines from './components/Storylines/Storylines';
import DashboardLayout from 'views/dashboardLayout/DashboardLayout';
import DashboardChart from './components/Chart/DashboardChart';
import SiderMobile from 'components/sider/SiderMobile';
import SkippedAddButton from './components/SkippedWalkthrough/SkippedAddButton';
import { BaseRightDrawerContext } from 'state/baseComponents/BaseRightDrawerContext';
import AddCompanyDrawer from 'components/common/addCompany/AddCompanyDrawer';
import AddWebsite from 'components/modal/modalTypes/addWebsite/AddWebsite';
import AddProduct from 'components/common/addProduct/AddProduct';
import { WalkthroughContext } from 'state/walkthrough/walkthroughContext';
import { SiderNavigationContext } from 'state/sider/navigation/siderNavigationContext';
import * as Api from 'api';
import { StarterContainerContext } from 'state/starterContainer/starterContainerContext';

const renderElements = (userStats: IUserStats | null) => {
  const { showDrawer } = useContext(BaseRightDrawerContext);

  if (!userStats?.companies) {
    return (
      <div className="skipped-container">
        <SkippedAddButton
          text="Add your first company"
          span="Start from here"
          onClick={() => {
            showDrawer({
              _content: <AddCompanyDrawer />,
              _title: 'New Company',
            });
          }}
        />
      </div>
    );
  } else if (!userStats?.websites) {
    return (
      <div className="skipped-container">
        <SkippedAddButton
          text="Add your first website"
          span="For stories widget"
          onClick={() => {
            showDrawer({
              _content: <AddWebsite />,
              _title: 'New Website',
            });
          }}
        />
      </div>
    );
  } else if (!userStats?.products) {
    return (
      <div className="skipped-container">
        <SkippedAddButton
          text="Add your first storyline"
          span="For completion"
          onClick={() => {
            showDrawer({
              _content: <AddProduct />,
              _title: 'New Storyline',
            });
          }}
        />
      </div>
    );
  } else {
    return (
      <>
        <SiderMobile style={{ marginTop: '30px', marginBottom: '15px' }} />
        <Storylines />
        <DashboardChart />
      </>
    );
  }
};

const StarterContainer = () => {
  const { userStats, setUserStats } = useContext(WalkthroughContext);
  const { isLoading, setIsLoading } = useContext(StarterContainerContext);
  const { siderState } = useContext(SiderNavigationContext);

  useEffect(() => {
    Api.getUserStats()
      .then((res) => {
        setUserStats(res.data.data);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [siderState]);

  return (
    <DashboardLayout>
      <StarterContainerStyled>
        <StarterHeader />
        {isLoading ? (
          <div
            style={{
              display: 'flex',
              height: '100%',
              width: '100%',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Spin size="large" />
          </div>
        ) : (
          renderElements(userStats)
        )}
      </StarterContainerStyled>
    </DashboardLayout>
  );
};

export default StarterContainer;
