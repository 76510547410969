import React from 'react';
import { Row, Col } from 'antd';
import { CompanyEditStyled } from './CompanyEdit.styled';
import { FormFieldsBuilder } from 'components';
import { Text } from 'components/styles';
import useCompanyEdit from './useCompanyEdit';
import { FormPageTypeEnum } from 'utils/enums';
import BaseSubmitButton from 'components/baseComponents/BaseSubmitButton';
import { ReactComponent as UploadIcon } from 'assets/image/svg/upload-icon-feather.svg';

type CompanyEditPropsType = {
  data: CompanyDetailsType;
};
const CompanyEdit = ({ data }: CompanyEditPropsType): React.ReactElement => {
  const {
    onSubmit,
    handleSubmit,
    control,
    errors,
    fontFamilies,
    watch,
    setValue,
    fieldData,
    handleLogoChange,
    tmpLogoUrl,
  } = useCompanyEdit(data);

  return (
    <CompanyEditStyled className="scrollable" onSubmit={handleSubmit(onSubmit)}>
      <Row className="logo-section">
        <Col span={3}>
          <Row style={{ height: '100%' }} align={'middle'}>
            <img src={tmpLogoUrl ? tmpLogoUrl : (data.logo as string)} alt="" />
          </Row>
        </Col>
        <Col span={15}>
          <Text
            style={{ marginLeft: '5px' }}
            fontFamily={fontFamilies.light}
            color="#181818"
            fontSize={16}
          >
            Change current logo
          </Text>
          <Text
            style={{ marginLeft: '5px' }}
            fontFamily={fontFamilies.light}
            color="#808E9B"
            fontSize={14}
          >
            <span style={{ color: '#EE5253' }}>*</span> Optimal logo size 42x42px
          </Text>
        </Col>
        <Col span={6}>
          <Row align="middle" style={{ height: '100%' }} justify={'end'}>
            <Col md={10} sm={4} style={{ textAlign: 'center' }}>
              <UploadIcon
                width={`${20 / 16}rem`}
                height={`${20 / 16}rem`}
                style={{ margin: '5px 5px 0 0' }}
              />
            </Col>
            <Col md={14} sm={20}>
              <label>
                <Text
                  fontFamily={fontFamilies.regular}
                  color="#4B4DC8"
                  fontSize={18}
                  style={{ cursor: 'pointer', textDecoration: 'underline', textAlign: 'center' }}
                >
                  Browse
                  <input
                    type="file"
                    style={{ display: 'none' }}
                    onChange={handleLogoChange}
                    accept=".jpg, .png, .jpeg"
                  />
                </Text>
              </label>
            </Col>
          </Row>
        </Col>
      </Row>
      <FormFieldsBuilder<AddCompanyFormType>
        fieldsData={fieldData}
        control={control}
        setValue={setValue}
        watch={watch}
        errors={errors}
        page={FormPageTypeEnum.addCompany}
      />
      <Row align={'middle'} className={'content__submit-container'}>
        <BaseSubmitButton
          title={'Save Details'}
          className={'btn--fill'}
          backgroundColor={'#4B4DC8'}
          style={{ marginTop: '12px' }}
          withArrow
        />
      </Row>
    </CompanyEditStyled>
  );
};

export default CompanyEdit;
