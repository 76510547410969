import { Row } from 'antd';
import styled from 'styled-components';

export const TableHeadStyled = styled(Row)`
  height: 80px;
  flex-flow: row nowrap !important;
  overflow: hidden;

  .header__col {
    p {
      text-transform: normal;
      font-size: 1.15rem;
    }
  }
  @media only screen and (max-width: 900px) {
    justify-content: flex-start;
    padding: 0 2rem;
    > p {
      margin-right: 1rem;
      display: flex;
    }
    div:nth-child(3),
    div:nth-child(4),
    div:nth-child(6),
    div:nth-child(7) {
      display: none;
    }
  }
  @media only screen and (max-width: 550px) {
    justify-content: center;
    padding: 0;
  }
`;
