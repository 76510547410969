import React from 'react';
import {
  AreaChart,
  XAxis,
  YAxis,
  Area,
  Tooltip,
  ResponsiveContainer,
  CartesianGrid,
} from 'recharts';
import { LoadingOutlined } from '@ant-design/icons';
import moment from 'moment';
import { StatisticPageWebsite } from 'api/statisticPage/website/types';

const black = 'black';

interface Props {
  data: StatisticPageWebsite;
}

const Visualization: React.FC<Props> = ({ data: currentStats }) => {
  const formatDates = (value: string) => {
    if (value && typeof value === 'string') {
      return moment(value, 'YYYY-MM-DD').format('MMM DD');
    } else {
      return value;
    }
  };

  if (!currentStats.data.statistics) {
    return <LoadingOutlined className="loading_icon" />;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const CustomTooltip = (props: any) => {
    if (props.payload && props.active) {
      return (
        <div
          style={{
            background: 'white',
            padding: '0.2rem 1.25rem',
            borderRadius: '10px',
          }}
        >
          <h4 style={{ margin: '0.5rem 0rem' }}>
            {moment(props.label, 'YYYY-MM-DD').format('DD / MM / YYYY')}
          </h4>
          <h4 style={{ margin: '0.5rem 0rem', color: '#01AE92' }}>
            Impressions: {props.payload[0].payload.totalImpressions}
          </h4>
          <h4 style={{ margin: '0.5rem 0rem', color: '#4B4DC8' }}>
            Clicks: {props.payload[0].payload.totalClicks}
          </h4>
        </div>
      );
    }
    return null;
  };

  const CustomDot = (props: React.SVGAttributes<SVGElement>) => {
    const { cx, cy, fill } = props;
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        x={(cx as number) - 8}
        y={(cy as number) - 8}
        width="16"
        height="16"
        viewBox="0 0 16 16"
      >
        <g id="Path_505" data-name="Path 505" transform="translate(3 3)" fill={fill}>
          <path
            d="M 5 11.5 C 1.415889978408813 11.5 -1.5 8.584110260009766 -1.5 5 C -1.5 1.415889978408813 1.415889978408813 -1.5 5 -1.5 C 8.584110260009766 -1.5 11.5 1.415889978408813 11.5 5 L 11.5 5.16949987411499 L 11.46218013763428 5.334720134735107 L 10.97883987426758 7.446090221405029 L 10.9409704208374 7.611519813537598 L 10.86709976196289 7.764299869537354 L 10.66779041290283 8.176529884338379 L 10.66434955596924 8.183639526367188 L 10.66084003448486 8.190710067749023 C 9.677980422973633 10.17010021209717 7.403059959411621 11.5 5 11.5 Z"
            stroke="none"
          />
          <path
            d="M 5 0 C 2.238580226898193 0 0 2.238580226898193 0 5 C 0 7.761420249938965 2.238580226898193 10 5 10 C 6.952330112457275 10 8.643309593200684 8.881050109863281 9.317350387573242 7.523600101470947 L 9.516659736633301 7.111370086669922 L 10 5 C 10 2.238580226898193 7.761420249938965 0 5 0 M 5 -3 C 9.411210060119629 -3 13 0.5887899398803711 13 5 L 13 5.339000225067139 L 12.36526966094971 8.111660003662109 L 12.00432968139648 8.857820510864258 C 10.7741003036499 11.3353796005249 7.959270000457764 13 5 13 C 0.5887899398803711 13 -3 9.411210060119629 -3 5 C -3 0.5887899398803711 0.5887899398803711 -3 5 -3 Z"
            stroke="none"
            fill="#fff"
          />
        </g>
      </svg>
    );
  };

  const dataFormater = (number: number) => {
    return Math.abs(number) > 999
      ? Math.sign(number) * ((Math.abs(number) / 1000).toFixed(1) as unknown as number) + 'k'
      : (Math.sign(number) * Math.abs(number)).toString();
  };

  // temporary data for visualization
  const tmpData = [
    {
      date: '2022-04-7',
      totalClicks: 10,
      totalImpressions: 7,
    },
    {
      date: '2022-04-8',
      totalClicks: 12,
      totalImpressions: 10,
    },
    {
      date: '2022-04-9',
      totalClicks: 10,
      totalImpressions: 6,
    },
    {
      date: '2022-04-10',
      totalClicks: 15,
      totalImpressions: 22,
    },
    {
      date: '2022-04-11',
      totalClicks: 13,
      totalImpressions: 7,
    },
    {
      date: '2022-04-12',
      totalClicks: 7,
      totalImpressions: 13,
    },
    {
      date: '2022-04-13',
      totalClicks: 17,
      totalImpressions: 17,
    },
    {
      date: '2022-04-14',
      totalClicks: 5,
      totalImpressions: 20,
    },
  ];

  return (
    <ResponsiveContainer>
      <AreaChart
        data={currentStats.data.statistics}
        margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
      >
        <defs>
          <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#4B4DC8" stopOpacity={0.1} />
            <stop offset="95%" stopColor="#4B4DC8" stopOpacity={0} />
          </linearGradient>
          <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#01AE92" stopOpacity={0.1} />
            <stop offset="95%" stopColor="#01AE92" stopOpacity={0} />
          </linearGradient>
        </defs>
        <XAxis
          tickMargin={10}
          tickSize={0}
          stroke={black}
          tickFormatter={formatDates}
          dataKey="date"
          minTickGap={15}
          axisLine={false}
          fontSize={12}
        />
        <YAxis
          tickFormatter={dataFormater}
          tickSize={90}
          type="number"
          tickCount={6}
          tick={{ dy: 15, dx: 50 }}
          stroke={black}
          fontSize={12}
          allowDecimals={false}
          axisLine={false}
        />
        <Tooltip
          content={<CustomTooltip />}
          wrapperStyle={{
            boxShadow: `0px 10px 30px #1818180d`,
          }}
        />
        <CartesianGrid opacity={0.3} />
        <Area
          type="monotone"
          dataKey="totalClicks"
          stroke="#4B4DC8"
          strokeWidth="2px"
          fillOpacity={1}
          fill="url(#colorUv)"
          activeDot={{ r: 8 }}
          dot={<CustomDot fill="#4B4DC8" />}
        />
        <Area
          type="monotone"
          dataKey="totalImpressions"
          stroke="#01AE92"
          strokeWidth="2px"
          fillOpacity={1}
          fill="url(#colorPv)"
          activeDot={{ r: 8 }}
          dot={<CustomDot fill="#01AE92" />}
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default Visualization;
