import { Layout } from 'antd';
import styled from 'styled-components';

export const ProductContainerWrapperStyled = styled(Layout)`
  background-color: transparent;
  .container {
    flex-basis: 100%;
    padding-top: 3vh;
    .container__content {
      background-color: #ffffff;
      /* max-height: calc(100% - 3% - 8.5rem); */
      height: calc(100% - 3vh - 8.5rem);
      box-shadow: 0px 0px 20px #543fc84f;
      border-radius: 1.25rem;
      transition: height 0.5s linear;
      &.auto {
        height: auto;
      }
      form {
        width: 100%;
      }
    }
  }
`;

export const LayoutWrapperStyled = styled(Layout)`
  height: 100%;
  flex-direction: column !important;
  @media only screen and (max-width: 1200px) {
    .sider_tree {
      opacity: 0;
      visibility: hidden;
      transition: 0.3s all ease-in-out;
    }
    .content-wrapper.sider_active {
      overflow-y: auto;
      background-color: #f6f9fc;
      position: relative;
      .sider_tree {
        transform: translateX(0%);
        opacity: 1;
        visibility: visible;
      }
    }
    .content-wrapper {
      .sider_tree {
        transform: translateX(-100%);
      }
    }
  }
  .form-submit-buttons {
    height: 5rem;
    padding: 0 3.625rem;
    padding-bottom: 1.7rem;
    display: flex;
  }
`;
