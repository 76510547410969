import React, { useContext } from 'react';
import { RightSideNavigationStyled } from './RightSideNavigation.styled';
import { BaseRightDrawerContext } from 'state/baseComponents/BaseRightDrawerContext';
import AddCompanyDrawer from 'components/common/addCompany/AddCompanyDrawer';
import AddWebsite from 'components/modal/modalTypes/addWebsite/AddWebsite';
import AddProduct from 'components/common/addProduct/AddProduct';
import BaseAddButton from 'components/baseComponents/BaseAddButton';
import { svg } from 'assets/image';
import { Link } from 'react-router-dom';
import { AddIcon } from 'components/svg';
import InviteUsers from 'views/user/components/InviteUsers';
import WalkthroughArrowWrapper from 'views/starterContainer/components/WalkthroughArrowWrapper/WalkthroughArrowWrapper';
import { WalkthroughContext } from 'state/walkthrough/walkthroughContext';
import { AppContext } from 'state/appContext';

export default function RightSideNavigation() {
  const { showDrawer } = useContext(BaseRightDrawerContext);
  const { userStats } = useContext(WalkthroughContext);
  const {
    state: { user },
  } = useContext(AppContext);

  return (
    <RightSideNavigationStyled>
      {user?.role && (
        <>
          <div>
            {['Organization Admin', 'Super Admin'].includes(user.role[0]) && (
              <WalkthroughArrowWrapper isActive={user?.walkthrough && !userStats?.companies}>
                <BaseAddButton
                  borderColor="#C4E1C8"
                  backgroundColor="#EDFFF0"
                  hoverBackgroundColor="#639B6B"
                  color="#639B6B"
                  hoverColor="#ffffff"
                  onClick={() => {
                    showDrawer({
                      _content: <AddCompanyDrawer />,
                      _title: 'New Company',
                      _className: 'new-company-drawer',
                    });
                  }}
                >
                  <AddIcon fill="#639B6B" stroke="#639B6B" />
                  Add Company
                </BaseAddButton>
              </WalkthroughArrowWrapper>
            )}

            <WalkthroughArrowWrapper
              isActive={user?.walkthrough && !userStats?.websites && userStats?.companies}
            >
              <BaseAddButton
                borderColor="#C7C3DC"
                backgroundColor="#F5F2FF"
                hoverBackgroundColor="#5F578C"
                color="#5F578C"
                hoverColor="#ffffff"
                onClick={() => {
                  showDrawer({
                    _content: <AddWebsite />,
                    _title: 'New Website',
                  });
                }}
              >
                <AddIcon fill="#5F578C" stroke="#5F578C" />
                Add Website
              </BaseAddButton>
            </WalkthroughArrowWrapper>

            <WalkthroughArrowWrapper
              isActive={user?.walkthrough && !userStats?.products && userStats?.websites}
            >
              <BaseAddButton
                borderColor="#DFCDC2"
                backgroundColor="#FFF3EC"
                hoverBackgroundColor="#957461"
                color="#957461"
                hoverColor="#ffffff"
                onClick={() => {
                  showDrawer({
                    _content: <AddProduct />,
                    _title: 'New Storyline',
                  });
                }}
              >
                <AddIcon fill="#957461" stroke="#957461" />
                Add Storyline
              </BaseAddButton>
            </WalkthroughArrowWrapper>
          </div>

          {['Organization Admin', 'Super Admin', 'Company Admin'].includes(user.role[0]) && (
            <div className="user-navigation-wrapper">
              <button>
                <Link to={'/user'}>
                  <img src={svg.userManagementIcon} alt="user management" />
                </Link>
              </button>

              <button
                onClick={() => {
                  showDrawer({
                    _content: <InviteUsers />,
                    _title: 'Invite Users',
                  });
                }}
              >
                <img src={svg.addUserIcon} alt="add user" />
              </button>
            </div>
          )}
        </>
      )}
    </RightSideNavigationStyled>
  );
}
