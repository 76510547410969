import { Row } from 'antd';
import styled from 'styled-components';

export const TableHeadStyled = styled(Row)`
  padding: 28px 0;
  margin: 0 30px;
  border-bottom: 1px solid rgba(128, 142, 155, 0.12);
  position: relative;
  .header__col {
    border-right: 2px solid #d9dde1;
    width: 16%;
    justify-content: center;
    &:first-of-type {
      width: 40%;
    }
    p {
      text-transform: capitalize;
      color: #181818;
      font-size: 1rem;
    }
  }
  @media only screen and (max-width: 680px) {
    .header__col.Impressions,
    .header__col.Clicks,
    .header__col.ClickRate {
      display: none;
    }
    .header__col {
      border-right: none;
      .text-wrapper {
        gap: 10px;
      }
      p {
        font-size: 16px;
      }
    }
  }
  @media only screen and (max-width: 1200px) {
    .header__col {
      width: 17%;
    }
  }
`;
