import styled from 'styled-components';

export const PurposeBoxStyled = styled.div`
  background-color: white;
  height: 100%;
  width: 100%;
  padding: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;

  p {
    width: 80% !important;
    text-align: center;
    margin-bottom: 50px;
  }

  span {
    font-size: 21px;
    text-align: center;
    @media only screen and (max-width: 768px) {
      text-align: start;
    }
    @media only screen and (min-width: 992px) and (max-width: 1599px) {
      font-size: 16px;
      width: calc(110% + 10px);
    }
  }

  span::before {
    content: '';
    display: block;
    background-color: #808e9b;
    border-radius: 20px;
    height: 2px;
    width: 20px;
    margin: 40px 0px;
    margin-left: auto;
    margin-right: auto;
    @media only screen and (max-width: 992px) {
      border-radius: 10px;
    }
  }

  @media (max-width: 992px) {
    flex-direction: row;
    padding: 20px;
    justify-content: flex-start;

    p {
      text-align: left;
    }

    span {
      display: flex;
      align-items: center;
      font-size: 16px;

      &::before {
        height: 20px;
        width: 2px;
        margin: 0px 40px;
      }
    }
  }

  @media (max-width: 768px) {
    img {
      width: 25px !important;
      height: 25px !important;
    }
  }

  @media only screen and (max-width: 576px) {
    span {
      font-size: 14px;
      &::before {
        height: 20px;
        width: 2px;
        margin: 0px 20px;
      }
    }
  }
`;
