import { Layout } from 'antd';
import styled from 'styled-components';

const { Header } = Layout;

export const HeaderStyled = styled(Header)`
  min-height: 4rem;
  height: 5vw;
  background-color: #ffffff;
  box-shadow: 0px 0px 12px #11348529;
  z-index: 1;
  padding: 0 1.25rem 0 1.875rem;
  .logo {
    img {
      width: 100%;
      height: 100%;
    }
  }

  .navbar-links {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .faq-link {
      color: #181818;
      margin-right: 15px;
      font-size: 19px;

      &:hover {
        color: #01ae92;
      }
    }
  }

  .header__left {
    .title {
      margin-bottom: 0;
    }
  }
  .mobile_menu {
    display: none;
    .anticon-menu,
    .anticon-close {
      color: ${({ theme: { colors } }) => colors.primaryColor};
      cursor: pointer;
      svg {
        height: 1.8rem;
        width: 1.8rem;
      }
    }
  }
  @media only screen and (max-width: 1200px) {
    .logo {
      height: 3.5rem;
    }
    .title {
      font-size: 2rem;
    }
    .user_account {
      display: none;
      line-height: normal !important;
      .ant-skeleton-element {
        line-height: normal !important;
      }
    }
    .mobile_menu {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
    }
  }
`;
