import React, { createContext, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

interface TypesBaseRightDrawerContext {
  readonly visible: boolean;
  readonly showDrawer: (args: {
    _content: React.ReactNode;
    _title: string;
    _titleColor?: string;
    _className?: string;
  }) => void;
  readonly onClose: () => void;
  readonly content: React.ReactNode;
  readonly title: string;
  readonly titleColor?: string;
  readonly setTitle: (title: string) => void;
  readonly className?: string;
}

interface Props {
  readonly children: React.ReactNode;
}

interface ShowDrawerProps {
  _content: React.ReactNode;
  _title: string;
  _titleColor?: string;
  _className?: string;
}

const BaseRightDrawerContext = createContext({} as TypesBaseRightDrawerContext);

const BaseRightDrawerProvider = ({ children }: Props) => {
  const [visible, setVisible] = useState(false);
  const [content, setContent] = useState<React.ReactNode | null>(null);
  const [title, setTitle] = useState('');
  const [titleColor, setTitleColor] = useState('black');
  const [className, setClassName] = useState<string>('');

  const location = useLocation();
  const showDrawer = ({ _content, _title, _titleColor, _className }: ShowDrawerProps) => {
    setVisible(true);
    setContent(_content);
    setTitle(_title);
    if (_titleColor) {
      setTitleColor(_titleColor);
    }
    if (_className) {
      setClassName(_className);
    }
  };
  const onClose = () => {
    setVisible(false);
    setContent(null);
    setTitle('');
    setTitleColor('black');
    setClassName('');
  };

  useEffect(() => {
    setVisible(false);
  }, [location]);

  return (
    <BaseRightDrawerContext.Provider
      value={{
        visible,
        showDrawer,
        onClose,
        content,
        title,
        titleColor,
        setTitle,
        className,
      }}
    >
      {children}
    </BaseRightDrawerContext.Provider>
  );
};

export { BaseRightDrawerContext, BaseRightDrawerProvider };
