import styled from 'styled-components';

export const UserAddFormStyled = styled.form`
  .content__form-container {
    padding-left: 3%;
    padding-right: 6%;
    .form-container__company-select {
      margin-bottom: 2.1rem;
    }
  }
  .scrollable {
    max-height: 30vh;
    .unreal-fields-row {
      pointer-events: none;
      cursor: pointer;
    }
  }

  .content__submit-container {
    padding-left: 6%;
    padding-right: 6%;
    padding-top: 1rem;
    flex-wrap: nowrap;
    .skip-step {
      p {
        text-transform: uppercase;
        opacity: 0.5;
        transition: all 0.2s;
        &:hover {
          opacity: 1;
        }
      }
    }
    .invite-user__bulk-upload {
      p {
        text-transform: uppercase;
        margin-left: 1rem;
      }
    }

    .btn {
      height: 4rem;
      border-radius: 2rem;
      width: 19%;
      min-width: 160px;
    }
  }
  @media only screen and (max-width: 1370px) {
    .content__form-container {
      .form-container__company-select {
        margin-bottom: 1.3rem;
      }
    }
    .scrollable {
      max-height: 30vh;
    }

    .content__submit-container {
      padding-top: 0.7rem;
      flex-wrap: nowrap;
      }
      .invite-user__bulk-upload {
      }

      .btn {
        height: 4rem;
        border-radius: 2rem;
        width: 19%;
        min-width: 160px;
      }
    }
  }
`;
