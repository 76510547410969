import React from 'react';
import { useHistory } from 'react-router-dom';
import { StorylineStyled, StorylineEmptyStyled } from './Storyline.styled';
import { routesPaths } from 'utils/helpers';
import { ReactComponent as ArrowMedium } from 'assets/image/svg/arrow-medium.svg';

interface StorylineProps {
  readonly logo: string;
  readonly slug: string;
  readonly name: string;
  readonly activeStories: number;
  readonly impressions: number;
  readonly clicks: number;
  readonly customStyle?: React.CSSProperties;
}

export const StorylineEmpty = () => {
  return (
    <StorylineEmptyStyled>
      <p>Empty Slot</p>
    </StorylineEmptyStyled>
  );
};

export default function Storyline({
  logo,
  slug,
  name,
  activeStories,
  impressions,
  clicks,
  customStyle,
}: StorylineProps) {
  const history = useHistory();
  return (
    <StorylineStyled
      onClick={() => {
        history.push(routesPaths.toCompanyOrWebsiteStatisticBySlug(slug));
      }}
      style={customStyle}
    >
      <div className="storyline-header">
        <img
          width={'43px'}
          height={'43px'}
          className="storyline-logo"
          src={logo}
          alt="company-logo"
        />
        <h3 className="storyline-title">{name}</h3>
      </div>
      <div className="storyline-body">
        <div className="storyline-stats">
          <div>
            <p>Active stories:</p>
            <span>{activeStories}</span>
          </div>
          <div>
            <p>Impressions:</p>
            <span>{impressions}</span>
          </div>
          <div>
            <p style={{ marginBottom: '0px' }}>Clicks:</p>
            <span>{clicks}</span>
          </div>
        </div>
      </div>
      <div className="arrow-button">
        <ArrowMedium />
      </div>
    </StorylineStyled>
  );
}
