import styled from 'styled-components';

export const WebsiteAddFormStyled = styled.form`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .website-add-form__bulk-upload {
    background-color: transparent;
    p {
      text-decoration: underline;
    }
  }
  /* .content__form-container {
    padding-left: 3%;
    padding-right: 6%;
    .form-container__company-select {
      margin-bottom: 2.1rem;
    }
  }
  .scrollable {
    max-height: 30vh;
    .unreal-fields-row {
      pointer-events: none;
      cursor: pointer;
    }
  }

  .content__submit-container {
    padding-left: 6%;
    padding-right: 6%;
    padding-top: 1rem;
    flex-wrap: nowrap;
    .skip-step {
      p {
        text-transform: uppercase;
        opacity: 0.5;
        transition: all 0.2s;
        &:hover {
          opacity: 1;
        }
      }
    }
    .invite-user__bulk-upload {
      p {
        text-transform: uppercase;
        margin-left: 1rem;
      }
    }

    .btn {
      height: 4rem;
      border-radius: 2rem;
      width: 19%;
      min-width: 160px;
    }
  }
  @media only screen and (max-width: 1370px) {
    .content__form-container {
      .form-container__company-select {
        margin-bottom: 1.3rem;
      }
    }
    .scrollable {
      max-height: 30vh;
    }

    .content__submit-container {
      padding-top: 0.7rem;
      flex-wrap: nowrap;
      }
      .invite-user__bulk-upload {
      }

      .btn {
        height: 4rem;
        border-radius: 2rem;
        width: 19%;
        min-width: 160px;
      }
    }
    .mobile_text{
      display:none;
    }
    @media only screen and (max-width:680px){
     .content__form-container{
       padding: 0 1.5rem;
       .mobile_text{
         display: block;
       }
       .ant-row{
         flex-direction: column;
         .ant-col{
           width: 100%;
           min-width: 100%;
           margin: 0.5rem 0rem;
            padding: 0;
         }
         .ant-col.ant-col-1{
           svg{
              display: none;
            }
         }
       }
     }
     .content__submit-container{
       flex-direction: column;
       .invite-user__bulk-upload{
         margin-bottom: 1rem;
       }
       .skip-step{
         display: none;
       }
     }
    }
  } */
`;
