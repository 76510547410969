import { Col } from 'antd';
import styled from 'styled-components';

export const UserDetailsStyled = styled(Col)`
  .config-form {
    h3 {
      font-family: 'Noir Pro Bold';
      font-size: 21px;
    }

    .input-container:not(:last-child) {
      margin-bottom: 30px;
    }

    .ant-divider {
      border-top: 1px solid #eaecf2;
    }

    .user-image-feedback {
      font-family: 'Noir Pro Light';
      font-size: 16px;
      color: #808e9b;
    }

    .user-image-container {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      background-color: #e8edf8;
      border-radius: 10px;
      padding: 10px;
      height: fit-content;
    }

    .user-image-box {
      height: 100%;
      display: flex;
      img {
        height: 100%;
        border-radius: 10px;
        cursor: pointer;
      }
    }
    .responsive_style {
      display: none;
    }
    .responsive_content {
      display: none;
    }

    .user-details {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      padding: 0px 15px;
    }

    .resend-invite-btn {
      width: 162px;
      height: 40px !important;
      font-size: 16px;
      font-family: 'Noir Pro Regular';
      svg {
        right: 11px !important;
        top: 32% !important;
        transform: scale(0.7) !important;
      }
    }

    .resend-feedback {
      font-size: 13px;
      font-family: 'Noir Pro Light';
      margin-top: 15px;
    }

    // Permissions Styles
    .company-box {
      background-color: white;

      border-radius: 10px;
      border: 1px solid #8085da33;
      margin-bottom: 30px;
    }

    .role-checkbox {
      display: flex;
      justify-content: center;

      span {
        font-size: 14px;
        font-family: 'Noir Pro Regular';
      }
    }

    .ant-collapse-header {
      padding: 15px;
      padding-right: 45px;
      color: #808e9b;
      font-size: 18px;
      font-family: 'Noir Pro Regular';
      display: flex;
    }

    .ant-collapse-content {
      padding: 15px;
      padding-top: 0px;

      .ant-collapse-content-box {
        padding: 0;
        padding-top: 15px;
        width: 100%;
        border-top: 1px solid #f2f3f5;

        .ant-row {
          justify-content: space-between;

          &:not(:last-child) {
            margin-bottom: 30px;
          }

          .website-box {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 10px;

            img {
              margin-top: -10px;
            }
          }

          .ant-switch-checked {
            background-color: #4b4dc8;
          }
        }
      }
    }

    .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-extra {
      float: unset;
      margin-left: auto;
      display: flex;
      align-items: center;
    }

    .ant-radio-checked .ant-radio-inner {
      border-color: #4b4dc8 !important ;
    }

    .ant-radio-checked .ant-radio-inner:after {
      background-color: #4b4dc8;
    }

    .ant-radio:hover .ant-radio-inner {
      border-color: #4b4dc8;
    }

    .arrow-div {
      cursor: pointer;
      min-width: 30px;
      height: 30px;
      width: 30px;
      border-radius: 4px;
      border: 1px solid #9fa2af4d;
      display: flex !important;
      align-items: center;
      justify-content: center;
    }

    .submit-container {
      display: flex;
      justify-content: space-between;
    }
  }

  @media (max-width: 1025px) {
    .submit-btn {
      height: 40px;
      width: 196px;

      svg {
        top: 30%;
      }
    }
  }

  @media only screen and (min-width: 1000px) and (max-width: 1020px) {
    .btn_wrapper {
      &::before {
        content: unset !important;
      }
    }
  }

  @media (max-width: 768px) {
    .btn_wrapper {
      max-width: unset !important;
    }

    h1 {
      font-size: 21px;
    }

    h3 {
      font-size: 16px;
    }

    .user-image-container {
      flex-direction: column;
    }

    .user-image-box {
      margin-bottom: 20px;
    }

    .user-image-feedback {
      font-size: 14px;
    }

    .submit-btn {
      width: 156px;
    }
    .resend-invite-btn {
      width: 100% !important;
    }
    .user-details {
      display: none !important;
    }
    .avatarWrapper {
      display: flex;
      align-items: center;
    }
    .responsive_style {
      display: flex !important;
      h3 {
        padding: 0 15px;
      }
    }
    .responsive_content {
      display: block !important;
      padding: 0 0 20px;
    }
    .userContainerWrapper {
      display: flex;
      justify-content: center;
      background-color: #e8edf8;
      border-radius: 10px;
      padding: 10px;
      .user-image-container {
        align-items: flex-start;
      }
    }
    .user-image-box {
      display: block !important;
    }
  }

  @media (max-width: 576px) {
    h1 {
      font-size: 18px;
    }

    h3 {
      font-size: 14px;
    }

    .user-image-feedback {
      font-size: 12px;
    }

    .ant-collapse-header.ant-collapse-header {
      flex-direction: column;
      padding-right: 15px;

      .arrow-div {
        top: 30px;
      }
      .ant-collapse-extra {
        margin-top: 20px;
        padding-top: 15px;
        border-top: 1px solid #f2f3f5;
        margin-left: 0 !important;
      }
    }

    .ant-collapse-item:not(.ant-collapse-item-active) {
      .ant-collapse-extra {
        display: none !important;
      }
    }

    .submit-container {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 20px;
    }
  }

  @media screen and (min-width: 770px) {
    .btn_wrapper {
      position: relative;
      &:before {
        content: '';
        height: 100%;
        width: 1px;
        background-color: #808e9b1f;
        position: absolute;
        left: -30px;
        top: 0;
      }
    }
  }
`;
