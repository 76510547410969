import styled from 'styled-components';

export const StarterContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  .skipped-container {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
