import { Row } from 'antd';
import styled from 'styled-components';

export const TopTitleAndSelectorStyled = styled(Row)`
  padding-left: 3%;
  padding-right: 6%;

  h2 {
    padding-bottom: 2rem;
  }
  .user-count-container {
    p {
      padding-top: 1.2rem;
      text-align: right;
    }
  }

  @media only screen and (max-width: 1370px) {
    h2 {
      padding-bottom: 1.5rem;
    }
    .user-count-container {
      p {
        padding-top: 1rem;
        text-align: right;
      }
    }
  }
`;
