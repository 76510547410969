import React from 'react';
import { lazyLoadable } from 'utils';
import { SetupPage } from 'components/skeletons';

export const SlideConfigPageLazy = lazyLoadable(
  () => import('./SlideConfigPage'),
  <>
    <SetupPage />
  </>,
);
