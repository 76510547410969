import { DynamicFieldsEnum } from 'utils/enums';

export const addCompanyForms: FormSectionWrapperType[] = [
  {
    id: 314,
    title: 'Entity',
    fields: [
      {
        id: 2432,
        type: DynamicFieldsEnum.Input,
        value: '',
        name: 'name',
        label: 'Company name ',
      },
    ],
  },
  {
    id: 34,
    title: 'Address',
    fields: [
      {
        id: 2432,
        type: DynamicFieldsEnum.Input,
        value: '',
        name: 'address',
        label: 'Company adress',
      },
    ],
  },
  {
    id: 123,
    title: 'Country',
    fields: [
      {
        id: 2,
        type: DynamicFieldsEnum.Select,
        value: '',
        name: 'country_iso',
        label: 'Country',
        itemsArray: [],
      },
    ],
  },
  {
    id: 1233,
    title: 'Region',
    fields: [
      {
        id: 243,
        type: DynamicFieldsEnum.Input,
        value: '',
        name: 'region',
        label: 'Region',
      },
    ],
  },
  {
    id: 14,
    title: 'City',
    fields: [
      {
        id: 2432,
        type: DynamicFieldsEnum.Input,
        value: '',
        name: 'city',
        label: 'City',
      },
    ],
  },

  {
    id: 140,
    title: 'Postal code',
    fields: [
      {
        id: 3,
        type: DynamicFieldsEnum.Input,
        value: '',
        name: 'postal_code',
        label: 'Postal code',
      },
    ],
  },
  {
    id: 123123123,
    title: 'Logo',
    fields: [
      {
        id: 1111222,
        type: DynamicFieldsEnum.HiddenInput,
        value: '',
        name: 'logo',
      },
    ],
  },
];
