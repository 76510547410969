import React from 'react';
import { Collapse } from 'antd';
import { svg } from 'assets/image';
import { FaqStyled } from './Faq.styled';
import { Text } from 'components/styles';
import FaqData from './FaqData';

export default function Faq() {
  const { Panel } = Collapse;

  return (
    <FaqStyled>
      <Collapse
        // defaultActiveKey={['1']}
        expandIcon={(props) => {
          return props.isActive ? <img src={svg.faqMinus} /> : <img src={svg.faqPlus} />;
        }}
        expandIconPosition={'right'}
      >
        {FaqData.map((item, index) => (
          <Panel header={item.question} key={index + 1}>
            <Text>{item.answer}</Text>
          </Panel>
        ))}
      </Collapse>
    </FaqStyled>
  );
}
