import React from 'react';
import { LoaderLineStyled } from './LoaderLine.styled';

type LoaderLinePropsType = {
  loading: boolean;
};
const LoaderLine = ({ loading }: LoaderLinePropsType) => {
  return (
    <LoaderLineStyled className={'line-loader-container'}>
      <div className={`line-loader ${loading ? 'active' : ''}`} />
    </LoaderLineStyled>
  );
};

export default LoaderLine;
