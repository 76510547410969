import React from 'react';
import { WalkthroughArrowWrapperStyled } from './WalkthroughArrowWrapper.styled';

interface IProps {
  readonly position?: string;
  readonly children: JSX.Element;
  readonly isActive?: boolean;
}

const WalkthroughArrowWrapper = ({ position = 'right', children, isActive = false }: IProps) => {
  return (
    <WalkthroughArrowWrapperStyled isActive={isActive} position={position}>
      {children}
    </WalkthroughArrowWrapperStyled>
  );
};

export default WalkthroughArrowWrapper;
