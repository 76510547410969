import React from 'react';
import { Typography } from 'antd';
import { BaseCopyableStyled } from './BaseCopyable.styled';
import { ReactComponent as CopyIcon } from 'assets/image/svg/btn-copy.svg';

const { Paragraph } = Typography;

const BaseCopyable = ({ value }: { value?: string }) => {
  return (
    <BaseCopyableStyled>
      <Paragraph
        copyable={{
          icon: <CopyIcon />,
          text: value,
          tooltips: ['', 'Copied!'],
        }}
      >
        {value}
      </Paragraph>
    </BaseCopyableStyled>
  );
};

export default BaseCopyable;
