import { useCallback, useMemo, useState } from 'react';

interface IDropdown {
  readonly isSelected: readonly string[];
  readonly toggleDropdown: (id: string) => void;
}

const useDropdown = (): IDropdown => {
  const [isSelected, setIsSelected] = useState<string[]>([]);

  const toggleDropdown = useCallback(
    (id: string) => {
      if (isSelected.includes(id)) {
        setIsSelected(isSelected.filter((el) => el !== id));
      } else {
        setIsSelected([id, ...isSelected]);
      }
    },
    [isSelected],
  );

  const value = useMemo(() => ({ isSelected, toggleDropdown }), [isSelected, toggleDropdown]);

  return value;
};

export default useDropdown;
