import {
  IStatisticPageCompanyContext,
  StatisticPageCompanyContextProps,
} from 'api/statisticPage/company/types';
import moment from 'moment';
import React, { createContext, useState } from 'react';

const StatisticPageCompanyContext = createContext({} as IStatisticPageCompanyContext);

const StatisticPageCompanyProvider = (props: StatisticPageCompanyContextProps) => {
  // State
  const [company, setCompany] = useState<IStatisticPageCompanyContext['company']>({
    isLoading: true,
  });
  const [range, setRange] = useState<IStatisticPageCompanyContext['range']>({
    range: {
      start: moment(new Date()).subtract('days', 7).format('YYYY-MM-DD'),
      end: moment(new Date()).format('YYYY-MM-DD'),
    },
  });

  const { children } = props;

  return (
    <StatisticPageCompanyContext.Provider
      value={{
        range,
        setRange,
        company,
        setCompany,
      }}
    >
      {children}
    </StatisticPageCompanyContext.Provider>
  );
};

export { StatisticPageCompanyContext, StatisticPageCompanyProvider };
