import { Button, Col, Row } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { SettingFilled } from '@ant-design/icons';
import { ProductItemStyled } from './ProductItem.styled';
import { ReactComponent as Device } from 'assets/image/svg/external-link.svg';
import { ReactComponent as Delete } from 'assets/image/svg/delete.svg';
import { ReactComponent as StoryLineIcon } from 'assets/image/svg/storyline-icon.svg';
import { Text } from 'components/styles';
import { ThemeContext } from 'styled-components';
import { Link, useHistory } from 'react-router-dom';
import { routesPaths } from 'utils/helpers';
import SortIcon from 'assets/image/svg/sort-statistic.svg';
import { ModalTypeEnum } from '../../../../../components/modal/ModalCustom';
import { ModalCustom } from '../../../../../components/modal';
import { DeleteIcon, MinusIcon, PlusIcon, SettingsIcon } from 'components/svg';
import { BaseRightDrawerContext } from 'state/baseComponents/BaseRightDrawerContext';
import { trigger } from 'swr';
import { endpoints } from 'services';

const ProductItem = ({
  name,
  bottomTitle,
  companySlug,
  slug,
  websiteSlug,
  type,
  clickRatePercentage,
  totalClicks,
  totalImpressions,
  isSelected,
  toggleDropdown,
}: StatisticBottomListItemType) => {
  const history = useHistory();
  const { fontFamilies } = useContext(ThemeContext);
  const [modalType, setModalType] = useState(ModalTypeEnum.remove);
  const [modalVisible, setModalVisible] = useState(false);
  const { showDrawer, visible: drawerVisible, onClose } = useContext(BaseRightDrawerContext);

  const toggleModal = (type: ModalTypeEnum) => {
    setModalType(type);
    setModalVisible(!modalVisible);
  };

  const redirectGoToLink = () => {
    if (type === 'Website') {
      // trigger(endpoints.getSlides(websiteSlug!, slug!));
      // history.push(routesPaths.toProductSlidesListById(companySlug, websiteSlug, slug));
      // return;
      return routesPaths.toProductSlidesListById(companySlug, websiteSlug, slug);
    }
    return routesPaths.toCompanyOrWebsiteStatisticBySlug(companySlug, slug);
  };

  useEffect(() => {
    if (!drawerVisible) {
      setModalVisible(false);
    }
  }, [drawerVisible]);

  useEffect(() => {
    if (!modalVisible) return;
    showDrawer({
      _content: (
        <ModalCustom
          visible={modalVisible}
          onCancel={toggleModal.bind(ProductItem, modalType)}
          width={modalType === ModalTypeEnum.addWebsite ? '80%' : '40%'}
          centered={true}
          type={modalType}
          websiteSlug={ModalTypeEnum.editWebsite ? slug : ''}
          companySlug={ModalTypeEnum.editWebsite ? companySlug : ''}
          data={{
            onClose: () => {
              toggleModal.call(ProductItem, modalType);
              onClose();
            },
            type: type === 'Website' ? 'Product' : 'Website',
            slug,
            websiteSlug,
            companySlug,
          }}
        />
      ),
      _title:
        modalType === ModalTypeEnum.remove
          ? slug === 'story-creator'
            ? 'Remove Storyline'
            : `Remove Website`
          : 'Edit Details',
      _titleColor: modalType === ModalTypeEnum.remove ? '#EE5253' : 'black',
    });
  }, [modalVisible]);

  const redirectEditLink = () => {
    if (type === 'Website') {
      return routesPaths.toProductSlidesSetupById(companySlug, websiteSlug!, slug!);
    }
    return routesPaths.toWebsiteEdit(companySlug, slug!);
  };

  return (
    <ProductItemStyled align={'middle'}>
      <Row align={'middle'} className={'item'}>
        <Col lg={1} md={1} sm={2} className="device_icon">
          <div className="device_wrapper">
            {!websiteSlug ? (
              <Button icon={<Device />} type={'ghost'} />
            ) : (
              <Button icon={<StoryLineIcon />} type={'ghost'} />
            )}
            <Link to={redirectGoToLink()} className="mobile_container__title">
              <Text color={'#000'} style={{ color: 'red' }} fontFamily={fontFamilies.regular} hover>
                {name}
              </Text>
            </Link>
          </div>
          <div className={'device_icons_wrapper'}>
            <Button
              icon={<Delete />}
              type={'ghost'}
              onClick={toggleModal.bind(ProductItem, ModalTypeEnum.remove)}
            />
            <div className="vertical_line"></div>
            <Link to={redirectEditLink()}>
              <Button icon={<SettingFilled />} type={'ghost'} />
            </Link>
          </div>
        </Col>
        <Col md={9} sm={8} className={'container__title'}>
          <Link to={redirectGoToLink()}>
            <Text
              color={'#000'}
              style={{ marginTop: '3px' }}
              fontFamily={fontFamilies.regular}
              hover
            >
              {name}
            </Text>
          </Link>
          <Text color={'#7D8CA2'} fontFamily={fontFamilies.regular}>
            {bottomTitle}
          </Text>
        </Col>
        <Col className={'container__impressions'}>
          <Text className="mobile_stats">
            IMPRESSIONS
            <img src={SortIcon} alt="sort icon svg" />
          </Text>
          <Text color={'#000'} fontFamily={fontFamilies.regular}>
            {totalImpressions}
          </Text>
        </Col>
        <Col className={'container__clicks'}>
          <Text className="mobile_stats">
            CLICKS
            <img src={SortIcon} alt="sort icon svg" />
          </Text>
          <Text color={'#000'} fontFamily={fontFamilies.regular}>
            {totalClicks}
          </Text>
        </Col>
        <Col className={'container__click_rate'}>
          <Text className="mobile_stats">
            CLICK RATE
            <img src={SortIcon} alt="sort icon svg" />
          </Text>
          <Text color={'#000'} fontFamily={fontFamilies.regular} className={'duration__title'}>
            {`${clickRatePercentage}%`}
          </Text>
        </Col>
        <Col className={'container__tools'}>
          <Row align={'middle'} justify={'center'} className={'tools__row'}>
            <Button
              icon={<DeleteIcon />}
              type={'ghost'}
              className={'tools__delete flex-center'}
              onClick={toggleModal.bind(ProductItem, ModalTypeEnum.remove)}
            />
            <Button
              icon={<SettingsIcon />}
              type={'ghost'}
              onClick={toggleModal.bind(
                ProductItem,
                websiteSlug ? ModalTypeEnum.editStoryline : ModalTypeEnum.editWebsite,
              )}
              className={'tools__setting flex-center'}
            />
          </Row>
        </Col>
      </Row>
      <div
        className="mobile-container"
        style={
          isSelected.includes(companySlug + name) ? { background: 'rgba(75,77,200, .05)' } : {}
        }
      >
        <div
          className="mobile-container__title"
          style={isSelected.includes(companySlug + name) ? { paddingBottom: '20px' } : {}}
        >
          {!websiteSlug ? (
            <Button icon={<Device />} type={'ghost'} />
          ) : (
            <Button icon={<StoryLineIcon />} type={'ghost'} />
          )}
          <Text
            color={'#000'}
            fontFamily={fontFamilies.regular}
            onClick={() => history.push(redirectGoToLink())}
          >
            {name}
          </Text>
          {!isSelected.includes(companySlug + name) ? (
            <PlusIcon
              onClick={() => toggleDropdown(companySlug + name)}
              style={{ marginLeft: 'auto' }}
            />
          ) : (
            <MinusIcon
              onClick={() => toggleDropdown(companySlug + name)}
              style={{ marginLeft: 'auto' }}
            />
          )}
        </div>
        <div
          className={`mobile-container__dropdown ${
            isSelected.includes(companySlug + name) ? 'show' : ''
          }`}
        >
          <div className="dropdown__stats">
            <div>
              <Text color={'#000'} fontFamily={fontFamilies.regular}>
                Impressions
              </Text>
              <Text color={'#000'} fontFamily={fontFamilies.regular}>
                {totalImpressions}
              </Text>
            </div>
            <div>
              <Text color={'#000'} fontFamily={fontFamilies.regular}>
                Clicks
              </Text>
              <Text color={'#000'} fontFamily={fontFamilies.regular}>
                {totalClicks}
              </Text>
            </div>
            <div>
              <Text color={'#000'} fontFamily={fontFamilies.regular}>
                Click Rate
              </Text>
              <Text color={'#000'} fontFamily={fontFamilies.regular}>
                {clickRatePercentage}%
              </Text>
            </div>
          </div>
          <div className={'dropdown__tools'}>
            <Button
              icon={<DeleteIcon fill="#4B4DC8" />}
              type={'ghost'}
              className={'tools__delete flex-center'}
              onClick={toggleModal.bind(ProductItem, ModalTypeEnum.remove)}
            />
            <Button
              icon={<SettingsIcon fill="#4B4DC8" />}
              type={'ghost'}
              onClick={toggleModal.bind(
                ProductItem,
                websiteSlug ? ModalTypeEnum.editStoryline : ModalTypeEnum.editWebsite,
              )}
              className={'tools__setting flex-center'}
            />
          </div>
        </div>
      </div>
      {/* <ModalCustom
        visible={modalVisible}
        onCancel={toggleModal.bind(ProductItem, modalType)}
        width={modalType === ModalTypeEnum.addWebsite ? '80%' : '40%'}
        centered={true}
        type={modalType}
        data={{
          onClose: () => {
            toggleModal.call(ProductItem, modalType);
          },
          type: type === 'Website' ? 'Product' : 'Website',
          slug,
          websiteSlug,
          companySlug,
        }}
      /> */}
    </ProductItemStyled>
  );
};

export default ProductItem;
