import styled from 'styled-components';
import images from 'assets/image';
import { svg } from 'assets/image';

export const FaqContainerStyled = styled.div`
  background-color: white;

  .faq_navbar {
    margin-bottom: 0;
  }

  .faq-banner {
    position: relative;
    padding: 40px 70px;
    background-image: url(${images.cloudDesktop});
    background-position: center center;
    background-size: cover;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 60px;
    background-repeat: no-repeat;
    margin-bottom: 30px;
    transform-style: preserve-3d;

    &::before {
      content: url(${svg.plus2});
      transform: scale(0.7) translateZ(-1px);
      position: absolute;
      left: -40px;
      top: 20%;
    }

    p,
    button {
      font-size: 21px;
      font-weight: bold;
    }
  }

  .faq_container {
    padding-left: 70px;
    padding-right: 70px;
    position: relative;

    &::after {
      content: url(${images.greenRectangle});
      transform: scale(0.5);
      position: absolute;
      right: 10%;
      bottom: -3%;
    }
  }

  h1,
  h2 {
    width: 100%;
    text-align: left;
    margin-bottom: 30px;
    span {
      color: #01ae92;
      font-weight: bold;
    }
  }

  .contact-us-box {
    padding-bottom: 20px;
    p {
      padding-top: 25px;
      border-top: 1px solid #e1e1f1;
    }

    div {
      margin-left: 10px;
    }

    span {
      display: block;
      margin-top: 15px;
      font-family: 'Noir Pro Regular';
      color: #787d88;
    }
  }

  /* .faq-video {
    video {
      width: 100%;
      border-radius: 10px;
    }

    p {

      padding-top: 25px;
      border-top: 1px solid #e1e1f1;
    }
  } */

  @media (max-width: 768px) {
    .faq-banner {
      padding: 40px 20px;

      p {
        font-size: 14px;
      }

      button {
        font-size: 14px;
      }
    }

    .contact-us-box {
      img {
        height: 40px;
        width: 40px;
      }
    }

    .faq_container {
      padding-left: 20px;
      padding-right: 20px;
    }

    .faq-banner {
      &::before {
        transform: scale(0.5) translateZ(-1px);
      }
    }

    .faq_container {
      &::after {
        display: none;
      }
    }
  }
`;
