import { Col } from 'antd';
import TextSkeleton from './TextSkeleton';
import React from 'react';
import { EmbedPageStyled } from './EmbedPage.styled';

const EmbedPage = () => {
  return (
    <EmbedPageStyled align={'middle'}>
      <Col offset={0} span={3}>
        <TextSkeleton width={'100%'} />
      </Col>
      <Col span={4} offset={2}>
        <TextSkeleton width={'100%'} />
      </Col>
      <Col offset={2} span={13}>
        <TextSkeleton width={'100%'} />
      </Col>
    </EmbedPageStyled>
  );
};

export default EmbedPage;
