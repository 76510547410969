import React, { useContext } from 'react';
import { Text } from 'components/styles';
import { BaseNoticeStyled } from './BaseNotice.styled';
import { ThemeContext } from 'styled-components';

export default function BaseNotice() {
  const { fontFamilies } = useContext(ThemeContext);

  return (
    <BaseNoticeStyled>
      <Text color={'#181818'} fontSize={'16px'} fontFamily={fontFamilies.light}>
        Prior to publishing the adverts, please ensure that the <strong>ads.txt</strong> file is
        available in the root folder of your website. In case you are unable to download the{' '}
        <strong>ads.txt</strong> file, please turn off any ad blocker extension that is currently in
        use.
      </Text>
    </BaseNoticeStyled>
  );
}
