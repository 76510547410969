import React, { useState, useContext } from 'react';

import { InviteUsersStyled } from '../components/InviteUsers.styled';
//import TopTitleAndSelector from '../components/TopTitleAndSelector';
import UserAddForm from '../components/UserAddForm';
import BulkUserAdd from '../components/BulkUserAdd';
import { BaseRightDrawerContext } from 'state/baseComponents/BaseRightDrawerContext';

type UserAddPropsType = {
  fromModal?: boolean;
  onClose?: () => void;
};

const InviteUsers = () => {
  const [bulkAction, setBulkAction] = useState(false);
  const [usersCount, setUsersCount] = useState(0);
  const [CSVData, setCSVData] = useState([]);
  const { onClose: drawerClose } = useContext(BaseRightDrawerContext);

  const onUploadMethodSwith = () => {
    setBulkAction(!bulkAction);
  };

  return (
    <InviteUsersStyled>
      {bulkAction ? (
        <BulkUserAdd onUploadMethodSwith={onUploadMethodSwith} setCSVData={setCSVData} />
      ) : (
        <UserAddForm
          onUploadMethodSwith={onUploadMethodSwith}
          setUsersCount={setUsersCount}
          fromModal={true}
          onClose={drawerClose}
          CSVData={CSVData}
        />
      )}
    </InviteUsersStyled>
  );
};

export default InviteUsers;
