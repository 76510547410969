import React from 'react';
import { Text } from 'components/styles';
import { Row, Col } from 'antd';
import { AddIcon } from 'components/svg';
import BaseSubmitButton from 'components/baseComponents/BaseSubmitButton';
import { AddCompanyDrawerStyled } from './AddCompanyDrawer.styles';
import { FormFieldsBuilder } from 'components';
import useAddCompanyComponent from './useAddCompanyDrawer';
import { FormPageTypeEnum } from 'utils/enums';

const AddCompanyComponent = () => {
  const {
    onSubmit,
    handleSubmit,
    control,
    errors,
    fontFamilies,
    watch,
    setValue,
    fieldData,
    handleLogoChange,
    tmpLogoUrl,
    tmpLogoFile,
  } = useAddCompanyComponent();

  return (
    <AddCompanyDrawerStyled onSubmit={handleSubmit(onSubmit)}>
      <Row>
        <Col span={24} className={'content__form-container'}>
          <FormFieldsBuilder<AddCompanyFormType>
            fieldsData={fieldData}
            control={control}
            setValue={setValue}
            watch={watch}
            errors={errors}
            page={FormPageTypeEnum.addCompany}
          />
        </Col>
        <Col span={24}>
          <label className="file-upload-box">
            {tmpLogoFile ? (
              <img src={tmpLogoUrl} alt="" />
            ) : (
              <AddIcon className="add-icon" fill={'#a3acb7'} stroke={'#a3acb7'} />
            )}
            <Text
              color="#808E9B"
              fontSize={16}
              style={{ marginTop: '15px' }}
              fontFamily={fontFamilies.light}
            >
              Please upload png/svg company logo
            </Text>
            {tmpLogoFile ? (
              <Text color="#808E9B" fontSize={21} fontFamily={fontFamilies.bold}>
                Change logotype
              </Text>
            ) : (
              <Text color="#808E9B" fontSize={21} fontFamily={fontFamilies.bold}>
                Add logotype
              </Text>
            )}
            <Text
              color="#808E9B"
              fontSize={14}
              style={{ marginTop: '15px' }}
              fontFamily={fontFamilies.regular}
            >
              <span style={{ color: '#EE5253' }}>*</span> Optimal logo size 42x42px
            </Text>
            <input
              type="file"
              style={{ display: 'none' }}
              onChange={handleLogoChange}
              accept=".jpg, .png, .jpeg"
            />
          </label>
        </Col>
      </Row>

      <Row align={'middle'} justify={'space-between'} className={'content__submit-container'}>
        <BaseSubmitButton
          title={'Add Company'}
          className={'btn--fill'}
          backgroundColor={'#4B4DC8'}
          style={{ marginTop: '12px' }}
          withArrow
        />
      </Row>
    </AddCompanyDrawerStyled>
  );
};

export default AddCompanyComponent;
