import React from 'react';
import { Row, Col, Divider } from 'antd';
import { TableHeadEnum } from '../useUsersPage';
import { TableHeadStyled } from './TableHead.styled';
import TableHeadSortableItem from './TableHeadSortableItem';
import TableHeadSettingsItem from './TableHeadSettingsItem';
import { Text } from 'components/styles';

type TableHeadPropsType = {
  activeHead: TableHeadEnum;
  sortingOrderIsAsc: boolean;
  onSelectSort: (sort: TableHeadEnum) => void;
};

const heads = [
  {
    text: TableHeadEnum.UserName,
    type: TableHeadEnum.UserName,
  },
  {
    text: TableHeadEnum.Company,
    type: TableHeadEnum.Company,
  },
  // {
  //   text: TableHeadEnum.Role,
  //   type: TableHeadEnum.Role,
  // },
  {
    text: TableHeadEnum.Settings,
    type: TableHeadEnum.Settings,
  },
];

const TableHead = ({ activeHead, onSelectSort, sortingOrderIsAsc }: TableHeadPropsType) => {
  return (
    <TableHeadStyled align={'middle'}>
      {heads.map(({ type, ...rest }, index) =>
        type === TableHeadEnum.Settings ? (
          <TableHeadSettingsItem
            key={index}
            index={index}
            arrayLength={heads.length}
            {...rest}
            className={'header__col'}
          />
        ) : (
          <TableHeadSortableItem
            key={index}
            index={index}
            arrayLength={heads.length}
            {...rest}
            className={'header__col'}
            onSelect={onSelectSort.bind(TableHead, type)}
            $active={type === activeHead}
            $sortingOrderIsAsc={sortingOrderIsAsc}
          />
        ),
      )}
      {/* <Col lg={3} md={3} sm={3} xs={3}></Col> */}
    </TableHeadStyled>
  );
};

export default TableHead;
