import React from 'react';
import { Link } from 'react-router-dom';
import images from 'assets/image';

export default [
  {
    question: 'What is Digital Story Hub? ',
    answer: (
      <>
        {`Digital Story Hub allows you to easily manage, analyze and monitor multiple websites from a single portal, while simultaneously creating engaging storytelling content through its main product Story Creator. The function of Story Creator is to be used for the configuration of customizable stories (slides) as a client-based promotions service. Story Creator provides a visually rich web-based version of the popular "Stories" format that blends video, images, animation, and text to create a dynamic visual experience. This format lets you explore content at your own pace by tapping through or swiping from one piece of content to the next.`}
      </>
    ),
  },
  {
    question: 'Why should your brand use Digital Story Hub? ',
    answer: (
      <>
        {`Nowadays, brands are compelled to use Stories as a modern tool of communication. The ability to swiftly inspire viewers to act is conveniently accommodated by the visually-appealing elements of the Stories. To effectively attract users, a true storyteller must know how to enhance a story, which is easily achievable through Digital Story Hub. Almost every brand has a story to tell, and Digital Story Hub makes sharing with your audience and followers easily accessible. By sharing additional insights, many brands expose their customers deliberately to their product, to build a credible and trustworthy community. Digital Story Hub could be used for sharing a variety of different stories – from the preparations around the launch of a new product to behind-the-scenes and community-related coverage. 
`}
      </>
    ),
  },
  {
    question: 'What are the advantages of using Digital Story Hub? ',
    answer: (
      <>
        <ul>
          <li>
            <strong>Customizable</strong>
          </li>
        </ul>
        <p>
          Flexible sorting controls, a variety of customizable components, and a sharable and
          linkable format that can be implemented across all different kinds of websites.&nbsp;{' '}
          <br /> &nbsp;
        </p>
        <ul>
          <li>
            <strong>Visually appealing</strong>
          </li>
        </ul>
        <p>
          Digital Story Hub helps you build content with just a few clicks, eliminating text-heavy
          content and engaging your audience through visually-rich, captivating images and videos.
        </p>
        <p>&nbsp;</p>
        <ul>
          <li>
            <strong>Improve your reach</strong>
          </li>
        </ul>
        <p>
          All stories created through Digital Story Hub can be shared across numerous sites without
          being restricted to a single ecosystem. The extremely fast loading time of the stories
          gives you the exciting ability to entertain your audience seamlessly, while also having
          the opportunity to review in-depth analytics of their engagement.{' '}
        </p>
      </>
    ),
  },
  {
    question: 'Starting today? ',
    answer: (
      <>
        {`Feel free to contact our support team through `}
        <a
          onClick={() => {
            // @ts-expect-error - Tawk_API is external plugin
            window.Tawk_API?.maximize();
          }}
        >
          chat support
        </a>
        {` or via `}
        <a href="mailto:info@digitalstoryhub.com">email</a>
        {` and our support team will swiftly help you proceed further.`}
      </>
    ),
  },
  {
    question: 'Will Digital Story Hub work for me? ',
    answer: (
      <>
        {`Digital Story Hub is supported across all operating systems and the most commonly used web browsers (Chrome, Firefox, Edge), as well as on mobile devices. `}
      </>
    ),
  },
  {
    question: 'What browsers are supported?',
    answer: (
      <>
        {`For an optimal Digital Story Hub experience, we recommend using the latest version of commonly popular web browsers (Chrome, Firefox, Edge).`}
      </>
    ),
  },
  // {
  //   question: 'How do I proceed after setting up my account?',
  //   answer: (
  //     <>
  //       {"Once you've successfully registered you need to "}
  //       <Link to="/login">Log In</Link>
  //       {' and navigate to the Main Dashboard.'} <br /> <br />
  //       <p>
  //         <strong>Create business structure & add Story Creator:</strong>
  //       </p>
  //       <ol>
  //         <li>Create a Company Business element through the Business Structure tab:</li>
  //         <img src={images.businessStructure} alt="business-structure" />
  //         <li>Through the same tab add a “Website” of your preference.</li>
  //         <li>Again, through the same tab add Story Creator as a Product.</li>
  //         <li>
  //           Once Story Creator is added, you can proceed with creating separate stories, to be later
  //           implemented on your website.
  //         </li>
  //       </ol>
  //       <p>
  //         <strong>Implement the Story Widget to your website:</strong>
  //       </p>
  //       <ol>
  //         <li>
  //           Download the{' '}
  //           <a href="/files/ads.txt" download>
  //             Ads.txt
  //           </a>{' '}
  //           file.
  //         </li>
  //         <li>Copy the plugin script through the &ldquo;Embed plugin&rdquo; button. </li>
  //         <li>Navigate to the root folder of your website and add the ads.txt file there. </li>
  //         <li>Go to the &lt;head&gt; of the website and add the copied plugin script. </li>
  //         <li>Check your website.</li>
  //       </ol>
  //       <p>
  //         <strong>Create a Story:</strong>
  //       </p>
  //       <ol>
  //         <li>Navigate to the Story Creator product.</li>
  //         <li>Click on Add Story.</li>
  //         <li>Customize it.</li>
  //       </ol>
  //       <p>
  //         Or you can contact us through our{' '}
  //         <a
  //           onClick={() => {
  //             // @ts-expect-error - Tawk_API is external plugin
  //             window.Tawk_API?.maximize();
  //           }}
  //         >
  //           chat support
  //         </a>{' '}
  //         and we&rsquo;ll walk you through creating your first story.
  //       </p>
  //     </>
  //   ),
  // },
  {
    question:
      'Are there any specific requirements, in order to use Digital Story Hub & Story Creator?',
    answer: (
      <p>
        There are a few quick steps standing between your story from appearing on your website:{' '}
        <br /> <br /> * insert the widget script in the &lt;head&gt; of the website
        <br /> * add the ads.txt file in the root folder of the website <br /> * the file can be
        accessed on domain.com/ads.txt
        <br /> <br /> Once all of the above are ensured, you can easily navigate through the steps
        for plugin implementation.
      </p>
    ),
  },
  {
    question: 'What is ads.txt and why/how should I add it?',
    answer: (
      <>
        {`Ads.txt is an IAB (Internet Advertising Bureau) initiative aimed at providing transparency for programmatic ad buyers. You can read more`}{' '}
        <a href="https://www.ads.plus/ads.txt">here</a>
        {`, otherwise, it’s practically a .txt file meant to be placed in the /root folder of your site and is mandatory for the free Digital Story Hub version to function properly.`}
      </>
    ),
  },
  {
    question: 'How long will my Story Creator story last?',
    answer: (
      <>
        {`Story Duration limits are custom set by the user; the allowed number of slides is between 1 to 10, with a sliding period range of 5 to 30 seconds.`}
      </>
    ),
  },
  {
    question: 'What are the best sizes to use for a story?',
    answer: (
      <>
        {`The most appropriate formats for your story are the following:`}
        <p>JPG;</p>
        <p>JPEG;</p>
        <p>PNG;</p>
        <p>MOV;</p>
        <p>MPEG;</p>
        <p>MP4;</p>
        {`The maximum size allowed is 2 MB for images and 20 MB for videos.
`}
      </>
    ),
  },
  {
    question: 'Can I create different stories for desktop/mobile visitors?',
    answer: (
      <>
        {`No, you cannot. However, we have a responsive web design that allows you to easily create the same results for the web as well as for mobile devices. The maximum size allowed is 2 MB for images and 20 MB for videos.
`}
      </>
    ),
  },
];
