import React, { forwardRef, useEffect, useState } from 'react';
import { BaseInputStyled } from './BaseInput.styled';
import Input, { InputProps } from 'antd/lib/input';
import { FieldError } from 'react-hook-form';
import { ReactComponent as InfoCircle } from 'assets/image/svg/info-circle.svg';
import { Text } from 'components/styles';
import NumberFormat, { NumberFormatProps } from 'react-number-format';
import BaseFloatingLabel from './BaseFloatingLabel';

type BaseInputStyledProps = InputProps & {
  inputTitle: boolean;
  Icon?: React.ReactNode;
  inputref?: React.MutableRefObject<Input | undefined>;
  error?: FieldError | undefined;
  formatfield?: boolean;
  label?: string;
  name?: string;
  setFormValue?(name: string, value: string): void;
  registrationPage?: boolean;
};
// eslint-disable-next-line react/display-name
const BaseInput = forwardRef(
  (
    {
      Icon,
      error,
      inputTitle,
      name,
      setFormValue,
      registrationPage,
      ...props
    }: BaseInputStyledProps,
    ref: React.ForwardedRef<any>,
  ): React.ReactElement => {
    const [value, setValue] = useState<string>();
    const [characterLeft, setCharacterLeft] = useState<number>(50);
    const showCharactersLeft =
      (props.disabled !== true && props.label === 'Company name') || props.label === 'Display name';

    useEffect(() => {
      if (showCharactersLeft) {
        if (typeof props.value === 'string') setValue(props.value);
      }
    }, [props.value]);

    const updateValue = (e: React.FormEvent<HTMLInputElement>) => {
      if (50 - e.currentTarget.value.length < 0) return;
      setCharacterLeft(50 - e.currentTarget.value.length);
      setValue(e.currentTarget.value);
      if (setFormValue) setFormValue(name!, e.currentTarget.value);
    };

    if (props.formatfield) {
      return (
        <BaseFloatingLabel label={props.label ?? ''} value={props.value}>
          <BaseInputStyled
            $error={!!error?.message}
            $inputTitle={inputTitle}
            className={'input-container'}
          >
            <NumberFormat
              {...(props as NumberFormatProps)}
              customInput={Input}
              // bordered={false}
              name={name}
              error={error?.message}
              ref={ref}
              prefix="+"
            />
            <div className={'input__input-error-container'}>
              <Text fontSize={12} className={'input-error__text'}>
                {error?.message}
              </Text>
              <InfoCircle />
            </div>
          </BaseInputStyled>
        </BaseFloatingLabel>
      );
    }

    return (
      <BaseFloatingLabel
        label={props.label ?? ''}
        value={value || props.value}
        characterCount={!registrationPage && showCharactersLeft}
      >
        <BaseInputStyled
          $error={!!error?.message}
          $inputTitle={inputTitle}
          className={'input-container'}
          $label={props.label}
          characterCount={!registrationPage && showCharactersLeft}
        >
          {value !== undefined ? (
            <Input
              // bordered={false}
              {...props}
              prefix={Icon ? Icon : undefined}
              ref={ref}
              value={value}
              onChange={updateValue}
            />
          ) : (
            <Input
              // bordered={false}
              {...props}
              prefix={Icon ? Icon : undefined}
              ref={ref}
            />
          )}
          <div className={'input__input-error-container'}>
            <Text fontSize={12} className={'input-error__text'}>
              {error?.message}
            </Text>
            <InfoCircle />
          </div>
        </BaseInputStyled>
        {showCharactersLeft && <p className="characterLeft">{characterLeft} characters left</p>}
      </BaseFloatingLabel>
    );
  },
);

export default BaseInput;
