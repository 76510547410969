import styled, { css } from 'styled-components';
import { svg } from 'assets/image';

export const WalkthroughArrowWrapperStyled = styled.div`
  ${(props) =>
    props.isActive
      ? css`
          z-index: 101;
          position: relative;

          ${(props) =>
            props.position === 'right'
              ? css`
                  &::before {
                    content: url(${svg.walkThroughArrow});
                    position: absolute;

                    bottom: -3em;
                    left: -3em;
                  }
                `
              : css`
                  &::before {
                    bottom: -3em;
                    right: -3em;
                    transform: rotate(-90deg);
                  }
                `}
        `
      : ''}

  @media only screen and (max-width: 1200px) {
    &::before {
      content: '' !important;
    }
  }
`;
