import { message, Row } from 'antd';
import { FormFieldsBuilder } from 'components';
import React, { useContext, useState } from 'react';
import * as Api from 'api';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { EditWebsiteForms } from './const';
import { FormPageTypeEnum } from 'utils/enums';
import { useForm } from 'react-hook-form';
import { WebsiteEditStyled } from './WebsiteEdit.styled';
import { ajax, editWebsite, useGetWebsite } from 'services';
import { routesPaths } from 'utils/helpers';
import { validateAxiosErrorInForms } from 'utils/helpers/functions';
import { useHistory, useParams } from 'react-router-dom';
import { SiderNavigationContext } from 'state/sider/navigation/siderNavigationContext';
import { BaseRightDrawerContext } from 'state/baseComponents/BaseRightDrawerContext';
import { StatisticPageCompanyContext } from 'state/productContainer/statisticPage/company/statisticPageCompanyContext';
import BaseSubmitButton from 'components/baseComponents/BaseSubmitButton';

const schema = Yup.object().shape({
  url: Yup.string().url().required('The field is required (min 3 char.)').min(3),
  name: Yup.string().required('The field is required (min 3 char.)').min(3),
  notes: Yup.string().optional(),
});

type WebsiteEditPropsType = {
  companySlug: string;
  websiteSlug: string;
};

type RouteParamsType = {
  companySlug: string;
  websiteSlug: string;
};

const WebsiteEdit = ({ companySlug, websiteSlug }: WebsiteEditPropsType): React.ReactElement => {
  const { data } = useGetWebsite(companySlug!, websiteSlug);
  const [loading, setLoading] = useState(false);
  const { onClose: closeDrawer } = useContext(BaseRightDrawerContext);
  const { range: rangeCompany, setCompany, company } = useContext(StatisticPageCompanyContext);
  const { siderActions } = useContext(SiderNavigationContext);
  const { handleSubmit, control, errors, watch, setValue, setError } = useForm<EditWebsiteFormType>(
    {
      resolver: yupResolver(schema),
      shouldFocusError: true,
      defaultValues: {
        url: data?.data.url,
        name: data?.data.name,
        notes: data?.data.notes ?? '',
      },
    },
  );

  // const { companySlug, websiteSlug } = useParams<RouteParamsType>();
  const { push } = useHistory();

  const onSubmit = async (formData: EditWebsiteFormType) => {
    try {
      setLoading(true);
      if (data == null) return;
      await editWebsite(data.data.company_slug, data?.data.slug, formData).then((res) => {
        push(
          routesPaths.toCompanyOrWebsiteStatisticBySlug(data.data.company_slug, res.data.data.slug),
        );
      });

      ajax.get(Api.endpoints.siderAllWebsites(data?.data.company_slug)).then((response) => {
        siderActions.setWebsites(response.data, data?.data.company_slug);
      });
      Api.getStatisticPageCompany({
        args: {
          companySlug: data?.data.company_slug,
          startDate: rangeCompany.range.start,
          endDate: rangeCompany.range.end,
        },
      }).then((res) => {
        setCompany({
          ...company,
          isLoading: false,
          data: res.data,
        });
      });
      message.success('Website updated successfully');
      closeDrawer();
    } catch (error) {
      validateAxiosErrorInForms(error, setError);
    } finally {
      setLoading(false);
    }
  };

  return (
    <WebsiteEditStyled onSubmit={handleSubmit(onSubmit)}>
      <FormFieldsBuilder<EditWebsiteFormType>
        fieldsData={EditWebsiteForms}
        control={control}
        setValue={setValue}
        watch={watch}
        errors={errors}
        page={FormPageTypeEnum.addCompany}
      />
      <Row align={'middle'} justify={'end'} className={'content__submit-container'}>
        <BaseSubmitButton
          title={'Save Website Details'}
          className={'btn--fill'}
          backgroundColor={'#4B4DC8'}
          style={{ marginTop: '12px' }}
          withArrow
        />
      </Row>
    </WebsiteEditStyled>
  );
};

export default WebsiteEdit;
