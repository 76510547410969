import { useEffect, useState, useMemo } from 'react';
import { endpoints, getCompaniesWithWebsites, moveUser, listUsers } from 'services';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { trigger } from 'swr';
import { message } from 'antd';
import { validateAxiosErrorInForms } from 'utils/helpers/functions';
import { useParams } from 'react-router-dom';
import { accordingRoles } from 'utils/helpers';

const schema = Yup.object().shape({
  company_slug: Yup.string().required('The field is required (min 3 char.)').min(3),
});

type ParamsType = {
  id: string;
};

const useUserMove = () => {
  const { data: companiesWithWebsite } = getCompaniesWithWebsites();
  const [companiesData, setCompaniesData] = useState<CompanyWithWebsite[]>([]);
  const { id } = useParams<ParamsType>();
  const [loading, setLoading] = useState(false);
  const [roles, setRoles] = useState<RolesTypes[]>([]);

  const { control, errors, watch, setValue, setError, handleSubmit } = useForm({
    resolver: yupResolver(schema),
    shouldFocusError: true,
    reValidateMode: 'onChange',
    mode: 'all',
    defaultValues: {
      company_id: '',
    },
  });

  const watchFields = watch(['company_id']);

  useEffect(() => {
    companiesWithWebsite?.data[0] &&
      setValue('company_id', companiesWithWebsite?.data[0].id.toString());
    setCompaniesData(companiesWithWebsite! && companiesWithWebsite.data!);
  }, [companiesWithWebsite, setValue]);

  useEffect(() => {
    if (companiesData?.length) {
      const chosenCompany = companiesData.find((c) => c.id == Number(watchFields.company_id));
      setRoles(accordingRoles(chosenCompany?.hasWebsites as number));
    }
  }, [watchFields, companiesData]);

  const getCompanies: SelectItemType[] = useMemo(
    () =>
      companiesData?.map(({ name, id }) => ({
        label: name,
        value: id.toString(),
      })) ?? [],
    [companiesData],
  );

  const getRoles: SelectItemType[] = useMemo(
    () =>
      roles.map(({ name, id }) => ({
        label: name,
        value: id.toString(),
      })) ?? [],
    [companiesData, watchFields],
  );

  const users = listUsers().data?.data.filter((user) => user.id !== Number(id));

  const getUsers: SelectItemType[] = useMemo(
    () =>
      users?.map(({ first_name, last_name, id }) => ({
        label: `${first_name} ${last_name}`,
        value: id.toString(),
      })) ?? [],
    [users],
  );

  const onSubmit = async ({ company_slug: enhiritedEntity }: MoveWebsiteFormType) => {
    try {
      setLoading(true);
      if (id) {
        await moveUser(Number(id), Number(enhiritedEntity));

        trigger(endpoints.getWebsites);
        trigger(endpoints.siderTree);
        message.success('User moved successfully');
      }
    } catch (error) {
      validateAxiosErrorInForms(error, setError);
    } finally {
      setLoading(false);
    }
  };

  return {
    onSubmit,
    control,
    errors,
    watch,
    setValue,
    loading,
    handleSubmit,
    getCompanies,
    getRoles,
    getUsers,
  };
};

export default useUserMove;
