import styled from 'styled-components';

export const WebsiteMoveStyled = styled.form`
  background-color: transparent;
  flex: 1;
  width: 100%;
  padding-bottom: 2rem;
  display: flex;
  flex-direction: column;
  textarea {
    margin: 0.5rem 0;
  }
  .content__form-container {
    padding: 0 2.5rem;
    & > div:last-child {
      border-bottom: 0 !important;
    }
  }
  .content__submit-container {
    padding-top: 1rem;
    margin-top: auto;
    p {
      text-transform: uppercase;
      opacity: 0.5;
      transition: all 0.2s;
      &:hover {
        opacity: 1;
      }
    }
    .btn {
      height: 4rem;
      border-radius: 2rem;
      width: 20rem;
      margin-left: 1rem;
    }
  }
  @media only screen and (max-width: 1370px) {
    h2 {
      padding: 0 2.5rem;
      padding-bottom: 0.5rem;
    }
    .content__submit-container {
      .btn {
        height: 4rem;
      }
    }
  }
  @media only screen and (max-width: 680px) {
    .content__form-container {
      .ant-row {
        flex-direction: column;
        p {
          font-size: 1.5rem;
        }
        .ant-col {
          margin: 1rem 0rem;
          width: 100%;
          min-width: 100%;
          .ant-row {
            width: 100%;
          }
        }
      }
    }
    .content__submit-container {
      flex-direction: column-reverse;
      button {
        margin: 0.5rem 0rem;
      }
    }
  }
`;
