import { Divider, Skeleton } from 'antd';
import { ArrowIcon, InfoIcon } from 'components/svg';
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { SiderNavigationContext } from 'state/sider/navigation/siderNavigationContext';
import WebsiteBorder from 'assets/image/svg/sider-website-border.svg';
import Products from './Products';

interface Props {
  readonly company: ICompany;
  readonly currentUrl: string;
  readonly websiteOnClick: (company: ICompany, website: IWebsite) => void;
  readonly productOnClick: (company: ICompany, website: IWebsite, product: IProduct) => void;
}

const Websites = ({ company, currentUrl, websiteOnClick, productOnClick }: Props) => {
  const history = useHistory();
  const { siderState, openWebsiteDropdownSlugs } = useContext(SiderNavigationContext);

  return (
    <>
      {siderState.websites
        .filter((item) => item.companySlug === company.slug)
        .map((website) =>
          website.data.length !== 0 ? (
            website.data.map((site) => (
              <React.Fragment key={site.slug}>
                <div className="website">
                  <img src={WebsiteBorder} alt="border" />
                  <p
                    style={
                      history.location.pathname.includes(site.slug) ||
                      currentUrl.includes(site.slug)
                        ? { color: '#4B4DC8' }
                        : undefined
                    }
                    onClick={() => websiteOnClick(company, site)}
                  >
                    {site.name}
                  </p>
                  <div>
                    <ArrowIcon
                      width="11px"
                      height="6px"
                      fill={
                        history.location.pathname.includes(site.slug) ||
                        currentUrl.includes(site.slug)
                          ? '#4B4DC8'
                          : '#181818'
                      }
                      style={
                        openWebsiteDropdownSlugs.includes(site.slug)
                          ? { cursor: 'pointer' }
                          : { transform: 'rotate(90deg)', cursor: 'pointer' }
                      }
                      onClick={() => websiteOnClick(company, site)}
                    />
                  </div>
                </div>
                {openWebsiteDropdownSlugs.includes(site.slug) &&
                  (!siderState.products.find((item) => item.websiteSlug === site.slug) ? (
                    <div className="product-skeleton">
                      <Skeleton active paragraph={{ rows: 1, width: '100%' }} title={false} />
                    </div>
                  ) : (
                    <Products
                      currentUrl={currentUrl}
                      siderState={siderState}
                      site={site}
                      company={company}
                      productOnClick={productOnClick}
                    />
                  ))}
              </React.Fragment>
            ))
          ) : (
            <p className="not-found">
              No websites found
              <InfoIcon
                width="14"
                height="14"
                title={'You have not added any websites for this company'}
                wrapperStyle={{ marginBottom: '-2px' }}
              />
            </p>
          ),
        )}
      <Divider style={{ color: '#808E9B', height: '1px', marginBottom: '30px' }} />
    </>
  );
};

export default Websites;
