import React, { useContext } from 'react';
import { ConfigHeaderStyled } from './ConfigHeader.styled';
import { Switch, Col, Breadcrumb } from 'antd';
import AddButtonsMobile from 'components/baseComponents/AddButtonsMobile';
import DisableUser from 'views/user/components/DisableUser';
import RestoreUser from 'views/user/components/RestoreUser';
import { useParams } from 'react-router-dom';
import { BaseRightDrawerContext } from 'state/baseComponents/BaseRightDrawerContext';
import SiderMobile from 'components/sider/SiderMobile';

type RouteParamsType = {
  id: string;
  companySlug: string;
};

type Props = {
  id: number;
  name: string;
  isActive: boolean;
};
const ConfigHeader = ({ isActive, name, id }: Props) => {
  const { companySlug } = useParams<RouteParamsType>();
  const { showDrawer } = useContext(BaseRightDrawerContext);

  return (
    <ConfigHeaderStyled>
      <div className="headerWrapper">
        <div className="titleWrapper">
          <Breadcrumb.Item separator="|">
            <div>
              <h1>User Management</h1>
            </div>
          </Breadcrumb.Item>
        </div>

        <div className="switcherWrapper">
          <span className="switch-label">{isActive ? 'Deactivate' : 'Activate'}</span>

          <Switch
            className={'switch'}
            checked={isActive}
            onClick={() => {
              isActive
                ? showDrawer({
                    _content: <DisableUser id={id} companySlug={companySlug} />,
                    _title: 'Disable User',
                    _titleColor: '#EE5253',
                  })
                : showDrawer({
                    _content: companySlug && (
                      <RestoreUser id={id} companySlug={companySlug} name={name} />
                    ),
                    _title: 'Restore User',
                    _titleColor: '#01AE92',
                  });
            }}
            style={{ marginLeft: '1rem' }}
          />
        </div>
      </div>

      <div className="mobileBtnWrapper">
        <AddButtonsMobile />
      </div>

      <div className="mobileMenuWrapper">
        <SiderMobile />
      </div>
    </ConfigHeaderStyled>
  );
};

export default ConfigHeader;
