import styled from 'styled-components';

export const AddButtonsMobileStyled = styled.div`
  display: none;
  align-items: flex-end;

  button {
    flex-direction: row;
    justify-content: center;
    width: 125px;
    height: 50px;
    padding: 5px;
    margin-bottom: initial;

    &:nth-child(2) {
      margin-left: 10px;
    }

    svg {
      margin: 0;
      height: 30px;
      width: 30px;
      margin-right: 5px;
    }
  }

  /* @media screen and (max-width: 768px) {
    button {
      width: 50% !important;
    }
  } */

  @media (max-width: 1200px) {
    display: flex;
  }

  @media (max-width: 576px) {
    padding: 20px 0px;
    width: 100%;
    justify-content: center;
    button {
      width: 137px;
    }
  }

  @media only screen and (min-width: 576px) and (max-width: 700px) {
    button {
      width: 90px;
    }
  }

  @media only screen and (max-width: 400px) {
    /* button {
      width: 50%;
    } */
  }
`;

export const AddButtonsMobileDrawerStyled = styled.div`
  display: flex;
  flex-direction: column;

  button {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;

    svg {
      height: 30px;
      width: 30px;
      margin: 0;
    }

    p {
      width: 100%;
      text-align: center;
      font-size: 18px;
      font-family: Noir Pro Medium;
    }
  }
`;

export const UsersMobileDrawerStyled = styled.div`
  display: flex;
  flex-direction: column;

  button {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;

    svg {
      height: 30px;
      width: 30px;
      margin: 0;
    }

    p {
      width: 100%;
      text-align: center;
      font-size: 18px;
      font-family: Noir Pro Medium;
    }
  }
`;
