import { StatisticPageCompany } from 'api/statisticPage/company/types';
import { StatisticPageWebsite } from 'api/statisticPage/website/types';
import { useMemo, useState } from 'react';

export enum KeyType {
  id = 'id',
  Spot = 'name',
  Impressions = 'totalImpressions',
  Clicks = 'totalClicks',
  ClickRate = 'clickRatePercentage',
}

export type ConfigKey = 'id' | 'totalImpressions' | 'totalClicks' | 'name' | 'clickRatePercentage';

export enum TableHeadEnum {
  Id = 'id',
  Spot = 'Spot',
  Impressions = 'Impressions',
  Clicks = 'Clicks',
  ClickRate = 'ClickRate',
}

const useSortStatisticPage = (data: StatisticPageWebsite | StatisticPageCompany | undefined) => {
  const [config, setConfig] = useState<null | {
    key: ConfigKey;
    type: TableHeadEnum;
    direction: string;
  }>(null);

  const sortedData = useMemo(() => {
    if (config != null && data != null) {
      data.data.products != null &&
        data?.data.products.sort((a, b) =>
          config.direction === 'ascending'
            ? a[config.key].toString().localeCompare(b[config.key].toString())
            : b[config.key].toString().localeCompare(a[config.key].toString()),
        );
      data.data.websites != null &&
        data?.data.websites.sort((a, b) =>
          config.direction === 'ascending'
            ? a[config.key].toString().localeCompare(b[config.key].toString())
            : b[config.key].toString().localeCompare(a[config.key].toString()),
        );
    }
    return data;
  }, [data, config]);

  const sort = (key: TableHeadEnum) => {
    let direction = 'ascending';
    config && config.direction === 'ascending' && (direction = 'descending');
    switch (key) {
      case TableHeadEnum.Spot:
        setConfig({ key: 'name', type: TableHeadEnum.Spot, direction });
        break;
      case TableHeadEnum.Impressions:
        setConfig({ key: 'totalImpressions', type: TableHeadEnum.Impressions, direction });
        break;
      case TableHeadEnum.Clicks:
        setConfig({ key: 'totalClicks', type: TableHeadEnum.Clicks, direction });
        break;
      case TableHeadEnum.ClickRate:
        setConfig({ key: 'clickRatePercentage', type: TableHeadEnum.ClickRate, direction });
        break;
      default:
        setConfig({ key: 'id', type: TableHeadEnum.Id, direction });
        break;
    }
  };

  return { items: sortedData, sort, config, KeyType };
};

export default useSortStatisticPage;
