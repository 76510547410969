import axios, { AxiosError } from 'axios';
import { logOut } from 'utils/helpers/functions';
import { getJWTFromLocalStorage } from 'utils/helpers/functions';

const ajax = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  timeout: 10000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
});

ajax.interceptors.request.use(
  function (config) {
    return config;
  },
  function (error) {
    return Promise.reject(error);
  },
);

ajax.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error: AxiosError) {
    if (error.response?.status === 401) {
      const token = getJWTFromLocalStorage();

      if (token) {
        logOut();
        location.replace('/login');
      }

      // window.history.replaceState({}, '', '/login');
    }
    return Promise.reject(error);
  },
);

export default ajax;
