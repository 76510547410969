export const archiveWebsiteItems = [
  {
    id: 'as2df',
    email: 'asdf',
    product: 5,
    slide: 4,
    website: 'asdfasfd',
  },
  {
    id: 'as2awddf',
    email: 'asdf',
    website: '234234',
    product: 5,
    slide: 4,
  },
  {
    id: 'asawds2df',
    email: 'asdf',
    website: 6,
    product: 0,
    slide: 0,
  },
];
