import styled from 'styled-components';

export const AddNewEntityStyled = styled.form`
  background-color: transparent;
  flex: 1;
  width: 100%;
  h2 {
    padding: 0 2.5rem;
    padding-bottom: 1rem;
  }
  .content__form-container {
    padding: 0 2.5rem;

    & > div:last-child {
      border-bottom: 0 !important;
    }
  }
  .content__submit-container {
    padding: 0 2.5rem;
    .btn {
      height: 4rem;
      border-radius: 2rem;
      width: 20rem;
      margin-left: 20%;
    }
  }
  @media only screen and (max-width: 1370px) {
    h2 {
      padding: 0 2.5rem;
      padding-bottom: 0.5rem;
    }

    .content__submit-container {
      .btn {
        height: 4rem;
      }
    }
  }
  @media only screen and (max-width: 550px) {
    .Flex-sc-1whurw7-0 {
      flex-direction: column;
    }
  }
`;
