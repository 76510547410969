import React from 'react';

import { SettingPageStyled } from './SettingPage.styled';
import { Row } from 'antd';
import { FormFieldsBuilder } from 'components';
import useSettingsPage from '../../../../state/productContainer/settingPage/useSettingPage';
import BaseSubmitButton from 'components/baseComponents/BaseSubmitButton';

const SettingPage = () => {
  const {
    productSlideSettings,
    fieldData,
    setValue,
    errors,
    watch,
    control,
    onSubmit,
    handleSubmit,
  } = useSettingsPage();

  return (
    <SettingPageStyled>
      {productSlideSettings ? (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={'scrollable'}>
            <FormFieldsBuilder<ProductSlideSettingsFormType>
              fieldsData={fieldData}
              control={control}
              setValue={setValue}
              watch={watch}
              errors={errors}
            />
          </div>
          <Row className={'form-submit-buttons'} justify={'end'} align={'middle'}>
            <BaseSubmitButton
              title={'Save Changes'}
              className={'btn--fill'}
              backgroundColor={'#5B59C2'}
              style={{ marginTop: '12px' }}
              withArrow
            />
          </Row>
        </form>
      ) : null}
    </SettingPageStyled>
  );
};

export default SettingPage;
