import styled from 'styled-components';

export const RightSideNavigationStyled = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 30px;
  border-left: 1px solid #eaecf2;
  width: 100%;
  height: 100%;

  .user-navigation-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-top: 1px solid #eaecf2;
    margin-bottom: 65px;

    button {
      margin-top: 20px;
      width: 100%;

      img {
        max-width: 100%;
      }
    }
  }
`;
