import React, { useContext, useEffect, useMemo } from 'react';
import { Route, RouteProps, useHistory } from 'react-router-dom';
import { AppContext } from 'state/appContext';
import { getLoggedUserInfo } from 'services';
import { appActions } from '../../state/appReducer';
import { setUser } from 'utils/helpers/functions';
import { StarterContainerProvider } from 'state/starterContainer/starterContainerContext';
import { WalkthroughProvider } from 'state/walkthrough/walkthroughContext';

const PrivateRoute = (props: RouteProps) => {
  const { state, dispatch } = useContext(AppContext);
  const { replace } = useHistory();

  const { data: userObj } = getLoggedUserInfo();

  useEffect(() => {
    dispatch(appActions.setUser(userObj?.data));
    setUser(userObj?.data);
  }, [userObj]);

  // dispatch(appActions.setUser(userObj));
  return useMemo(() => {
    if (!state.appReady) return <></>;
    if (!state.hasToken) {
      replace('/login');
      return <></>;
    }
    if (state.user && state.appReady && !state.hasToken) {
      replace('/login');
      return <></>;
    }

    return (
      <StarterContainerProvider>
        <WalkthroughProvider>
          <Route {...props} />
        </WalkthroughProvider>
      </StarterContainerProvider>
    );
  }, [state.appReady, state.user, state.hasToken, userObj, props]);
};

export default PrivateRoute;
