import { Moment } from 'moment';

export const endpoints = {
  siderAllCompanies: 'api/v1/ux/tree/companies',
  siderAllWebsites: (companySlug: ICompany['slug']) =>
    `api/v1/ux/tree/companies/${companySlug}/websites`,
  siderAllProducts: (companySlug: ICompany['slug'], websiteSlug: IWebsite['slug']) =>
    `api/v1/ux/tree/companies/${companySlug}/websites/${websiteSlug}/products`,
  statisticPageWebsite: (
    companySlug: ICompany['slug'],
    websiteSlug: IWebsite['slug'],
    startDate: string | Moment,
    endDate: string | Moment,
  ) =>
    `api/v1/companies/${companySlug}/websites/${websiteSlug}/stat?startDate=${startDate}&endDate=${endDate}`,
  statisticPageCompany: (
    companySlug: ICompany['slug'],
    startDate: string | Moment,
    endDate: string | Moment,
  ) => `api/v1/companies/${companySlug}/stat?startDate=${startDate}&endDate=${endDate}`,
  storylineStats: 'api/v1/companies/storylineStats',
  storylines: '/api/v1/companies/storylines',
  userStats: '/api/v1/users/stats',
  searchCompanies: (searchValue: string) => `/api/v1/companies/search/${searchValue}`,
};
