import React, { useContext } from 'react';
import { Drawer } from 'antd';
import { BaseRightDrawerContext } from 'state/baseComponents/BaseRightDrawerContext';
import { BaseRightDrawerStyled } from './BaseRightDrawer.styled';
import { ReactComponent as CloseIcon } from 'assets/image/svg/close.svg';

const BaseRightDrawer = () => {
  const { visible, onClose, content, title, titleColor, className } =
    useContext(BaseRightDrawerContext);
  return (
    <BaseRightDrawerStyled>
      <Drawer
        title={
          <span style={titleColor ? { color: titleColor, font: 'inherit' } : { font: 'inherit' }}>
            {title}
          </span>
        }
        placement="right"
        onClose={onClose}
        visible={visible}
        getContainer={false}
        closeIcon={<CloseIcon />}
        className={className}
      >
        {content}
        <div className="drawer-walkthrough-overlay"></div>
      </Drawer>
    </BaseRightDrawerStyled>
  );
};

export default BaseRightDrawer;
