import React, { useState, useContext } from 'react';
import { DashboardHeaderStyled } from './DashboardHeader.styled';
import { svg } from 'assets/image';
import { Link } from 'react-router-dom';
import { BaseRightDrawerContext } from 'state/baseComponents/BaseRightDrawerContext';
import { SearchContainerContext } from 'state/searchContainer/SearchContainerContext';
import { AppContext } from 'state/appContext';
import NavigationMenu from './NavigationMenu';
import { Skeleton } from 'antd';

export default function DashboardHeader() {
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const { showDrawer } = useContext(BaseRightDrawerContext);
  const {
    state: { user },
  } = useContext(AppContext);

  const { setSearchOverlay } = useContext(SearchContainerContext);

  const handleOverlay = () => {
    setSearchOverlay(true);
  };

  return (
    <DashboardHeaderStyled>
      {!isSearchOpen && (
        <Link className="header-logo" to="/home">
          <img src={svg.logo} alt="logo" />
        </Link>
      )}
      <div className="header-right-side">
        {isSearchOpen && (
          <div className="search-mobile-container">
            <input className="search" type="text" placeholder="Search" onFocus={handleOverlay} />
            <button onClick={() => setIsSearchOpen(false)}>x</button>
          </div>
        )}
        <input className="search" type="text" placeholder="Search" onFocus={handleOverlay} />

        {!isSearchOpen && (
          <button onClick={() => setIsSearchOpen(true)} className="search-btn">
            <img className="burger-icon" src={svg.searchIcon} alt="search" />
          </button>
        )}

        <button
          className="burger-btn"
          onClick={() => {
            showDrawer({ _content: <NavigationMenu />, _title: 'Menu' });
          }}
        >
          <img className="burger-icon" src={svg.burgerIcon} alt="burger-menu" />

          {user ? (
            <img className="profile-image" src={user.avatar} alt="profile-image" />
          ) : (
            <Skeleton.Button style={{ width: '50px', height: '50px' }} active />
          )}
        </button>
      </div>
    </DashboardHeaderStyled>
  );
}
